import { GLOBAL_STYLES } from "./GLOBAL_STYLE"
import { type TableStyles } from "react-data-table-component"

export const customStylesContext = (
  theme: string,
  backgroud = GLOBAL_STYLES.BACKGROUND.TYPE_5,
  heightRow = "40px"
): TableStyles => {
  return {
    table: {
      style: {
        width: "100%",
        fontSize: "0.8rem !important",
        borderRadius: "0px !important",
        backgroundColor: GLOBAL_STYLES.BACKGROUND.TYPE_2[theme]
      }
    },
    head: {
      style: {
        color: "red",
        fontSize: "12px",
        overflow: "visible !important",
        fontWeight: 500
      }
    },
    pagination: {
      style: {
        color: GLOBAL_STYLES.LABEL.TYPE_2[theme],
        fontSize: "0.9rem",
        minHeight: "56px",
        backgroundColor: GLOBAL_STYLES.BACKGROUND.TYPE_2[theme],
        borderTop: GLOBAL_STYLES.BORDER.TYPE_2[theme],
        borderRadius: "0 0 5px 5px !important"
      },
      pageButtonsStyle: {
        borderRadius: "50%",
        height: "40px",
        width: "40px",
        padding: "8px",
        margin: "px",
        cursor: "pointer",
        transition: "0.4s",
        fill: theme === "DARK" ? "#FFF" : "#000",
        backgroundColor: "transparent",
        "&:disabled": {
          cursor: "unset",
          color: "grey",
          fill: "grey"
        },
        "&:hover:not(:disabled)": {
          backgroundColor: GLOBAL_STYLES.BACKGROUND.TYPE_1[theme]
        },
        "&:focus": {
          outline: "none",
          backgroundColor: "transparent"
        }
      }
    },
    headRow: {
      style: {
        overflow: "visible !important",
        border: "none",
        borderTop: GLOBAL_STYLES.BORDER.TYPE_2[theme],
        backgroundColor: GLOBAL_STYLES.BACKGROUND.TYPE_2[theme]
      }
    },

    rows: {
      style: {
        height: heightRow,
        border: "none !important",
        overflow: "visible !important",
        fontSize: "0.8rem",
        borderTop: `${GLOBAL_STYLES.BORDER.TYPE_2[theme]} !important`,
        backgroundColor: GLOBAL_STYLES.BACKGROUND.TYPE_2[theme],
        color: GLOBAL_STYLES.LABEL.TYPE_1[theme]
      },
      stripedStyle: {
        color: GLOBAL_STYLES.LABEL.TYPE_1[theme],
        backgroundColor: `${backgroud[theme]} !important`
      }
    },
    // expanderRow: {
    //   style: {
    //     color: "blue",
    //     backgroundColor: "red"
    //   }
    // },
    // expanderCell: {
    //   style: {
    //     flex: "0 0 48px"
    //   }
    // },
    expanderButton: {
      style: {
        color: GLOBAL_STYLES.LABEL.TYPE_2[theme],
        // fill: "yellow",
        backgroundColor: "transparent",
        borderRadius: "2px",
        transition: "0.25s",
        height: "100%",
        width: "100%",
        "&:hover:enabled": {
          cursor: "pointer"
        },
        "&:disabled": {
          color: "grey",
          cursor: "no-drop"
        },
        "&:hover:not(:disabled)": {
          cursor: "pointer",
          backgroundColor: GLOBAL_STYLES.BACKGROUND.TYPE_1[theme]
        },
        svg: {
          margin: "auto"
        }
      }
    },
    headCells: {
      style: {
        overflow: "visible !important",
        backgroundColor: GLOBAL_STYLES.BACKGROUND.TYPE_2[theme],
        paddingLeft: "8px",
        paddingRight: "8px",
        color: GLOBAL_STYLES.LABEL.TYPE_1[theme],
        fontWeight: 900,
        justifyContent: "center"
      }
    },
    cells: {
      style: {}
    }
  }
}
