import styled from "styled-components"
import { type IStyle } from "../../interfaces/IStyle"

export const Container = styled.div<IStyle>`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  background-color: ${({ background }) => background};
  z-index: 999999999;
`

export const Content = styled.div<IStyle>`
  position: relative;
  background-color: transparent;
  width: ${({ width }) => width};
  height: ${({ width }) => width};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ContainerLoader = styled.div<IStyle>`
  position: absolute;
  width: ${({ width }) => `${width}%`};
  height: ${({ width }) => `${width}%`};
  border-radius: 50%;
  border: 2px solid ${({ color }) => color};
  border-top-color: ${({ borderTop }) => borderTop};
  border-right-color: ${({ borderRight }) => borderRight};
  border-bottom-color: ${({ borderBottom }) => borderBottom};
  animation: spin ${({ time }) => time}s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`
