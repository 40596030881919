// SAGAS
import { put, takeLatest, call } from "redux-saga/effects"
import { type SagaIterator } from "redux-saga"
import * as types from "../types"

import {
  getCommoditiesFailure,
  getCommoditiesSuccess,
  postExportSuccess,
  postExportFailure,
  postQuoterSuccess,
  postQuoterFailure,
  getProccessInfoSuccess,
  getProccessInfoFailure,
  getInProccessSuccess,
  getInProccessFailure
} from "../actions"

import { postExport, getCommodities, postQuoter, getProccessInfo, getInProccess } from "../services"

function* getCommoditiesWorker(): SagaIterator {
  try {
    const { data } = yield call(getCommodities)
    yield put(getCommoditiesSuccess(data))
  } catch (error) {
    yield put(getCommoditiesFailure(error))
  }
}

function* getOroccessInfoWorker(): SagaIterator {
  try {
    const { data } = yield call(getProccessInfo)
    yield put(getProccessInfoSuccess(data))
  } catch (error) {
    yield put(getProccessInfoFailure(error))
  }
}

function* getInProccessInfoWorker(): SagaIterator {
  try {
    const { data } = yield call(getInProccess)
    yield put(getInProccessSuccess(data))
  } catch (error) {
    yield put(getInProccessFailure(error))
  }
}

function* postExportWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(postExport, request.payload)
    yield put(postExportSuccess(data))
  } catch (error) {
    yield put(postExportFailure(error))
  }
}

function* postQuoterWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(postQuoter, request.payload)
    yield put(postQuoterSuccess(data))
  } catch (error) {
    yield put(postQuoterFailure(error))
  }
}

export function* listWatcher(): SagaIterator {
  yield takeLatest(types.COMMODITIES_GET_REQUEST, getCommoditiesWorker)
  yield takeLatest(types.EXPORT_POST_REQUEST, postExportWorker)
  yield takeLatest(types.QUOTER_POST_REQUEST, postQuoterWorker)
  yield takeLatest(types.PROCCESS_INFO_GET_REQUEST, getOroccessInfoWorker)
  yield takeLatest(types.IN_PROCCESS_GET_REQUEST, getInProccessInfoWorker)
}

export const sagas = [listWatcher]
