/* eslint-disable @typescript-eslint/strict-boolean-expressions */
export const formattedNumber = (valNumber): string => {
  const newValue = Number(valNumber?.toString()?.replace(/\D/g, ""))?.toString()
  if (!valNumber || valNumber.length === 0 || Number(newValue) === 0) return ""
  const decimals = newValue.length > 1 ? newValue.substring(newValue.length - 2, newValue.length) : `0${newValue}`
  const thousands =
    newValue.length > 2
      ? new Intl.NumberFormat("es-CL").format(Number(newValue.substring(0, newValue.length - 2))).replaceAll(",", ".")
      : "0"

  return `${thousands},${decimals}`
}

const thousandsDot = (num): string => {
  const newValue = Number(num?.toString()?.replace(/\D/g, ""))?.toString()
  return new Intl.NumberFormat("es-CL").format(Number(newValue)).replaceAll(",", ".")
}

export const formatNumberToTable = (num): string => {
  if (num.length === 0) return ""
  const parts = num.toString().replaceAll(".", "").toString().split(",")
  if (parts.length === 1 || parts[1].length === 0) return `${num.toString().replaceAll(".", "").replace(",", "")},00`
  else if (parts[1].length === 1) return `${thousandsDot(parts[0])},${parts[1]}0`
  return `${thousandsDot(parts[0])},${parts[1].substring(0, 2)}`
}

export const formattedNumberTable = (valNumber): string => {
  const newValue = Number(valNumber?.toString()?.replace(/\D/g, ""))?.toString()
  if (!valNumber || valNumber.length === 0 || Number(newValue) === 0) return ""
  const decimals = newValue.length > 1 ? newValue.substring(newValue.length - 2, newValue.length) : `0${newValue}`
  const thousands =
    newValue.length > 2
      ? new Intl.NumberFormat("es-CL").format(Number(newValue.substring(0, newValue.length - 2))).replaceAll(",", ".")
      : "0"

  return `${thousands},${decimals}`
}

export const formatNumberToCalc = (valNumber): number => {
  const formatNum = formattedNumber(
    valNumber ? Number(valNumber.toString().replaceAll(".", "").replaceAll(",", ".")).toFixed(2) : valNumber
  )
  return Number(formatNum.replaceAll(".", "").replaceAll(",", "."))
}
