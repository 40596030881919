import { TRANSLATION_INDEX_GLOBAL, TRANSLATION_INDEX_RESPONSE_CODE } from "../config/translation"
import { type IOption } from "../interfaces/IOption"
import * as Yup from "yup"

export const INPUT_TYPE = {
  TEXT: "text",
  NUMBER: "number",
  EMAIL: "email",
  CHECKBOX: "checkbox",
  RADIO: "radio",
  PASSWORD: "password"
}

export const LANGUAGE = {
  EN: {
    VALUE: "en",
    LABEL: "English",
    FLAG: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Flag_of_Great_Britain_%28English_version%29.png/640px-Flag_of_Great_Britain_%28English_version%29.png"
  },
  ES: {
    VALUE: "es",
    LABEL: "Español",
    FLAG: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/71/Flag_of_the_First_Spanish_Republic.svg/220px-Flag_of_the_First_Spanish_Republic.svg.png"
  }
}

export const OPTION_STATE = {
  ACTIVO: 1,
  INACTIVO: -1,
  PENDING: 2
}

export const STATES_YES_NO: IOption[] = [
  {
    value: null,
    label: TRANSLATION_INDEX_GLOBAL.SELECT_OPTION
  },
  {
    value: false,
    label: "NO"
  },
  {
    value: true,
    label: TRANSLATION_INDEX_GLOBAL.YES
  }
]

export const STATES_CLIENT_ADMIN: IOption[] = [
  {
    value: true,
    label: TRANSLATION_INDEX_GLOBAL.YES
  },
  {
    value: false,
    label: "NO"
  }
]

export const STATES: IOption[] = [
  {
    value: null,
    label: TRANSLATION_INDEX_GLOBAL.SELECT_OPTION
  },
  {
    value: 1,
    label: TRANSLATION_INDEX_GLOBAL.ACTIVE
  },
  {
    value: 2,
    label: TRANSLATION_INDEX_GLOBAL.AUTHORIZATION_PENDING
  },
  {
    value: -1,
    label: TRANSLATION_INDEX_GLOBAL.INACTIVE
  }
]

export const ACTIONS = {
  ADD: {
    VALUE: "add",
    LABEL: TRANSLATION_INDEX_GLOBAL.ADD,
    SUCCESS: TRANSLATION_INDEX_GLOBAL.ADDED
  },
  APPROVE: {
    VALUE: "approve",
    LABEL: TRANSLATION_INDEX_GLOBAL.APPROVE,
    SUCCESS: TRANSLATION_INDEX_GLOBAL.SUCCESS_UPDATE
  },
  DELETE: {
    VALUE: "delete",
    LABEL: TRANSLATION_INDEX_GLOBAL.DELETE,
    SUCCESS: TRANSLATION_INDEX_GLOBAL.DELETED
  },
  EDIT: {
    VALUE: "edit",
    LABEL: TRANSLATION_INDEX_GLOBAL.EDIT,
    SUCCESS: TRANSLATION_INDEX_GLOBAL.SUCCESS_UPDATE
  },
  EXPORT: {
    VALUE: "export",
    LABEL: TRANSLATION_INDEX_GLOBAL.EXPORT,
    SUCCESS: TRANSLATION_INDEX_GLOBAL.EXPORT
  },
  REJECT: {
    VALUE: "reject",
    LABEL: TRANSLATION_INDEX_GLOBAL.REJECT,
    SUCCESS: TRANSLATION_INDEX_GLOBAL.SUCCESS_UPDATE
  },
  VALIDATE: {
    VALUE: "validate",
    LABEL: TRANSLATION_INDEX_GLOBAL.VALIDATE,
    SUCCESS: TRANSLATION_INDEX_GLOBAL.SUCCESS_UPDATE
  },
  VIEW: {
    VALUE: "view",
    LABEL: TRANSLATION_INDEX_GLOBAL.VIEW,
    SUCCESS: TRANSLATION_INDEX_GLOBAL.ADDED
  }
}

export const PERMISSION = {
  USUARIO_BACKOFFICE_CONSULTAR: "USUARIO_BACKOFFICE_CONSULTAR",
  USUARIO_BACKOFFICE_MODIFICAR: "USUARIO_BACKOFFICE_MODIFICAR",
  USUARIO_BACKOFFICE_CREAR: "USUARIO_BACKOFFICE_CREAR",
  USUARIO_APP_CONSULTAR: "USUARIO_APP_CONSULTAR",
  USUARIO_APP_MODIFICAR: "USUARIO_APP_MODIFICAR",
  PERFIL_CONSULTAR: "PERFIL_CONSULTAR",
  PERFIL_CREAR: "PERFIL_CREAR",
  PERFIL_MODIFICAR: "PERFIL_MODIFICAR",
  PERFIL_ELIMINAR: "PERFIL_ELIMINAR",
  PERMISO_PERFIL_CONSULTAR: "PERMISO_PERFIL_CONSULTAR",
  CALIFICACIONES_CONSULTAR: "CALIFICACIONES_CONSULTAR",
  PERMISO_PERFIL_MODIFICAR: "PERMISO_PERFIL_MODIFICAR",
  AUT_INFOCLIENTES_CONSULTAR: "AUT_INFOCLIENTES_CONSULTAR",
  AUT_INFOCLIENTES_MODIFICAR: "AUT_INFOCLIENTES_MODIFICAR",
  COTIZADOR_CONSULTAR: "COTIZADOR_CONSULTAR",
  REFERENCIA_CLIENTE_CONSULTAR: "REFERENCIA_CLIENTE_CONSULTAR",
  PREFERENCIAS_CONSULTAR: "PREFERENCIAS_CONSULTAR",
  PREFERENCIAS_MODIFICAR: "PREFERENCIAS_MODIFICAR",
  CLIENTE_CONSULTAR: "CLIENTE_CONSULTAR",
  CLIENTE_CONSULTAR_OTROS_USUARIOS: "CLIENTE_CONSULTAR_OTROS_USUARIOS",
  COTIZACIONES_ENVIAR_OTROS_CLIENTES: "COTIZACIONES_ENVIAR_OTROS_CLIENTES",
  COTIZACIONES_ENVIAR: "COTIZACIONES_ENVIAR",
  CLIENTE_CREAR: "CLIENTE_CREAR",
  CLIENTE_MODIFICAR: "CLIENTE_MODIFICAR",
  CLIENTE_ELIMINAR: "CLIENTE_ELIMINAR",
  CLIENTE_AGREGAR_ASOCIADOS: "CLIENTE_AGREGAR_ASOCIADOS",
  GASTOS_ORIGEN_CONSULTAR: "GASTOS_ORIGEN_CONSULTAR",
  GASTOS_ORIGEN_MODIFICAR: "GASTOS_ORIGEN_MODIFICAR",
  GASTOS_ORIGEN_ELIMINAR: "GASTOS_ORIGEN_ELIMINAR",
  GASTOS_ORIGEN_CREAR: "GASTOS_ORIGEN_CREAR",
  GASTOS_BSAS_CREAR: "GASTOS_BSAS_CREAR",
  GASTOS_BSAS_MODIFICAR: "GASTOS_BSAS_MODIFICAR",
  GASTOS_BSAS_ELIMINAR: "GASTOS_BSAS_ELIMINAR",
  GASTOS_BSAS_CONSULTAR: "GASTOS_BSAS_CONSULTAR",
  COTIZACIONES_TEMPLATE_CONSULTAR: "COTIZACIONES_TEMPLATE_CONSULTAR",
  COTIZACIONES_CONSULTAR: "COTIZACIONES_CONSULTAR",
  COTIZACIONES_CONSULTAR_OTROS_USUARIOS: "COTIZACIONES_CONSULTAR_OTROS_USUARIOS",
  COTIZACIONES_TEMPLATE_CREAR: "COTIZACIONES_TEMPLATE_CREAR",
  COTIZACIONES_TEMPLATE_MODIFICAR: "COTIZACIONES_TEMPLATE_MODIFICAR",
  COTIZACIONES_TEMPLATE_ELIMINAR: "COTIZACIONES_TEMPLATE_ELIMINAR",
  ADUANA_CONSULTAR: "ADUANA_CONSULTAR",
  ADUANA_IMPORTAR: "ADUANA_IMPORTAR",
  ASIGNACION_MASIVA_COMERCIAL: "ASIGNACION_MASIVA_COMERCIAL",
  EVENTOS_CONSULTAR: "EVENTOS_CONSULTAR",
  EVENTOS_CREAR: "EVENTOS_CREAR",
  EVENTOS_MODIFICAR: "EVENTOS_MODIFICAR",
  EVENTOS_ELIMINAR: "EVENTOS_ELIMINAR",
  EVENTOS_CONSULTAR_OTROS_USUARIOS: "EVENTOS_CONSULTAR_OTROS_USUARIOS",
  RECORDATORIOS_CONSULTAR: "RECORDATORIOS_CONSULTAR",
  RECORDATORIOS_CREAR: "RECORDATORIOS_CREAR",
  RECORDATORIOS_MODIFICAR: "RECORDATORIOS_MODIFICAR",
  RECORDATORIOS_ELIMINAR: "RECORDATORIOS_ELIMINAR",
  REPORTE_EVENTOS_X_COMERCIAL: "REPORTE_EVENTOS_X_COMERCIAL",
  REPORTE_EVENTOS_X_CLIENTE: "REPORTE_EVENTOS_X_CLIENTE",
  REPORTE_CLIENTES_SIN_CONTACTAR: "REPORTE_CLIENTES_SIN_CONTACTAR"
}

export const ROUTES = {
  HOME: {
    pathName: "/home",
    permision: "*"
  },
  TERMS: {
    pathName: "/terms",
    permision: "*"
  },
  CUSTOMER_PROSPECT: {
    pathName: "/customer_prospect",
    permision: PERMISSION.CLIENTE_CONSULTAR
  },
  COMMERCIAL_BULK_ASSOCIATION: {
    pathName: "/commercials_bulk_association",
    permision: PERMISSION.ASIGNACION_MASIVA_COMERCIAL
  },
  AUTHORIZE_INFORMATION_TO_CLIENTS: {
    pathName: "/authorize_information_to_Clients",
    permision: PERMISSION.AUT_INFOCLIENTES_CONSULTAR
  },
  QUOTER: {
    pathName: "/quoter",
    permision: PERMISSION.COTIZADOR_CONSULTAR
  },
  CUSTOMS: {
    pathName: "/customs",
    permision: PERMISSION.ADUANA_CONSULTAR
  },
  RATINGS: {
    pathName: "/ratings",
    permision: PERMISSION.CALIFICACIONES_CONSULTAR
  },
  EXPENSES_BA: {
    pathName: "/expenses_at_ba",
    permision: PERMISSION.GASTOS_BSAS_CONSULTAR
  },
  EXPENSES_ORIGIN: {
    pathName: "/expenses_at_origin",
    permision: PERMISSION.GASTOS_ORIGEN_CONSULTAR
  },
  REFERENCE_CONSULT: {
    pathName: "/reference_Consult",
    permision: PERMISSION.REFERENCIA_CLIENTE_CONSULTAR
  },
  USERS_BACKOFFICE: {
    pathName: "/users_backoffice",
    permision: PERMISSION.USUARIO_BACKOFFICE_CONSULTAR
  },
  USERS_APP_CLIENTS: {
    pathName: "/users_app_clients",
    permision: PERMISSION.USUARIO_APP_CONSULTAR
  },
  PROFILES: {
    pathName: "/profiles",
    permision: PERMISSION.PERFIL_CONSULTAR
  },
  QUOTE_TEMPLATE: {
    pathName: "/quotes_template",
    permision: PERMISSION.COTIZACIONES_TEMPLATE_CONSULTAR
  },
  SEND_QUOTE: {
    pathName: "/send_quotes",
    permision: PERMISSION.COTIZACIONES_ENVIAR
  },
  RESEND_QUOTE: {
    pathName: "/resend_quotes",
    permision: PERMISSION.COTIZACIONES_ENVIAR
  },
  VIEW_SEND_QUOTE: {
    pathName: "/view_sent_quotes",
    permision: PERMISSION.COTIZACIONES_CONSULTAR
  },
  PERMISSION_BY_PROFILES: {
    pathName: "/permission_by_profiles",
    permision: PERMISSION.PERMISO_PERFIL_CONSULTAR
  },
  PREFERENCES: {
    pathName: "/preferences",
    permision: PERMISSION.PREFERENCIAS_CONSULTAR
  },
  EVENTS: {
    pathName: "/events",
    permision: PERMISSION.EVENTOS_CONSULTAR
  },
  REMINDER: {
    pathName: "/reminders",
    permision: PERMISSION.RECORDATORIOS_CONSULTAR
  },
  REPORT_EVENT_BY_COMMERCIAL: {
    pathName: "/reportEventByCommercial",
    permision: PERMISSION.REPORTE_EVENTOS_X_COMERCIAL
  },
  REPORT_EVENT_BY_CUSTOMER: {
    pathName: "/reportEventByCustomer",
    permision: PERMISSION.REPORTE_EVENTOS_X_CLIENTE
  },
  REPORT_CUSTOMER_WITHOUT_CONTACT: {
    pathName: "/reportUncontactCustomer",
    permision: PERMISSION.REPORTE_CLIENTES_SIN_CONTACTAR
  },
  LOGIN: {
    pathName: "/login",
    permision: "*"
  }
}

export const STATES_STEP = {
  INACTIVE: "inactive",
  ACTIVE: "active",
  VALID_DATA: "valid",
  ERROR: "error",
  SUCCESS: "success"
}

export const sectionByCategory = { settings: [1, 2, 3, 4, 5], operations: [6, 7, 8] }
// Agregar categoria y agregar relacion en PERMISSION_RELATIONAL
export const sectionByCategoryV2 = [
  { id: 1, value: "settings" },
  { id: 2, value: "settings" },
  { id: 3, value: "settings" },
  { id: 4, value: "settings" },
  { id: 5, value: "settings" },
  { id: 6, value: "operations" },
  { id: 7, value: "operations" },
  { id: 8, value: "operations" },
  { id: 9, value: "commercial" },
  { id: 10, value: "operations" },
  { id: 11, value: "commercial" },
  { id: 12, value: "commercial" },
  { id: 13, value: "commercial" },
  { id: 14, value: "commercial" },
  { id: 15, value: "commercial" },
  { id: 16, value: "commercial" },
  { id: 17, value: "commercial" },
  { id: 18, value: "commercial" },
  { id: 19, value: "commercial" }
]

export const PERMISSION_RELATIONAL = {
  all: {
    count: 56,
    settings: {
      count: 13,
      items: {
        "1": {
          count: 3
        },
        "2": {
          count: 2
        },
        "3": {
          count: 4
        },
        "4": {
          count: 2
        },
        "5": {
          count: 2
        }
      }
    },
    operations: {
      count: 6,
      items: {
        "6": {
          count: 2
        },
        "7": {
          count: 2
        },
        "8": {
          count: 1
        },
        "10": {
          count: 1
        }
      }
    },
    commercial: {
      count: 37,
      items: {
        "9": {
          count: 7
        },
        "11": {
          count: 4
        },
        "12": {
          count: 4
        },
        "13": {
          count: 4
        },
        "14": {
          count: 2
        },
        "15": {
          count: 2
        },
        "16": {
          count: 2
        },
        "17": {
          count: 5
        },
        "18": {
          count: 4
        },
        "19": {
          count: 3
        }
      }
    }
  }
}

export const FORMAT_DATE = "dd/MM/yyyy HH:mm:ss"

export const FORMAT_DATE_ONLY = "dd/MM/yyyy"

export const MENU_TYPE = {
  NORMAL: "normal",
  LANGUAJE: "languaje",
  THEME: "theme",
  NOTIFICATION: "notification",
  LOGOUT: "logout"
}

export const schemeObject = (): any => {
  return Yup.object()
    .required(TRANSLATION_INDEX_GLOBAL.REQUIRED)
    .shape({
      value: Yup.string()
        .required(TRANSLATION_INDEX_GLOBAL.REQUIRED)
        .matches(/^[^\s]*$/, TRANSLATION_INDEX_GLOBAL.REQUIRED)
    })
}

export const schemeStringRequired = (): any => Yup.string().required(TRANSLATION_INDEX_GLOBAL.REQUIRED)

export const schemeStringRequiredWithoutSpace = (): any =>
  Yup.string()
    .required(TRANSLATION_INDEX_GLOBAL.REQUIRED)
    .matches(/^[^\s]*$/, TRANSLATION_INDEX_RESPONSE_CODE.ENTER_USER)

export const schemeStringRequiredWithoutSpaceInitial = (): any =>
  Yup.string()
    .required(TRANSLATION_INDEX_GLOBAL.REQUIRED)
    .matches(/^[^\s]+/, TRANSLATION_INDEX_RESPONSE_CODE.ENTER_USER)

export const schemeArrayRequired = (): any =>
  Yup.array().required(TRANSLATION_INDEX_GLOBAL.REQUIRED).min(1, TRANSLATION_INDEX_GLOBAL.REQUIRED)

export const schemeStringRequiredNotMailNotSpace = (): any =>
  Yup.string()
    .required(TRANSLATION_INDEX_GLOBAL.REQUIRED)
    .matches(/^[^@]*$/, TRANSLATION_INDEX_RESPONSE_CODE.NOT_ARROBA)
    .matches(/^[^\s]*$/, TRANSLATION_INDEX_RESPONSE_CODE.ENTER_USER)

export const schemeEmailRequired = (): any =>
  Yup.string().required(TRANSLATION_INDEX_GLOBAL.REQUIRED).email(TRANSLATION_INDEX_GLOBAL.EMAIL_FORMAT_INCORRECT)

export const schemeStringRequiredNotMailNotSpaceInitial = (): any =>
  Yup.string()
    .required(TRANSLATION_INDEX_GLOBAL.REQUIRED)
    .matches(/^[^@]*$/, TRANSLATION_INDEX_RESPONSE_CODE.NOT_ARROBA)
    .matches(/^[^\s]+[a-zA-Z1-9]*[\s]*[a-zA-Z1-9]+$/, TRANSLATION_INDEX_RESPONSE_CODE.ENTER_USER)

export const schemeStringRequeredCuit = (): any =>
  Yup.string()
    .required(TRANSLATION_INDEX_GLOBAL.REQUIRED)
    .matches(/^[0-9]{2,2}[-][0-9]{8,8}[-][0-9]{1,1}$/, TRANSLATION_INDEX_GLOBAL.FORMAT_INCORRECT)

export const HEAD_HTML = `<!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700&display=swap" rel="stylesheet">
      <title>Cargo S.A.</title>
    
    </head>
    <body>`

export const FOOTER_HTML = `</body>
  </html>`
