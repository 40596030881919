// SAGAS
import { put, takeLatest, call } from "redux-saga/effects"
import { type SagaIterator } from "redux-saga"
import * as types from "../types"

import {
  getEventFailure,
  getEventSuccess,
  addEventSuccess,
  addEventFailure,
  deleteEventSuccess,
  deleteEventFailure,
  putEventSuccess,
  putEventFailure,
  getTypeEventSuccess,
  getTypeEventFailure,
  getContactEventSuccess,
  getContactEventFailure
} from "../actions"

import { addEvent, deleteEvent, getContactByCustomer, getEvent, getTypeEvent, putEvent } from "../services"

function* getEventWorker(): SagaIterator {
  try {
    const { data } = yield call(getEvent)
    yield put(getEventSuccess(data))
  } catch (error) {
    yield put(getEventFailure(error))
  }
}

function* getTypeEventWorker(): SagaIterator {
  try {
    const { data } = yield call(getTypeEvent)
    yield put(getTypeEventSuccess(data))
  } catch (error) {
    yield put(getTypeEventFailure(error))
  }
}

function* getContactEventWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(getContactByCustomer, request.payload)
    yield put(getContactEventSuccess(data))
  } catch (error) {
    yield put(getContactEventFailure(error))
  }
}

function* addEventWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(addEvent, request.payload)
    yield put(addEventSuccess(data))
  } catch (error) {
    yield put(addEventFailure(error))
  }
}

function* putEventWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(putEvent, request.payload)
    yield put(putEventSuccess(data))
  } catch (error) {
    yield put(putEventFailure(error))
  }
}

function* deleteEventWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(deleteEvent, request.payload)
    yield put(deleteEventSuccess(data))
  } catch (error) {
    yield put(deleteEventFailure(error))
  }
}

export function* listWatcher(): SagaIterator {
  yield takeLatest(types.EVENT_GET_REQUEST, getEventWorker)
  yield takeLatest(types.EVENT_ADD_REQUEST, addEventWorker)
  yield takeLatest(types.EVENT_PUT_REQUEST, putEventWorker)
  yield takeLatest(types.EVENT_TYPE_GET_REQUEST, getTypeEventWorker)
  yield takeLatest(types.EVENT_CONTACT_GET_REQUEST, getContactEventWorker)
  yield takeLatest(types.EVENT_DELETE_REQUEST, deleteEventWorker)
}

export const sagas = [listWatcher]
