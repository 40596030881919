import styled, { keyframes } from "styled-components"
import { type IStyle } from "../../interfaces/IStyle"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"

export const Container = styled.div<IStyle>`
  width: 100%;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const ContainerSubItem = styled.div<IStyle>`
  padding: 0;
  margin-bottom: 10px;
`
const openBlur = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const openMenu = keyframes`
  0% { width: 0%; }
  100% { width: 80%; }
`

const openMenuBottom = keyframes`
  0% { height: 0%;opacity:0 }
  100% { height: 100%;opacity:1 }
`

export const SubMenu = styled.div<IStyle>`
  height: 0%;
  animation-name: ${openMenuBottom};
  animation-duration: 1s;
  background-color: ${({ themeContext }) => GLOBAL_STYLES.BACKGROUND.TYPE_5[themeContext]};
`

export const Icon = styled.span`
  font-size: 25px;
  margin-left: 10px;
`

export const CloseIcon = styled.div<IStyle>`
  position: absolute;
  left: 8%;
  top: 2%;
  cursor: pointer;
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_5[themeContext]};
  :hover {
    color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_2[themeContext]};
  }
`
export const ContainerBlur = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: 0%;
  animation-name: ${openBlur};
  animation-duration: 1s;
  background-color: rgba(0, 0, 0, 0.5);
`

export const Menu = styled.div<IStyle>`
  width: 80%;
  height: 100%;
  padding-top: 20px;
  padding-left: 10px;
  animation-name: ${openMenu};
  animation-duration: 1s;
  background-color: ${({ themeContext }) => GLOBAL_STYLES.BACKGROUND.TYPE_3[themeContext]};
  overflow-y: scroll;
  ::-webkit-scrollbar-thumb {
    background: #808080;
    border-radius: 50px;
  }
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    margin-top: 5px;
    background-color: #ececec;
    border-radius: 270px;
  }
  z-index: 999;
`

export const MenuLevel1 = styled.div`
  padding: 5px 5px;
  display: flex;
  align-items: center;
`

export const MenuLevel2 = styled.div`
  padding: 10px 15px;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`
