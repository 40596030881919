import { expensiveorigin } from "./_duck"
import * as types from "./types"

export const getExpensiveOriginRequest = expensiveorigin.createAction(types.EXPENSIVE_ORIGIN_GET_REQUEST)
export const getExpensiveOriginSuccess = expensiveorigin.createAction(types.EXPENSIVE_ORIGIN_GET_SUCCESS)
export const getExpensiveOriginFailure = expensiveorigin.createAction(types.EXPENSIVE_ORIGIN_GET_FAILURE)

export const addExpensiveOriginRequest = expensiveorigin.createAction(types.EXPENSIVE_ORIGIN_ADD_REQUEST)
export const addExpensiveOriginSuccess = expensiveorigin.createAction(types.EXPENSIVE_ORIGIN_ADD_SUCCESS)
export const addExpensiveOriginFailure = expensiveorigin.createAction(types.EXPENSIVE_ORIGIN_ADD_FAILURE)

export const putExpensiveOriginRequest = expensiveorigin.createAction(types.EXPENSIVE_ORIGIN_PUT_REQUEST)
export const putExpensiveOriginSuccess = expensiveorigin.createAction(types.EXPENSIVE_ORIGIN_PUT_SUCCESS)
export const putExpensiveOriginFailure = expensiveorigin.createAction(types.EXPENSIVE_ORIGIN_PUT_FAILURE)

export const deleteExpensiveOriginRequest = expensiveorigin.createAction(types.EXPENSIVE_ORIGIN_DELETE_REQUEST)
export const deleteExpensiveOriginSuccess = expensiveorigin.createAction(types.EXPENSIVE_ORIGIN_DELETE_SUCCESS)
export const deleteExpensiveOriginFailure = expensiveorigin.createAction(types.EXPENSIVE_ORIGIN_DELETE_FAILURE)
