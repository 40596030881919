import React, { type ReactElement } from "react"
import Loader from "../Loader"
import { Button, Icon } from "./_styles"
import { type Props } from "./_types"
import { useGlobalContext } from "../../context/GlobalContext"

const ButtonCustom = ({
  onClick = () => {},
  type = "submit",
  label,
  icon,
  loading = false,
  background,
  backgroundHover,
  width = "100%",
  marginLeft = false,
  height = "28px",
  marginBottom = false,
  disabled,
  style
}: Props): ReactElement => {
  const { themeContext } = useGlobalContext()

  return (
    <Button
      themeContext={themeContext}
      style={style}
      disabled={disabled}
      onClick={onClick}
      type={type}
      width={width}
      background={background}
      backgroundHover={backgroundHover}
      marginLeft={marginLeft}
      marginBottom={marginBottom}
      height={height}
      data-testid="button"
    >
      {loading ? (
        <Loader width="30px" />
      ) : (
        <>
          {icon != null && <Icon>{icon}</Icon>}
          <span style={{ marginLeft: icon != null ? "5px" : "" }}>{label}</span>
        </>
      )}
    </Button>
  )
}

export default ButtonCustom
