import { quoteTemplate } from "./_duck"
import * as types from "./types"

export const getQuoteTemplateRequest = quoteTemplate.createAction(types.QUOTE_TEMPLATE_GET_REQUEST)
export const getQuoteTemplateSuccess = quoteTemplate.createAction(types.QUOTE_TEMPLATE_GET_SUCCESS)
export const getQuoteTemplateFailure = quoteTemplate.createAction(types.QUOTE_TEMPLATE_GET_FAILURE)

export const getQuoteSentRequest = quoteTemplate.createAction(types.QUOTE_SENT_GET_REQUEST)
export const getQuoteSentSuccess = quoteTemplate.createAction(types.QUOTE_SENT_GET_SUCCESS)
export const getQuoteSentFailure = quoteTemplate.createAction(types.QUOTE_SENT_GET_FAILURE)

export const getQuoteSentCustomerRequest = quoteTemplate.createAction(types.QUOTE_SENT_CUSTOMER_GET_REQUEST)
export const getQuoteSentCustomerSuccess = quoteTemplate.createAction(types.QUOTE_SENT_CUSTOMER_GET_SUCCESS)
export const getQuoteSentCustomerFailure = quoteTemplate.createAction(types.QUOTE_SENT_CUSTOMER_GET_FAILURE)

export const getQuoteSentByIdRequest = quoteTemplate.createAction(types.QUOTE_SENT_BY_ID_GET_REQUEST)
export const getQuoteSentByIdSuccess = quoteTemplate.createAction(types.QUOTE_SENT_BY_ID_GET_SUCCESS)
export const getQuoteSentByIdFailure = quoteTemplate.createAction(types.QUOTE_SENT_BY_ID_GET_FAILURE)

export const getQuoteTemplateCustomerRelativeRequest = quoteTemplate.createAction(
  types.QUOTE_TEMPLATE_CUSTOMER_RELATIVE_GET_REQUEST
)
export const getQuoteTemplateCustomerRelativeSuccess = quoteTemplate.createAction(
  types.QUOTE_TEMPLATE_CUSTOMER_RELATIVE_GET_SUCCESS
)
export const getQuoteTemplateCustomerRelativeFailure = quoteTemplate.createAction(
  types.QUOTE_TEMPLATE_CUSTOMER_RELATIVE_GET_FAILURE
)

export const addQuoteTemplateRequest = quoteTemplate.createAction(types.QUOTE_TEMPLATE_ADD_REQUEST)
export const addQuoteTemplateSuccess = quoteTemplate.createAction(types.QUOTE_TEMPLATE_ADD_SUCCESS)
export const addQuoteTemplateFailure = quoteTemplate.createAction(types.QUOTE_TEMPLATE_ADD_FAILURE)

export const cleanPDFQuoteTemplate = quoteTemplate.createAction(types.QUOTE_TEMPLATE_CLEAN_PDF)
export const cleanResponseErrorQuoteTemplate = quoteTemplate.createAction(types.QUOTE_TEMPLATE_CLEAN_RESPONSE_ERROR)

export const createPDFQuoteTemplateRequest = quoteTemplate.createAction(types.QUOTE_TEMPLATE_CREATE_PDF_REQUEST)
export const createPDFQuoteTemplateSuccess = quoteTemplate.createAction(types.QUOTE_TEMPLATE_CREATE_PDF_SUCCESS)
export const createPDFQuoteTemplateFailure = quoteTemplate.createAction(types.QUOTE_TEMPLATE_CREATE_PDF_FAILURE)

export const previewPDFQuoteTemplateRequest = quoteTemplate.createAction(types.QUOTE_TEMPLATE_PREVIEW_PDF_REQUEST)
export const previewPDFQuoteTemplateSuccess = quoteTemplate.createAction(types.QUOTE_TEMPLATE_PREVIEW_PDF_SUCCESS)
export const previewPDFQuoteTemplateFailure = quoteTemplate.createAction(types.QUOTE_TEMPLATE_PREVIEW_PDF_FAILURE)

export const postSendQuoteRequest = quoteTemplate.createAction(types.QUOTE_SEND_POST_REQUEST)
export const postSendQuoteSuccess = quoteTemplate.createAction(types.QUOTE_SEND_POST_SUCCESS)
export const postSendQuoteFailure = quoteTemplate.createAction(types.QUOTE_SEND_POST_FAILURE)

export const putQuoteTemplateRequest = quoteTemplate.createAction(types.QUOTE_TEMPLATE_PUT_REQUEST)
export const putQuoteTemplateSuccess = quoteTemplate.createAction(types.QUOTE_TEMPLATE_PUT_SUCCESS)
export const putQuoteTemplateFailure = quoteTemplate.createAction(types.QUOTE_TEMPLATE_PUT_FAILURE)

export const deleteQuoteTemplateRequest = quoteTemplate.createAction(types.QUOTE_TEMPLATE_DELETE_REQUEST)
export const deleteQuoteTemplateSuccess = quoteTemplate.createAction(types.QUOTE_TEMPLATE_DELETE_SUCCESS)
export const deleteQuoteTemplateFailure = quoteTemplate.createAction(types.QUOTE_TEMPLATE_DELETE_FAILURE)
