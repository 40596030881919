/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import ContainerPage from "../../components/ContainerPage"
import {
  TRANSLATION_INDEX_EVENT,
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_PERMISSION,
  TRANSLATION_INDEX_REPORT
} from "../../config/translation"
import DataTable from "react-data-table-component"

import { ContainerTable } from "./_styles"
import { customStylesContext } from "../../styles/_styleDatatable"
import { useDispatch, useSelector } from "react-redux"
import SearchUserClientApp from "./forms/search/index"
import { toast } from "react-toastify"

import { getProfileRequest } from "../../state/modules/profile/actions"

import { NoData } from "../../components/NoData"
import { useGlobalContext } from "../../context/GlobalContext"
import "../../styles/styles.css"
import { useMobile } from "../../hook/useMobile"
import { getReportClearAll, getReportCommercialRequest } from "../../state/modules/reportCommercial/actions"
import { type IReportCommercial } from "../../interfaces/IReportCommercial"
import { reportCommercialSelector } from "../../state/modules/reportCommercial/_selectors"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { ToolTipRow } from "../CustomerProspect/_styles"
import { type IEvent } from "../../interfaces/IEvent"
import { EVENT_TYPE } from "../Event/constants"
import { format } from "date-fns"
import { FORMAT_DATE_ONLY } from "../../utils/constants"
import { getAssignedCommercialRequest } from "../../state/modules/customer/actions"
import IconExcel from "../../components/Icons/IconExcel"
import ContainerLabelTwoRow from "../../components/ContainerLabelTwoRow"
import { capitalizeFirstLetter } from "../../utils/functions"

const ReportCommercialPage = (): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useMobile()
  const dispatch = useDispatch()
  const reportCommercial = useSelector(reportCommercialSelector)

  const [dataFiltered, setDataFiltered] = React.useState<IReportCommercial[]>()
  const [filters, setFilters] = React.useState<any>()

  const [searchData, setSearchData] = React.useState(false)
  const [openModal, setOpenModal] = React.useState(false)
  const { setLoadingContext, themeContext } = useGlobalContext()
  const [clearFilter, setClearFilter] = React.useState(false)
  const { loadingContext } = useGlobalContext()
  const handleOpenModal = (action = ""): void => {
    setOpenModal(!openModal)
  }

  const paginationComponentOptions = {
    rowsPerPageText: t(TRANSLATION_INDEX_GLOBAL.ROWS_PER_PAGE),
    rangeSeparatorText: t(TRANSLATION_INDEX_GLOBAL.OF),
    selectAllRowsItem: true,
    selectAllRowsItemText: t(TRANSLATION_INDEX_GLOBAL.ALL)
  }

  const columns = [
    {
      name: t(TRANSLATION_INDEX_REPORT.COUNT_EVENT),
      selector: (row: IReportCommercial) => row?.comercial?.cantidadEventos,
      width: isMobile ? "100px" : "10%",
      center: true,
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_REPORT.USER),
      selector: (row: IReportCommercial) => `${row.comercial.apellido} ${row.comercial.nombre}`,
      width: isMobile ? "2500px" : "80%",
      sortable: true
    }
  ]

  const columns2 = [
    {
      name: t(TRANSLATION_INDEX_EVENT.DATE),
      selector: (row: IEvent) => row.fecha,
      sortable: true,
      width: isMobile ? "150px" : `10%`,
      center: true,
      cell: (row: IEvent) => {
        return <div>{row?.fecha != null ? format(new Date(row?.fecha), FORMAT_DATE_ONLY) : "-"}</div>
      }
    },
    {
      name: t(TRANSLATION_INDEX_EVENT.EVENT_TYPE),
      selector: (row: IEvent) =>
        t(
          EVENT_TYPE.find(itemEvent => itemEvent.idTipoEvento === row.tipoEvento.idTipoEvento)?.descripcion ?? "-"
        ).toUpperCase() ?? "-",
      sortable: true,
      width: isMobile ? "200px" : `15%`
    },
    {
      name: t(TRANSLATION_INDEX_EVENT.CUSTOMER_PROSPECT),
      selector: (row: IEvent) => row.cliente.razonSocial,
      sortable: true,
      width: isMobile ? "400px" : `calc(30%)`,
      cell: (row: IEvent) => {
        return <ToolTipRow>{row.cliente.razonSocial}</ToolTipRow>
      }
    },

    {
      name: t(TRANSLATION_INDEX_EVENT.CONTACT),
      selector: (row: IEvent) =>
        row?.contacto
          ? `-${capitalizeFirstLetter(row?.contacto?.apellido)} ${capitalizeFirstLetter(row?.contacto?.nombre)}`
          : "",
      sortable: true,
      width: isMobile ? "250px" : `15%`
    },
    {
      name: t(TRANSLATION_INDEX_EVENT.COMMENT),
      selector: (row: IEvent) => row.comentario,
      sortable: true,
      width: isMobile ? "250px" : `30%`,
      cell: (row: IEvent) => {
        return <ContainerLabelTwoRow label={capitalizeFirstLetter(row.comentario)} />
      }
    }
  ]

  React.useEffect(() => {
    if (reportCommercial?.showToast) {
      if (reportCommercial.hasErrMessage) {
        toast.error(t(reportCommercial.errorCode))
      } else {
        toast.success(t(reportCommercial.toastMessage))
        handleOpenModal()
        dispatch(getProfileRequest())
      }
    }
  }, [reportCommercial?.showToast])

  React.useEffect(() => {
    setLoadingContext(reportCommercial?.loading)
    setDataFiltered(reportCommercial?.data)
  }, [reportCommercial])

  React.useEffect(() => {
    if (searchData) {
      setLoadingContext(true)
      dispatch(getReportCommercialRequest(filters))
      setSearchData(false)
    }
  }, [searchData])
  React.useEffect(() => {
    if (clearFilter) {
      setDataFiltered([])
      dispatch(getReportClearAll())
      setClearFilter(false)
    }
  }, [clearFilter])
  React.useEffect(() => {
    dispatch(getAssignedCommercialRequest())
    return () => dispatch(getReportClearAll())
  }, [])
  const ExpandedComponent = (rowData): any => (
    <div
      style={{
        width: "calc(100% - 10px)",
        padding: "5px",
        background: GLOBAL_STYLES.BACKGROUND.TYPE_1[themeContext],
        boxShadow: GLOBAL_STYLES.BOX_SHADOW.TYPE_1[themeContext]
      }}
    >
      <ContainerPage title={``} actions={[]}>
        <ContainerTable themeContext={themeContext} opacity={1}>
          <DataTable
            data={rowData.data.eventos ?? []}
            theme="solarized"
            noDataComponent={<NoData />}
            columns={columns2}
            dense
            pagination
            paginationComponentOptions={paginationComponentOptions}
            striped
            customStyles={customStylesContext(themeContext)}
            defaultSortFieldId={0}
          />
        </ContainerTable>
      </ContainerPage>
    </div>
  )
  const handleExcel = (): void => {
    let queryParam = ""
    if (filters.fechaDesde) queryParam = queryParam + `fechaDesde=${filters?.fechaDesde}&`
    if (filters.fechaHasta) queryParam = queryParam + `fechaHasta=${filters?.fechaHasta}&`
    if (filters.comerciales) queryParam = queryParam + `clientes=${filters?.comerciales.join(",")}&`

    window.open(
      `${process.env.REACT_APP_API_URL}/api/backoffice/v1/reportes/eventosPorComercial/exportar?${queryParam}`
    )
  }
  return (
    <>
      <ContainerPage
        title={`${t(TRANSLATION_INDEX_PERMISSION.REPORTE_EVENTOS_X_COMERCIAL)}`}
        actions={
          reportCommercial?.data?.length > 0
            ? [
                {
                  label: t(TRANSLATION_INDEX_GLOBAL.EXPORT),
                  icon: <IconExcel size={13} />,
                  permission: "*",
                  click: handleExcel
                }
              ]
            : []
        }
      >
        <>
          <ContainerTable themeContext={themeContext} opacity={1}>
            <SearchUserClientApp
              data={reportCommercial?.data}
              setDataFiltered={setDataFiltered}
              setFilters={setFilters}
              setSearchData={setSearchData}
              setClearFilter={setClearFilter}
            />
            <DataTable
              data={dataFiltered ?? []}
              theme="solarized"
              noDataComponent={
                <NoData
                  label={
                    loadingContext
                      ? ""
                      : filters
                      ? TRANSLATION_INDEX_GLOBAL.NO_DATA_2
                      : TRANSLATION_INDEX_GLOBAL.USE_SEARCH_TO_GET_RESULTS
                  }
                />
              }
              columns={columns}
              dense
              pagination
              expandableRows
              expandableRowDisabled={row => row?.eventos.length === 0}
              expandableRowsComponent={ExpandedComponent}
              paginationComponentOptions={paginationComponentOptions}
              striped
              customStyles={customStylesContext(themeContext)}
              // defaultSortFieldId={1}
            />
          </ContainerTable>
        </>
      </ContainerPage>
    </>
  )
}

export default ReportCommercialPage
