import * as Yup from "yup"
import { TRANSLATION_INDEX_GLOBAL } from "../../config/translation"
import { schemeArrayRequired } from "../../utils/constants"

export const deleteScheme = Yup.object().shape({})

export const addUpdateScheme = Yup.object().shape({
  nombre: Yup.string().required(TRANSLATION_INDEX_GLOBAL.REQUIRED),
  asuntoEmail: Yup.string().required(TRANSLATION_INDEX_GLOBAL.REQUIRED),
  vigenciaDesde: Yup.string().required(TRANSLATION_INDEX_GLOBAL.REQUIRED),
  vigenciaHasta: Yup.string().required(TRANSLATION_INDEX_GLOBAL.REQUIRED),
  procedencias: schemeArrayRequired(),
  destinos: schemeArrayRequired()
})
