/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { type ReactElement } from "react"
import { Icon, MenuLevel2 } from "../../_styles"
import { useTranslation } from "react-i18next"
import { type IProps } from "./_types"
import { useNavigate } from "react-router"

import { useGlobalContext } from "../../../../context/GlobalContext"

import { STORAGE } from "../../../../utils/localStorage"
import { MENU_TYPE } from "../../../../utils/constants"
import IconArrowDown from "../../../Icons/IconArrowDown"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { isStringEmpty } from "../../../../utils/functions"

const SubMenuLevel2: React.FC<IProps> = ({ menu, subItem }): ReactElement => {
  const [activeSubMenu, setactiveSubMenu] = React.useState(false)
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const { setOpenMenuMobile, themeContext } = useGlobalContext()

  const handleRoute = (route): void => {
    if (route !== undefined && !isStringEmpty(route)) {
      navigate(route)
      setOpenMenuMobile(false)
    }
    if (subItem?.submenu && subItem?.submenu?.length > 0) {
      setactiveSubMenu(!activeSubMenu)
    }
  }

  const selectTheme = (theme: string): void => {
    STORAGE.set(STORAGE.THEME, theme)
    window.location.reload()
  }

  const selectLanguage = (language: string): void => {
    void i18n.changeLanguage(language)
    window.location.reload()
  }
  return (
    <MenuLevel2
      key={subItem.label}
      onClick={() => {
        if (menu.menuType === MENU_TYPE.LANGUAJE) {
          selectLanguage(subItem.value)
        } else if (menu.menuType === MENU_TYPE.THEME) {
          selectTheme(subItem.value)
        } else {
          handleRoute(subItem.route)
        }
      }}
    >
      <div style={{ width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
        {t(subItem.label)}
        {subItem?.submenu && subItem?.submenu?.length > 0 && (
          <Icon>
            <IconArrowDown size={18} color={GLOBAL_STYLES.LABEL.TYPE_1[themeContext]} />
          </Icon>
        )}
      </div>
      {subItem?.submenu && subItem?.submenu?.length > 0 && activeSubMenu && (
        <div style={{ width: "100%", padding: "5px 0" }}>
          {subItem?.submenu?.map(item => {
            return (
              <div
                key={item.label}
                role="button"
                tabIndex={0}
                onKeyDown={()=>{ handleRoute(item.route)}}
                onClick={()=>{ handleRoute(item.route)}}
                style={{ padding: "10px", background: GLOBAL_STYLES.BACKGROUND.TYPE_5[themeContext] }}
              >
                {t(item?.label)}
              </div>
            )
          })}
        </div>
      )}
    </MenuLevel2>
  )
}

export default SubMenuLevel2
