import styled from "styled-components"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { type IStyle } from "../../../../interfaces/IStyle"

export const Container = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const ContainerCuit = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const ContainerGroup = styled.div<IStyle>`
  position: relative;
  min-height: 70px;
  width: calc(100% - 90px);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 45px;
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
`

export const Group2 = styled.div<IStyle>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: ${({ alignItems }) => alignItems ?? "flex-start"};
`

export const Group = styled.div<IStyle>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: ${({ alignItems }) => alignItems ?? "flex-start"};
  @media (max-width: 900px) {
    flex-direction: column;
  }
`

export const GroupButtom = styled.div<IStyle>`
  width: calc(100% - 30px);
  display: flex;
  padding: 10px 15px;
  justify-content: flex-end;
  border-top: ${({ themeContext }) => GLOBAL_STYLES.BORDER.TYPE_2[themeContext]};
`
export const ContainerMessage = styled.div<IStyle>`
  min-height: 70px;
  width: calc(100% - 90px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 45px;
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
`

export const Contact = styled.div<IStyle>`
  width: 100%;
  margin-top: 15px;
  border: ${({ themeContext }) => GLOBAL_STYLES.BORDER.TYPE_2[themeContext]};
  padding: 15px 0px;
  border-radius: 10px;
`

export const Title = styled.div<IStyle>`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
  font-size: 1.1rem;
  padding: 0px 10px 10px 10px;
  border-bottom: ${({ themeContext }) => GLOBAL_STYLES.BORDER.TYPE_2[themeContext]};
`

export const Content = styled.div<IStyle>`
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
  border-radius: 5px;
`

export const ContainerItem = styled.div`
  padding: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  @media (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const Item = styled.div<IStyle>`
  border: ${({ themeContext }) => GLOBAL_STYLES.BORDER.TYPE_2[themeContext]};
  padding: 15px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin: 5px;
  border-radius: 10px;

  @media (max-width: 900px) {
    max-width: calc(100% - 40px);
  }
`
