import { type AxiosPromise } from "axios"
import { type ILogin_Request } from "../../../../interfaces/ILogin"
import httpModule from "../../../../utils/http"

export const postLogin = async ({ usuario, clave }: ILogin_Request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/usuarios/login`, { usuario, clave })

export const postLoginForgot = async ({ usuario }: ILogin_Request): Promise<AxiosPromise<any>> =>
  await httpModule.get(`/api/mobile/v1/usuarios/recuperarclave/${usuario}`)

export const postLoginChange = async (nuevaClave): Promise<AxiosPromise<any>> =>
  await httpModule.patch(`/api/mobile/v1/usuarios/cambiarclave`, nuevaClave)
