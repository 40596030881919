/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { type ReactElement } from "react"

import ButtonCustom from "../../../../components/ButtonCustom"

import { useTranslation } from "react-i18next"

import {
  TRANSLATION_INDEX_AUTHORIZE_INFORMACION,
  TRANSLATION_INDEX_CUSTOMER_PROSPECT,
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_MENU,
  TRANSLATION_INDEX_RESPONSE_CODE
} from "../../../../config/translation"
import {
  Container,
  Group,
  GroupButtom,
  ContainerGroup,
  ContainerMessage,
  Contact,
  Title,
  Content,
  ContainerItem,
  Item,
  ContainerCuit,
  Group2
} from "./_styles"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { ACTIONS, PERMISSION, STATES_YES_NO } from "../../../../utils/constants"
import { capitalizeFirstLetter, countDigits, getAuth } from "../../../../utils/functions"
import "react-toastify/dist/ReactToastify.css"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"
import { withFormik } from "formik"
import { deleteScheme, schemaAdd } from "../../_schema"
import InputMaskCustom from "../../../../components/InputMaskCustom"
import { useDispatch, useSelector } from "react-redux"
import { getCustomerByFilterRequest } from "../../../../state/modules/customer/actions"
import { customerSelector } from "../../../../state/modules/customer/_selectors"
import { useMobile } from "../../../../hook/useMobile"
import SelectCustom from "../../../../components/SelectCustom"
import AlertModal from "../../../../components/AlertModal"
import ContactAddUpdate from "../ContactAddUpdate/ContactAddUpdate"
import SelectAsyncCustom from "../../../../components/SelectAsyncCustom"
import CustomerService from "../../../../services/CustomerService"
import { toast } from "react-toastify"
import { t } from "i18next"
import { useGlobalContext } from "../../../../context/GlobalContext"
import InputCustomSetField from "../../../../components/InputCustomSetField"

const FormaddUpdate = ({
  data,
  isUserPending = false,
  loading = false,
  action = "",
  closeModal = () => {},
  accept = () => {},
  setFieldValue,
  values,
  touched,
  validateForm,
  errors,
  setSubmitting,
  isSubmitting,
  handleChange,
  handleBlur,
  handleSubmit
}: IFormListProps): ReactElement => {
  const isView = action === ACTIONS.VIEW.VALUE
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const customer = useSelector(customerSelector)
  const isMobile = useMobile()
  const [openModal, setOpenModal] = React.useState(false)
  const [actionContact, setActionContact] = React.useState<string>("")
  const [selected, setSelected] = React.useState(null)
  const [existCuit, setExistCuit] = React.useState<boolean | null>(null)
  const { themeContext } = useGlobalContext()
  const [activeButtonCuit, setActiveButtonCuit] = React.useState(false)
  const promiseOptions = async (inputValue: string): Promise<any> => {
    const response = await new Promise(resolve => {
      if (inputValue.length > 2) {
        resolve(CustomerService(inputValue, values?.razonSocial))
      } else {
        resolve([{ value: "-1", label: t(TRANSLATION_INDEX_GLOBAL.ENTER_THREE_CHARACTER), isDisabled: true }])
      }
    })
    return response
  }
  const handleOpenModal = (action = ""): void => {
    setActionContact(action)
    setOpenModal(!openModal)
  }
  const getData = (): void => {
    const request = {
      ordenamiento: { columna: 1, direccion: "asc" },
      paginado: { pagina: 1, registrosPorPagina: 10 },
      filtro: {
        cuit: values?.cuit ?? null,
        razonSocial: null,
        idTipoCliente: null,
        comercialAsignado: null,
        esCargaCIF: null,
        esImportador: null,
        esExportador: null
      }
    }
    dispatch(getCustomerByFilterRequest(request))
  }

  const deleteContact = (id): void => {
    if (setFieldValue != null) {
      setFieldValue(
        "contactos",
        values?.contactos?.filter(item => item.id !== id)
      )
    }
    setOpenModal(false)
  }

  const getContacts = (contact: any): void => {
    if (actionContact === ACTIONS.DELETE.VALUE) {
      deleteContact(contact.id)
    } else {
      if (setFieldValue != null) {
        if (values?.contactos?.some(item => item.id === contact.id)) {
          const newValues = values?.contactos.map(item => {
            if (item.id === contact.id) return contact
            return item
          })

          setFieldValue("contactos", newValues)
        } else {
          setFieldValue("contactos", [...values?.contactos, contact])
        }
        setOpenModal(false)
      }
    }
  }

  const buttonGroup = (): ReactElement => {
    return (
      <GroupButtom themeContext={themeContext}>
        <ButtonCustom
          onClick={closeModal}
          width="80px"
          type="button"
          label={t("global.cancel") ?? ""}
          background={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE[themeContext]}
          backgroundHover={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE_HOVER[themeContext]}
        />
        {!isView && (
          <ButtonCustom
            width="80px"
            type="submit"
            label={
              action === ACTIONS.DELETE.VALUE
                ? t(TRANSLATION_INDEX_GLOBAL.DELETE) ?? ""
                : t(TRANSLATION_INDEX_GLOBAL.CONFIRM) ?? ""
            }
            background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
            backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]}
            marginLeft
          />
        )}
      </GroupButtom>
    )
  }

  const handleKeyPressEnter = (_, keyCode): void => {
    if (keyCode === 13 || keyCode === 9) {
      getData()
    }
  }

  React.useEffect(() => {
    if (touched.razonSocial === true && Object.keys(errors ?? {}).length > 0) validateForm()
  }, [values])

  React.useEffect(() => {
    if (customer?.dataByFilter != null) {
      if (customer?.dataByFilter?.totalRegistros > 0) {
        setExistCuit(true)
        toast.error(t(TRANSLATION_INDEX_RESPONSE_CODE.CUIT_ALREADY_IN_USE))
      } else {
        setExistCuit(false)
      }
    } else setExistCuit(null)
  }, [customer])

  React.useEffect(() => {
    if (countDigits(values?.cuit ?? "") === 11) setActiveButtonCuit(true)
    else setActiveButtonCuit(false)
  }, [values])

  React.useEffect(() => {
    if (setFieldValue)
      setFieldValue(
        "usuariosAsociados",
        values?.usuariosAsociados?.filter(item => item.value !== values?.comercialAsignado?.value)
      )
  }, [values?.comercialAsignado])

  React.useEffect(() => {
    if (
      customer.assignedCommercial &&
      customer.assignedCommercial.length > 0 &&
      setFieldValue &&
      action === ACTIONS.EDIT.VALUE
    ) {
      const comercialAsignado = values?.comercialAsignado
        ? customer.assignedCommercial.find(item => item.nombreUsuario === values?.comercialAsignado?.value)
        : null
      setFieldValue(
        "comercialAsignado",
        comercialAsignado
          ? {
              value: comercialAsignado?.nombreUsuario,
              label: `${comercialAsignado?.apellido.toLocaleUpperCase()} ${comercialAsignado?.nombre.toLocaleUpperCase()}`
            }
          : null
      )
    }
  }, [customer.assignedCommercial])

  React.useEffect(() => {
    setExistCuit(null)
    if (setSubmitting) setSubmitting(false)
  }, [])

  return (
    <>
      {openModal && (
        <AlertModal
          maxWidth={isMobile ? "90%" : actionContact === ACTIONS.DELETE.VALUE ? "40%" : "75%"}
          closeModal={() => {
            handleOpenModal()
          }}
          activeCloseModal
          title={`${t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.CONTACT)}`}
        >
          <ContactAddUpdate accept={getContacts} action={actionContact} data={selected} closeModal={handleOpenModal} />
        </AlertModal>
      )}
      {action === ACTIONS.DELETE.VALUE ? (
        <Container onSubmit={handleSubmit}>
          <ContainerMessage themeContext={themeContext}>{t(TRANSLATION_INDEX_GLOBAL.CONFIRM_DELETE)}</ContainerMessage>
          {buttonGroup()}
        </Container>
      ) : action === ACTIONS.ADD.VALUE && (existCuit === null || existCuit) ? (
        <ContainerCuit>
          <ContainerGroup themeContext={themeContext}>
            <Group2 themeContext={themeContext} alignItems="flex-end">
              <InputMaskCustom
                mask="99-99999999-9"
                label="CUIT"
                width="50%"
                alwaysShowMask
                name="cuit"
                validError={false}
                handleKeyPressEnter={handleKeyPressEnter}
                handleChange={handleChange}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                value={values?.cuit ?? ""}
              />
              <ButtonCustom
                label={t(TRANSLATION_INDEX_GLOBAL.VALIDATE) ?? ""}
                width="80px"
                disabled={!activeButtonCuit}
                onClick={getData}
                background={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE[themeContext]}
                backgroundHover={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE_HOVER[themeContext]}
                marginLeft
                marginBottom
              />
            </Group2>
          </ContainerGroup>
        </ContainerCuit>
      ) : (
        <Container onSubmit={handleSubmit}>
          <ContainerGroup themeContext={themeContext}>
            <Group themeContext={themeContext}>
              <InputMaskCustom
                mask="99-99999999-9"
                label="CUIT (*)"
                width={isMobile ? "100%" : "50%"}
                alwaysShowMask
                disabled={isView}
                name="cuit"
                errors={errors?.cuit}
                handleChange={handleChange}
                value={values?.cuit ?? ""}
              />
              {setFieldValue && (
                <InputCustomSetField
                  label={`${t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.COMPANY_NAME) ?? ""} (*)`}
                  value={values?.razonSocial ?? ""}
                  errors={errors?.razonSocial}
                  handleChange={setFieldValue}
                  name="razonSocial"
                  disabled={isView}
                  marginLeft
                  placeHolder={`${t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.COMPANY_NAME)}` ?? ""}
                  width={isMobile ? "100%" : "50%"}
                  maxCharacters={255}
                />
              )}
            </Group>
            <>
              <Group themeContext={themeContext}>
                {setFieldValue != null && (
                  <SelectCustom
                    isDisabled={isView}
                    label={`${t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.TYPE) ?? ""} (*)`}
                    value={values?.clienteTipo ?? null}
                    handleChange={setFieldValue}
                    name="clienteTipo"
                    placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
                    width={isMobile ? "100%" : "50%"}
                    validError={true}
                    errors={errors?.clienteTipo}
                    options={customer.clientType.map(item => {
                      return {
                        value: item.idTipoCliente,
                        label: t(
                          item.idTipoCliente === 1
                            ? TRANSLATION_INDEX_CUSTOMER_PROSPECT.CUSTOMER
                            : TRANSLATION_INDEX_CUSTOMER_PROSPECT.PROSPECT
                        ).toUpperCase()
                      }
                    })}
                  />
                )}
                {setFieldValue && (
                  <InputCustomSetField
                    label={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.SOURCE_CHANNEL) ?? ""}
                    value={values?.canalOrigen ?? ""}
                    handleChange={setFieldValue}
                    marginLeft
                    disabled={isView}
                    name="canalOrigen"
                    placeHolder={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.HOW_ARRIVE_CUSTOMER) ?? ""}
                    width={isMobile ? "100%" : "50%"}
                    errors={errors?.canalOrigen}
                    maxCharacters={255}
                  />
                )}
              </Group>
              <Group themeContext={themeContext} alignItems="flex-end">
                {setFieldValue && (
                  <InputCustomSetField
                    disabled={isView}
                    label={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.ADRESS) ?? ""}
                    value={values?.domicilio ?? ""}
                    handleChange={setFieldValue}
                    name="domicilio"
                    placeHolder={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.ADRESS) ?? ""}
                    width={isMobile ? "100%" : "50%"}
                    maxCharacters={255}
                    errors={errors?.domicilio}
                  />
                )}
                {setFieldValue != null && (
                  <SelectAsyncCustom
                    isMulti
                    marginLeft
                    isDisabled={isView}
                    width={isMobile ? "100%" : "50%"}
                    label={`${t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.RELATED_CUSTOMERS)}`}
                    value={
                      values?.clientesRelacionados?.filter(
                        item => item.label.toLowerCase() !== values.razonSocial.toLowerCase()
                      ) ?? null
                    }
                    handleChange={setFieldValue}
                    name="clientesRelacionados"
                    placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_MORE) ?? ""}
                    promiseOptions={promiseOptions}
                    errors={errors?.clientesRelacionados}
                  />
                )}
              </Group>
              <Group themeContext={themeContext}>
                {setFieldValue != null && (
                  <SelectCustom
                    isDisabled={isView}
                    label={`${t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.CIF_CARGO) ?? ""} (*)`}
                    value={values?.esCargaCIF ?? null}
                    handleChange={setFieldValue}
                    name="esCargaCIF"
                    placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
                    width={isMobile ? "100%" : "50%"}
                    validError={true}
                    errors={errors?.esCargaCIF}
                    options={STATES_YES_NO.filter(item => (item?.value ?? 2) !== 2).map(item => {
                      return { ...item, label: t(item.label).toUpperCase() }
                    })}
                  />
                )}
                {setFieldValue &&
                  values?.esCargaCIF !== null &&
                  (values?.esCargaCIF?.value ? (
                    <InputCustomSetField
                      disabled={isView}
                      label={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.SUPPLIER) ?? ""}
                      value={values?.proveedor ?? ""}
                      handleChange={setFieldValue}
                      marginLeft
                      name="proveedor"
                      placeHolder={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.SUPPLIER) ?? ""}
                      width={isMobile ? "100%" : "50%"}
                      maxCharacters={255}
                    />
                  ) : (
                    values?.clienteTipo?.value === 2 &&
                    setFieldValue != null && (
                      <SelectCustom
                        isDisabled={isView}
                        label={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.CUSTOMER_CLASIFICATION) ?? ""}
                        value={values?.clienteClasificacion ?? null}
                        handleChange={setFieldValue}
                        name="clienteClasificacion"
                        marginLeft
                        placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
                        width={isMobile ? "100%" : "50%"}
                        validError={false}
                        options={customer.classification.map(item => {
                          return {
                            value: item?.idClasificacionCliente,
                            label: item?.descripcion.toLocaleUpperCase()
                          }
                        })}
                      />
                    )
                  ))}
              </Group>
              {values?.esCargaCIF?.value === false && (
                <>
                  <Group themeContext={themeContext}>
                    {setFieldValue != null && (
                      <SelectCustom
                        isDisabled={isView}
                        label={`${t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.IS_IMPORTER) ?? ""} (*)`}
                        value={values?.esImportador ?? null}
                        handleChange={setFieldValue}
                        name="esImportador"
                        errors={errors?.esImportador}
                        placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
                        width={isMobile ? "100%" : "50%"}
                        validError={true}
                        options={STATES_YES_NO.filter(item => (item?.value ?? 2) !== 2).map(item => {
                          return { ...item, label: t(item.label).toUpperCase() }
                        })}
                      />
                    )}
                    {setFieldValue != null && values?.esImportador && values?.esImportador?.value && (
                      <SelectCustom
                        isDisabled={isView}
                        label={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.ORIGIN) ?? ""}
                        value={values?.procedencias ?? null}
                        handleChange={setFieldValue}
                        name="procedencias"
                        isMulti
                        errors={errors?.procedencias}
                        marginLeft
                        placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
                        width={isMobile ? "100%" : "50%"}
                        validError={true}
                        options={customer.countries.map(item => {
                          return { label: item?.descripcion.toUpperCase(), value: item.idPais }
                        })}
                      />
                    )}
                  </Group>
                  <Group themeContext={themeContext}>
                    {setFieldValue != null && (
                      <SelectCustom
                        isDisabled={isView}
                        label={`${t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.IS_EXPORTER) ?? ""} (*)`}
                        value={values?.esExportador ?? null}
                        handleChange={setFieldValue}
                        name="esExportador"
                        errors={errors?.esExportador}
                        placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
                        width={isMobile ? "100%" : "50%"}
                        validError={true}
                        options={STATES_YES_NO.filter(item => (item?.value ?? 2) !== 2).map(item => {
                          return { ...item, label: t(item.label).toUpperCase() }
                        })}
                      />
                    )}
                    {setFieldValue != null && values?.esExportador && values?.esExportador?.value && (
                      <SelectCustom
                        isDisabled={isView}
                        label={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.DESTINATION) ?? ""}
                        value={values?.destinos ?? null}
                        handleChange={setFieldValue}
                        name="destinos"
                        isMulti
                        errors={errors?.destinos}
                        placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
                        width={isMobile ? "100%" : "50%"}
                        validError={true}
                        marginLeft
                        options={customer.countries.map(item => {
                          return { label: item?.descripcion.toUpperCase(), value: item.idPais }
                        })}
                      />
                    )}
                  </Group>
                </>
              )}

              <Group themeContext={themeContext}>
                {setFieldValue != null && (
                  <SelectCustom
                    isDisabled={isView}
                    label={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.ASSGNED_COMMERCIAL) ?? ""}
                    value={values?.comercialAsignado ?? null}
                    handleChange={setFieldValue}
                    name="comercialAsignado"
                    placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
                    width={isMobile ? "100%" : "50%"}
                    validError={false}
                    options={customer.assignedCommercial.map(item => {
                      return {
                        value: item.nombreUsuario,
                        label: `${item.apellido.toLocaleUpperCase()} ${item.nombre.toLocaleUpperCase()}`
                      }
                    })}
                  />
                )}
                {setFieldValue != null && (
                  <SelectCustom
                    label={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.ASSOCIATE_USERS) ?? ""}
                    value={values?.usuariosAsociados ?? null}
                    isDisabled={!getAuth(PERMISSION.CLIENTE_AGREGAR_ASOCIADOS)[0] || isView}
                    handleChange={setFieldValue}
                    name="usuariosAsociados"
                    isMulti
                    marginLeft
                    placeHolder={
                      !getAuth(PERMISSION.CLIENTE_AGREGAR_ASOCIADOS)[0]
                        ? `${t(TRANSLATION_INDEX_GLOBAL.THERE_IS_NO)} ${t(
                            TRANSLATION_INDEX_CUSTOMER_PROSPECT.ASSOCIATE_USERS
                          )}`
                        : t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""
                    }
                    width={isMobile ? "100%" : "50%"}
                    validError={false}
                    options={
                      customer?.assignedCommercial
                        ? customer?.assignedCommercial
                            ?.filter(item => item.nombreUsuario !== values?.comercialAsignado?.value)
                            .map(item => {
                              return {
                                value: item.nombreUsuario,
                                label: `${item.apellido.toLocaleUpperCase()} ${item.nombre.toLocaleUpperCase()}`
                              }
                            })
                        : []
                    }
                  />
                )}
              </Group>
              <Group themeContext={themeContext}></Group>
              <Group themeContext={themeContext}>
                <Contact themeContext={themeContext}>
                  <Title themeContext={themeContext}>
                    {t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.CONTACTS) ?? ""}
                    {!isView && (
                      <ButtonCustom
                        label={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.ADD_CONTACTS) ?? ""}
                        onClick={() => {
                          setSelected(null)
                          handleOpenModal("add")
                        }}
                        width="110px"
                        type="button"
                        background={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE[themeContext]}
                        backgroundHover={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE_HOVER[themeContext]}
                      />
                    )}
                  </Title>
                  <Content themeContext={themeContext}>
                    {values?.contactos && values?.contactos?.length > 0 ? (
                      <ContainerItem>
                        {values?.contactos.map((item, index) => {
                          return (
                            <Item themeContext={themeContext} key={index}>
                              <div>
                                <b>{`${t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.NAME)} - ${t(
                                  TRANSLATION_INDEX_CUSTOMER_PROSPECT.POSITION
                                )}:`}</b>{" "}
                                {`${item.apellido ?? ""} ${item.nombre ?? ""} - ${item.puesto ?? ""}`}
                              </div>
                              <div>
                                <b>Mails:</b> {item?.emails?.join(", ")}
                              </div>
                              <div>
                                <b>{t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.PHONES)}:</b> {item?.telefonos?.join(", ")}
                              </div>
                              <div>
                                <b>{t(TRANSLATION_INDEX_MENU.SEND_QUOTE)}:</b>{" "}
                                {item?.envioCotizacion === 0
                                  ? "No"
                                  : capitalizeFirstLetter(t(TRANSLATION_INDEX_GLOBAL.YES))}
                              </div>
                              {!isView && (
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                  <ButtonCustom
                                    style={{ marginTop: "10px" }}
                                    type="button"
                                    width="50%"
                                    onClick={() => {
                                      setSelected(item)
                                      handleOpenModal(ACTIONS.DELETE.VALUE)
                                    }}
                                    background={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE[themeContext]}
                                    backgroundHover={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE_HOVER[themeContext]}
                                    label={t(TRANSLATION_INDEX_GLOBAL.DELETE) ?? ""}
                                  />
                                  <ButtonCustom
                                    width="50%"
                                    marginLeft
                                    type="button"
                                    style={{ marginTop: "10px" }}
                                    onClick={() => {
                                      setSelected(item)
                                      handleOpenModal("edit")
                                    }}
                                    background={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE[themeContext]}
                                    backgroundHover={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE_HOVER[themeContext]}
                                    label={t(TRANSLATION_INDEX_GLOBAL.EDIT) ?? ""}
                                  />
                                </div>
                              )}
                            </Item>
                          )
                        })}
                      </ContainerItem>
                    ) : (
                      <div style={{ padding: "10px 10px" }}>
                        {t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.NO_CONTACTS) ?? ""}
                      </div>
                    )}
                  </Content>
                </Contact>
              </Group>
            </>
          </ContainerGroup>

          {(existCuit != null || action === ACTIONS.EDIT.VALUE || isView) && buttonGroup()}
        </Container>
      )}
    </>
  )
}

export default withFormik<IFormListProps, any>({
  mapPropsToValues: ({ data }): any => {
    return {
      idCliente: data?.idCliente,
      canalOrigen: data?.canalOrigen ?? "",
      clienteClasificacion:
        data?.clienteClasificacion === undefined || data?.clienteClasificacion === null
          ? null
          : {
              label: data?.clienteClasificacion?.descripcion.toUpperCase() ?? "",
              value: data?.clienteClasificacion?.idClasificacionCliente ?? null
            },
      clienteTipo:
        data?.clienteTipo === undefined || data?.clienteTipo === null
          ? null
          : {
              label:
                t(
                  data?.clienteTipo.idTipoCliente === 1
                    ? TRANSLATION_INDEX_CUSTOMER_PROSPECT.CUSTOMER
                    : TRANSLATION_INDEX_CUSTOMER_PROSPECT.PROSPECT
                ).toUpperCase() ?? "",
              value: data?.clienteTipo.idTipoCliente ?? null
            },
      comercialAsignado: data?.comercialAsignado
        ? {
            label: data?.comercialAsignado ?? "",
            value: data?.comercialAsignado ?? null
          }
        : null,
      cuit: data?.cuit,
      destinos: data?.destinos?.map(item => {
        return { value: item?.idPais, label: item?.descripcion }
      }),
      domicilio: data?.domicilio ?? "",
      email: data?.email,
      esCargaCIF:
        data?.esCargaCIF === undefined || data?.esCargaCIF === null
          ? null
          : {
              label: t(STATES_YES_NO.find(item => item.value === data?.esCargaCIF)?.label ?? "").toUpperCase(),
              value: STATES_YES_NO.find(item => item.value === data?.esCargaCIF)?.value
            },
      esExportador:
        data?.esExportador === undefined || data?.esExportador === null
          ? null
          : {
              label: t(STATES_YES_NO.find(item => item.value === data?.esExportador)?.label ?? "").toUpperCase(),
              value: STATES_YES_NO.find(item => item.value === data?.esExportador)?.value
            },
      esImportador:
        data?.esImportador === undefined || data?.esImportador === null
          ? null
          : {
              label: t(STATES_YES_NO.find(item => item.value === data?.esImportador)?.label ?? "").toUpperCase(),
              value: STATES_YES_NO.find(item => item.value === data?.esImportador)?.value
            },
      contactos: data?.contactos
        ? data?.contactos.map((item, index) => {
            item.id = index
            return item
          })
        : [],
      procedencias: data?.procedencias?.map(item => {
        return { value: item?.idPais, label: item?.descripcion }
      }),
      clientesRelacionados: data?.clientesRelacionados?.map(item => {
        return { value: item?.idCliente, label: `${item.razonSocial.toUpperCase()}` }
      }),
      proveedor: data?.proveedor ?? "",
      razonSocial: data?.razonSocial ?? "",
      telefono: data?.telefono ?? "",
      usuariosAsociados: data?.usuariosAsociados?.map(item => {
        return { value: item?.nombreUsuario, label: `${item.apellido.toUpperCase()} ${item.nombre.toUpperCase()}` }
      })
    }
  },
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: ({ action }) => (action === ACTIONS.DELETE.VALUE ? deleteScheme : schemaAdd),
  handleSubmit: (values, { setSubmitting, props: { accept } }): any => {
    const request = {
      cuit: values?.cuit,
      razonSocial: values?.razonSocial.trim(),
      esImportador: values?.esImportador?.value ?? false,
      esExportador: values?.esExportador?.value ?? false,
      clienteTipo: {
        idTipoCliente: values?.clienteTipo?.value,
        descripcion: ""
      },
      domicilio: values?.domicilio.trim(),
      email: values?.email,
      telefono: values?.telefono.trim(),
      esCargaCIF: values?.esCargaCIF?.value,
      proveedor: values?.proveedor.trim(),
      clienteClasificacion: values?.clienteClasificacion?.value && {
        idClasificacionCliente: values?.clienteClasificacion?.value,
        descripcion: ""
      },
      comercialAsignado: values?.comercialAsignado?.value,
      canalOrigen: values?.canalOrigen.trim(),
      procedencias: values?.procedencias
        ? values?.procedencias.map(item => {
            return {
              idPais: item?.value,
              descripcion: ""
            }
          })
        : null,
      destinos: values?.destinos
        ? values?.destinos.map(item => {
            return {
              idPais: item?.value,
              descripcion: ""
            }
          })
        : null,
      usuariosAsociados: values?.usuariosAsociados
        ? values?.usuariosAsociados.map(item => {
            return {
              nombreUsuario: item?.value,
              nombre: "",
              apellido: ""
            }
          })
        : null,
      clientesRelacionados: values?.clientesRelacionados
        ? values?.clientesRelacionados
            .filter(item => item.label.toUpperCase() !== values.razonSocial.toUpperCase().trim())
            .map(item => {
              return {
                idCliente: item?.value,
                cuit: "",
                razonSocial: ""
              }
            })
        : null,
      contactos: values?.contactos,
      idCliente: values?.idCliente
    }
    if (accept != null) accept(request)
    setSubmitting(false)
  }
})(FormaddUpdate)
