import { TRANSLATION_INDEX_EVENT } from "../../config/translation"

export const EVENT_TYPE = [
  {
    idTipoEvento: 6,
    descripcion: TRANSLATION_INDEX_EVENT.QUOTE_SUBMISSION
  },
  {
    idTipoEvento: 3,
    descripcion: "MAIL"
  },
  {
    idTipoEvento: 1,
    descripcion: TRANSLATION_INDEX_EVENT.PRESENTIAL_MEETING
  },
  {
    idTipoEvento: 4,
    descripcion: TRANSLATION_INDEX_EVENT.PHONE
  },
  {
    idTipoEvento: 2,
    descripcion: TRANSLATION_INDEX_EVENT.VIDEO_CALL
  },
  {
    idTipoEvento: 5,
    descripcion: "WHATSAPP"
  }
]
