/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import ContainerPage from "../../components/ContainerPage"
import {
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_MENU,
  TRANSLATION_INDEX_PERMISSION
} from "../../config/translation"

import { ContainerButtom, ContainerTable, ItemList, List } from "./_styles"

import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"

import ContainerGroupItemList from "./components/ContainerGroupItemList/ContainerGroupItemList"

import MainContainerGroup from "./components/MainContainerGroup/MainContainerGroup"
import { profileSelector } from "../../state/modules/profile/_selectors"
import Search from "./form/search"
import {
  getPermissionByProfileRequest,
  getPermissionRequest,
  postPermissionRequest
} from "../../state/modules/permissionByProfile/actions"
import { permissionSelector } from "../../state/modules/permissionByProfile/_selectors"
import ButtonCustom from "../../components/ButtonCustom"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { getAuth } from "../../utils/functions"
import { getProfileRequest } from "../../state/modules/profile/actions"
import { PERMISSION } from "../../utils/constants"
import { useGlobalContext } from "../../context/GlobalContext"
import { type IProfile } from "../../interfaces/IProfile"
import { type IPermission } from "../../interfaces/IPermission"

const PermissionByProfilePage = (): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const profile = useSelector(profileSelector)
  const permissions = useSelector(permissionSelector)
  const { setLoadingContext, setOpenAllCollansePermission, themeContext } = useGlobalContext()
  const [permissionsSelected, setPermissionsSelected] = React.useState<IPermission[]>([])
  const [profileSelected, setProfileSelected] = React.useState<IProfile | null>(null)
  const [optionIsNull, setOptionIsNull] = React.useState(false)
  const [collapseAll, setCollapseAll] = React.useState<boolean>(false)
  const [collapseSetting, setCollapseSetting] = React.useState<boolean>(false)
  const [collapseOperation, setCollapseOperation] = React.useState<boolean>(false)
  const [collapseCommercial, setCollapseCommercial] = React.useState<boolean>(false)
  const addPermission = (obj: IPermission, array: IPermission[] = []): void => {
    if (getAuth(PERMISSION.PERMISO_PERFIL_MODIFICAR)[0]) {
      if (array.length > 0) {
        let items = permissionsSelected
        if (permissionsSelected.some(item => item.categoria === array[0].categoria)) {
          items = items.filter(item => item.categoria !== array[0].categoria)
        } else {
          array.forEach(item => {
            items.push(item)
          })
        }
        setPermissionsSelected([...items])
      } else setPermissionsSelected([...permissionsSelected, obj])
    }
  }

  const deletePermission = (obj: IPermission): void => {
    if (getAuth(PERMISSION.PERMISO_PERFIL_MODIFICAR)[0]) {
      setPermissionsSelected(permissionsSelected.filter(item => item.idPermiso !== obj.idPermiso))
    }
  }

  const updatePermission = (): void => {
    dispatch(
      postPermissionRequest({
        permisos: permissionsSelected.map(item => {
          return {
            idPermiso: item.idPermiso,
            codigo: item.codigo,
            categoria: item.categoria
          }
        }),
        perfil: {
          idPerfil: profileSelected?.idPerfil,
          descripcion: profileSelected?.descripcion
        }
      })
    )
  }

  React.useEffect(() => {
    setLoadingContext(profile.loading)
  }, [profile])

  React.useEffect(() => {
    if (profileSelected?.descripcion === undefined) setProfileSelected(null)
  }, [profileSelected])

  React.useEffect(() => {
    setLoadingContext(permissions.loading)
  }, [permissions])

  React.useEffect(() => {
    if (permissions.showToast) {
      if (permissions.hasErrMessage) {
        toast.error(t(permissions.errorCode))
      } else {
        toast.success(t(permissions.toastMessage))
        dispatch(getPermissionRequest())
        dispatch(getProfileRequest())
      }
    }
  }, [permissions.showToast])

  React.useEffect(() => {
    if (profile.showToast) {
      if (profile.hasErrMessage) {
        toast.error(t(profile.errorCode))
      } else {
        toast.success(t(profile.toastMessage))
        dispatch(getPermissionRequest())
        dispatch(getProfileRequest())
      }
    }
  }, [profile.showToast])

  React.useEffect(() => {
    setPermissionsSelected(permissions.permissionByProfileSelected)
  }, [permissions.permissionByProfileSelected])

  React.useEffect(() => {
    if (profileSelected != null) dispatch(getPermissionByProfileRequest(profileSelected?.idPerfil))
  }, [profileSelected])

  React.useEffect(() => {
    if (collapseAll) setOpenAllCollansePermission(true)
    else setOpenAllCollansePermission(false)
  }, [collapseAll])

  React.useEffect(() => {
    setLoadingContext(true)
    dispatch(getPermissionRequest())
    dispatch(getProfileRequest())
  }, [])

  return (
    <>
      <ContainerPage title={`${t(TRANSLATION_INDEX_MENU.PERMISSION_BY_PROFILES)}`} actions={[]}>
        <>
          <ContainerTable themeContext={themeContext} opacity={1}>
            <>
              <Search
                data={profile.data}
                valueSelected={profileSelected}
                setValueSelected={setProfileSelected}
                setOptionIsNull={setOptionIsNull}
              />
              {profileSelected != null && !optionIsNull && (
                <div style={{ position: "relative", width: "450px", marginLeft: "-12px", color: "white" }}>
                  <List themeContext={themeContext}>
                    <ItemList>
                      <MainContainerGroup
                        label={t(TRANSLATION_INDEX_GLOBAL.SELECT_ALL)}
                        section="all"
                        collapseSetting={collapseAll}
                        setCollapseSetting={setCollapseAll}
                        setPermissionsSelected={setPermissionsSelected}
                        permissionSelected={permissionsSelected}
                      />
                      <List themeContext={themeContext}>
                        <ItemList>
                          <MainContainerGroup
                            label={t(TRANSLATION_INDEX_MENU.SETTING)}
                            section="settings"
                            collapseSetting={collapseSetting}
                            setCollapseSetting={setCollapseSetting}
                            setPermissionsSelected={setPermissionsSelected}
                            permissionSelected={permissionsSelected}
                          />

                          {collapseSetting && (
                            <List themeContext={themeContext}>
                              <ItemList>
                                <ContainerGroupItemList
                                  label={t(TRANSLATION_INDEX_PERMISSION.USUARIO_BACKOFFICE) ?? ""}
                                  permissionSelected={permissionsSelected}
                                  handleAdd={addPermission}
                                  handleDelete={deletePermission}
                                  permission={permissions.data}
                                  section={"settings"}
                                  category={1}
                                />
                              </ItemList>
                              <ItemList>
                                <ContainerGroupItemList
                                  label={t(TRANSLATION_INDEX_PERMISSION.USUARIO_APP) ?? ""}
                                  permissionSelected={permissionsSelected}
                                  handleAdd={addPermission}
                                  handleDelete={deletePermission}
                                  permission={permissions.data}
                                  section={"settings"}
                                  category={2}
                                />
                              </ItemList>
                              <ItemList>
                                <ContainerGroupItemList
                                  label={t(TRANSLATION_INDEX_PERMISSION.PERFIL) ?? ""}
                                  permissionSelected={permissionsSelected}
                                  handleAdd={addPermission}
                                  handleDelete={deletePermission}
                                  permission={permissions.data}
                                  section={"settings"}
                                  category={3}
                                />
                              </ItemList>
                              <ItemList>
                                <ContainerGroupItemList
                                  label={t(TRANSLATION_INDEX_PERMISSION.PERMISO_PERFIL) ?? ""}
                                  permissionSelected={permissionsSelected}
                                  handleAdd={addPermission}
                                  handleDelete={deletePermission}
                                  permission={permissions.data}
                                  section={"settings"}
                                  category={4}
                                />
                              </ItemList>
                              <ItemList>
                                <ContainerGroupItemList
                                  label={t(TRANSLATION_INDEX_PERMISSION.PREFERENCIAS) ?? ""}
                                  permissionSelected={permissionsSelected}
                                  handleAdd={addPermission}
                                  handleDelete={deletePermission}
                                  permission={permissions.data}
                                  section={"settings"}
                                  category={5}
                                />
                              </ItemList>
                            </List>
                          )}
                        </ItemList>
                        <ItemList>
                          <MainContainerGroup
                            label={t(TRANSLATION_INDEX_MENU.OPERATION)}
                            section="operations"
                            collapseSetting={collapseOperation}
                            setCollapseSetting={setCollapseOperation}
                            setPermissionsSelected={setPermissionsSelected}
                            permissionSelected={permissionsSelected}
                          />
                          {collapseOperation && (
                            <List themeContext={themeContext}>
                              <ItemList>
                                <ContainerGroupItemList
                                  label={t(TRANSLATION_INDEX_PERMISSION.AUT_INFOCLIENTES) ?? ""}
                                  permissionSelected={permissionsSelected}
                                  handleAdd={addPermission}
                                  handleDelete={deletePermission}
                                  permission={permissions.data}
                                  section={"operations"}
                                  category={6}
                                />
                              </ItemList>
                              <ItemList>
                                <ContainerGroupItemList
                                  label={t(TRANSLATION_INDEX_PERMISSION.COTIZADOR) ?? ""}
                                  permissionSelected={permissionsSelected}
                                  handleAdd={addPermission}
                                  handleDelete={deletePermission}
                                  permission={permissions.data}
                                  section={"operations"}
                                  category={7}
                                />
                              </ItemList>
                              <ItemList>
                                <ContainerGroupItemList
                                  label={t(TRANSLATION_INDEX_PERMISSION.REFERENCIA_CLIENTE) ?? ""}
                                  permissionSelected={permissionsSelected}
                                  handleAdd={addPermission}
                                  handleDelete={deletePermission}
                                  permission={permissions.data}
                                  section={"operations"}
                                  category={8}
                                />
                              </ItemList>
                              <ItemList>
                                <ContainerGroupItemList
                                  label={t(TRANSLATION_INDEX_MENU.RATINGS) ?? ""}
                                  permissionSelected={permissionsSelected}
                                  handleAdd={addPermission}
                                  handleDelete={deletePermission}
                                  permission={permissions.data}
                                  section={"operations"}
                                  category={10}
                                />
                              </ItemList>
                            </List>
                          )}
                        </ItemList>
                        <ItemList>
                          <MainContainerGroup
                            label={t(TRANSLATION_INDEX_MENU.COMMERCIAL)}
                            section="commercial"
                            collapseSetting={collapseCommercial}
                            setCollapseSetting={setCollapseCommercial}
                            setPermissionsSelected={setPermissionsSelected}
                            permissionSelected={permissionsSelected}
                          />
                          {collapseCommercial && (
                            <List themeContext={themeContext}>
                              <ItemList>
                                <ContainerGroupItemList
                                  label={t(TRANSLATION_INDEX_MENU.PROSPECTS) ?? ""}
                                  permissionSelected={permissionsSelected}
                                  handleAdd={addPermission}
                                  handleDelete={deletePermission}
                                  permission={permissions.data}
                                  section={"commercial"}
                                  category={9}
                                />
                              </ItemList>
                              <ItemList>
                                <ContainerGroupItemList
                                  label={t(TRANSLATION_INDEX_MENU.EXPENSES_ORIGIN) ?? ""}
                                  permissionSelected={permissionsSelected}
                                  handleAdd={addPermission}
                                  handleDelete={deletePermission}
                                  permission={permissions.data}
                                  section={"commercial"}
                                  category={11}
                                />
                              </ItemList>
                              <ItemList>
                                <ContainerGroupItemList
                                  label={t(TRANSLATION_INDEX_MENU.EXPENSES_BA) ?? ""}
                                  permissionSelected={permissionsSelected}
                                  handleAdd={addPermission}
                                  handleDelete={deletePermission}
                                  permission={permissions.data}
                                  section={"commercial"}
                                  category={12}
                                />
                              </ItemList>
                              <ItemList>
                                <ContainerGroupItemList
                                  label={t(TRANSLATION_INDEX_MENU.QQUOTES_TEMPLATE) ?? ""}
                                  permissionSelected={permissionsSelected}
                                  handleAdd={addPermission}
                                  handleDelete={deletePermission}
                                  permission={permissions.data}
                                  section={"commercial"}
                                  category={13}
                                />
                              </ItemList>
                              <ItemList>
                                <ContainerGroupItemList
                                  label={t(TRANSLATION_INDEX_MENU.SEND_QUOTE) ?? ""}
                                  permissionSelected={permissionsSelected}
                                  handleAdd={addPermission}
                                  handleDelete={deletePermission}
                                  permission={permissions.data}
                                  section={"commercial"}
                                  category={14}
                                />
                              </ItemList>
                              <ItemList>
                                <ContainerGroupItemList
                                  label={t(TRANSLATION_INDEX_MENU.VIEW_SEND_QUOTE) ?? ""}
                                  permissionSelected={permissionsSelected}
                                  handleAdd={addPermission}
                                  handleDelete={deletePermission}
                                  permission={permissions.data}
                                  section={"commercial"}
                                  category={15}
                                />
                              </ItemList>
                              <ItemList>
                                <ContainerGroupItemList
                                  label={t(TRANSLATION_INDEX_MENU.CUSTOMS) ?? ""}
                                  permissionSelected={permissionsSelected}
                                  handleAdd={addPermission}
                                  handleDelete={deletePermission}
                                  permission={permissions.data}
                                  section={"commercial"}
                                  category={16}
                                />
                              </ItemList>
                              <ItemList>
                                <ContainerGroupItemList
                                  label={t(TRANSLATION_INDEX_MENU.EVENTS) ?? ""}
                                  permissionSelected={permissionsSelected}
                                  handleAdd={addPermission}
                                  handleDelete={deletePermission}
                                  permission={permissions.data}
                                  section={"commercial"}
                                  category={17}
                                />
                              </ItemList>
                              <ItemList>
                                <ContainerGroupItemList
                                  label={t(TRANSLATION_INDEX_MENU.REMINDERS) ?? ""}
                                  permissionSelected={permissionsSelected}
                                  handleAdd={addPermission}
                                  handleDelete={deletePermission}
                                  permission={permissions.data}
                                  section={"commercial"}
                                  category={18}
                                />
                              </ItemList>
                              <ItemList>
                                <ContainerGroupItemList
                                  label={t(TRANSLATION_INDEX_MENU.REPORT) ?? ""}
                                  permissionSelected={permissionsSelected}
                                  handleAdd={addPermission}
                                  handleDelete={deletePermission}
                                  permission={permissions.data}
                                  section={"commercial"}
                                  category={19}
                                />
                              </ItemList>
                            </List>
                          )}
                        </ItemList>
                      </List>
                    </ItemList>
                  </List>
                </div>
              )}
            </>
          </ContainerTable>
          {getAuth(PERMISSION.PERMISO_PERFIL_MODIFICAR)[0] && profileSelected != null && !optionIsNull && (
            <ContainerButtom themeContext={themeContext}>
              <ButtonCustom
                width="80px"
                background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
                backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]}
                label={t(TRANSLATION_INDEX_GLOBAL.UPDATE) ?? ""}
                onClick={() => {
                  updatePermission()
                }}
              />
            </ContainerButtom>
          )}
        </>
      </ContainerPage>
    </>
  )
}

export default PermissionByProfilePage
