import i18n from "i18next"
import languajeDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import global_es from "./es/global.json"
import global_en from "./en/global.json"
import gretting_es from "./es/gretting.json"
import gretting_en from "./en/gretting.json"
import login_es from "./es/login.json"
import login_en from "./en/login.json"
import menu_es from "./es/menu.json"
import menu_en from "./en/menu.json"
import userBackofficeClientApp_es from "./es/userBackofficeClientApp.json"
import userBackofficeClientApp_en from "./en/userBackofficeClientApp.json"

import profile_es from "./es/profile.json"
import profile_en from "./en/profile.json"

import permission_es from "./es/permission.json"
import permission_en from "./en/permission.json"

import responseCode_es from "./es/responseCode.json"
import responseCode_en from "./en/responseCode.json"

import authorizeInformation_es from "./es/authorizeInformation.json"
import authorizeInformation_en from "./en/authorizeInformation.json"

import preference_es from "./es/preference.json"
import preference_en from "./en/preference.json"

import quoter_es from "./es/quoter.json"
import quoter_en from "./en/quoter.json"

import event_es from "./es/event.json"
import event_en from "./en/event.json"

import calendar_es from "./es/calendar.json"
import calendar_en from "./en/calendar.json"

import customerProspect_es from "./es/customerProspect.json"
import customerProspect_en from "./en/customerProspect.json"

import report_es from "./es/report.json"
import report_en from "./en/report.json"

void i18n
  .use(languajeDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    resources: {
      en: {
        translation: {
          global: global_en,
          gretting: gretting_en,
          login: login_en,
          menu: menu_en,
          userBackoffice: userBackofficeClientApp_en,
          profile: profile_en,
          responseCode: responseCode_en,
          permission: permission_en,
          authorizeInformation: authorizeInformation_en,
          preference: preference_en,
          quoter: quoter_en,
          calendar: calendar_en,
          customerProspect: customerProspect_en,
          event: event_en,
          report: report_en
        }
      },
      es: {
        translation: {
          global: global_es,
          gretting: gretting_es,
          login: login_es,
          menu: menu_es,
          userBackoffice: userBackofficeClientApp_es,
          profile: profile_es,
          responseCode: responseCode_es,
          permission: permission_es,
          authorizeInformation: authorizeInformation_es,
          preference: preference_es,
          calendar: calendar_es,
          quoter: quoter_es,
          customerProspect: customerProspect_es,
          event: event_es,
          report: report_es
        }
      }
    }
  })
