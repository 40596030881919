/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { type ReactElement, useEffect } from "react"
import {
  Blur,
  Container,
  ContainerLanguaje,
  ContainerLanguajeActive,
  ContainerLogin,
  ImageWrapperMain
} from "./_styles"
import { useDispatch, useSelector } from "react-redux"

import { postLoginChangeRequest, postLoginRequest } from "../../state/modules/login/actions"
import { loginSelector } from "../../state/modules/login/_selectors"
import { IoMdArrowDropdown } from "react-icons/io"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import Login from "./form/Login"
import { LANGUAGE, ROUTES } from "../../utils/constants"
import { STORAGE } from "../../utils/localStorage"
import ChangePassword from "./form/ChangePassword"

const LoginPage = (): ReactElement => {
  const [activeLanguage, setActiveLanguage] = React.useState<boolean>(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const login = useSelector(loginSelector)

  const handleDispatch = (values): void => {
    dispatch(postLoginRequest(values))
  }
  const handleDispatchChange = (values): void => {
    dispatch(postLoginChangeRequest({ nuevaClave: values.newPass }))
  }

  const getCurrentLangage = (): string => {
    const language = i18n.language.toLocaleUpperCase().slice(0, 2)
    return ["ES", "EN"].includes(language) ? language : "EN"
  }

  const selectLanguage = (language: string): void => {
    void i18n.changeLanguage(language)
    setActiveLanguage(false)
  }

  useEffect(() => {
    if ((login?.token || STORAGE.get(STORAGE.TOKEN)) && !STORAGE.get(STORAGE.CHANGE_PASSWORD)) {
      navigate(ROUTES.HOME.pathName)
    }
  }, [login])

  return (
    <Container>
      <ImageWrapperMain />
      <ContainerLogin>
        <Blur />
        <ContainerLanguajeActive
          onClick={() => {
            setActiveLanguage(!activeLanguage)
          }}
        >
          <img
            width="30px"
            style={{ marginRight: "10px" }}
            src={LANGUAGE[getCurrentLangage()].FLAG}
            alt={LANGUAGE[getCurrentLangage()].FLAG}
          />
          <IoMdArrowDropdown size={20} color="#fff" />
        </ContainerLanguajeActive>
        {activeLanguage && (
          <div
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
              width: "100%",
              height: "calc(100% - 2px)"
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0px",
                right: "0px",
                width: "100%",
                height: "calc(100% - 2px)",
                zIndex: "99"
              }}
              role="button"
              tabIndex={0}
              onKeyDown={() => {
                setActiveLanguage(!setActiveLanguage)
              }}
              onClick={() => {
                setActiveLanguage(!setActiveLanguage)
              }}
            />
            {getCurrentLangage() === "EN" && (
              <ContainerLanguaje
                style={{ top: "60px" }}
                onClick={() => {
                  selectLanguage("es")
                }}
              >
                <img
                  width="30px"
                  style={{ marginRight: "10px", boxShadow: "0px 0px 5px 0px rgba(252, 252, 252, 0.75)" }}
                  src={LANGUAGE.ES.FLAG}
                  alt={LANGUAGE.ES.FLAG}
                />
              </ContainerLanguaje>
            )}
            {getCurrentLangage() === "ES" && (
              <ContainerLanguaje
                style={{ top: "60px" }}
                onClick={() => {
                  selectLanguage("en")
                }}
              >
                <img
                  width="30px"
                  style={{ marginRight: "10px", boxShadow: "0px 0px 5px 0px rgba(252, 252, 252, 0.75)" }}
                  src={LANGUAGE.EN.FLAG}
                  alt={LANGUAGE.EN.FLAG}
                />
              </ContainerLanguaje>
            )}
          </div>
        )}
        {STORAGE.get(STORAGE.CHANGE_PASSWORD) === true && STORAGE.get(STORAGE.TOKEN) ? (
          <ChangePassword accept={handleDispatchChange} />
        ) : (
          <Login accept={handleDispatch} />
        )}
      </ContainerLogin>
    </Container>
  )
}

export default LoginPage
