import { login } from "./_duck"
import * as types from "./types"

// Types
import { type PayloadAction } from "../../types"
import { type ModuleType } from "./_definitions"
import { STORAGE } from "../../../utils/localStorage"
import { TRANSLATION_INDEX_RESPONSE_CODE } from "../../../config/translation"

const initialModuleState: ModuleType = {
  token: STORAGE.get(STORAGE.TOKEN) ?? "",
  changePassword: STORAGE.get(STORAGE.CHANGE_PASSWORD) ?? false,
  loading: false,
  hasErrMessage: false,
  showToast: false,
  toastMessage: "",
  errorCode: ""
}

const reducer = login.createReducer(
  {
    [types.LOGIN_POST_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.LOGIN_POST_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      STORAGE.set(STORAGE.TOKEN, payload.token)
      STORAGE.set(STORAGE.NOTIFICATION, payload.notificacionesPorMail)
      STORAGE.set(STORAGE.CHANGE_PASSWORD, payload.debeCambiarClave ?? true)
      return {
        ...state,
        token: payload.token,
        changePassword: payload?.debeCambiarClave ?? true,
        loading: false
      }
    },
    [types.LOGIN_POST_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        token: "",
        loading: false,
        hasErrMessage: true,
        errorCode: payload?.response?.data?.message ?? "GENERIC_ERROR"
      }
    },
    [types.LOGIN_FORGOT_POST_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      hasErrMessage: false,
      showToast: false,
      toastMessage: "",
      errorCode: ""
    }),
    [types.LOGIN_FORGOT_POST_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        loading: false,
        showToast: true,
        toastMessage: TRANSLATION_INDEX_RESPONSE_CODE.EMAIL_LOGIN_SEND
      }
    },
    [types.LOGIN_FORGOT_POST_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        token: "",
        loading: false,
        hasErrMessage: true,
        showToast: true,
        toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
        errorCode: payload?.response?.data?.message ?? "GENERIC_ERROR"
      }
    },
    [types.LOGIN_CHANGE_POST_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      hasErrMessage: false,
      showToast: false,
      toastMessage: "",
      errorCode: ""
    }),
    [types.LOGIN_CHANGE_POST_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      STORAGE.set(STORAGE.CHANGE_PASSWORD, false)
      return {
        ...state,
        loading: false,
        changePassword: false
      }
    },
    [types.LOGIN_CHANGE_POST_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        loading: false,
        hasErrMessage: true,
        showToast: true,
        toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
        errorCode: payload?.response?.data?.message ?? "GENERIC_ERROR"
      }
    },
    [types.LOGOUT]: (): ModuleType => ({
      token: "",
      changePassword: false,
      loading: false,
      hasErrMessage: false,
      errorCode: "",
      showToast: false,
      toastMessage: ""
    }),
    [types.CLEAN_ERROR]: (state: ModuleType): ModuleType => ({
      ...state,
      hasErrMessage: false,
      errorCode: "",
      showToast: false,
      toastMessage: ""
    }),
    [types.LOGIN_GET_TOKEN_STORAGE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      token: payload.token
    })
  },
  initialModuleState
)

export default reducer
