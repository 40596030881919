/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import ContainerPage from "../../components/ContainerPage"
import {
  TRANSLATION_INDEX_EVENT,
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_PERMISSION,
  TRANSLATION_INDEX_REPORT
} from "../../config/translation"
import DataTable from "react-data-table-component"

import { ContainerTable } from "./_styles"
import { customStylesContext } from "../../styles/_styleDatatable"
import { useDispatch, useSelector } from "react-redux"
import SearchUserClientApp from "./forms/search/index"

import { NoData } from "../../components/NoData"
import { useGlobalContext } from "../../context/GlobalContext"
import "../../styles/styles.css"
import { useMobile } from "../../hook/useMobile"
import { getReportClearAll, getReportUncontactCustomerRequest } from "../../state/modules/reportCommercial/actions"
import { reportCommercialSelector } from "../../state/modules/reportCommercial/_selectors"

import IconExcel from "../../components/Icons/IconExcel"
import { type IReportUncontactCustomer } from "../../interfaces/IReportUncontactCustomer"
import { getAssignedCommercialRequest } from "../../state/modules/customer/actions"

const ReportUncontactedCustomerPage = (): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useMobile()
  const dispatch = useDispatch()
  const reportCustomer = useSelector(reportCommercialSelector)
  const [dataFiltered, setDataFiltered] = React.useState<IReportUncontactCustomer[]>()
  const [filters, setFilters] = React.useState<any>()

  const [searchData, setSearchData] = React.useState(false)
  const [clearFilter, setClearFilter] = React.useState(false)
  const { setLoadingContext, themeContext } = useGlobalContext()
  const { loadingContext } = useGlobalContext()

  const paginationComponentOptions = {
    rowsPerPageText: t(TRANSLATION_INDEX_GLOBAL.ROWS_PER_PAGE),
    rangeSeparatorText: t(TRANSLATION_INDEX_GLOBAL.OF),
    selectAllRowsItem: true,
    selectAllRowsItemText: t(TRANSLATION_INDEX_GLOBAL.ALL)
  }

  const columns = [
    {
      name: "CUIT",
      selector: (row: IReportUncontactCustomer) => `${row.cliente.cuit}`,
      width: isMobile ? "100px" : "20%",
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_EVENT.CUSTOMER_PROSPECT),
      selector: (row: IReportUncontactCustomer) => `${row.cliente.razonSocial}`,
      width: isMobile ? "500px" : "30%",
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_REPORT.USER),
      selector: (row: IReportUncontactCustomer) => `${row.comercial.apellido} ${row.comercial.nombre}`,
      width: isMobile ? "200px" : "35%",
      sortable: true
    }
  ]

  const handleExcel = (): void => {
    let queryParam = ""
    if (filters.fechaDesde) queryParam = queryParam + `fechaDesde=${filters?.fechaDesde}&`
    if (filters.fechaHasta) queryParam = queryParam + `fechaHasta=${filters?.fechaHasta}&`
    if (filters.idsClientes) queryParam = queryParam + `idsClientes=${filters?.idsClientes.join(",")}&`
    if (filters.comerciales) queryParam = queryParam + `comerciales=${filters?.comerciales.join(",")}&`

    window.open(
      `${process.env.REACT_APP_API_URL}/api/backoffice/v1/reportes/clientesSinContactar/exportar?${queryParam}`
    )
  }

  React.useEffect(() => {
    setLoadingContext(reportCustomer?.loading)
    setDataFiltered(reportCustomer?.reportUncontactCustomer)
  }, [reportCustomer])

  React.useEffect(() => {
    if (searchData) {
      setLoadingContext(true)
      dispatch(getReportUncontactCustomerRequest(filters))
      setSearchData(false)
    }
  }, [searchData])

  React.useEffect(() => {
    if (clearFilter) {
      setDataFiltered([])
      dispatch(getReportClearAll())
      setClearFilter(false)
    }
  }, [clearFilter])

  React.useEffect(() => {
    dispatch(getAssignedCommercialRequest())
    return () => dispatch(getReportClearAll())
  }, [])

  return (
    <>
      <ContainerPage
        title={`${t(TRANSLATION_INDEX_PERMISSION.REPORTE_CLIENTES_SIN_CONTACTAR)}`}
        actions={
          reportCustomer?.reportUncontactCustomer?.length > 0
            ? [
                {
                  label: t(TRANSLATION_INDEX_GLOBAL.EXPORT),
                  icon: <IconExcel size={13} />,
                  permission: "*",
                  click: handleExcel
                }
              ]
            : []
        }
      >
        <>
          <ContainerTable themeContext={themeContext} opacity={1}>
            <SearchUserClientApp
              data={reportCustomer?.data}
              setDataFiltered={setDataFiltered}
              setFilters={setFilters}
              setSearchData={setSearchData}
              setClearFilter={setClearFilter}
            />
            <DataTable
              data={dataFiltered ?? []}
              theme="solarized"
              noDataComponent={
                <NoData
                  label={
                    loadingContext
                      ? ""
                      : filters
                      ? TRANSLATION_INDEX_GLOBAL.NO_DATA_2
                      : TRANSLATION_INDEX_GLOBAL.USE_SEARCH_TO_GET_RESULTS
                  }
                />
              }
              columns={columns}
              dense
              pagination
              paginationComponentOptions={paginationComponentOptions}
              striped
              customStyles={customStylesContext(themeContext)}
            />
          </ContainerTable>
        </>
      </ContainerPage>
    </>
  )
}

export default ReportUncontactedCustomerPage
