/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import { type IPropSearch } from "./_types"
import { withFormik } from "formik"

import { ContainerSerch, Group } from "./_styles"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"
import { TRANSLATION_INDEX_EVENT, TRANSLATION_INDEX_GLOBAL } from "../../../../config/translation"
import { useMobile } from "../../../../hook/useMobile"
import { DatePickerCustom } from "../../../../components/DatePickerCustom/DatePickerCustom/Index"
import { useResize } from "../../../../hook/useResize"
import SelectAsyncCustom from "../../../../components/SelectAsyncCustom"
import CustomerService from "../../../../services/CustomerService"
import SelectCustom from "../../../../components/SelectCustom"
import { useSelector } from "react-redux"
import { eventSelector } from "../../../../state/modules/event/_selectors"
import { getAuth } from "../../../../utils/functions"
import { PERMISSION } from "../../../../utils/constants"
import { customerSelector } from "../../../../state/modules/customer/_selectors"
import { ContainerButtom } from "../../../CustomerProspect/forms/search/_styles"
import ButtonCustom from "../../../../components/ButtonCustom"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { useGlobalContext } from "../../../../context/GlobalContext"
import { EVENT_TYPE } from "../../constants"
import { type IOption } from "../../../../interfaces/IOption"
import { filterDataEvent } from "./filter"

const SearchUserClientApp = ({
  setDataFiltered = () => {},
  setFilters,
  data,
  isUserPending = false,
  values,
  errors,
  refreshFilter,
  setClearFilter,
  setSubmitting,
  isSubmitting,
  handleChange,
  setFieldValue,
  handleReset,
  handleBlur,
  handleSubmit
}: IFormListProps): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useMobile()
  const screenSize = useResize()
  const { themeContext } = useGlobalContext()
  const [fromDate, setFromDate] = React.useState<string | Date | null>(null)
  const [toDate, setToDate] = React.useState<string | Date | null>(null)
  const events = useSelector(eventSelector)
  const customers = useSelector(customerSelector)
  const promiseOptions = async (inputValue: string): Promise<any> => {
    const response = await new Promise(resolve => {
      if (inputValue.length > 2) {
        resolve(CustomerService(inputValue))
      } else {
        resolve([{ value: "-1", label: t(TRANSLATION_INDEX_GLOBAL.ENTER_THREE_CHARACTER), isDisabled: true }])
      }
    })
    return response
  }

  const handleSearch = (): void => {
    if (setFilters) setFilters(values)

    setDataFiltered(filterDataEvent(data, values))
  }
  const getUserCurrentToAssignmentCommercial = (): void => {
    if (data && customers?.assignedCommercial) {
      const user = getAuth(PERMISSION.EVENTOS_CONSULTAR_OTROS_USUARIOS)
      if (!user[0] && setFieldValue) {
        const valueUser = customers.assignedCommercial.find(item => item.nombreUsuario === user[1])
        setFieldValue("assignedCommercial", {
          value: valueUser?.nombreUsuario,
          label: `${valueUser?.apellido.toLocaleUpperCase()} ${valueUser?.nombre.toLocaleUpperCase()}`
        })
      }
    }
  }
  const handleResetFilters = (): void => {
    if (setClearFilter) {
      setFromDate(null)
      setToDate(null)
      setClearFilter(true)
      if (setFieldValue) setFieldValue("clientes", null)
      if (setFieldValue) setFieldValue("assignedCommercial", null)
      if (setFieldValue) setFieldValue("eventType", null)
      if (!getAuth(PERMISSION.CLIENTE_CONSULTAR_OTROS_USUARIOS)[0]) {
        getUserCurrentToAssignmentCommercial()
      }
    }
  }

  React.useEffect(() => {
    getUserCurrentToAssignmentCommercial()
  }, [customers.assignedCommercial, data])

  React.useEffect(() => {
    handleSearch()
  }, [values, refreshFilter])

  React.useEffect(() => {
    handleSearch()
  }, [data])

  React.useEffect(() => {
    if (setFieldValue) {
      setFieldValue("dateFrom", fromDate)
      setFieldValue("dateTo", toDate)
    }
  }, [fromDate, toDate])

  return (
    <ContainerSerch>
      <Group>
        <DatePickerCustom
          value={fromDate}
          iconLeft
          placeHolder={t(TRANSLATION_INDEX_EVENT.DATE_FROM) ?? ""}
          label={t(TRANSLATION_INDEX_EVENT.DATE_FROM) ?? ""}
          setValue={setFromDate}
          dateFormat="dd/MM/yyyy"
          width={isMobile ? "100%" : `${screenSize / 3 - 12}px`}
          marginRight
        />
        <DatePickerCustom
          iconLeft
          value={toDate}
          placeHolder={t(TRANSLATION_INDEX_EVENT.DATE_TO) ?? ""}
          label={t(TRANSLATION_INDEX_EVENT.DATE_TO) ?? ""}
          setValue={setToDate}
          marginLeft
          dateFormat="dd/MM/yyyy"
          width={isMobile ? "100%" : `${screenSize / 3 - 12}px`}
          marginRight
        />
        {setFieldValue != null && (
          <SelectCustom
            label={t(TRANSLATION_INDEX_EVENT.EVENT_TYPE) ?? ""}
            value={values?.eventType ?? null}
            iconLeft
            handleChange={setFieldValue}
            name="eventType"
            placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
            width={isMobile ? "100%" : `${screenSize / 3}px`}
            marginLeft
            validError={false}
            options={events.eventType
              .map(item => {
                return {
                  value: item.idTipoEvento,
                  label:
                    t(
                      EVENT_TYPE.find(itemEvent => itemEvent.idTipoEvento === item.idTipoEvento)?.descripcion ?? "-"
                    ).toUpperCase() ?? "-"
                }
              })
              .sort((a: IOption, b: IOption) => (a.label > b.label ? 1 : -1))}
          />
        )}
      </Group>
      <Group>
        {setFieldValue != null && (
          <SelectAsyncCustom
            listTop={false}
            iconLeft
            width={isMobile ? "100%" : `${screenSize / 3 + 14}px`}
            label={t(TRANSLATION_INDEX_EVENT.CUSTOMER_PROSPECT) ?? ""}
            value={values?.clientes}
            handleChange={setFieldValue}
            name="clientes"
            validError={false}
            placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
            promiseOptions={promiseOptions}
          />
        )}
        {setFieldValue != null && (
          <SelectCustom
            label={t(TRANSLATION_INDEX_EVENT.USER) ?? ""}
            isDisabled={!getAuth(PERMISSION.EVENTOS_CONSULTAR_OTROS_USUARIOS)[0]}
            value={values?.assignedCommercial ?? null}
            iconLeft
            handleChange={setFieldValue}
            name="assignedCommercial"
            placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
            width={isMobile ? "100%" : `${screenSize / 3 + 12}px`}
            marginLeft
            validError={false}
            options={customers.assignedCommercial.map(item => {
              return {
                value: item.nombreUsuario,
                label: `${item.apellido.toLocaleUpperCase()} ${item.nombre.toLocaleUpperCase()}`
              }
            })}
          />
        )}
        <ContainerButtom
          width={`${screenSize / 3}px`}
          themeContext={themeContext}
          style={{ marginLeft: "20px", paddingTop: isMobile ? "25PX" : "" }}
        >
          <ButtonCustom
            label={t(TRANSLATION_INDEX_GLOBAL.CLEAR) ?? ""}
            background={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE[themeContext]}
            backgroundHover={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE_HOVER[themeContext]}
            onClick={handleResetFilters}
            width={isMobile ? "100%" : "110px"}
          />
        </ContainerButtom>
      </Group>
    </ContainerSerch>
  )
}

export default withFormik<IFormListProps, IPropSearch>({
  mapPropsToValues: (): IPropSearch => ({}),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, { setSubmitting }): any => {}
})(SearchUserClientApp)
