/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { type ReactElement } from "react"
import InputCustom from "../../../../components/InputCustom"

import ButtonCustom from "../../../../components/ButtonCustom"
import { withFormik } from "formik"
import { addUpdateScheme, deleteScheme } from "../../_schema"

import { useTranslation } from "react-i18next"

import { TRANSLATION_INDEX_GLOBAL, TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP } from "../../../../config/translation"
import { Container, ContainerGroup, ContainerMessage, Group, GroupButtom } from "./_styles"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { ACTIONS, FORMAT_DATE, STATES, STATES_CLIENT_ADMIN, STATES_YES_NO } from "../../../../utils/constants"
import { t } from "i18next"
import SelectAsyncCustom from "../../../../components/SelectAsyncCustom"

import "react-toastify/dist/ReactToastify.css"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"
import { type IUserAppClient } from "../../../../interfaces/IUserAppClient"
import SelectCustom from "../../../../components/SelectCustom"
import OrganizationService from "../../../../services/OrganizationService"
import { format } from "date-fns"
import { LabelUpdate } from "../../_styles"
import { useMobile } from "../../../../hook/useMobile"
import { useGlobalContext } from "../../../../context/GlobalContext"

const FormUserAppClient = ({
  data,
  isUserPending = false,
  loading = false,
  action,
  closeModal = () => {},
  accept = () => {},
  setFieldValue,
  values,
  errors,
  setSubmitting,
  isSubmitting,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  validateForm
}: IFormListProps): ReactElement => {
  const { t } = useTranslation()
  const { themeContext } = useGlobalContext()
  const isMobile = useMobile()
  const promiseOptions = async (inputValue: string): Promise<any> => {
    return await new Promise(resolve => {
      if (inputValue.length > 2) {
        resolve(OrganizationService(inputValue))
      } else {
        resolve([{ value: "-1", label: t(TRANSLATION_INDEX_GLOBAL.ENTER_THREE_CHARACTER), isDisabled: true }])
      }
    })
  }

  React.useEffect(() => {
    if (touched.apellido === true && Object.keys(errors ?? {}).length > 0) validateForm()
  }, [values])

  return (
    <Container onSubmit={handleSubmit}>
      {action === ACTIONS.DELETE.VALUE ? (
        <ContainerMessage themeContext={themeContext}>{t(TRANSLATION_INDEX_GLOBAL.CONFIRM_DELETE)}</ContainerMessage>
      ) : (
        <ContainerGroup themeContext={themeContext}>
          <Group>
            <InputCustom
              label={`${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.LAST_NAME) ?? ""} (*)`}
              value={values?.apellido ?? ""}
              handleChange={handleChange}
              name="apellido"
              placeHolder={t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.LAST_NAME) ?? ""}
              errors={errors?.apellido}
              touched={touched?.apellido}
            />

            <InputCustom
              label={`${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.NAME) ?? ""} (*)`}
              value={values?.nombre ?? ""}
              handleChange={handleChange}
              name="nombre"
              placeHolder={t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.NAME) ?? ""}
              marginLeft
              disabled={action === ACTIONS.DELETE.VALUE}
              errors={errors?.nombre}
              touched={touched?.nombre}
            />
          </Group>
          <Group>
            <InputCustom
              label="DNI (*)"
              value={values?.dni ?? ""}
              handleChange={handleChange}
              name="dni"
              placeHolder="DNI"
              disabled={action === ACTIONS.DELETE.VALUE}
              errors={errors?.dni}
              touched={touched?.dni}
            />

            <InputCustom
              label={`${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.EMAIL) ?? ""} (*)`}
              value={values?.email ?? ""}
              handleChange={handleChange}
              name="email"
              marginLeft
              placeHolder={t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.EMAIL) ?? ""}
              disabled={action === ACTIONS.DELETE.VALUE}
              errors={errors?.email}
              touched={touched?.email}
            />
          </Group>
          <Group>
            <InputCustom
              label={`${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.MOBILE_NUMBER) ?? ""} (*)`}
              value={values?.numeroCelular ?? ""}
              handleChange={handleChange}
              name="numeroCelular"
              placeHolder={t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.MOBILE_NUMBER) ?? ""}
              disabled={action === ACTIONS.DELETE.VALUE}
              errors={errors?.numeroCelular}
              touched={touched?.numeroCelular}
            />
            <InputCustom
              label={`${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.JOB) ?? ""} (*)`}
              value={values?.puesto ?? ""}
              handleChange={handleChange}
              name="puesto"
              marginLeft
              placeHolder={t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.JOB) ?? ""}
              disabled={action === ACTIONS.DELETE.VALUE}
              errors={errors?.puesto}
              touched={touched?.puesto}
            />
          </Group>

          <Group>
            {setFieldValue != null && (
              <SelectCustom
                isDisabled={data === null || action === ACTIONS.DELETE.VALUE}
                label={`${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.IS_ADMIN) ?? ""} (*)`}
                value={values?.clienteAdminFormik ?? null}
                handleChange={setFieldValue}
                width={isUserPending ? "calc(50% - 5px)" : "100%"}
                name="clienteAdminFormik"
                placeHolder={`${t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION)}`}
                errors={errors?.clienteAdminFormik}
                options={STATES_CLIENT_ADMIN.map(item => {
                  return { ...item, label: t(item?.label).toUpperCase() }
                })}
              />
            )}
            {!isUserPending && (
              <InputCustom
                label={`${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.ROLE) ?? ""}`}
                value={
                  data?.idRol === 1
                    ? t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.CUSTOMER).toUpperCase() ?? ""
                    : t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.BROKER).toUpperCase() ?? ""
                }
                handleChange={handleChange}
                name="idRol"
                marginLeft
                placeHolder={t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.ROLE) ?? ""}
                disabled={true}
                errors={errors?.puesto}
                touched={touched?.puesto}
              />
            )}
          </Group>
          <Group>
            {data?.idRol === 2 && !isUserPending && (
              <>
                <InputCustom
                  label={`${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.BROKER) ?? ""}`}
                  value={data?.despachante?.razonSocial ?? ""}
                  handleChange={handleChange}
                  name="idRol"
                  placeHolder={t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.BROKER) ?? ""}
                  disabled={true}
                  errors={errors?.puesto}
                  touched={touched?.puesto}
                />
                {setFieldValue != null && (
                  <SelectCustom
                    isDisabled={data === null || action === ACTIONS.DELETE.VALUE}
                    label={`${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.STATE) ?? ""} (*)`}
                    value={values?.estado ?? null}
                    handleChange={setFieldValue}
                    name="estado"
                    marginLeft
                    placeHolder={`${t(TRANSLATION_INDEX_GLOBAL.SELECT_A)} ${t(
                      TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.STATE
                    )}`}
                    errors={errors?.estado}
                    options={STATES.filter(item => (item?.value ?? 2) !== 2).map(item => {
                      return { ...item, label: t(item?.label).toUpperCase() }
                    })}
                  />
                )}
              </>
            )}
            {data?.idRol === 1 && !isUserPending && (
              <>
                {setFieldValue != null && (
                  <SelectCustom
                    width={isMobile ? "100%" : "calc(50% - 5px)"}
                    isDisabled={data === null || action === ACTIONS.DELETE.VALUE}
                    label={`${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.STATE) ?? ""} (*)`}
                    value={values?.estado ?? null}
                    handleChange={setFieldValue}
                    name="estado"
                    placeHolder={`${t(TRANSLATION_INDEX_GLOBAL.SELECT_A)} ${t(
                      TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.STATE
                    )}`}
                    errors={errors?.estado}
                    options={STATES.filter(item => (item?.value ?? 2) !== 2).map(item => {
                      return { ...item, label: t(item?.label).toUpperCase() }
                    })}
                  />
                )}
              </>
            )}
          </Group>
          {isUserPending && (
            <Group>
              <InputCustom
                label={`${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.ORGANIZATIONS_TO_VALIDATE) ?? ""}`}
                value={values?.organizacionesParaValidar ?? ""}
                handleChange={handleChange}
                name="organizacionesParaValidar"
                placeHolder={t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.MOBILE_NUMBER) ?? ""}
                disabled={true}
                errors={errors?.organizacionesParaValidar}
                touched={touched?.organizacionesParaValidar}
                isTextArea
              />
            </Group>
          )}
          <Group>
            {setFieldValue != null && (
              <SelectAsyncCustom
                isMulti
                label={`${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.ORGANIZATIONS)} (*)`}
                value={values?.organizacionesFormik ?? null}
                handleChange={setFieldValue}
                name="organizacionesFormik"
                placeHolder={t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.ORGANIZATIONS) ?? ""}
                promiseOptions={promiseOptions}
                isDisabled={data.idRol === 2}
                errors={errors?.organizacionesFormik}
              />
            )}
          </Group>
        </ContainerGroup>
      )}
      {isUserPending ? (
        <LabelUpdate themeContext={themeContext}>{`${t(
          TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.REGISTRATION_DATE
        )}: ${data?.fechaActualizacion && format(data?.fechaActualizacion, FORMAT_DATE)}`}</LabelUpdate>
      ) : (
        <>
          {(data.usuarioAprobacion != null || data.fechaAprobacion != null) && (
            <LabelUpdate themeContext={themeContext}>{`${t(
              TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.USER_APPROVE
            )}: ${data.usuarioAprobacion != null ? data.usuarioAprobacion : ""} ${
              data.fechaAprobacion != null ? format(data.fechaAprobacion, FORMAT_DATE) : ""
            }`}</LabelUpdate>
          )}
          <LabelUpdate themeContext={themeContext}>{`${t(
            TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.LAST_UPDATE
          )}:  ${data.usuarioActualizacion != null ? data.usuarioActualizacion : ""}  ${
            data.fechaActualizacion != null && format(data.fechaActualizacion, FORMAT_DATE)
          }`}</LabelUpdate>
        </>
      )}

      <GroupButtom themeContext={themeContext}>
        <ButtonCustom
          onClick={closeModal}
          width="80px"
          type="button"
          label={t("global.cancel") ?? ""}
          background={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE[themeContext]}
          backgroundHover={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE_HOVER[themeContext]}
        />
        <ButtonCustom
          marginLeft
          width="80px"
          type="submit"
          label={
            t(action === ACTIONS.DELETE.VALUE ? TRANSLATION_INDEX_GLOBAL.DELETE : TRANSLATION_INDEX_GLOBAL.CONFIRM) ??
            ""
          }
          background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
          backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]}
        />
      </GroupButtom>
    </Container>
  )
}

export default withFormik<IFormListProps, IUserAppClient>({
  mapPropsToValues: ({ data, action }): any => ({
    email: data?.email,
    idRol: data?.idRol,
    despachante: data?.despachante,
    dni: data?.dni,
    emailAnterior: data?.email,
    puesto: data?.puesto,
    estado:
      {
        label: t(STATES.find(item => item.value === data?.estado?.idEstado)?.label ?? ""),
        value: STATES.find(item => item.value === data?.estado?.idEstado)?.value ?? null
      } ?? null,
    clienteAdminFormik:
      data?.clienteAdmin === null
        ? null
        : {
            label: t(STATES_CLIENT_ADMIN.find(item => item.value === data?.clienteAdmin)?.label ?? ``),
            value: STATES_CLIENT_ADMIN.find(item => item.value === data?.clienteAdmin)?.value ?? ""
          },
    numeroCelular: data?.numeroCelular,
    changePass:
      {
        label: t(STATES_YES_NO.find(item => item.value === data?.changePass)?.label ?? ""),
        value: STATES_YES_NO.find(item => item.value === data?.changePass)?.value ?? null
      } ?? null,
    nombre: data?.nombre,
    apellido: data?.apellido,
    organizacionesParaValidar: data?.organizacionesParaValidar,
    organizacionesFormik: data?.organizaciones?.map(item => {
      return { value: item?.idOrganizacion, label: item.descripcion }
    })
  }),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: ({ action }): any => (action === ACTIONS.DELETE.VALUE ? deleteScheme : addUpdateScheme),
  handleSubmit: (values, { setSubmitting, props: { accept } }): any => {
    if (accept != null) {
      const request: IUserAppClient = {
        estado: {
          idEstado: values?.estado?.value ?? 1,
          descripcion: values?.estado?.label?.toUpperCase() ?? "ACTIVO"
        },
        dni: values.dni,
        despachante: values.despachante,
        idRol: values.idRol,
        numeroCelular: values.numeroCelular,
        puesto: values.puesto,
        clienteAdmin: Boolean(values?.clienteAdminFormik?.value) ?? false,
        nombre: values.nombre,
        apellido: values.apellido,
        email: values.email,
        emailAnterior: values.emailAnterior,
        organizaciones:
          values?.organizacionesFormik != null
            ? values?.organizacionesFormik.map(item => {
                return { idOrganizacion: String(item.value), descripcion: item?.label }
              })
            : []
      }
      accept(request)
    }
    setSubmitting(false)
  }
})(FormUserAppClient)
