import { type AxiosPromise } from "axios"
import httpModule from "../../../../utils/http"

export const getRemenders = async (request): Promise<AxiosPromise<any>> =>
  // await new Promise<any>((resolve, reject) => {
  //   setTimeout(() => {
  //     resolve({ data: mockListRemenders })
  //   }, 2000)
  // })
  await httpModule.get(`/api/backoffice/v1/recordatorios${request}`)

export const addRemenders = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/recordatorios`, request)

export const putRemenders = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.put(`/api/backoffice/v1/recordatorios`, request)

export const deleteRemenders = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.delete(`/api/backoffice/v1/recordatorios/${request}`)

export const getTypeRemenders = async (): Promise<AxiosPromise<any>> =>
  await httpModule.get(`/api/backoffice/v1/recordatorios/tipos`)

export const getContactByCustomer = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.get(`/api/backoffice/v1/clientes/${request}/contactos`)
