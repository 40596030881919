/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react/prop-types */
import React from "react"
import ButtonCustom from "../../../../components/ButtonCustom"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { useGlobalContext } from "../../../../context/GlobalContext"
import { Group, Input, Label } from "./_styles"
import SelectCustom from "../../../../components/SelectCustom"
import { INPUT_TYPE, STATES_YES_NO } from "../../../../utils/constants"
import { useSelector } from "react-redux"
import { TRANSLATION_INDEX_QUOTER } from "../../../../config/translation"
import { useTranslation } from "react-i18next"
import IconEditCustom from "../../../../components/Icons/IconEditCustom"
import IconListCustom from "../../../../components/Icons/IconListCustom"
import IconTrash from "../../../../components/Icons/IconTrash"
import { expensivebaSelector } from "../../../../state/modules/expensiveBA/_selectors"

const TableBAExpensive = ({ setFieldValue, table, setRenderHtml, tables, handleChangeTableInput }): any => {
  const { themeContext } = useGlobalContext()
  const expensiveBA = useSelector(expensivebaSelector)
  const { t } = useTranslation()
  const [gastosBA, setGastosBA] = React.useState<any>(
    table?.gastosBuenosAires?.map((item, index) => {
      item.id = index
      if (item?.idGastoBuenosAires !== null) item.activeSelect = true
      else item.activeSelect = false
      return item
    })
  )

  const handleAddGasto = (): void => {
    setGastosBA([
      ...gastosBA,
      { id: Number(gastosBA.length) + 1, idGastoBuenosAires: 0, textoLibre: "", valor: "", activeSelect: true }
    ])

    const newTables = tables.map(item => {
      if (item.id === table.id)
        item.gastosBuenosAires = [
          ...gastosBA,
          { id: Number(gastosBA.length) + 1, idGastoBuenosAires: 0, textoLibre: "", valor: "", activeSelect: true }
        ]
      return item
    })
    if (setFieldValue) setFieldValue("tablas", newTables)

    setRenderHtml(true)
  }

  const handleDeleteGasto = (id, code = "Enter"): void => {
    if (code === "Enter") {
      const newData = gastosBA.filter(item => item?.id !== id)
      setGastosBA(newData)
      const newTables = tables.map(item => {
        if (item.id === table.id) item.gastosBuenosAires = newData
        return item
      })
      if (setFieldValue) setFieldValue("tablas", newTables)
    }
  }

  const handleChangeGastos = (id, column, value): void => {
    const newData = gastosBA.map(item => {
      if (item?.id === id) item[column] = value
      return item
    })
    setGastosBA(newData)
    const newTables = tables.map(item => {
      if (item.id === table.id) item.gastosBuenosAires = newData
      return item
    })
    if (setFieldValue) setFieldValue("tablas", newTables)
  }

  const handleChangeGastoSelectInputType = (id, value, code = "Enter"): void => {
    if (code === "Enter") {
      const newData = gastosBA.map(item => {
        if (item?.id === id) {
          item.activeSelect = value
          if (!value) item.idGastoBuenosAires = null
          else {
            item.idGastoBuenosAires = 0
            item.textoLibre = ""
          }
        }
        return item
      })
      setGastosBA(newData)
      const newTables = tables.map(item => {
        if (item.id === table.id) item.gastosBuenosAires = newData
        return item
      })
      if (setFieldValue) setFieldValue("tablas", newTables)
    }
  }

  const handleChangeGastosSelect = (name, value): void => {
    const dataValue = name.split(".")

    const newData = gastosBA.map(item => {
      if (item?.id === Number(dataValue[1])) {
        item[dataValue[0]] = value?.label ?? ""
        item.idGastoBuenosAires = value?.value ?? 0
      }
      return item
    })
    setGastosBA(newData)
    const newTables = tables.map(item => {
      if (item.id === table.id) item.gastosBuenosAires = newData
      return item
    })
    if (setFieldValue) setFieldValue("tablas", newTables)
  }

  const handleChangeTableSelect = (tableIdProp, value): void => {
    const newTables = tables.map(item => {
      if (item.id === Number(tableIdProp)) item.mostrarGastosBuenosAires = value
      return item
    })
    if (setFieldValue) setFieldValue("tablas", newTables)
  }

  React.useEffect(() => {
    if (table?.mostrarGastosBuenosAires?.value === true && table?.gastosBuenosAires.length === 0) {
      handleAddGasto()
    }
  }, [table?.mostrarGastosBuenosAires])
  return (
    <>
      {setFieldValue != null && (
        <Group themeContext={themeContext}>
          <SelectCustom
            name={`${table.id}`}
            label={t(TRANSLATION_INDEX_QUOTER.EXPENSE_AT_BA) ?? ""}
            value={table?.mostrarGastosBuenosAires}
            handleChange={handleChangeTableSelect}
            options={STATES_YES_NO.filter(item => item?.value !== null).map(item => {
              return {
                label: t(item.label).toUpperCase(),
                value: Boolean(item?.value)
              }
            })}
          />
        </Group>
      )}
      {table?.mostrarGastosBuenosAires?.value && (
        <>
          <Group themeContext={themeContext}>
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th style={{ border: GLOBAL_STYLES.BORDER.TYPE_1[themeContext] }}>
                    {t(TRANSLATION_INDEX_QUOTER.CONCEPT)}
                  </th>
                  <th style={{ border: GLOBAL_STYLES.BORDER.TYPE_1[themeContext] }}>
                    {t(TRANSLATION_INDEX_QUOTER.AMOUNT)}
                  </th>
                  <th></th>
                </tr>
              </thead>
              {gastosBA?.map(item => {
                return (
                  <tbody key={`${item?.id}-gao`}>
                    <tr>
                      <td style={{ border: GLOBAL_STYLES.BORDER.TYPE_1[themeContext] }}>
                        {item.activeSelect ? (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <SelectCustom
                              name={`textoLibre.${item?.id}.ba`}
                              value={{
                                label: t(item.textoLibre),
                                value: item?.idGastoBuenosAires
                              }}
                              handleChange={handleChangeGastosSelect}
                              options={expensiveBA?.data?.map(item => {
                                return {
                                  label: t(item.descripcion),
                                  value: item?.idGastoBsAs
                                }
                              })}
                            />
                            <span
                              role="button"
                              tabIndex={0}
                              onKeyDown={e => {
                                handleChangeGastoSelectInputType(item?.id, false, e.key)
                              }}
                              onClick={() => {
                                handleChangeGastoSelectInputType(item?.id, false)
                              }}
                              style={{
                                margin: "0 10px",
                                cursor: "pointer"
                              }}
                            >
                              <IconEditCustom color={themeContext} />
                            </span>
                          </div>
                        ) : (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <Input
                              autoComplete="off"
                              onKeyDown={e => {
                                if (e?.key === "Enter") e.preventDefault()
                              }}
                              style={{
                                width: "calc(100% - 15px)"
                              }}
                              borderRadius="5px"
                              themeContext={themeContext}
                              type={INPUT_TYPE.TEXT}
                              border={GLOBAL_STYLES.BORDER.TYPE_3[themeContext]}
                              value={item.textoLibre}
                              onChange={e => {
                                handleChangeGastos(item?.id, "textoLibre", e.target?.value)
                              }}
                            />
                            <span
                              role="button"
                              tabIndex={0}
                              onKeyDown={e => {
                                handleChangeGastoSelectInputType(item?.id, true, e.key)
                              }}
                              onClick={() => {
                                handleChangeGastoSelectInputType(item?.id, true)
                              }}
                              style={{
                                margin: "0 10px",
                                cursor: "pointer"
                              }}
                            >
                              <IconListCustom size={25} color={themeContext} />
                            </span>
                          </div>
                        )}
                      </td>
                      <td style={{ border: GLOBAL_STYLES.BORDER.TYPE_1[themeContext] }}>
                        <Input
                          autoComplete="off"
                          style={{
                            width: "calc(100% - 15px)"
                          }}
                          onKeyDown={e => {
                            if (e?.key === "Enter") e.preventDefault()
                          }}
                          borderRadius="5px"
                          themeContext={themeContext}
                          type={INPUT_TYPE.TEXT}
                          border={GLOBAL_STYLES.BORDER.TYPE_3[themeContext]}
                          value={item.valor}
                          onChange={e => {
                            handleChangeGastos(item?.id, "valor", e.target?.value)
                          }}
                        />
                      </td>
                      <td>
                        <div
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                          role="button"
                          onKeyDown={e => {
                            handleDeleteGasto(item?.id, e.key)
                          }}
                          tabIndex={0}
                          onClick={() => {
                            handleDeleteGasto(item?.id)
                          }}
                        >
                          {<IconTrash size={20} color={GLOBAL_STYLES.BUTTON.DANGER_BG[themeContext]} />}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )
              })}
              <tfoot>
                <tr>
                  <td style={{ border: GLOBAL_STYLES.BORDER.TYPE_1[themeContext] }} colSpan={2}>
                    <ButtonCustom
                      type="button"
                      label={t(TRANSLATION_INDEX_QUOTER.ADD_ROW) ?? ""}
                      background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
                      backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]}
                      onClick={() => {
                        handleAddGasto()
                      }}
                    />
                  </td>
                </tr>
              </tfoot>
            </table>
          </Group>
          <div style={{ margin: "10px 0", width: "calc(100% - 15px)" }}>
            <Label themeContext={themeContext} color={GLOBAL_STYLES.LABEL.TYPE_1[themeContext]}>
              {t(TRANSLATION_INDEX_QUOTER.LEGEND_EXPENSE_BA)}
            </Label>
            <Input
              autoComplete="off"
              borderRadius="5px"
              onKeyDown={e => {
                if (e?.key === "Enter") e.preventDefault()
              }}
              themeContext={themeContext}
              type={INPUT_TYPE.TEXT}
              border={GLOBAL_STYLES.BORDER.TYPE_3[themeContext]}
              value={table.leyendaGastosBuenosAires}
              onChange={e => {
                handleChangeTableInput(table?.id, "leyendaGastosBuenosAires", e.target?.value)
              }}
            />
          </div>
        </>
      )}
    </>
  )
}

export default TableBAExpensive
