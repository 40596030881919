import { isStringEmpty } from "../utils/functions"
import httpModule from "../utils/http"

const CustomerService = async (inputValue, comapny = ""): Promise<any[]> => {
  const request = {
    ordenamiento: { columna: 1, direccion: "asc" },
    paginado: { pagina: 1, registrosPorPagina: 100000 },
    filtro: {
      cuit: null,
      razonSocial: inputValue,
      idTipoCliente: null,
      comercialAsignado: null,
      esCargaCIF: null,
      esImportador: null,
      esExportador: null,
      paginaOrigen: "clientesABM"
    }
  }
  return await httpModule.post(`/api/backoffice/v1/clientes/listar`, request).then(res => {
    return res.data.clientes
      .filter(item => isStringEmpty(comapny) || item.razonSocial.toLowerCase() !== comapny.toLowerCase())
      .map(item => {
        return { value: item?.idCliente, label: item.razonSocial }
      })
  })
}

export default CustomerService
