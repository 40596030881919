import React, { type ReactElement } from "react"

import { Container, Content } from "./_styles"
import Login from "../../pages/Login"
import { Routes, Route } from "react-router-dom"
import HomePage from "../../pages/Home"
import PrivateRoute from "../../components/PrivateRoute"
import { ROUTES } from "../../utils/constants"
import UserBackofficePage from "../../pages/UserBackoffice"
import ProfilePage from "../../pages/Profile"
import UserAppClientPage from "../../pages/UserAppClient"
import PermissionByProfilePage from "../../pages/PermissionByProfile"
import AuthorizeInformationPage from "../../pages/AuthorizeInformation"
import PreferencePage from "../../pages/Preference"
import SearchCustomerReferencesPage from "../../pages/SearchCustomerReferences"
import QuoterPage from "../../pages/Quote"
import CustomerProspectPage from "../../pages/CustomerProspect"
import TermsPage from "../../pages/Terms"
import RatingsPage from "../../pages/ratings"
import { useGlobalContext } from "../../context/GlobalContext"
import ExpensiveOriginPage from "../../pages/ExpensiveOrigin"
import ExpensiveBAPage from "../../pages/ExpensiveBA"
import QuotesTemplatePage from "../../pages/QuotesTemplate"
import QuoteSend from "../../pages/QuoteSend"
import QuoteViewSentPage from "../../pages/QuoteViewSent"
import CustomsPage from "../../pages/Customs"
import EventPage from "../../pages/Event"
import CalenderPage from "../../pages/Calendar"
import ReportCommercialPage from "../../pages/ReportCommercial"
import ReportCustomerPage from "../../pages/ReporteCustomer"
import ReportUncontactedCustomerPage from "../../pages/ReportUncontactedCustomer"
import CommercialBulkAssociates from "../../pages/CommercialBulkAssociates"

const MainLayout = (): ReactElement => {
  const { themeContext, activeModal } = useGlobalContext()
  return (
    <Container themeContext={themeContext} activeModal={activeModal}>
      <Content themeContext={themeContext}>
        <Routes>
          <Route path="/" element={<PrivateRoute />}>
            <Route path={ROUTES.HOME.pathName} element={<HomePage />} />
            <Route path={ROUTES.AUTHORIZE_INFORMATION_TO_CLIENTS.pathName} element={<AuthorizeInformationPage />} />
            <Route path={ROUTES.QUOTER.pathName} element={<QuoterPage />} />
            <Route path={ROUTES.REFERENCE_CONSULT.pathName} element={<SearchCustomerReferencesPage />} />
            <Route path={ROUTES.USERS_BACKOFFICE.pathName} element={<UserBackofficePage />} />
            <Route path={ROUTES.USERS_APP_CLIENTS.pathName} element={<UserAppClientPage />} />
            <Route path={ROUTES.RATINGS.pathName} element={<RatingsPage />} />
            <Route path={ROUTES.PROFILES.pathName} element={<ProfilePage />} />
            <Route path={ROUTES.PERMISSION_BY_PROFILES.pathName} element={<PermissionByProfilePage />} />
            <Route path={ROUTES.CUSTOMER_PROSPECT.pathName} element={<CustomerProspectPage />} />
            <Route path={ROUTES.PREFERENCES.pathName} element={<PreferencePage />} />
            <Route path={ROUTES.EXPENSES_ORIGIN.pathName} element={<ExpensiveOriginPage />} />
            <Route path={ROUTES.EXPENSES_BA.pathName} element={<ExpensiveBAPage />} />
            <Route path={ROUTES.QUOTE_TEMPLATE.pathName} element={<QuotesTemplatePage />} />
            <Route path={ROUTES.SEND_QUOTE.pathName} element={<QuoteSend />} />
            <Route path={ROUTES.RESEND_QUOTE.pathName} element={<QuoteSend />} />
            <Route path={ROUTES.VIEW_SEND_QUOTE.pathName} element={<QuoteViewSentPage />} />
            <Route path={ROUTES.CUSTOMS.pathName} element={<CustomsPage />} />
            <Route path={ROUTES.EVENTS.pathName} element={<EventPage />} />
            <Route path={ROUTES.REMINDER.pathName} element={<CalenderPage />} />
            <Route path={ROUTES.REPORT_EVENT_BY_COMMERCIAL.pathName} element={<ReportCommercialPage />} />
            <Route path={ROUTES.REPORT_EVENT_BY_CUSTOMER.pathName} element={<ReportCustomerPage />} />
            <Route path={ROUTES.REPORT_CUSTOMER_WITHOUT_CONTACT.pathName} element={<ReportUncontactedCustomerPage />} />
            <Route path={ROUTES.COMMERCIAL_BULK_ASSOCIATION.pathName} element={<CommercialBulkAssociates />} />

            <Route path="/*" element={<HomePage />} />
          </Route>
          <Route path={ROUTES.LOGIN.pathName} element={<Login />} />
          <Route path={ROUTES.TERMS.pathName} element={<TermsPage />} />
        </Routes>
      </Content>
    </Container>
  )
}

export default MainLayout
