import styled from "styled-components"

export const ContainerIcons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const ContainerSerch = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 15px;
`

export const Group = styled.div`
  position: relative;
  width: calc(100% - 0px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 10px;
  @media (max-width: 900px) {
    flex-direction: column;
    margin-top: 0;
  }
`

export const ContainerTable = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const ContainerButtom = styled.div`
  /* width: 25%; */
  margin-left: 1%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  @media (max-width: 1080px) {
    margin-left: 0;
    margin-top: 1%;
    width: 100%;
  }
`
