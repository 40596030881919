import styled from "styled-components"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { type IStyle } from "../../../../interfaces/IStyle"

export const Container = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const ContainerItem = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-right: 8px;
  margin-bottom: 10px;
`
export const Text = styled.div<IStyle>`
  display: inline-block;
  position: relative;
  text-overflow: clip;
  justify-content: flex-start;
  padding: 0px 7px;
  border: ${({ themeContext }) => GLOBAL_STYLES.BORDER.TYPE_2[themeContext]};
  background-color: ${({ themeContext }) => GLOBAL_STYLES.BACKGROUND.TYPE_3[themeContext]};
  border-radius: 5px 0px 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const ButtonDelete = styled.div<IStyle>`
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
  cursor: pointer;
  background-color: ${({ themeContext }) => GLOBAL_STYLES.BACKGROUND.TYPE_3[themeContext]};
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  align-items: center;
  border-radius: 0px 5px 5px 0px;
  justify-content: center;
  border-top: ${({ themeContext }) => GLOBAL_STYLES.BORDER.TYPE_2[themeContext]};
  border-bottom: ${({ themeContext }) => GLOBAL_STYLES.BORDER.TYPE_2[themeContext]};
  border-right: ${({ themeContext }) => GLOBAL_STYLES.BORDER.TYPE_2[themeContext]};
  font-weight: 700;
  font-size: 0.8rem;
  :hover {
    color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_2[themeContext]};
  }
`
