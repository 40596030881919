/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { type ReactElement } from "react"

import { useTranslation } from "react-i18next"

import {
  TRANSLATION_INDEX_CUSTOMER_PROSPECT,
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_QUOTER
} from "../../../../config/translation"

import { ACTIONS } from "../../../../utils/constants"

import "react-toastify/dist/ReactToastify.css"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"

import { withFormik } from "formik"
import { addUpdateScheme, deleteScheme } from "../../_schema"
import { useGlobalContext } from "../../../../context/GlobalContext"
import { Container } from "./_styles"
import { useDispatch, useSelector } from "react-redux"
import { getQuoteTemplateCustomerRelativeRequest } from "../../../../state/modules/quoteTemplate/actions"
import { quoteTemplateSelector } from "../../../../state/modules/quoteTemplate/_selectors"
import DataTable from "react-data-table-component"
import { type ICustomer } from "../../../../interfaces/ICustomer"
import { customStylesContext } from "../../../../styles/_styleDatatable"
import AlertModal from "../../../../components/AlertModal"
import SelectCustom from "../../../../components/SelectCustom"
import ButtonCustom from "../../../../components/ButtonCustom"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { ToolTipRow } from "../../../CustomerProspect/_styles"

const Step2 = ({ data, customers, setDataFiltered, values, handleSubmit }: IFormListProps): ReactElement => {
  const { t } = useTranslation()
  const { themeContext } = useGlobalContext()
  const quoterTemplateContext = useSelector(quoteTemplateSelector)
  const [activeRelatedCustomer, setActiveRelatedCustomer] = React.useState(false)
  const dispatch = useDispatch()
  const [showSelectedCustomer, setShowSelectedCustomer] = React.useState(false)
  const handleOpenModalSelectedCustomer = (): void => {
    setShowSelectedCustomer(!showSelectedCustomer)
  }

  const columnsSelected = [
    {
      id: "1",
      name: t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.COMPANY_NAME),
      selector: (row: ICustomer) => row.razonSocial.toUpperCase(),
      cell: (row: ICustomer) => {
        return <ToolTipRow>{row.razonSocial.toUpperCase()}</ToolTipRow>
      },
      width: `30%`,
      sortable: true
    },
    {
      id: "2",
      name: "CUIT",
      selector: (row: ICustomer) => row.cuit,
      width: "50%",
      center: true,
      sortable: true,
      omit: activeRelatedCustomer
    },
    {
      id: "2",
      name: t(TRANSLATION_INDEX_QUOTER.RELATED_CUSTOMER_TITLE),
      selector: (row: ICustomer) => row.razonSocialClienteRelacionado?.toLocaleUpperCase(),
      cell: (row: ICustomer) => {
        return <div>{row.razonSocialClienteRelacionado?.toLocaleUpperCase()}</div>
      },
      width: "70%",
      omit: showSelectedCustomer,
      sortable: true
    }
  ]
  const paginationComponentOptions = {
    rowsPerPageText: t(TRANSLATION_INDEX_GLOBAL.ROWS_PER_PAGE),
    rangeSeparatorText: t(TRANSLATION_INDEX_GLOBAL.OF),
    selectAllRowsItem: false,
    selectAllRowsItemText: t(TRANSLATION_INDEX_GLOBAL.ALL)
  }

  const handleOpenModal = (): void => {
    setActiveRelatedCustomer(!activeRelatedCustomer)
  }

  const handleChangeSelect = (_: any, value: any): void => {
    if (setDataFiltered) setDataFiltered(value)
  }

  React.useEffect(() => {
    if (quoterTemplateContext.customerRelative.length > 0) handleOpenModal()
  }, [quoterTemplateContext.customerRelative])

  React.useEffect(() => {
    setActiveRelatedCustomer(false)
    dispatch(
      getQuoteTemplateCustomerRelativeRequest(
        customers?.map(item => {
          return item.idCliente
        })
      )
    )
  }, [])

  return (
    <Container onSubmit={handleSubmit}>
      {showSelectedCustomer && (
        <AlertModal
          title={`${t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.CUSTOMER)}s/${t(
            TRANSLATION_INDEX_CUSTOMER_PROSPECT.PROSPECT
          )}s`}
          closeModal={handleOpenModalSelectedCustomer}
          activeCloseModal
        >
          <DataTable
            data={customers ?? []}
            theme="solarized"
            columns={columnsSelected}
            dense
            pagination
            paginationComponentOptions={paginationComponentOptions}
            paginationRowsPerPageOptions={[10, 25, 50, 100, 500]}
            striped
            customStyles={customStylesContext(themeContext)}
            paginationDefaultPage={1}
            // paginationResetDefaultPage={resetPage}
          />
        </AlertModal>
      )}
      {activeRelatedCustomer && (
        <AlertModal
          title={t(TRANSLATION_INDEX_QUOTER.RELATED_CUSTOMER_TITLE)}
          closeModal={handleOpenModal}
          activeCloseModal
        >
          <div
            style={{
              width: "70%",
              margin: "20px auto",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column"
            }}
          >
            <div style={{ textAlign: "center", margin: "15px 0" }}>{t(TRANSLATION_INDEX_QUOTER.RELATED_CUSTOMER)}</div>
            <DataTable
              data={quoterTemplateContext.customerRelative}
              theme="solarized"
              columns={columnsSelected}
              dense
              pagination
              paginationComponentOptions={paginationComponentOptions}
              paginationRowsPerPageOptions={[10, 25, 50, 100, 500]}
              striped
              customStyles={customStylesContext(themeContext)}
              paginationDefaultPage={1}
              // paginationResetDefaultPage={resetPage}
            />
          </div>
        </AlertModal>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          margin: "25px 0",
          justifyContent: "center"
        }}
      >
        <ButtonCustom
          label={t(TRANSLATION_INDEX_QUOTER.SHOW_CUSTOMERS) ?? ""}
          background={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE[themeContext]}
          backgroundHover={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE_HOVER[themeContext]}
          width="250px"
          onClick={handleOpenModalSelectedCustomer}
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <SelectCustom
          label={t(TRANSLATION_INDEX_QUOTER.SELECT_TEMPLATE_TO_SEND) ?? ""}
          value={data}
          iconLeft
          handleChange={handleChangeSelect}
          name="selectTemplate"
          placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
          maxMenuHeight={500}
          width="50%"
          marginLeft
          validError={false}
          options={quoterTemplateContext.data.map(item => {
            return {
              label: `${item.nombre} - ${
                item.modoTransporte === "AIR+SEA"
                  ? `${t(TRANSLATION_INDEX_QUOTER.AIR)} + ${t(TRANSLATION_INDEX_QUOTER.SEA)}`
                  : t(TRANSLATION_INDEX_QUOTER[item.modoTransporte])
              }`,
              value: item.idCotizacion
            }
          })}
        />
      </div>
    </Container>
  )
}

export default withFormik<IFormListProps, any>({
  mapPropsToValues: ({ data }): any => ({}),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: ({ action }) => (action === ACTIONS.DELETE.VALUE ? deleteScheme : addUpdateScheme),
  handleSubmit: (values, { setSubmitting, props: { accept } }): any => {
    if (accept != null) accept(values)
    setSubmitting(false)
  }
})(Step2)
