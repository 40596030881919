/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { type ReactElement } from "react"
import { INPUT_TYPE } from "../../utils/constants"
import { Container, Label, ContainerInput, Input } from "./_styles"
import { type Props } from "./_types"

import { isStringEmpty } from "../../utils/functions"

import InputMask from "react-input-mask"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { useTranslation } from "react-i18next"
import { FormHelperText } from "@mui/material"
import { useGlobalContext } from "../../context/GlobalContext"

const InputMaskCustom = ({
  name,
  label = "",
  value = "",
  handleChange,
  type = INPUT_TYPE.TEXT,
  width = "100%",
  marginLeft = false,
  disabled = false,
  errors = "",
  touched,
  autoFocus,
  validError = true,
  borderRadius = "5px",
  colorType = "",
  mask,
  alwaysShowMask = false,
  handleKeyPressEnter
}: Props): ReactElement => {
  const { t } = useTranslation()
  const handleKey = (name, e): void => {
    if (e.keyCode === 13) e.preventDefault()
    handleKeyPressEnter != null ? handleKeyPressEnter(name, e.keyCode) : {}
  }
  const inputRef = React.useRef(null)
  const { themeContext } = useGlobalContext()
  return (
    <Container themeContext={themeContext} width={width} marginLeft={marginLeft} data-testid="containerInput">
      {!isStringEmpty(label) && (
        <Label themeContext={themeContext} color={colorType}>
          {label}
        </Label>
      )}
      <ContainerInput>
        {/* {disabled && (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              background: "#f6f6f6",
              opacity: 0.3,
              borderRadius: "5px"
            }}
          ></div>
        )} */}
        <InputMask
          mask={mask}
          ref={inputRef}
          alwaysShowMask={alwaysShowMask}
          autoComplete="off"
          value={value ?? ""}
          onChange={handleChange}
          // style={{ color: "red !important" }}
          disabled={disabled}
          type={type}
          data-testid="input"
          border={
            !isStringEmpty(errors)
              ? GLOBAL_STYLES.BORDER.TYPE_4[themeContext]
              : GLOBAL_STYLES.BORDER.TYPE_3[themeContext]
          }
        >
          {inputProps => (
            <Input
              {...inputProps}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={autoFocus}
              ref={inputRef}
              borderRadius={borderRadius}
              disabled={disabled}
              autoComplete="off"
              name={name}
              style={{ background: disabled ? "#f6f6f6" : "white", cursor: "default" }}
              // disabled={disabled}
              value={value ?? ""}
              onChange={handleChange}
              type={type}
              data-testid="input"
              onKeyDown={e => {
                handleKey(name, e)
              }}
              border={
                !isStringEmpty(errors)
                  ? GLOBAL_STYLES.BORDER.TYPE_4[themeContext]
                  : GLOBAL_STYLES.BORDER.TYPE_3[themeContext]
              }
            />
          )}
        </InputMask>
      </ContainerInput>
      {validError && (
        <FormHelperText style={{ color: GLOBAL_STYLES.LABEL.TYPE_4[themeContext] }}>{t(errors)}</FormHelperText>
      )}
    </Container>
  )
}

export default InputMaskCustom
