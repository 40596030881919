/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import ContainerPage from "../../components/ContainerPage"
import {
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_MENU,
  TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP
} from "../../config/translation"
import DataTable, { createTheme } from "react-data-table-component"

import AlertModal from "../../components/AlertModal"

import { ACTIONS, OPTION_STATE, PERMISSION } from "../../utils/constants"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { getAuth } from "../../utils/functions"
import ButtonCustom from "../../components/ButtonCustom"
import { ContainerIcons, ContainerTable, ContainerTab, Tab } from "./_styles"
import { type IUserAppClient } from "../../interfaces/IUserAppClient"
import { useDispatch, useSelector } from "react-redux"
import {
  patchUserAppClientRequest,
  deleteUserAppClientRequest,
  getUserAppClientRequest,
  postUserAppClientRequest
} from "../../state/modules/userClientApp/actions"
import { userAppClientSelector } from "../../state/modules/userClientApp/_selectors"
import FormUserAppClient from "./forms/addUpdate/FormUserAppClient"
import SearchUserClientApp from "./forms/search/index"
import { toast } from "react-toastify"
import IconEdit from "../../components/Icons/IconEdit"
import IconDelete from "../../components/Icons/IconDelete"
import { customStylesContext } from "../../styles/_styleDatatable"
import ContainerLabelTwoRow from "../../components/ContainerLabelTwoRow"
import { NoData } from "../../components/NoData"
import { useGlobalContext } from "../../context/GlobalContext"
import "../../styles/styles.css"
import { filterData } from "./functios"
import { useMobile } from "../../hook/useMobile"
import ExportToExcel from "./ExportToExcel"

const UserAppClientPage = (): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useMobile()
  const { themeContext } = useGlobalContext()
  const dispatch = useDispatch()
  const userAppClient = useSelector(userAppClientSelector)
  const [dataFiltered, setDataFiltered] = React.useState<IUserAppClient[]>(userAppClient.data)
  const [clearFilter, setClearFilter] = React.useState<boolean>(false)
  const [filters, setFilters] = React.useState(null)
  const [openModal, setOpenModal] = React.useState(false)
  const { setLoadingContext } = useGlobalContext()
  const [userSelected, setUserSelected] = React.useState<IUserAppClient | null>(null)
  const [action, setAction] = React.useState<string>("")
  const [openRegistered, setOpenRegistered] = React.useState(false)
  const [openPending, setOpenPending] = React.useState(true)

  const handleLoading = (): void => {
    setLoadingContext(true)
    setTimeout(() => {
      setLoadingContext(false)
    }, 500)
  }

  const handleOpenModal = (action = ""): void => {
    setAction(action)
    setOpenModal(!openModal)
  }

  const handleOpenRegistered = (): void => {
    if (openPending) {
      setClearFilter(true)
      handleLoading()

      setOpenRegistered(true)
      setOpenPending(false)
    }
  }

  const handleOpenPending = (): void => {
    if (openRegistered) {
      setClearFilter(true)
      handleLoading()
      setOpenPending(true)
      setOpenRegistered(false)
    }
  }

  const paginationComponentOptions = {
    rowsPerPageText: t(TRANSLATION_INDEX_GLOBAL.ROWS_PER_PAGE),
    rangeSeparatorText: t(TRANSLATION_INDEX_GLOBAL.OF),
    selectAllRowsItem: true,
    selectAllRowsItemText: t(TRANSLATION_INDEX_GLOBAL.ALL)
  }

  const columns = [
    {
      name: `${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.LAST_NAME)} ${t(
        TRANSLATION_INDEX_GLOBAL.AND
      )} ${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.NAME)}`,
      selector: (row: IUserAppClient) => row.apellido?.toUpperCase(),
      cell: (row: IUserAppClient) => {
        return <ContainerLabelTwoRow label={`${row.apellido?.toUpperCase()} ${row.nombre?.toUpperCase()}`} />
      },
      width: isMobile ? "120px" : `${openPending ? "26%" : "17%"}`,
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.EMAIL),
      selector: (row: IUserAppClient) => row.email,
      cell: (row: IUserAppClient) => {
        return <ContainerLabelTwoRow label={row.email} />
      },
      width: isMobile ? "180px" : `${openPending ? "18%" : "17%"}`,
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.JOB),
      cell: (row: IUserAppClient) => {
        return <ContainerLabelTwoRow label={row.puesto?.toUpperCase()} />
      },
      selector: (row: IUserAppClient) => row.puesto?.toUpperCase(),
      sortable: true,
      omit: openRegistered,
      width: isMobile ? "150px" : "15%"
    },
    {
      name: t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.IS_ADMIN),
      cell: (row: IUserAppClient) => {
        return row?.clienteAdmin ? (
          <div style={{ width: "100%", textAlign: "center" }}>{t(TRANSLATION_INDEX_GLOBAL.YES)?.toUpperCase()}</div>
        ) : (
          <div style={{ width: "100%", textAlign: "center" }}>NO</div>
        )
      },
      width: isMobile ? "120px" : "9%",
      omit: openPending,
      selector: (row: IUserAppClient) => (row.clienteAdmin ? "admin" : "mien"),
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.ROLE),
      cell: (row: IUserAppClient) => {
        return row?.idRol === 1 ? (
          <div style={{ width: "100%", textAlign: "center" }}>
            {t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.CUSTOMER)?.toUpperCase()}
          </div>
        ) : (
          <div style={{ width: "100%", textAlign: "center" }}>
            {" "}
            {t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.BROKER)?.toUpperCase()}
          </div>
        )
      },
      width: isMobile ? "120px" : "10%",
      omit: openPending,
      selector: (row: IUserAppClient) =>
        row.idRol === 1
          ? t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.CUSTOMER)?.toUpperCase()
          : t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.BROKER)?.toUpperCase(),
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.BROKER),
      cell: (row: IUserAppClient) => {
        return <ContainerLabelTwoRow label={row?.despachante?.razonSocial ?? ""} />
      },
      width: isMobile ? "150px" : "10%",
      omit: openPending,
      selector: (row: IUserAppClient) => row?.despachante?.razonSocial ?? "",
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.CHANGE_PASS),
      selector: (row: IUserAppClient) => (row.debeCambiarClave ? "yes" : "no"),
      width: isMobile ? "100px" : "7%",
      cell: (row: IUserAppClient) => {
        return row.debeCambiarClave ? (
          <div style={{ width: "100%", textAlign: "center" }}>{t(TRANSLATION_INDEX_GLOBAL.YES)?.toUpperCase()}</div>
        ) : (
          <div style={{ width: "100%", textAlign: "center" }}>NO</div>
        )
      },
      omit: openPending,
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.STATE),
      width: isMobile ? "100px" : "8%",
      selector: (row: IUserAppClient) => (row?.estado?.idEstado === OPTION_STATE.ACTIVO ? "yes" : "no"),
      omit: openPending,
      cell: row => {
        return row.estado.idEstado === OPTION_STATE.ACTIVO ? (
          <div style={{ width: "100%", textAlign: "center" }}>{t(TRANSLATION_INDEX_GLOBAL.ACTIVE)?.toUpperCase()}</div>
        ) : (
          <div style={{ width: "100%", textAlign: "center" }}>
            {t(TRANSLATION_INDEX_GLOBAL.INACTIVE)?.toUpperCase()}
          </div>
        )
      },
      sortable: true
    },
    {
      name: t(
        openPending
          ? TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.ORGANIZATIONS_TO_VALIDATE
          : TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.ORGANIZATIONS
      ),
      width: isMobile ? "200px" : `${openPending ? `calc(39% - 200px)` : `calc(23% - 120px)`}`,
      cell: (row: IUserAppClient) => {
        const organization: string = row.organizaciones
          .map(item => {
            return ` ${item.descripcion}`
          })
          .join()
        return (
          <ContainerLabelTwoRow
            label={
              openPending ? row?.organizacionesParaValidar?.toLocaleUpperCase() ?? "" : organization.toLocaleUpperCase()
            }
          />
        )
      }
    },

    {
      name: t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.ACTIONS),
      cell: (row: IUserAppClient) => {
        return (
          <ContainerIcons>
            {getAuth(PERMISSION.USUARIO_APP_MODIFICAR)[0] && (
              <ButtonCustom
                label={t(openPending ? TRANSLATION_INDEX_GLOBAL.VALIDATE : TRANSLATION_INDEX_GLOBAL.EDIT) ?? ""}
                onClick={() => {
                  setUserSelected(row)
                  handleOpenModal(
                    row?.estado?.idEstado === OPTION_STATE.PENDING ? ACTIONS.VALIDATE.VALUE : ACTIONS.EDIT.VALUE
                  )
                }}
                width="auto"
                background={GLOBAL_STYLES.BUTTON.DARK_BG_IMAGE[themeContext]}
                backgroundHover={GLOBAL_STYLES.BUTTON.DARK_BG_IMAGE_HOVER[themeContext]}
                icon={<IconEdit />}
              />
            )}

            {row?.estado?.idEstado === OPTION_STATE.PENDING && getAuth(PERMISSION.USUARIO_APP_MODIFICAR)[0] && (
              <ButtonCustom
                marginLeft
                label={t(TRANSLATION_INDEX_GLOBAL.DELETE) ?? ""}
                onClick={() => {
                  setUserSelected(row)
                  handleOpenModal(ACTIONS.DELETE.VALUE)
                }}
                width="auto"
                background={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE[themeContext]}
                backgroundHover={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE_HOVER[themeContext]}
                icon={<IconDelete />}
              />
            )}
          </ContainerIcons>
        )
      },
      omit: !getAuth(PERMISSION.USUARIO_APP_MODIFICAR)[0],
      minWidth: `${openPending ? "220px" : "100px"}`
    }
  ]

  createTheme(
    "solarized",
    {
      context: {
        text: GLOBAL_STYLES.HEADER.MENU.BACKGROUND_3[themeContext]
      }
    },
    "dark"
  )
  const handleDispatch = (values): void => {
    switch (action) {
      case ACTIONS.DELETE.VALUE:
        dispatch(deleteUserAppClientRequest(userSelected?.email))
        break
      case ACTIONS.VALIDATE.VALUE:
        dispatch(patchUserAppClientRequest(values))
        break
      case ACTIONS.EDIT.VALUE:
        dispatch(postUserAppClientRequest(values))
        break
      default:
        break
    }
  }

  React.useEffect(() => {
    if (userAppClient.showToast) {
      if (userAppClient.hasErrMessage) {
        toast.error(t(userAppClient.errorCode))
      } else {
        toast.success(t(userAppClient.toastMessage))
        handleOpenModal()
        dispatch(getUserAppClientRequest())
      }
    }
  }, [userAppClient.showToast])

  React.useEffect(() => {
    setLoadingContext(userAppClient.loading)
    setDataFiltered(filterData(userAppClient.data, filters))
  }, [userAppClient])

  React.useEffect(() => {
    setLoadingContext(true)
    dispatch(getUserAppClientRequest())
  }, [])

  return (
    <>
      <ContainerPage
        title={`${t(TRANSLATION_INDEX_MENU.USERS_APP_CLIENTS)}`}
        actions={[
          {
            isButton: true,
            component: (
              <ExportToExcel
                data={
                  dataFiltered?.filter(
                    item =>
                      (openRegistered && item?.estado?.idEstado !== OPTION_STATE.PENDING) ||
                      (openPending && item?.estado?.idEstado === OPTION_STATE.PENDING)
                  ) ?? []
                }
                isPending={openPending}
              />
            )
          }
        ]}
      >
        <>
          {openModal && (
            <AlertModal
              activeCloseModal={isMobile}
              maxWidth={isMobile ? "95%" : action === ACTIONS.DELETE.VALUE ? "450px" : "70%"}
              closeModal={() => {
                handleOpenModal()
              }}
              title={`${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.USER_APP_CLIENT)} | ${t(
                TRANSLATION_INDEX_GLOBAL[action.toUpperCase()]
              )}`}
            >
              <FormUserAppClient
                data={userSelected}
                isUserPending={openPending}
                action={action}
                closeModal={handleOpenModal}
                loading={userAppClient.loading}
                accept={handleDispatch}
              />
            </AlertModal>
          )}
          <ContainerTab>
            <Tab
              themeContext={themeContext}
              background={
                openPending
                  ? GLOBAL_STYLES.BUTTON.TAB_BG_IMAGE_ACTIVE[themeContext]
                  : GLOBAL_STYLES.BUTTON.TAB_BG_IMAGE[themeContext]
              }
              role="button"
              tabIndex={0}
              onKeyDown={() => {
                handleOpenPending()
              }}
              onClick={() => {
                handleOpenPending()
              }}
            >
              {t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.PENDING_USERS)}
            </Tab>
            <Tab
              themeContext={themeContext}
              background={
                openRegistered
                  ? GLOBAL_STYLES.BUTTON.TAB_BG_IMAGE_ACTIVE[themeContext]
                  : GLOBAL_STYLES.BUTTON.TAB_BG_IMAGE[themeContext]
              }
              role="button"
              tabIndex={0}
              onKeyDown={() => {
                handleOpenRegistered()
              }}
              onClick={() => {
                handleOpenRegistered()
              }}
            >
              {t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.REGISTERED_USERS)}
            </Tab>
          </ContainerTab>
          <ContainerTable themeContext={themeContext} opacity={1}>
            <SearchUserClientApp
              data={userAppClient.data}
              setDataFiltered={setDataFiltered}
              isUserPending={openPending}
              clearFilter={clearFilter}
              setClearFilter={setClearFilter}
              setFilters={setFilters}
            />
            <DataTable
              data={
                dataFiltered?.filter(
                  item =>
                    (openRegistered && item?.estado?.idEstado !== OPTION_STATE.PENDING) ||
                    (openPending && item?.estado?.idEstado === OPTION_STATE.PENDING)
                ) ?? []
              }
              theme="solarized"
              paginationComponentOptions={paginationComponentOptions}
              columns={columns}
              dense
              pagination
              striped
              noDataComponent={<NoData />}
              customStyles={customStylesContext(themeContext, GLOBAL_STYLES.BUTTON.TAB_BG_IMAGE_ACTIVE[themeContext])}
              defaultSortFieldId={1}
            />
          </ContainerTable>
        </>
      </ContainerPage>
    </>
  )
}

export default UserAppClientPage
