import axios, { type AxiosRequestConfig, type AxiosError } from "axios"
import { STORAGE } from "./localStorage"
const baseURL = process.env.REACT_APP_API_MAEU

const httpModuleMaerk = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${STORAGE.get(STORAGE.TOKEN)}`
  }
})

httpModuleMaerk.interceptors.request.use(
  async (config: AxiosRequestConfig): Promise<any> => {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${STORAGE.get(STORAGE.TOKEN)}`
    }
    return await Promise.resolve(config)
  },
  async (error: AxiosError): Promise<AxiosError> => {
    return await Promise.reject(error)
  }
)

export default httpModuleMaerk
