/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable prettier/prettier */
import React from "react"
import * as XLSX from "xlsx"
import { type IUserBackoffice } from "../../interfaces/IUserBackoffice"
import ButtonCustom from "../../components/ButtonCustom"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { useTranslation } from "react-i18next"
import { TRANSLATION_INDEX_GLOBAL, TRANSLATION_INDEX_MENU, TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP } from "../../config/translation"
import { format } from "date-fns"
import { FORMAT_DATE, STATES } from "../../utils/constants"
import IconExcel from "../../components/Icons/IconExcel"
import { useGlobalContext } from "../../context/GlobalContext"
interface IProps {
  data: IUserBackoffice[]
}
const ExportToExcel: React.FC<IProps> = ({ data }): React.ReactElement => {
  const {setLoadingContext} = useGlobalContext()
  const {t} = useTranslation()
//   const titulo = [{ A: "Reporte de Productos" }, {}]

//   const informacionAdicional = {
//     A: "Creado por: iTana el Martes, 04 de Abril del 2023"
//   }

  const longitudes:number[] = [25, 15, 15, 25, 15, 10, 35]

  const handleDownload = (): void => {
    setLoadingContext(true)

    const tabla = [
      {
        A: t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.USER_NAME),
        B: t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.LAST_NAME),
        C: t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.NAME),
        D: t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.EMAIL),
        E: t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.PROFILE),
        F: t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.STATE),
        G: t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.LAST_UPDATE)
      }
    ]

    data.forEach(item => {
      tabla.push({
        A: item.usuario,
        B: item.apellido,
        C: item.nombre,
        D: item.email,
        E: item.perfil.descripcion ?? "-",
        F:  t(STATES.find(val => val.value ===item.estado.idEstado)?.label ??"") ?? "-",
        G: `${item.usuarioActualizacion ? item.usuarioActualizacion:""} ${item.fechaActualizacion ?format(new Date(item.fechaActualizacion ??""), FORMAT_DATE):""}` ?? "-"
      })
    })

    // const dataFinal = [...titulo, ...tabla, informacionAdicional]
    const dataFinal = [...tabla]

    setTimeout(() => {
      creandoArchivo(dataFinal)
      setLoadingContext(false)
    }, 1000)
  }

  const creandoArchivo = (dataFinal): void => {
    const libro = XLSX.utils.book_new()

    const hoja = XLSX.utils.json_to_sheet(dataFinal, { skipHeader: true })

    // hoja["!merges"] = [
    //   XLSX.utils.decode_range("A1:G1"),
    //   XLSX.utils.decode_range("A2:G2"),
    //   XLSX.utils.decode_range("A34:G34")
    // ]

    const propiedades:any[] = []

    longitudes.forEach((col:number) => {
      return propiedades.push({
            width: col
        })
    })

    hoja["!cols"] = propiedades

    XLSX.utils.book_append_sheet(libro, hoja, "Data")

    XLSX.writeFile(libro, `${t(TRANSLATION_INDEX_MENU.USERS_BACKOFFICE)??""}_${format(new Date(),"yyyy-MM-dd hh-mm-ss")}.xlsx`)
  }

  return <ButtonCustom width="auto" marginLeft label={t(TRANSLATION_INDEX_GLOBAL.EXPORT)??""} icon={<IconExcel size={13} />} background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE.DARK} backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER.LIGHT} onClick={handleDownload} />
}

export default ExportToExcel
