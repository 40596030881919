import { customer } from "./_duck"
import * as types from "./types"

// Types
import { type PayloadAction } from "../../types"
import { type ModuleType } from "./_definitions"
import { TRANSLATION_INDEX_GLOBAL, TRANSLATION_INDEX_RESPONSE_CODE } from "../../../config/translation"

const initialModuleState: ModuleType = {
  data: null,
  customerToUpdate: null,
  clientType: [],
  assignedCommercial: [],
  countries: [],
  classification: [],
  dataByFilter: null,
  terms: null,
  loading: false,
  showToast: false,
  toastMessage: "",
  hasErrMessage: false,
  bulkassigmenteOk: false,
  errorCode: ""
}

const reducer = customer.createReducer(
  {
    [types.TERMS_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      dataByFilter: null,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.TERMS_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        terms: payload,
        loading: false
      }
    },
    [types.TERMS_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      terms: null,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.BULK_ASSIGMENT_POST_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      bulkassigmenteOk: false,
      loading: true,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.BULK_ASSIGMENT_POST_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        bulkassigmenteOk: true,
        showToast: true,
        toastMessage: TRANSLATION_INDEX_GLOBAL.SUCCESS,
        loading: false
      }
    },
    [types.BULK_ASSIGMENT_POST_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      data: null,
      bulkassigmenteOk: false,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),

    [types.REBULK_ASSIGMENT_POST_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      bulkassigmenteOk: false,
      loading: true,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.REBULK_ASSIGMENT_POST_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        bulkassigmenteOk: true,
        showToast: true,
        toastMessage: TRANSLATION_INDEX_GLOBAL.SUCCESS,
        loading: false
      }
    },
    [types.REBULK_ASSIGMENT_POST_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      data: null,
      bulkassigmenteOk: false,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),

    [types.CUSTOMER_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      customerToUpdate: null,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.CUSTOMER_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        data: payload,
        loading: false
      }
    },
    [types.CUSTOMER_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      data: null,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),

    [types.CUSTOMER_BY_ID_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      customerToUpdate: null,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.CUSTOMER_BY_ID_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        customerToUpdate: payload,
        loading: false
      }
    },
    [types.CUSTOMER_BY_ID_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      customerToUpdate: null,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),

    [types.CUSTOMER_EXCEL_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      dataByFilter: null,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.CUSTOMER_EXCEL_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        showToast: true,
        dataByFilter: payload,
        toastMessage: TRANSLATION_INDEX_GLOBAL.SUCCESS_ADD,
        loading: false
      }
    },
    [types.CUSTOMER_EXCEL_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      data: null,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.CUSTOMER_BY_FILTER_CLEAR]: (state: ModuleType): ModuleType => ({
      ...state,
      dataByFilter: null,
      customerToUpdate: null
    }),
    [types.CUSTOMER_CLEAR]: (state: ModuleType): ModuleType => ({
      ...state,
      dataByFilter: null,
      customerToUpdate: null,
      data: null
    }),
    [types.CUSTOMER_CLEAR_ALL]: (state: ModuleType): ModuleType => ({
      ...state,
      data: null,
      customerToUpdate: null,
      // clientType: [],
      // assignedCommercial: [],
      // countries: [],
      // classification: [],
      dataByFilter: null,
      terms: null,
      loading: false,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.CUSTOMER_BY_FILTER_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      dataByFilter: null,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.CUSTOMER_BY_FILTER_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        dataByFilter: payload,
        loading: false
      }
    },
    [types.CUSTOMER_BY_FILTER_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      dataByFilter: null,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.CLIENT_TYPE_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.CLIENT_TYPE_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        clientType: payload,
        loading: false
      }
    },
    [types.CLIENT_TYPE_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      clientType: [],
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.COUNTRY_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.COUNTRY_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        countries: payload,
        loading: false
      }
    },
    [types.COUNTRY_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      countries: [],
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.CLASSIFICATION_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.CLASSIFICATION_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        classification: payload,
        loading: false
      }
    },
    [types.CLASSIFICATION_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      countries: [],
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.ASSIGNED_COMMERCIAL_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.ASSIGNED_COMMERCIAL_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        assignedCommercial: payload,
        loading: false
      }
    },
    [types.ASSIGNED_COMMERCIAL_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      assignedCommercial: [],
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.CUSTOMER_ADD_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.CUSTOMER_ADD_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        showToast: true,
        toastMessage: TRANSLATION_INDEX_GLOBAL.SUCCESS_ADD,
        loading: false
      }
    },
    [types.CUSTOMER_ADD_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.CUSTOMER_PUT_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.CUSTOMER_PUT_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        loading: false,
        showToast: true,
        toastMessage: TRANSLATION_INDEX_GLOBAL.SUCCESS_UPDATE
      }
    },
    [types.CUSTOMER_PUT_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.CUSTOMER_DELETE_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.CUSTOMER_DELETE_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        loading: false,
        showToast: true,
        toastMessage: TRANSLATION_INDEX_GLOBAL.SUCCESS_DELETE
      }
    },
    [types.CUSTOMER_DELETE_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    })
  },
  initialModuleState
)

export default reducer
