/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, type ReactElement } from "react"
import { useTranslation } from "react-i18next"

import ContainerPage from "../../components/ContainerPage"
import { ContainerTable } from "./_styles"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { useGlobalContext } from "../../context/GlobalContext"
import { preferenceSelector } from "../../state/modules/preferences/_selectors"
import { getPreferenceRequest } from "../../state/modules/preferences/actions"
import { TRANSLATION_INDEX_MENU } from "../../config/translation"
import { type IPreferenceMails } from "../../interfaces/IPreference"
import Poc from "./poc/Poc"
import { getAuth } from "../../utils/functions"
import { PERMISSION } from "../../utils/constants"

const PreferencePage = (): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const preference = useSelector(preferenceSelector)
  const { themeContext } = useGlobalContext()
  const [request, setRequest] = useState<IPreferenceMails>({
    mailsAlertaCambios: [],
    mailsRegistroUsuarios: [],
    mailsCifPricing: [],
    mailsNuevosClientes: [],
    mailsUsuariosSinLogin: [],
    mailsSolicitudFactura: [],
    mailsClienteSinComercialAsignado: [],
    mailsClienteAlertaSinContacto: []
  })
  const { setLoadingContext } = useGlobalContext()
  React.useEffect(() => {
    if (preference.showToast) {
      if (preference.hasErrMessage) {
        toast.error(t(preference.errorCode))
      } else {
        toast.success(t(preference.toastMessage))
        dispatch(getPreferenceRequest())
      }
    }
  }, [preference.showToast])

  React.useEffect(() => {
    setLoadingContext(preference.loading)
    if (preference.data != null) {
      setRequest({
        ...request,
        mailsRegistroUsuarios: preference?.data?.mailsRegistroUsuarios?.split(",") ?? [],
        mailsUsuariosSinLogin: preference?.data?.mailsUsuariosSinLogin?.split(",") ?? [],
        mailsAlertaCambios: preference?.data?.mailsAlertaCambios?.split(",") ?? [],
        mailsCifPricing: preference?.data?.mailsCifPricing?.split(",") ?? [],
        mailsNuevosClientes: preference?.data?.mailsNuevosClientes?.split(",") ?? [],
        mailsSolicitudFactura: preference?.data?.mailsSolicitudFactura?.split(",") ?? [],
        mailsClienteSinComercialAsignado: preference?.data?.mailsClienteSinComercialAsignado?.split(",") ?? [],
        mailsClienteAlertaSinContacto: preference?.data?.mailsClienteAlertaSinContacto?.split(",") ?? [],
        days: preference?.data?.cantidadDiasCambios,
        clienteDiasSinEventos: preference?.data?.clienteDiasSinEventos,
        clienteDiasSinContacto: preference?.data?.clienteDiasSinContacto
      })
    }
  }, [preference])

  React.useEffect(() => {
    setLoadingContext(true)
    dispatch(getPreferenceRequest())
  }, [])
  return (
    <>
      <ContainerPage title={`${t(TRANSLATION_INDEX_MENU.PREFERENCES)}`} actions={[]}>
        <>
          <ContainerTable themeContext={themeContext} opacity={1}>
            <Poc
              canEdit={getAuth(PERMISSION.PREFERENCIAS_MODIFICAR)[0]}
              days={request.days ?? 0}
              clienteDiasSinEventos={request.clienteDiasSinEventos ?? 0}
              clienteDiasSinContacto={request.clienteDiasSinContacto ?? 0}
              mailsAlertaCambios={request.mailsAlertaCambios.map((item, index) => {
                return {
                  label: item.toLowerCase(),
                  value: index.toString(),
                  hidden: false
                }
              })}
              mailsUsuariosSinLogin={request.mailsUsuariosSinLogin.map((item, index) => {
                return {
                  label: item.toLowerCase(),
                  value: index.toString(),
                  hidden: false
                }
              })}
              mailsRegistroUsuarios={request.mailsRegistroUsuarios.map((item, index) => {
                return {
                  label: item.toLowerCase(),
                  value: index.toString(),
                  hidden: false
                }
              })}
              mailsCifPricing={request?.mailsCifPricing.map((item, index) => {
                return {
                  label: item.toLowerCase(),
                  value: index.toString(),
                  hidden: false
                }
              })}
              mailsNuevosClientes={request?.mailsNuevosClientes.map((item, index) => {
                return {
                  label: item.toLowerCase(),
                  value: index.toString(),
                  hidden: false
                }
              })}
              mailsSolicitudFactura={request?.mailsSolicitudFactura.map((item, index) => {
                return {
                  label: item.toLowerCase(),
                  value: index.toString(),
                  hidden: false
                }
              })}
              mailsClienteSinComercialAsignado={request?.mailsClienteSinComercialAsignado.map((item, index) => {
                return {
                  label: item.toLowerCase(),
                  value: index.toString(),
                  hidden: false
                }
              })}
              mailsClienteAlertaSinContacto={request?.mailsClienteAlertaSinContacto.map((item, index) => {
                return {
                  label: item.toLowerCase(),
                  value: index.toString(),
                  hidden: false
                }
              })}
            />
          </ContainerTable>
        </>
      </ContainerPage>
    </>
  )
}

export default PreferencePage
