import { quoteTemplate } from "./_duck"

export const QUOTE_TEMPLATE_GET_REQUEST: string = quoteTemplate.defineType("QUOTE_TEMPLATE_GET_REQUEST")
export const QUOTE_TEMPLATE_GET_SUCCESS: string = quoteTemplate.defineType("QUOTE_TEMPLATE_GET_SUCCESS")
export const QUOTE_TEMPLATE_GET_FAILURE: string = quoteTemplate.defineType("QUOTE_TEMPLATE_GET_FAILURE")

export const QUOTE_SENT_GET_REQUEST: string = quoteTemplate.defineType("QUOTE_SENT_GET_REQUEST")
export const QUOTE_SENT_GET_SUCCESS: string = quoteTemplate.defineType("QUOTE_SENT_GET_SUCCESS")
export const QUOTE_SENT_GET_FAILURE: string = quoteTemplate.defineType("QUOTE_SENT_GET_FAILURE")

export const QUOTE_SENT_CUSTOMER_GET_REQUEST: string = quoteTemplate.defineType("QUOTE_SENT_CUSTOMER_GET_REQUEST")
export const QUOTE_SENT_CUSTOMER_GET_SUCCESS: string = quoteTemplate.defineType("QUOTE_SENT_CUSTOMER_GET_SUCCESS")
export const QUOTE_SENT_CUSTOMER_GET_FAILURE: string = quoteTemplate.defineType("QUOTE_SENT_CUSTOMER_GET_FAILURE")

export const QUOTE_SENT_BY_ID_GET_REQUEST: string = quoteTemplate.defineType("QUOTE_SENT_BY_ID_GET_REQUEST")
export const QUOTE_SENT_BY_ID_GET_SUCCESS: string = quoteTemplate.defineType("QUOTE_SENT_BY_ID_GET_SUCCESS")
export const QUOTE_SENT_BY_ID_GET_FAILURE: string = quoteTemplate.defineType("QUOTE_SENT_BY_ID_GET_FAILURE")

export const QUOTE_TEMPLATE_CUSTOMER_RELATIVE_GET_REQUEST: string = quoteTemplate.defineType(
  "QUOTE_TEMPLATE_CUSTOMER_RELATIVE_GET_REQUEST"
)
export const QUOTE_TEMPLATE_CUSTOMER_RELATIVE_GET_SUCCESS: string = quoteTemplate.defineType(
  "QUOTE_TEMPLATE_CUSTOMER_RELATIVE_GET_SUCCESS"
)
export const QUOTE_TEMPLATE_CUSTOMER_RELATIVE_GET_FAILURE: string = quoteTemplate.defineType(
  "QUOTE_TEMPLATE_CUSTOMER_RELATIVE_GET_FAILURE"
)

export const QUOTE_TEMPLATE_ADD_REQUEST: string = quoteTemplate.defineType("QUOTE_TEMPLATE_ADD_REQUEST")
export const QUOTE_TEMPLATE_ADD_SUCCESS: string = quoteTemplate.defineType("QUOTE_TEMPLATE_ADD_SUCCESS")
export const QUOTE_TEMPLATE_ADD_FAILURE: string = quoteTemplate.defineType("QUOTE_TEMPLATE_ADD_FAILURE")

export const QUOTE_TEMPLATE_CLEAN_PDF: string = quoteTemplate.defineType("QUOTE_TEMPLATE_CLEAN_PDF")
export const QUOTE_TEMPLATE_CLEAN_RESPONSE_ERROR: string = quoteTemplate.defineType(
  "QUOTE_TEMPLATE_CLEAN_RESPONSE_ERROR"
)

export const QUOTE_TEMPLATE_CREATE_PDF_REQUEST: string = quoteTemplate.defineType("QUOTE_TEMPLATE_CREATE_PDF_REQUEST")
export const QUOTE_TEMPLATE_CREATE_PDF_SUCCESS: string = quoteTemplate.defineType("QUOTE_TEMPLATE_CREATE_PDF_SUCCESS")
export const QUOTE_TEMPLATE_CREATE_PDF_FAILURE: string = quoteTemplate.defineType("QUOTE_TEMPLATE_CREATE_PDF_FAILURE")

export const QUOTE_TEMPLATE_PREVIEW_PDF_REQUEST: string = quoteTemplate.defineType("QUOTE_TEMPLATE_PREVIEW_PDF_REQUEST")
export const QUOTE_TEMPLATE_PREVIEW_PDF_SUCCESS: string = quoteTemplate.defineType("QUOTE_TEMPLATE_PREVIEW_PDF_SUCCESS")
export const QUOTE_TEMPLATE_PREVIEW_PDF_FAILURE: string = quoteTemplate.defineType("QUOTE_TEMPLATE_PREVIEW_PDF_FAILURE")

export const QUOTE_SEND_POST_REQUEST: string = quoteTemplate.defineType("QUOTE_SEND_POST_REQUEST")
export const QUOTE_SEND_POST_SUCCESS: string = quoteTemplate.defineType("QUOTE_SEND_POST_SUCCESS")
export const QUOTE_SEND_POST_FAILURE: string = quoteTemplate.defineType("QUOTE_SEND_POST_FAILURE")

export const QUOTE_TEMPLATE_PUT_REQUEST: string = quoteTemplate.defineType("QUOTE_TEMPLATE_PUT_REQUEST")
export const QUOTE_TEMPLATE_PUT_SUCCESS: string = quoteTemplate.defineType("QUOTE_TEMPLATE_PUT_SUCCESS")
export const QUOTE_TEMPLATE_PUT_FAILURE: string = quoteTemplate.defineType("QUOTE_TEMPLATE_PUT_FAILURE")

export const QUOTE_TEMPLATE_DELETE_REQUEST: string = quoteTemplate.defineType("QUOTE_TEMPLATE_DELETE_REQUEST")
export const QUOTE_TEMPLATE_DELETE_SUCCESS: string = quoteTemplate.defineType("QUOTE_TEMPLATE_DELETE_SUCCESS")
export const QUOTE_TEMPLATE_DELETE_FAILURE: string = quoteTemplate.defineType("QUOTE_TEMPLATE_DELETE_FAILURE")
