/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { type ReactElement } from "react"

import AsyncSelect from "react-select/async"
import { Container, Label } from "./_styles"
import { type Props } from "./_types"
import { FormHelperText } from "@mui/material"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { isStringEmpty } from "../../utils/functions"
import { useTranslation } from "react-i18next"
import { TRANSLATION_INDEX_GLOBAL } from "../../config/translation"
import { useGlobalContext } from "../../context/GlobalContext"
import { ContainerIcon, ContainerInput } from "../SelectCustom/_styles"
import IconSearch from "../Icons/IconSearch"

const SelectAsyncCustom = ({
  promiseOptions,
  isMulti = false,
  components,
  isDisabled,
  name,
  errors,
  label = "",
  value,
  handleChange,
  listTop = true,
  iconLeft = false,
  placeHolder = "",
  width = "100%",
  marginLeft = false,
  disabled = false,
  validError = true,
  setOptionIsNull
}: Props): ReactElement => {
  const { t } = useTranslation()
  const error = t(typeof errors === "string" ? errors : errors?.value)
  const { themeContext } = useGlobalContext()
  return (
    <Container themeContext={themeContext} data-testid="select" width={width} marginLeft={marginLeft}>
      {!isStringEmpty(label) && <Label themeContext={themeContext}>{label}</Label>}
      <ContainerInput>
        {iconLeft && (
          <ContainerIcon
            themeContext={themeContext}
            iconLeft={iconLeft}
            background={iconLeft && GLOBAL_STYLES.BACKGROUND.TYPE_6[themeContext]}
          >
            <IconSearch color="#495057" />
          </ContainerIcon>
        )}

        <AsyncSelect
          aria-sort="ascending"
          isClearable
          menuPlacement={listTop ? "top" : "bottom"}
          noOptionsMessage={() => t(TRANSLATION_INDEX_GLOBAL.NO_OPTIONS)}
          loadingMessage={() => t(TRANSLATION_INDEX_GLOBAL.LOADING)}
          maxMenuHeight={265}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              cursor: state.isDisabled ? "no-drop" : "pointer",
              border: !isStringEmpty(error)
                ? "1px solid red !important"
                : state.isFocused
                ? "1px solid #bebebe !important"
                : " 1px solid #c0c0c0",
              boxShadow: state.isFocused ? " 0px 0px 0px 1px #bebebe  !important" : "none !important",
              fontFamily: `${GLOBAL_STYLES.FONT.FAMILY}`,
              background: state.isDisabled && themeContext === "DARK" ? "#f6f6f6" : "",
              minHeight: "42px",
              fontSize: "0.8rem !important",
              borderRadius: iconLeft ? "0px 5px 5px 0px" : "5px"
            }),
            menu: (baseStyles, state) => ({
              ...baseStyles,
              marginBottom: listTop ? "0px" : "9px",
              marginTop: listTop ? "-6px" : "9px",
              cursor: isDisabled ? "no-drop" : "pointer",
              top: listTop ? "" : "30px",
              zIndex: 9999
            }),
            option: (baseStyles, state) => {
              return {
                ...baseStyles,
                color: "#000",
                cursor: state.isDisabled ? "no-drop" : "pointer"
              }
            },
            noOptionsMessage: (baseStyle, state) => ({
              ...baseStyle,
              textAlign: "left",
              color: "#000",
              background: "#DEEBFF"
            }),
            container: (baseStyles, state) => {
              return {
                ...baseStyles,
                cursor: state.isDisabled ? "no-drop" : "pointer",
                width: "100%",
                fontSize: "0.8rem !important",
                fontFamily: `${GLOBAL_STYLES.FONT.FAMILY}`,
                borderShadow: "none !important"
              }
            }
          }}
          cacheOptions
          defaultOptions
          loadOptions={promiseOptions}
          isDisabled={isDisabled}
          name={name}
          isMulti={isMulti}
          value={value ?? null}
          onChange={e => {
            if (setOptionIsNull != null) {
              if (e === null) setOptionIsNull(true)
              else setOptionIsNull(false)
            }
            handleChange(name, e)
          }}
          placeholder={placeHolder}
        />
      </ContainerInput>
      {validError && (
        <FormHelperText style={{ color: GLOBAL_STYLES.LABEL.TYPE_4[themeContext] }}>{error}</FormHelperText>
      )}
    </Container>
  )
}

export default SelectAsyncCustom
