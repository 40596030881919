/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import { type IPropSearch } from "./_types"
import { withFormik } from "formik"

import { ContainerSerch } from "./_styles"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"
import {
  TRANSLATION_INDEX_EVENT,
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_REPORT
} from "../../../../config/translation"
import { useMobile } from "../../../../hook/useMobile"
import ButtonCustom from "../../../../components/ButtonCustom"
import { useResize } from "../../../../hook/useResize"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { useGlobalContext } from "../../../../context/GlobalContext"
import { DatePickerCustom } from "../../../../components/DatePickerCustom/DatePickerCustom/Index"
import { ContainerButtom } from "../../../CustomerProspect/forms/search/_styles"
import SelectCustom from "../../../../components/SelectCustom"
import { useSelector } from "react-redux"
import { customerSelector } from "../../../../state/modules/customer/_selectors"

const SearchUserClientApp = ({
  setDataFiltered = () => {},
  data,
  isUserPending = false,
  values,
  errors,
  setSubmitting,
  isSubmitting,
  handleChange,
  setFieldValue,
  handleReset,
  handleBlur,
  handleSubmit,
  setSearchData,
  setFilters,
  setClearFilter
}: IFormListProps): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useMobile()
  const screenSize = useResize()
  const { themeContext } = useGlobalContext()
  const [fromDate, setFromDate] = React.useState<string | Date | null>(null)
  const [toDate, setToDate] = React.useState<string | Date | null>(null)
  const customers = useSelector(customerSelector)
  const handleSearch = (): void => {
    // TODO: hacer dispatch de busqueda
    const request = {
      fechaDesde: values?.fechaDesde ? new Date(values?.fechaDesde).getTime() : null,
      fechaHasta: values?.fechaHasta ? new Date(values?.fechaHasta).getTime() : null,
      comerciales: values?.commercials?.map(item => item.value) ?? null
    }
    if (setFilters) setFilters(request)
    if (setSearchData) setSearchData(true)
  }
  const handleResetFilters = (): void => {
    if (setFilters && setFieldValue) {
      setFieldValue("commercials", null)
      setFromDate(null)
      setToDate(null)
      setFilters({
        fechaDesde: null,
        fechaHasta: null,
        commercials: null
      })
    }
    if (setClearFilter) setClearFilter(true)
  }
  React.useEffect(() => {
    if (setFieldValue) {
      setFieldValue("fechaDesde", fromDate)
      setFieldValue("fechaHasta", toDate)
    }
  }, [fromDate, toDate])
  return (
    <ContainerSerch>
      <DatePickerCustom
        value={fromDate}
        iconLeft
        placeHolder={t(TRANSLATION_INDEX_EVENT.DATE_FROM) ?? ""}
        label={t(TRANSLATION_INDEX_EVENT.DATE_FROM) ?? ""}
        setValue={setFromDate}
        dateFormat="dd/MM/yyyy"
        width={isMobile ? "100%" : `${screenSize / 4 - 12}px`}
        marginRight
      />
      <DatePickerCustom
        iconLeft
        value={toDate}
        placeHolder={t(TRANSLATION_INDEX_EVENT.DATE_TO) ?? ""}
        label={t(TRANSLATION_INDEX_EVENT.DATE_TO) ?? ""}
        setValue={setToDate}
        marginLeft
        dateFormat="dd/MM/yyyy"
        width={isMobile ? "100%" : `${screenSize / 4 - 12}px`}
        marginRight
      />
      {setFieldValue != null && (
        <SelectCustom
          label={t(TRANSLATION_INDEX_REPORT.USER) ?? ""}
          value={values?.commercials ?? null}
          iconLeft
          isMulti
          handleChange={setFieldValue}
          name="commercials"
          placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_MORE) ?? ""}
          width={isMobile ? "100%" : `${screenSize / 2 + 12}px`}
          marginLeft
          validError={false}
          options={customers.assignedCommercial.map(item => {
            return {
              value: item.nombreUsuario,
              label: `${item.apellido.toLocaleUpperCase()} ${item.nombre.toLocaleUpperCase()}`
            }
          })}
        />
      )}
      <ContainerButtom themeContext={themeContext} style={{ width: isMobile ? "100%" : `${screenSize / 4 - 15}px` }}>
        <ButtonCustom
          label={t(TRANSLATION_INDEX_GLOBAL.CLEAR) ?? ""}
          background={GLOBAL_STYLES.BUTTON.GRAY_BG_IMAGE[themeContext]}
          backgroundHover={GLOBAL_STYLES.BUTTON.GRAY_BG_IMAGE_HOVER[themeContext]}
          onClick={handleResetFilters}
          width={isMobile ? "100%" : "110px"}
        />
        <ButtonCustom
          label={t(TRANSLATION_INDEX_GLOBAL.SEARCH) ?? ""}
          background={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE[themeContext]}
          backgroundHover={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE_HOVER[themeContext]}
          onClick={handleSearch}
          width={isMobile ? "100%" : "80px"}
          marginLeft
        />
      </ContainerButtom>
    </ContainerSerch>
  )
}

export default withFormik<IFormListProps, IPropSearch>({
  mapPropsToValues: (): IPropSearch => ({}),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, { setSubmitting }): any => {}
})(SearchUserClientApp)
