/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import { type IPropSearch } from "./_types"
import { withFormik } from "formik"

import { ContainerSerch, Group } from "./_styles"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"
import { TRANSLATION_INDEX_GLOBAL, TRANSLATION_INDEX_PROFILE } from "../../../../config/translation"
import SelectCustom from "../../../../components/SelectCustom"
import { useMobile } from "../../../../hook/useMobile"

const SearchForm = ({
  setDataFiltered = () => {},
  setOptionIsNull,
  data,
  valueSelected,
  setValueSelected = () => {},
  isUserPending = false,
  values,
  errors,
  setSubmitting,
  isSubmitting,
  handleChange,
  setFieldValue,
  handleReset,
  handleBlur,
  handleSubmit
}: IFormListProps): ReactElement => {
  const isMobile = useMobile()
  const { t } = useTranslation()
  const handleSearch = (): void => {
    if (values?.perfil) setValueSelected(data.find(item => item.idPerfil === values?.perfil?.value))
  }

  React.useEffect(() => {
    handleSearch()
  }, [values])

  return (
    <ContainerSerch>
      <Group>
        {setFieldValue != null && (
          <SelectCustom
            maxMenuHeight={325}
            setOptionIsNull={setOptionIsNull}
            isDisabled={data === null}
            value={values?.perfil ?? null}
            handleChange={setFieldValue}
            name="perfil"
            width={isMobile ? "100%" : "25%"}
            placeHolder={`${t(TRANSLATION_INDEX_GLOBAL.SELECT_A) ?? ""} ${t(TRANSLATION_INDEX_PROFILE.PROFILE)}`}
            marginLeft
            iconLeft
            options={data
              .sort((a, b) => (a.descripcion > b.descripcion ? 1 : -1))
              .map(item => {
                return { value: item.idPerfil, label: item.descripcion.toUpperCase() }
              })}
          />
        )}
      </Group>
    </ContainerSerch>
  )
}

export default withFormik<IFormListProps, IPropSearch>({
  mapPropsToValues: (): IPropSearch => ({}),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, { setSubmitting }): any => {}
})(SearchForm)
