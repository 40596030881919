import styled from "styled-components"

export const ContainerIcons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const ContainerSerch = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
`

export const ContainerTable = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const LabelAction = styled.div`
  margin-left: 5px;
`
