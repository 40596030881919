/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import styled from "styled-components"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { type IStyle } from "../../../../interfaces/IStyle"

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const ContainerGroup = styled.div<IStyle>`
  min-height: 70px;
  width: calc(100% - 90px);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 45px;
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
`

export const Group = styled.div<IStyle>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: ${({ alignItems }) => alignItems ?? "flex-end"};
  @media (max-width: 900px) {
    flex-direction: column;
  }
`

export const GroupButtom = styled.div<IStyle>`
  width: calc(100% - 30px);
  display: flex;
  padding: 10px 15px;
  justify-content: flex-end;
  border-top: ${({ themeContext }) => GLOBAL_STYLES.BORDER.TYPE_2[themeContext]};
`
export const ContainerMessage = styled.div<IStyle>`
  min-height: 70px;
  width: calc(100% - 90px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 45px;
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
`

export const Contact = styled.div<IStyle>`
  width: 100%;
  margin-top: 15px;
  border: ${({ themeContext }) => GLOBAL_STYLES.BORDER.TYPE_2[themeContext]};
  padding: 15px 5px;
`

export const Title = styled.div<IStyle>`
  margin-bottom: 10px;
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
  font-size: 1.1rem;
`

export const Content = styled.div<IStyle>`
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
`

export const ContainerItems = styled.div<IStyle>`
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  margin-top: -6px;
  flex-wrap: wrap;
  border-radius: 5px;
  position: relative;
  width: 50%;
  padding: 10px 5px 0px 5px;
  border-left: ${({ isActive, themeContext }) => (isActive ? GLOBAL_STYLES.BORDER.TYPE_2[themeContext] : "")};
  border-right: ${({ isActive, themeContext }) => (isActive ? GLOBAL_STYLES.BORDER.TYPE_2[themeContext] : "")};
  border-bottom: ${({ isActive, themeContext }) => (isActive ? GLOBAL_STYLES.BORDER.TYPE_2[themeContext] : "")};
  word-wrap: break-word;
  @media (max-width: 900px) {
    width: calc(100% - 12px);
    margin-top: -15px;
  }
`

export const ContainerItem = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-right: 8px;
  margin-bottom: 10px;
`
export const Text = styled.div<IStyle>`
  display: inline-block;
  position: relative;
  text-overflow: clip;
  justify-content: flex-start;
  padding: 0px 7px;
  border: ${({ themeContext }) => GLOBAL_STYLES.BORDER.TYPE_2[themeContext]};
  background-color: ${({ themeContext }) => GLOBAL_STYLES.BACKGROUND.TYPE_3[themeContext]};
  border-radius: 5px 0px 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const ButtonDelete = styled.div<IStyle>`
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
  cursor: pointer;
  background-color: ${({ themeContext }) => GLOBAL_STYLES.BACKGROUND.TYPE_3[themeContext]};
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  align-items: center;
  border-radius: 0px 5px 5px 0px;
  justify-content: center;
  border-top: ${({ themeContext }) => GLOBAL_STYLES.BORDER.TYPE_2[themeContext]};
  border-bottom: ${({ themeContext }) => GLOBAL_STYLES.BORDER.TYPE_2[themeContext]};
  border-right: ${({ themeContext }) => GLOBAL_STYLES.BORDER.TYPE_2[themeContext]};
  font-weight: 700;
  font-size: 0.8rem;
  :hover {
    color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_2[themeContext]};
  }
`
