/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { format, formatDistanceStrict } from "date-fns"
import { type IDecodedToken } from "../interfaces/IDecodedToken"
import { STORAGE } from "./localStorage"
import jwt_decode from "jwt-decode"

export const getCurrentLanguage = (): string => {
  const value = localStorage.getItem("i18nextLng")
  if (value && (value?.substring(0, 2) === "es" || value?.substring(0, 2) === "en")) return value?.substring(0, 2)
  else return "en"
}

export const isStringEmpty = (str: string): boolean =>
  str === undefined || str === null || str.toString().trim().length === 0

export const capitalizeFirstLetter = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase()

export const formatSelectOptions = (dataOptions: any): any[] => {
  return dataOptions.map(opc => {
    return {
      value: opc.value,
      label: opc?.label ?? opc.value
    }
  })
}
export const formatNumberWithThousandsSeparator = (number): string => {
  if (isNaN(number)) return "0"
  const newValue = Number(Number(number).toFixed(2))
  return new Intl.NumberFormat().format(newValue)
}
const isTokenExpired = (num): boolean => Math.floor(new Date().getTime() / 1000) >= num

export const getAuth = (permission = "*"): [boolean, string, string[], boolean] => {
  let decoded: IDecodedToken | null = null
  let isAuth = false
  let userName = ""
  let permissions: string[] = []
  let errorToken: boolean = false
  const tokenStorage = STORAGE.get(STORAGE.TOKEN)

  if (tokenStorage !== null && !isStringEmpty(tokenStorage)) {
    try {
      decoded = jwt_decode(tokenStorage)
      if (isTokenExpired(decoded?.exp ?? "")) {
        STORAGE.remove(STORAGE.TOKEN)
        STORAGE.remove(STORAGE.NOTIFICATION)
        STORAGE.remove(STORAGE.CHANGE_PASSWORD)
        location.replace("/")
      }
      userName = decoded?.sub ?? ""
      permissions = decoded?.authorities?.split(",") ?? []
    } catch {
      decoded = null
      errorToken = true
    }
    if (permission === "*" || (permissions.some(item => item === permission) ?? false)) {
      isAuth = true
    } else {
      isAuth = false
    }
  } else {
    isAuth = false
  }
  return [isAuth, userName.toLowerCase(), permissions, errorToken]
}

export const daysDiffernce = (from: number, to: number): string => {
  const response = formatDistanceStrict(
    new Date(`${format(new Date(from), "yyyy-MM-dd")}T00:00:00.000Z`),
    new Date(`${format(new Date(to), "yyyy-MM-dd")}T00:00:00.000Z`),
    {
      addSuffix: true,
      unit: "day"
    }
  )
    .replaceAll("in ", "-")
    .replaceAll(" days", "")
    .replaceAll(" day", "")
    .replaceAll(" ago", "")
  return response
}

export const formatNumber = (num: number): string =>
  new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(num)

const validateLenghtTwo = (str: string | number): string => (str.toString().length === 2 ? str.toString() : `0${str}`)

export const getCurrentDateToFile = (): string => {
  const date = new Date()
  const month = validateLenghtTwo((date.getMonth() + 1).toString())
  const day = validateLenghtTwo(date.getDay().toString())
  const hour = validateLenghtTwo(date.getHours().toString())
  const minute = validateLenghtTwo(date.getMinutes().toString())
  const second = validateLenghtTwo(date.getSeconds().toString())

  return `${date.getFullYear()}${month}${day}_${hour}${minute}${second}`
}

export const countDigits = (str): number => {
  let acu = 0

  Array.prototype.forEach.call(str, function (val) {
    acu += val.charCodeAt(0) > 47 && val.charCodeAt(0) < 58 ? 1 : 0
  })

  return acu
}
