import { customer } from "./_duck"

export const TERMS_GET_REQUEST: string = customer.defineType("TERMS_GET_REQUEST")
export const TERMS_GET_SUCCESS: string = customer.defineType("TERMS_GET_SUCCESS")
export const TERMS_GET_FAILURE: string = customer.defineType("TERMS_GET_FAILURE")

export const CUSTOMER_GET_REQUEST: string = customer.defineType("CUSTOMER_GET_REQUEST")
export const CUSTOMER_GET_SUCCESS: string = customer.defineType("CUSTOMER_GET_SUCCESS")
export const CUSTOMER_GET_FAILURE: string = customer.defineType("CUSTOMER_GET_FAILURE")

export const CUSTOMER_EXCEL_GET_REQUEST: string = customer.defineType("CUSTOMER_EXCEL_GET_REQUEST")
export const CUSTOMER_EXCEL_GET_SUCCESS: string = customer.defineType("CUSTOMER_EXCEL_GET_SUCCESS")
export const CUSTOMER_EXCEL_GET_FAILURE: string = customer.defineType("CUSTOMER_EXCEL_GET_FAILURE")

export const CUSTOMER_BY_FILTER_CLEAR: string = customer.defineType("CUSTOMER_BY_FILTER_CLEAR")
export const CUSTOMER_CLEAR: string = customer.defineType("CUSTOMER_CLEAR")
export const CUSTOMER_CLEAR_ALL: string = customer.defineType("CUSTOMER_CLEAR_ALL")

export const CUSTOMER_BY_FILTER_GET_REQUEST: string = customer.defineType("CUSTOMER_BY_FILTER_GET_REQUEST")
export const CUSTOMER_BY_FILTER_GET_SUCCESS: string = customer.defineType("CUSTOMER_BY_FILTER_GET_SUCCESS")
export const CUSTOMER_BY_FILTER_GET_FAILURE: string = customer.defineType("CUSTOMER_BY_FILTER_GET_FAILURE")

export const COUNTRY_GET_REQUEST: string = customer.defineType("COUNTRY_GET_REQUEST")
export const COUNTRY_GET_SUCCESS: string = customer.defineType("COUNTRY_GET_SUCCESS")
export const COUNTRY_GET_FAILURE: string = customer.defineType("COUNTRY_GET_FAILURE")

export const CLASSIFICATION_GET_REQUEST: string = customer.defineType("CLASSIFICATION_GET_REQUEST")
export const CLASSIFICATION_GET_SUCCESS: string = customer.defineType("CLASSIFICATION_GET_SUCCESS")
export const CLASSIFICATION_GET_FAILURE: string = customer.defineType("CLASSIFICATION_GET_FAILURE")

export const CLIENT_TYPE_GET_REQUEST: string = customer.defineType("CLIENT_TYPE_GET_REQUEST")
export const CLIENT_TYPE_GET_SUCCESS: string = customer.defineType("CLIENT_TYPE_GET_SUCCESS")
export const CLIENT_TYPE_GET_FAILURE: string = customer.defineType("CLIENT_TYPE_GET_FAILURE")

export const ASSIGNED_COMMERCIAL_GET_REQUEST: string = customer.defineType("ASSIGNED_COMMERCIAL_GET_REQUEST")
export const ASSIGNED_COMMERCIAL_GET_SUCCESS: string = customer.defineType("ASSIGNED_COMMERCIAL_GET_SUCCESS")
export const ASSIGNED_COMMERCIAL_GET_FAILURE: string = customer.defineType("ASSIGNED_COMMERCIAL_GET_FAILURE")

export const CUSTOMER_ADD_REQUEST: string = customer.defineType("CUSTOMER_ADD_REQUEST")
export const CUSTOMER_ADD_SUCCESS: string = customer.defineType("CUSTOMER_ADD_SUCCESS")
export const CUSTOMER_ADD_FAILURE: string = customer.defineType("CUSTOMER_ADD_FAILURE")

export const CUSTOMER_PUT_REQUEST: string = customer.defineType("CUSTOMER_PUT_REQUEST")
export const CUSTOMER_PUT_SUCCESS: string = customer.defineType("CUSTOMER_PUT_SUCCESS")
export const CUSTOMER_PUT_FAILURE: string = customer.defineType("CUSTOMER_PUT_FAILURE")

export const CUSTOMER_DELETE_REQUEST: string = customer.defineType("CUSTOMER_DELETE_REQUEST")
export const CUSTOMER_DELETE_SUCCESS: string = customer.defineType("CUSTOMER_DELETE_SUCCESS")
export const CUSTOMER_DELETE_FAILURE: string = customer.defineType("CUSTOMER_DELETE_FAILURE")

export const CUSTOMER_BY_ID_GET_REQUEST: string = customer.defineType("CUSTOMER_BY_ID_GET_REQUEST")
export const CUSTOMER_BY_ID_GET_SUCCESS: string = customer.defineType("CUSTOMER_BY_ID_GET_SUCCESS")
export const CUSTOMER_BY_ID_GET_FAILURE: string = customer.defineType("CUSTOMER_BY_ID_GET_FAILURE")

export const BULK_ASSIGMENT_POST_REQUEST: string = customer.defineType("BULK_ASSIGMENT_POST_REQUEST")
export const BULK_ASSIGMENT_POST_SUCCESS: string = customer.defineType("BULK_ASSIGMENT_POST_SUCCESS")
export const BULK_ASSIGMENT_POST_FAILURE: string = customer.defineType("BULK_ASSIGMENT_POST_FAILURE")

export const REBULK_ASSIGMENT_POST_REQUEST: string = customer.defineType("REBULK_ASSIGMENT_POST_REQUEST")
export const REBULK_ASSIGMENT_POST_SUCCESS: string = customer.defineType("REBULK_ASSIGMENT_POST_SUCCESS")
export const REBULK_ASSIGMENT_POST_FAILURE: string = customer.defineType("REBULK_ASSIGMENT_POST_FAILURE")
