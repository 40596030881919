import { customs } from "./_duck"
import * as types from "./types"

export const getCustomsRequest = customs.createAction(types.CUSTOMS_GET_REQUEST)
export const getCustomsSuccess = customs.createAction(types.CUSTOMS_GET_SUCCESS)
export const getCustomsFailure = customs.createAction(types.CUSTOMS_GET_FAILURE)

export const customclear = customs.createAction(types.CUSTOMS_CLEAR)
export const customclearAll = customs.createAction(types.CUSTOMS_CLEAR_ALL)

export const postCustomsImportRequest = customs.createAction(types.CUSTOMS_POST_IMPORT_REQUEST)
export const postCustomsImportSuccess = customs.createAction(types.CUSTOMS_POST_IMPORT_SUCCESS)
export const postCustomsImportFailure = customs.createAction(types.CUSTOMS_POST_IMPORT_FAILURE)

export const getCustomsByIdRequest = customs.createAction(types.CUSTOMS_BY_ID_GET_REQUEST)
export const getCustomsByIdSuccess = customs.createAction(types.CUSTOMS_BY_ID_GET_SUCCESS)
export const getCustomsByIdFailure = customs.createAction(types.CUSTOMS_BY_ID_GET_FAILURE)

export const getCustomsPlacesRequest = customs.createAction(types.CUSTOMS_PLACES_GET_REQUEST)
export const getCustomsPlacesSuccess = customs.createAction(types.CUSTOMS_PLACES_GET_SUCCESS)
export const getCustomsPlacesFailure = customs.createAction(types.CUSTOMS_PLACES_GET_FAILURE)
