/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"
import { useSelector } from "react-redux"
import { remendersSelector } from "../../../../state/modules/remenders/_selectors"
import { TRANSLATION_INDEX_CALENDAR } from "../../../../config/translation"
import ContainerPage from "../../../../components/ContainerPage"
import { useGlobalContext } from "../../../../context/GlobalContext"
import { ContainerTable } from "../../../Profile/_styles"
import { type IRemenders } from "../../../../interfaces/IRemenders"
import { format } from "date-fns"
import { ContainerRowTask } from "../../_styles"
import Divisor from "../../../../components/Divisor"

const TableRemender = (): ReactElement => {
  const { t } = useTranslation()
  const remenders = useSelector(remendersSelector)
  const { themeContext } = useGlobalContext()

  return (
    <>
      <ContainerPage title={`${t(TRANSLATION_INDEX_CALENDAR.TASKS_OF_THE_DAY)}`} actions={[]}>
        <>
          <ContainerTable themeContext={themeContext} opacity={1}>
            <>
              {remenders?.data
                ?.sort((a, b) => (a.fechaHoraInicio > b.fechaHoraInicio ? 1 : -1))
                .map((item: IRemenders, index) => {
                  return (
                    <>
                      <ContainerRowTask themeContext={themeContext} key={item.idRecordatorio}>
                        <div style={{ whiteSpace: "nowrap" }}>{`${format(
                          new Date(item.fechaHoraInicio),
                          "HH:mm"
                        )} - ${format(new Date(item.fechaHoraFin), "HH:mm")} `}</div>
                        <div style={{ marginLeft: "15px" }}>
                          <span style={{ fontWeight: "500", textDecoration: "underline " }}>{`${
                            item.cliente.razonSocial
                          }${
                            item?.contacto?.apellido ? ` (${item.contacto.apellido} ${item.contacto.nombre})` : ""
                          }`}</span>
                          {`: ${item.comentario ?? ""}`}
                        </div>
                      </ContainerRowTask>
                      {index + 1 !== remenders.data?.length && <Divisor />}
                    </>
                  )
                })}
            </>
          </ContainerTable>
        </>
      </ContainerPage>
    </>
  )
}

export default TableRemender
