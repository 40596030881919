/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { type ReactElement } from "react"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import ButtonCustom from "../ButtonCustom"
import { Container, ContainerHeader, ContainerAction, Title, Content } from "./_styles"
import { type Props } from "./_types"
import { getAuth, isStringEmpty } from "../../utils/functions"
import { useGlobalContext } from "../../context/GlobalContext"

const ContainerPage: React.FC<Props> = ({ title, actions, children }): ReactElement => {
  const { themeContext } = useGlobalContext()
  return (
    <Container themeContext={themeContext} data-testid="container-page">
      <ContainerHeader themeContext={themeContext} active={!isStringEmpty(title)}>
        {!isStringEmpty(title) && <Title themeContext={themeContext}>{title}</Title>}
        {actions != null && actions.length > 0 && (
          <ContainerAction>
            {actions.map(action => {
              return getAuth(action?.permission)[0] ? (
                action.isButton ? (
                  action.component
                ) : (
                  <ButtonCustom
                    icon={action.icon}
                    key={action.label}
                    background={action?.background ?? GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
                    backgroundHover={
                      action?.backgroundHover ?? GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]
                    }
                    width={action?.width ? action?.width : "auto"}
                    marginLeft
                    label={action.label}
                    onClick={action.click}
                  />
                )
              ) : (
                <div key={action?.label}></div>
              )
            })}
          </ContainerAction>
        )}
      </ContainerHeader>
      <Content themeContext={themeContext}>{children}</Content>
    </Container>
  )
}

export default ContainerPage
