// SAGAS
import { put, takeLatest, call } from "redux-saga/effects"
import { type SagaIterator } from "redux-saga"
import * as types from "../types"

import {
  getUserBackofficeFailure,
  getUserBackofficeSuccess,
  addUserBackofficeSuccess,
  addUserBackofficeFailure,
  deleteUserBackofficeSuccess,
  deleteUserBackofficeFailure,
  postUserBackofficeSuccess,
  postUserBackofficeFailure,
  getUserBackofficeImageSuccess,
  getUserBackofficeImageFailure,
  getUserBackofficeNotificationSuccess,
  getUserBackofficeNotificationFailure,
  getUserBackofficeImageURLSuccess,
  getUserBackofficeImageURLFailure
} from "../actions"

import {
  addUserBackoffice,
  deleteUserBackoffice,
  getUserBackoffice,
  getUserBackofficeImage,
  getUserBackofficeImageURL,
  getUserBackofficeNotificationMail,
  postUserBackoffice
} from "../services"

function* getUserBackofficeWorker(): SagaIterator {
  try {
    const { data } = yield call(getUserBackoffice)
    yield put(getUserBackofficeSuccess(data))
  } catch (error) {
    yield put(getUserBackofficeFailure(error))
  }
}

function* getUserBackofficeImageWorker(): SagaIterator {
  try {
    const { data } = yield call(getUserBackofficeImage)
    yield put(getUserBackofficeImageSuccess(data))
  } catch (error) {
    yield put(getUserBackofficeImageFailure(error))
  }
}

function* getUserBackofficeImageURLWorker(): SagaIterator {
  try {
    const { data } = yield call(getUserBackofficeImageURL)
    yield put(getUserBackofficeImageURLSuccess(data))
  } catch (error) {
    yield put(getUserBackofficeImageURLFailure(error))
  }
}

function* getUserBackofficeNotificationWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(getUserBackofficeNotificationMail, request.payload)
    yield put(getUserBackofficeNotificationSuccess(data))
  } catch (error) {
    yield put(getUserBackofficeNotificationFailure(error))
  }
}

function* addUserBackofficeWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(addUserBackoffice, request.payload)
    yield put(addUserBackofficeSuccess(data))
  } catch (error) {
    yield put(addUserBackofficeFailure(error))
  }
}

function* postUserBackofficeWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(postUserBackoffice, request.payload)
    yield put(postUserBackofficeSuccess(data))
  } catch (error) {
    yield put(postUserBackofficeFailure(error))
  }
}

function* deleteUserBackofficeWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(deleteUserBackoffice, request.payload)
    yield put(deleteUserBackofficeSuccess(data))
  } catch (error) {
    yield put(deleteUserBackofficeFailure(error))
  }
}

export function* listWatcher(): SagaIterator {
  yield takeLatest(types.USER_BACKOFFICE_GET_REQUEST, getUserBackofficeWorker)
  yield takeLatest(types.USER_BACKOFFICE_IMAGE_GET_REQUEST, getUserBackofficeImageWorker)
  yield takeLatest(types.USER_BACKOFFICE_IMAGE_URL_GET_REQUEST, getUserBackofficeImageURLWorker)
  yield takeLatest(types.USER_BACKOFFICE_ADD_REQUEST, addUserBackofficeWorker)
  yield takeLatest(types.USER_BACKOFFICE_NOTIFICATION_GET_REQUEST, getUserBackofficeNotificationWorker)
  yield takeLatest(types.USER_BACKOFFICE_POST_REQUEST, postUserBackofficeWorker)
  yield takeLatest(types.USER_BACKOFFICE_DELETE_REQUEST, deleteUserBackofficeWorker)
}

export const sagas = [listWatcher]
