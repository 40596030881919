/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { type ReactElement } from "react"
import InputCustom from "../../../../components/InputCustom"

import ButtonCustom from "../../../../components/ButtonCustom"

import { useTranslation } from "react-i18next"

import { TRANSLATION_INDEX_EVENT, TRANSLATION_INDEX_GLOBAL } from "../../../../config/translation"
import { Container, Group, GroupButtom, ContainerGroup, ContainerMessage } from "./_styles"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { ACTIONS } from "../../../../utils/constants"

import "react-toastify/dist/ReactToastify.css"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"
import { withFormik } from "formik"
import { addUpdateScheme, deleteScheme } from "../../_schema"
import { useGlobalContext } from "../../../../context/GlobalContext"
import { DatePickerCustom } from "../../../../components/DatePickerCustom/DatePickerCustom/Index"
import { useMobile } from "../../../../hook/useMobile"
import SelectCustom from "../../../../components/SelectCustom"
import { useDispatch, useSelector } from "react-redux"
import { eventSelector } from "../../../../state/modules/event/_selectors"
import { useResize } from "../../../../hook/useResize"
import CustomerService from "../../../../services/CustomerService"
import SelectAsyncCustom from "../../../../components/SelectAsyncCustom"
import { clearContactEvents, getContactEventRequest } from "../../../../state/modules/event/actions"
import { EVENT_TYPE } from "../../constants"
import { type IOption } from "../../../../interfaces/IOption"
import { t } from "i18next"

const FormaddUpdate = ({
  data,
  isUserPending = false,
  loading = false,
  action,
  closeModal = () => {},
  accept = () => {},
  setFieldValue,
  values,
  touched,
  validateForm,
  errors,
  setSubmitting,
  isSubmitting,
  handleChange,
  handleBlur,
  handleSubmit
}: IFormListProps): ReactElement => {
  const { t } = useTranslation()
  const { themeContext } = useGlobalContext()
  const dispatch = useDispatch()
  const screenSize = useResize()
  const events = useSelector(eventSelector)
  const [fromDate, setFromDate] = React.useState<string | Date | null>(data?.fecha ? new Date(data?.fecha) : null)
  const [isFirst, setIsFirst] = React.useState(true)
  const isMobile = useMobile()

  const promiseOptions = async (inputValue: string): Promise<any> => {
    const response = await new Promise(resolve => {
      if (inputValue.length > 2) {
        resolve(CustomerService(inputValue))
      } else {
        resolve([{ value: "-1", label: t(TRANSLATION_INDEX_GLOBAL.ENTER_THREE_CHARACTER), isDisabled: true }])
      }
    })
    return response
  }

  React.useEffect(() => {
    dispatch(clearContactEvents())
    if (values?.cliente?.value) dispatch(getContactEventRequest(values?.cliente?.value))
    if (!isFirst && setFieldValue) setFieldValue("contacto", null)
  }, [values?.cliente])

  React.useEffect(() => {
    if (events.contactByCustomer.length > 0) setIsFirst(false)
  }, [events.contactByCustomer])

  React.useEffect(() => {
    if (setFieldValue) {
      setFieldValue("fecha", fromDate)
    }
  }, [fromDate])
  React.useEffect(() => {
    if (touched.fecha === true && Object.keys(errors ?? {}).length > 0) validateForm()
  }, [values])
  React.useEffect(() => {
    return () => {
      dispatch(clearContactEvents())
    }
  }, [])

  return (
    <Container onSubmit={handleSubmit}>
      {action === ACTIONS.DELETE.VALUE ? (
        <ContainerMessage themeContext={themeContext}>{t(TRANSLATION_INDEX_GLOBAL.CONFIRM_DELETE)}</ContainerMessage>
      ) : (
        <ContainerGroup themeContext={themeContext}>
          <Group>
            <DatePickerCustom
              value={fromDate}
              placeHolder={t(TRANSLATION_INDEX_EVENT.DATE) ?? ""}
              label={`${t(TRANSLATION_INDEX_EVENT.DATE)} (*)` ?? ""}
              setValue={setFromDate}
              errors={errors?.fecha}
              name="fecha"
              validError
              dateFormat="dd/MM/yyyy"
              disabled={action === ACTIONS.VIEW.VALUE}
              width={isMobile ? "100%" : `${screenSize / 2}px`}
            />
            {setFieldValue != null && (
              <SelectCustom
                label={`${t(TRANSLATION_INDEX_EVENT.EVENT_TYPE)} (*)` ?? ""}
                value={values?.tipoEvento ?? null}
                handleChange={setFieldValue}
                name="tipoEvento"
                placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
                width={isMobile ? "100%" : `${screenSize / 2}px`}
                marginLeft
                isDisabled={action === ACTIONS.VIEW.VALUE}
                errors={errors?.tipoEvento}
                options={events.eventType
                  .map(item => {
                    return {
                      value: item.idTipoEvento,
                      label:
                        t(
                          EVENT_TYPE.find(itemEvent => itemEvent.idTipoEvento === item.idTipoEvento)?.descripcion ?? "-"
                        ).toUpperCase() ?? "-"
                    }
                  })
                  .sort((a: IOption, b: IOption) => (a.label > b.label ? 1 : -1))}
              />
            )}
          </Group>
          <Group>
            {setFieldValue != null && (
              <SelectAsyncCustom
                listTop={false}
                width={isMobile ? "100%" : `50%`}
                label={`${t(TRANSLATION_INDEX_EVENT.CUSTOMER_PROSPECT)} (*)` ?? ""}
                value={values?.cliente}
                handleChange={setFieldValue}
                name="cliente"
                validError
                placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
                promiseOptions={promiseOptions}
                isDisabled={action === ACTIONS.VIEW.VALUE}
                errors={errors?.cliente}
              />
            )}
            {setFieldValue != null && (
              <SelectCustom
                label={t(TRANSLATION_INDEX_EVENT.CONTACT) ?? ""}
                value={values?.contacto ?? null}
                handleChange={setFieldValue}
                name="contacto"
                isDisabled={action === ACTIONS.VIEW.VALUE}
                placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
                width={isMobile ? "100%" : `50%`}
                marginLeft
                options={events.contactByCustomer.map(item => {
                  return {
                    value: item.idClienteContacto,
                    label: `${item?.apellido?.toUpperCase()} ${item?.nombre?.toUpperCase()}`
                  }
                })}
              />
            )}
          </Group>
          <Group>
            <InputCustom
              label={t(TRANSLATION_INDEX_EVENT.COMMENT) ?? ""}
              value={values?.comentario ?? ""}
              handleChange={handleChange}
              name="comentario"
              isTextArea
              height="80px"
              width={isMobile ? "100%" : `100%`}
              disabled={action === ACTIONS.VIEW.VALUE}
              errors={errors?.comentario}
              touched={touched?.comentario}
            />
          </Group>
        </ContainerGroup>
      )}
      <GroupButtom themeContext={themeContext}>
        <ButtonCustom
          onClick={closeModal}
          width="80px"
          type="button"
          label={t("global.cancel") ?? ""}
          background={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE[themeContext]}
          backgroundHover={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE_HOVER[themeContext]}
        />
        {action !== ACTIONS.VIEW.VALUE && (
          <ButtonCustom
            width="80px"
            type="submit"
            label={
              action === ACTIONS.DELETE.VALUE
                ? t(TRANSLATION_INDEX_GLOBAL.DELETE) ?? ""
                : t(TRANSLATION_INDEX_GLOBAL.CONFIRM) ?? ""
            }
            background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
            backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]}
            marginLeft
          />
        )}
      </GroupButtom>
    </Container>
  )
}

export default withFormik<IFormListProps, any>({
  mapPropsToValues: ({ data }): any => ({
    tipoEvento: data?.tipoEvento
      ? {
          value: data.tipoEvento.idTipoEvento,
          label:
            t(
              EVENT_TYPE.find(itemEvent => itemEvent.idTipoEvento === data.tipoEvento.idTipoEvento)?.descripcion ?? "-"
            ).toUpperCase() ?? "-"
        }
      : null,
    idEvento: data?.idEvento,
    contacto: data?.contacto
      ? {
          value: data.contacto.idClienteContacto,
          label: `${data?.contacto?.apellido?.toUpperCase()} ${data?.contacto?.nombre?.toUpperCase()}`
        }
      : null,
    fecha: data?.fecha,
    comentario: data?.comentario,
    cliente: data?.cliente ? { value: data.cliente.idCliente, label: data.cliente.razonSocial } : null
  }),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: ({ action }) => (action === ACTIONS.DELETE.VALUE ? deleteScheme : addUpdateScheme),
  handleSubmit: (values, { setSubmitting, props: { accept } }): any => {
    const request = {
      tipoEvento: { idTipoEvento: values?.tipoEvento?.value, descripcion: "-" },
      cliente: { idCliente: values?.cliente?.value, cuit: "-", razonSocial: "-" },
      contacto: { idClienteContacto: values?.contacto?.value, apellido: "-", nombre: "-" },
      idEvento: values?.idEvento,
      fecha: values?.fecha,
      comentario: values?.comentario
    }
    if (accept != null) accept(request)
    setSubmitting(false)
  }
})(FormaddUpdate)
