import { quoter } from "./_duck"

export const COMMODITIES_GET_REQUEST: string = quoter.defineType("COMMODITIES_GET_REQUEST")
export const COMMODITIES_GET_SUCCESS: string = quoter.defineType("COMMODITIES_GET_SUCCESS")
export const COMMODITIES_GET_FAILURE: string = quoter.defineType("COMMODITIES_GET_FAILURE")

export const QUOTER_POST_REQUEST: string = quoter.defineType("QUOTER_POST_REQUEST")
export const QUOTER_POST_SUCCESS: string = quoter.defineType("QUOTER_POST_SUCCESS")
export const QUOTER_POST_FAILURE: string = quoter.defineType("QUOTER_POST_FAILURE")

export const EXPORT_POST_REQUEST: string = quoter.defineType("EXPORT_POST_REQUEST")
export const EXPORT_POST_SUCCESS: string = quoter.defineType("EXPORT_POST_SUCCESS")
export const EXPORT_POST_FAILURE: string = quoter.defineType("EXPORT_POST_FAILURE")

export const PROCCESS_INFO_GET_REQUEST: string = quoter.defineType("PROCCESS_INFO_GET_REQUEST")
export const PROCCESS_INFO_GET_SUCCESS: string = quoter.defineType("PROCCESS_INFO_GET_SUCCESS")
export const PROCCESS_INFO_GET_FAILURE: string = quoter.defineType("PROCCESS_INFO_GET_FAILURE")

export const IN_PROCCESS_GET_REQUEST: string = quoter.defineType("IN_PROCCESS_GET_REQUEST")
export const IN_PROCCESS_GET_SUCCESS: string = quoter.defineType("IN_PROCCESS_GET_SUCCESS")
export const IN_PROCCESS_GET_FAILURE: string = quoter.defineType("IN_PROCCESS_GET_FAILURE")

export const CLEAR: string = quoter.defineType("CLEAR")
