import React, { type ReactElement } from "react"

import { Container } from "./_styles"
import { type IProps } from "./_types"

const ContainerLabelTwoRow: React.FC<IProps> = ({ label }): ReactElement => {
  return <Container title={label}>{label}</Container>
}

export default ContainerLabelTwoRow
