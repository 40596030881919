import httpsModule from "../utils/http"

const OrganizationService = async (inputValue): Promise<any[]> => {
  return await httpsModule.get(`/api/backoffice/v1/organizaciones/${inputValue}`).then(res =>
    res.data.map(item => {
      return { value: item?.idOrganizacion, label: item.descripcion }
    })
  )
}

export default OrganizationService
