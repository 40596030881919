import * as Yup from "yup"
import {
  schemeEmailRequired,
  schemeObject,
  schemeStringRequiredNotMailNotSpace,
  schemeStringRequiredWithoutSpaceInitial
} from "../../utils/constants"

export default Yup.object().shape({
  usuario: schemeStringRequiredNotMailNotSpace(),
  nombre: schemeStringRequiredWithoutSpaceInitial(),
  apellido: schemeStringRequiredWithoutSpaceInitial(),
  email: schemeEmailRequired(),
  perfil: schemeObject(),
  estado: schemeObject()
})
