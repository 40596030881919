/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { type ReactElement } from "react"
import { useTranslation } from "react-i18next"

import ContainerPage from "../../components/ContainerPage"
import {
  TRANSLATION_INDEX_AUTHORIZE_INFORMACION,
  TRANSLATION_INDEX_CUSTOMER_PROSPECT,
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_MENU,
  TRANSLATION_INDEX_PROFILE
} from "../../config/translation"

import IconPlus from "../../components/Icons/IconPlus"

import "../../styles/styles.css"
import { ACTIONS, FORMAT_DATE, PERMISSION } from "../../utils/constants"
import AlertModal from "../../components/AlertModal"
import FormaddUpdate from "./forms/addUpdate/FormaddUpdate"
import { ContainerIcons, ContainerTable } from "./_styles"
import Search from "./forms/search"
import DataTable from "react-data-table-component"
import { NoData } from "../../components/NoData"
import { customStylesContext } from "../../styles/_styleDatatable"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import ButtonCustom from "../../components/ButtonCustom"
import { getAuth, isStringEmpty } from "../../utils/functions"
import IconEdit from "../../components/Icons/IconEdit"
import IconDelete from "../../components/Icons/IconDelete"
import { useGlobalContext } from "../../context/GlobalContext"
import { useDispatch, useSelector } from "react-redux"
import { useMobile } from "../../hook/useMobile"
import { toast } from "react-toastify"
import { format } from "date-fns"
import { customerSelector } from "../../state/modules/customer/_selectors"
import { type ICustomerFilter, type ICustomer } from "../../interfaces/ICustomer"
import {
  addCustomerRequest,
  deleteCustomerRequest,
  getAssignedCommercialRequest,
  getClassificationRequest,
  getClientTypeRequest,
  getCountryRequest,
  getCustomerByFilterClear,
  getCustomerByIdRequest,
  getCustomerClear,
  getCustomerClearAll,
  getCustomerRequest,
  putCustomerRequest
} from "../../state/modules/customer/actions"
import IconExcel from "../../components/Icons/IconExcel"
import IconView from "../../components/Icons/IconView"
import ContainerLabelTwoRow from "../../components/ContainerLabelTwoRow"

const CustomerProspectPage = (): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useMobile()

  const dispatch = useDispatch()
  const customer = useSelector(customerSelector)
  const [openModal, setOpenModal] = React.useState(false)
  const [pagination, setPagination] = React.useState({ pagina: 1, registrosPorPagina: 10 })
  const [resetPage, setResetPage] = React.useState(false)
  const [resetFilter, setResetFilter] = React.useState<boolean>(false)
  const { loadingContext, setLoadingContext, themeContext } = useGlobalContext()
  const [userSelected, setUserSelected] = React.useState<any | null>(null)
  const [idSelected, setIdSelected] = React.useState<number | null>(null)
  const [action, setAction] = React.useState<string>("")
  const buttomsToEdit =
    1 + (getAuth(PERMISSION.CLIENTE_MODIFICAR)[0] ? 1 : 0) + (getAuth(PERMISSION.CLIENTE_ELIMINAR)[0] ? 1 : 0)
  const [filters, setFilters] = React.useState<any>(null)
  const [isView, setIsView] = React.useState(false)
  const [sortBy, setSortBy] = React.useState<any>({ columna: 1, direccion: "asc" })

  const handleOpenModal = (action = ""): void => {
    setAction(action)
    setOpenModal(!openModal)
    dispatch(getCustomerByFilterClear())
  }

  const handleAdd = (): void => {
    setUserSelected(null)
    handleOpenModal(ACTIONS.ADD.VALUE)
  }

  const getRequest = (): ICustomerFilter => {
    return {
      ordenamiento: sortBy,
      paginado: pagination,
      filtro: {
        cuit: filters?.cuit ?? null,
        razonSocial: filters?.companyName ?? null,
        idTipoCliente: filters?.clientType?.value ?? null,
        comercialAsignado: filters?.assignedCommercial?.value ?? null,
        esCargaCIF: filters?.cifCargo?.value ?? null,
        esImportador: filters?.isImporter?.value ?? null,
        esExportador: filters?.isExporter?.value ?? null,
        procedencias: filters?.origins?.map(item => item.value) ?? [],
        destinos: filters?.destinations?.map(item => item.value) ?? [],
        clientesSinContactos: filters?.clientesSinContactos ?? false,
        paginaOrigen: "clientesABM"
      }
    }
  }

  const handleExcel = (): void => {
    setAction(ACTIONS.EXPORT.VALUE)
    const request = getRequest()
    let filterRequest: string = ""
    if (!isStringEmpty(request?.filtro?.cuit ?? "")) {
      filterRequest = `${filterRequest}cuit=${request.filtro.cuit}&`
    }

    if (!isStringEmpty(request.filtro.razonSocial ?? "")) {
      filterRequest = `${filterRequest}razonSocial=${request.filtro.razonSocial}&`
    }

    if (!isStringEmpty(request.filtro.idTipoCliente ?? "")) {
      filterRequest = `${filterRequest}tipoCliente=${request.filtro.idTipoCliente}&`
    }

    if (!isStringEmpty(request.filtro.comercialAsignado ?? "")) {
      filterRequest = `${filterRequest}comercialAsignado=${request.filtro.comercialAsignado}&`
    }

    if (!isStringEmpty(request?.filtro?.esCargaCIF?.toString() ?? "")) {
      filterRequest = `${filterRequest}esCargaCIF=${request.filtro.esCargaCIF}&`
    }

    if (request?.filtro?.procedencias.length > 0) {
      filterRequest = `${filterRequest}procedencias=${request.filtro.procedencias}&`
    }

    if (request?.filtro?.destinos?.length > 0) {
      filterRequest = `${filterRequest}destinos=${request.filtro.destinos.join(",")}&`
    }

    if (!isStringEmpty(request?.filtro?.esExportador?.toString() ?? "")) {
      filterRequest = `${filterRequest}esExportador=${request.filtro.esExportador}&`
    }

    filterRequest = `${filterRequest}clientesSinContactos=${request.filtro.clientesSinContactos}&`

    window.open(`${process.env.REACT_APP_API_URL}/api/backoffice/v1/clientes/exportar?${filterRequest}`)
  }

  const getClientById = (id): void => {
    setIdSelected(id)
    dispatch(getCustomerByIdRequest(id))
  }

  const handleDispatch = (values: any): void => {
    switch (action) {
      case ACTIONS.DELETE.VALUE:
        dispatch(deleteCustomerRequest(values.idCliente))
        break
      case ACTIONS.ADD.VALUE:
        dispatch(addCustomerRequest(values))
        break
      case ACTIONS.EDIT.VALUE:
        dispatch(putCustomerRequest(values))
        break
      default:
        break
    }
  }

  const getData = (): void => {
    const request = getRequest()
    dispatch(getCustomerRequest(request))
  }

  const paginationComponentOptions = {
    rowsPerPageText: t(TRANSLATION_INDEX_GLOBAL.ROWS_PER_PAGE),
    rangeSeparatorText: t(TRANSLATION_INDEX_GLOBAL.OF),
    selectAllRowsItem: false,
    selectAllRowsItemText: t(TRANSLATION_INDEX_GLOBAL.ALL)
  }

  const handlePageChange = (page: number): void => {
    setPagination({ ...pagination, pagina: page })
  }

  const handleSort = (column, sortDirection): void => {
    setResetPage(true)
    setSortBy({ columna: column?.id ?? 1, direccion: sortDirection })
    setPagination({ ...pagination, pagina: 1 })
  }

  const handlePerRowsChange = (newPerPage: number, page: number): void => {
    setResetPage(true)
    setPagination({ registrosPorPagina: newPerPage, pagina: 1 })
  }

  const columns = [
    {
      id: "1",
      name: t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.COMPANY_NAME),
      selector: (row: ICustomer) => row.razonSocial.toUpperCase(),
      cell: (row: ICustomer) => {
        return <ContainerLabelTwoRow label={row.razonSocial.toUpperCase()} />
      },
      width: isMobile ? "180px" : `calc(16.5% - ${20 * buttomsToEdit}px)`,
      sortable: true
    },
    {
      id: "2",
      name: "CUIT",
      selector: (row: ICustomer) => row.cuit,
      width: isMobile ? "120px" : "10%",
      center: true,
      sortable: true
    },
    {
      id: "3",
      name: t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.TYPE),
      selector: (row: ICustomer) =>
        t(
          row.clienteTipo.idTipoCliente === 1
            ? TRANSLATION_INDEX_CUSTOMER_PROSPECT.CUSTOMER
            : TRANSLATION_INDEX_CUSTOMER_PROSPECT.PROSPECT
        ).toUpperCase(),
      width: isMobile ? "100px" : `calc(11% - ${10 * buttomsToEdit}px)`,
      sortable: true
    },
    {
      id: "4",
      name: t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.ASSGNED_COMMERCIAL),
      selector: (row: ICustomer) => row.comercialAsignado,
      width: isMobile ? "120px" : `calc(11% - ${10 * buttomsToEdit}px)`,
      sortable: true
    },
    {
      id: "5",
      name: t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.CIF_CARGO),
      selector: (row: ICustomer) => (row.esCargaCif === 1 ? t(TRANSLATION_INDEX_GLOBAL.YES) : "NO"),
      width: isMobile ? "80px" : `calc(8% - ${10 * buttomsToEdit}px)`,
      center: true,
      sortable: true
    },
    {
      id: "6",
      name: t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.IS_IMPORTER),
      selector: (row: ICustomer) => (row.esImportador === 1 ? t(TRANSLATION_INDEX_GLOBAL.YES) : "NO"),
      width: isMobile ? "80px" : `calc(9% - ${10 * buttomsToEdit}px)`,
      center: true,
      sortable: true
    },
    {
      id: "7",
      name: t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.IS_EXPORTER),
      selector: (row: ICustomer) => (row.esExportador === 1 ? t(TRANSLATION_INDEX_GLOBAL.YES) : "NO"),
      width: isMobile ? "80px" : `calc(9% - ${10 * buttomsToEdit}px)`,
      center: true,
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.USER_UPDATE),
      selector: (row: ICustomer) => row.usuarioActualizacion ?? "-",
      width: isMobile ? "120px" : `calc(14% - ${15 * buttomsToEdit}px)`,
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.LAST_uPDATE),
      selector: (row: ICustomer) => row?.fechaActualizacion?.toString() ?? "-",
      cell: (row: ICustomer) => {
        return (
          <div>{row?.fechaActualizacion != null ? format(new Date(row?.fechaActualizacion), FORMAT_DATE) : "-"}</div>
        )
      },
      sortable: true,
      width: isMobile ? "120px" : "10%",
      center: true
    },
    {
      name: t(TRANSLATION_INDEX_PROFILE.COLUMNS.ACTIONS),
      cell: (row: ICustomer) => {
        return (
          <ContainerIcons>
            <ButtonCustom
              label={t(TRANSLATION_INDEX_GLOBAL.VIEW) ?? ""}
              onClick={() => {
                setIsView(true)
                getClientById(row.idCliente)
              }}
              width="85px"
              background={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE[themeContext]}
              backgroundHover={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE_HOVER[themeContext]}
              icon={<IconView />}
            />
            {getAuth(PERMISSION.CLIENTE_MODIFICAR)[0] && (
              <ButtonCustom
                label={t(TRANSLATION_INDEX_GLOBAL.EDIT) ?? ""}
                marginLeft
                onClick={() => {
                  setIsView(false)
                  getClientById(row.idCliente)
                }}
                width="85px"
                background={GLOBAL_STYLES.BUTTON.DARK_BG_IMAGE[themeContext]}
                backgroundHover={GLOBAL_STYLES.BUTTON.DARK_BG_IMAGE_HOVER[themeContext]}
                icon={<IconEdit />}
              />
            )}

            {getAuth(PERMISSION.CLIENTE_ELIMINAR)[0] && (
              <ButtonCustom
                marginLeft
                label={t(TRANSLATION_INDEX_GLOBAL.DELETE) ?? ""}
                onClick={() => {
                  setIsView(false)
                  setUserSelected(row)
                  handleOpenModal(ACTIONS.DELETE.VALUE)
                }}
                width="85px"
                background={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE[themeContext]}
                backgroundHover={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE_HOVER[themeContext]}
                icon={<IconDelete />}
              />
            )}
          </ContainerIcons>
        )
      },
      center: true,
      // omit: !getAuth(PERMISSION.CLIENTE_MODIFICAR)[0] && !getAuth(PERMISSION.CLIENTE_ELIMINAR)[0],
      width: `${isMobile ? 100 * buttomsToEdit : 85 * buttomsToEdit}px`
    }
  ]

  React.useEffect(() => {
    if (filters) getData()
  }, [pagination, sortBy])

  React.useEffect(() => {
    if (filters) {
      setResetPage(true)
      setPagination({ ...pagination, pagina: 1 })
    }
  }, [filters])

  React.useEffect(() => {
    if (resetFilter) {
      setFilters(null)
      setResetFilter(false)
      dispatch(getCustomerClearAll())
    }
  }, [resetFilter])

  React.useEffect(() => {
    if (resetPage) setResetPage(false)
  }, [resetPage])

  React.useEffect(() => {
    if (customer.showToast) {
      if (customer.hasErrMessage) {
        toast.error(t(customer.errorCode))
      } else {
        toast.success(t(customer.toastMessage))
        if (action !== ACTIONS.EXPORT.VALUE) {
          handleOpenModal()
          getData()
        }
      }
    }
  }, [customer.showToast])

  React.useEffect(() => {
    if (customer.customerToUpdate) {
      const value = customer.customerToUpdate
      value.idCliente = idSelected
      setUserSelected(value)
      handleOpenModal(isView ? ACTIONS.VIEW.VALUE : ACTIONS.EDIT.VALUE)
    }
  }, [customer.customerToUpdate])

  React.useEffect(() => {
    setLoadingContext(customer.loading)
  }, [customer])

  React.useEffect(() => {
    dispatch(getCustomerClear())
    setLoadingContext(true)
    dispatch(getClientTypeRequest())
    dispatch(getCountryRequest())
    dispatch(getClassificationRequest())
    dispatch(getAssignedCommercialRequest())

    return () => {
      dispatch(getCustomerClearAll())
    }
  }, [])

  return (
    <ContainerPage
      title={`${t(TRANSLATION_INDEX_MENU.PROSPECTS)}`}
      actions={
        filters
          ? [
              {
                label: t(TRANSLATION_INDEX_GLOBAL.ADD),
                icon: <IconPlus size={13} />,
                permission: PERMISSION.CLIENTE_CREAR,
                click: handleAdd
              },
              {
                label: t(TRANSLATION_INDEX_GLOBAL.EXPORT),
                icon: <IconExcel size={13} />,
                permission: PERMISSION.CLIENTE_CONSULTAR,
                click: handleExcel
              }
            ]
          : [
              {
                label: t(TRANSLATION_INDEX_GLOBAL.ADD),
                icon: <IconPlus size={13} />,
                permission: PERMISSION.CLIENTE_CREAR,
                click: handleAdd
              }
            ]
      }
    >
      <>
        {openModal && (
          <AlertModal
            maxWidth={isMobile ? "95%" : action === ACTIONS.DELETE.VALUE ? "50%" : "80%"}
            closeModal={() => {
              handleOpenModal()
            }}
            activeCloseModal
            title={`${t(TRANSLATION_INDEX_MENU.PROSPECT)} | ${t(TRANSLATION_INDEX_GLOBAL[action.toUpperCase()])}`}
          >
            <FormaddUpdate
              data={userSelected}
              action={action}
              closeModal={handleOpenModal}
              loading={loadingContext}
              accept={handleDispatch}
            />
          </AlertModal>
        )}

        <ContainerTable themeContext={themeContext} opacity={1}>
          <Search
            data={customer.data?.clientes}
            setFilters={setFilters}
            resetFilter={resetFilter}
            setClearFilter={setResetFilter}
          />
          <DataTable
            data={customer.data?.clientes ?? []}
            theme="solarized"
            noDataComponent={
              <NoData
                label={
                  loadingContext
                    ? ""
                    : filters
                    ? TRANSLATION_INDEX_GLOBAL.NO_DATA_2
                    : TRANSLATION_INDEX_GLOBAL.USE_SEARCH_TO_GET_RESULTS
                }
              />
            }
            columns={columns}
            dense
            pagination
            paginationComponentOptions={paginationComponentOptions}
            paginationRowsPerPageOptions={[10, 25, 50, 100, 500]}
            striped
            customStyles={customStylesContext(themeContext)}
            paginationServer
            paginationTotalRows={customer?.data?.totalRegistros}
            paginationDefaultPage={1}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            onSort={handleSort}
            paginationResetDefaultPage={resetPage}
          />
        </ContainerTable>
      </>
    </ContainerPage>
  )
}

export default CustomerProspectPage
