import { login } from "./_duck"
import * as types from "./types"

export const postLoginRequest = login.createAction(types.LOGIN_POST_REQUEST)
export const postLoginSuccess = login.createAction(types.LOGIN_POST_SUCCESS)
export const postLoginFailure = login.createAction(types.LOGIN_POST_FAILURE)

export const postLoginForgotRequest = login.createAction(types.LOGIN_FORGOT_POST_REQUEST)
export const postLoginForgotSuccess = login.createAction(types.LOGIN_FORGOT_POST_SUCCESS)
export const postLoginForgotFailure = login.createAction(types.LOGIN_FORGOT_POST_FAILURE)

export const postLoginChangeRequest = login.createAction(types.LOGIN_CHANGE_POST_REQUEST)
export const postLoginChangeSuccess = login.createAction(types.LOGIN_CHANGE_POST_SUCCESS)
export const postLoginChangeFailure = login.createAction(types.LOGIN_CHANGE_POST_FAILURE)

export const getTokenStorage = login.createAction(types.LOGIN_GET_TOKEN_STORAGE)
export const logout = login.createAction(types.LOGOUT)
export const cleanError = login.createAction(types.CLEAN_ERROR)
