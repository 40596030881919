import styled from "styled-components"
import { type IStyle } from "../../interfaces/IStyle"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"

export const Container = styled.div<IStyle>`
  width: ${({ width }) => width};
  margin: 5px 0 0 0;
  font-family: ${GLOBAL_STYLES.FONT.FAMILY};
  margin-left: ${({ marginLeft }) => (marginLeft ?? false ? "10px" : "")};
  @media (max-width: 900px) {
    margin: 10px 0;
  }
`

export const Label = styled.label<IStyle>`
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
  font-weight: 500;
  width: 100%;
  display: flex;
  font-size: 0.8rem !important;
  margin-bottom: 10px;
  @media (max-width: 1080px) {
    font-size: 0.7rem !important;
  }
`

export const Input = styled.input`
  width: calc(100% - 15px);
  height: 25px;
  font-size: 0.8rem !important;
  margin: 0;
  border: 2px solid #c0c0c0;
  padding: 5px;
  border-radius: 5px;
  :hover {
    border: 2px solid #cccccc;
  }
  :focus {
    border: 2px solid #72bc69;
    outline: none;
  }
`

export const ContainerIcon = styled.div<IStyle>`
  position: relative;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ background }) => background};

  border-left: ${({ iconLeft }) => (iconLeft === true ? " 1px solid #c0c0c0" : "")};
  border-right: ${({ iconRight }) => (iconRight === true ? " 1px solid #c0c0c0" : "")};

  border-top: 1px solid #c0c0c0;
  border-bottom: 1px solid #c0c0c0;

  border-radius: ${({ iconRight }) => (iconRight === true ? "0px 5px 5px 0px" : "5px 0px 0px 5px")};

  height: 40px;
  cursor: pointer;
`

export const ContainerInput = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
