import { quoter } from "./_duck"
import * as types from "./types"

export const getCommoditiesRequest = quoter.createAction(types.COMMODITIES_GET_REQUEST)
export const getCommoditiesSuccess = quoter.createAction(types.COMMODITIES_GET_SUCCESS)
export const getCommoditiesFailure = quoter.createAction(types.COMMODITIES_GET_FAILURE)

export const postQuoterRequest = quoter.createAction(types.QUOTER_POST_REQUEST)
export const postQuoterSuccess = quoter.createAction(types.QUOTER_POST_SUCCESS)
export const postQuoterFailure = quoter.createAction(types.QUOTER_POST_FAILURE)

export const postExportRequest = quoter.createAction(types.EXPORT_POST_REQUEST)
export const postExportSuccess = quoter.createAction(types.EXPORT_POST_SUCCESS)
export const postExportFailure = quoter.createAction(types.EXPORT_POST_FAILURE)

export const getProccessInfoRequest = quoter.createAction(types.PROCCESS_INFO_GET_REQUEST)
export const getProccessInfoSuccess = quoter.createAction(types.PROCCESS_INFO_GET_SUCCESS)
export const getProccessInfoFailure = quoter.createAction(types.PROCCESS_INFO_GET_FAILURE)

export const getInProccessRequest = quoter.createAction(types.IN_PROCCESS_GET_REQUEST)
export const getInProccessSuccess = quoter.createAction(types.IN_PROCCESS_GET_SUCCESS)
export const getInProccessFailure = quoter.createAction(types.IN_PROCCESS_GET_FAILURE)

export const clearDataQuoter = quoter.createAction(types.CLEAR)
