import styled from "styled-components"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { type IStyle } from "../../interfaces/IStyle"

export const ContainerIcons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`

export const ContainerTable = styled.div<IStyle>`
  width: 100%;
  opacity: ${({ opacity }) => opacity};
  display: flex;
  justify-content: center;
  flex-direction: column;
  transition: opacity 3s;
  margin-top: -2px;
  margin-left: 2px;
  z-index: 10;
`

export const ContainerTree = styled.div`
  position: relative;
  background: #2b2b2b;
  padding: 10px;
`

export const List = styled.ul<IStyle>`
  position: relative;
  font-size: 0.85rem;
  font-weight: 700;
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
  list-style: none;
  padding: 0 0 0 10px;
  margin-left: 25px;
`

export const ItemList = styled.li`
  position: relative;
  margin-left: -10px;
  margin-top: 10px;
  padding: 0px 0;
  cursor: pointer;
`

export const ContainerIconLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`

export const Label = styled.div`
  margin-left: 10px;
`

export const ContainerLoaderTable = styled.div`
  height: 250px;
  background-color: blue;
`

export const Icon = styled.div<IStyle>`
  font-size: 0.8rem;
  background: ${({ themeContext }) => GLOBAL_STYLES.BACKGROUND.TYPE_2[themeContext]};
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ themeContext }) => (themeContext === "DARK" ? "#fff" : "#fff")};
  border-radius: 3px;
  margin: 0 8px;
  cursor: pointer;
  :hover {
    box-shadow: ${({ themeContext }) => GLOBAL_STYLES.BOX_SHADOW.TYPE_3[themeContext]};
  }
`

export const LabelAction = styled.div`
  margin-left: 5px;
`

export const ContainerTab = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const Tab = styled.div<IStyle>`
  background-image: ${({ background }) => background};
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_2[themeContext]};
  border: ${({ themeContext }) => GLOBAL_STYLES.BORDER.TYPE_1[themeContext]};
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  z-index: 12;
  cursor: pointer;
`

export const ContainerButtom = styled.div<IStyle>`
  width: 100%;
  border-top: ${({ themeContext }) => GLOBAL_STYLES.BORDER.TYPE_3[themeContext]};
  display: flex;
  padding: 15px 0 0px 0;
  justify-content: center;
  align-items: center;
`
