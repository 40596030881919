/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import styled from "styled-components"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { type IStyle } from "../../interfaces/IStyle"

export const Container = styled.div<IStyle>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: calc(100vh - 50px);
  padding-top: 50px;
  position: relative;
  max-width: ${window.screen.width};
  overflow: ${({ activeModal }) => (activeModal ? "hidden" : "auto")};
`

export const Content = styled.div<IStyle>`
  width: 100%;
  background-color: ${({ themeContext }) => GLOBAL_STYLES.BACKGROUND.TYPE_3[themeContext]};
`
