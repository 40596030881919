import React from "react"
import { Container, ContainerText } from "./_styles"
import { TRANSLATION_INDEX_GLOBAL } from "../../config/translation"
import { useTranslation } from "react-i18next"
import { type IProps } from "./_types"
import { useGlobalContext } from "../../context/GlobalContext"

export const NoData: React.FC<IProps> = ({ label = TRANSLATION_INDEX_GLOBAL.NO_DATA }): React.ReactElement => {
  const { t } = useTranslation()
  const { themeContext } = useGlobalContext()
  return (
    <Container themeContext={themeContext}>
      <ContainerText themeContext={themeContext}>{t(label)}</ContainerText>
    </Container>
  )
}
