import { login } from "./_duck"

export const LOGIN_POST_REQUEST: string = login.defineType("LOGIN_POST_REQUEST")
export const LOGIN_POST_SUCCESS: string = login.defineType("LOGIN_POST_SUCCESS")
export const LOGIN_POST_FAILURE: string = login.defineType("LOGIN_POST_FAILURE")

export const LOGIN_FORGOT_POST_REQUEST: string = login.defineType("LOGIN_FORGOT_POST_REQUEST")
export const LOGIN_FORGOT_POST_SUCCESS: string = login.defineType("LOGIN_FORGOT_POST_SUCCESS")
export const LOGIN_FORGOT_POST_FAILURE: string = login.defineType("LOGIN_FORGOT_POST_FAILURE")

export const LOGIN_CHANGE_POST_REQUEST: string = login.defineType("LOGIN_CHANGE_POST_REQUEST")
export const LOGIN_CHANGE_POST_SUCCESS: string = login.defineType("LOGIN_CHANGE_POST_SUCCESS")
export const LOGIN_CHANGE_POST_FAILURE: string = login.defineType("LOGIN_CHANGE_POST_FAILURE")

export const LOGIN_GET_TOKEN_STORAGE: string = login.defineType("LOGIN_GET_TOKEN_STORAGE")

export const LOGOUT: string = login.defineType("LOGOUT")

export const CLEAN_ERROR: string = login.defineType("CLEAN_ERROR")
