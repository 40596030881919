import React, { type ReactElement } from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import { ROUTES } from "../../utils/constants"
import { getAuth, isStringEmpty } from "../../utils/functions"
import { STORAGE } from "../../utils/localStorage"
import { useDispatch } from "react-redux"
import { logout } from "../../state/modules/login/actions"

const PrivateRoute = (): ReactElement => {
  const location = useLocation()
  const dispatch = useDispatch()
  const pathName = location.pathname.slice(1).toLocaleUpperCase()
  const [isAuth, , , errorToken] = getAuth(ROUTES[pathName]?.permision)

  const logoutHandle = (): React.ReactElement => {
    dispatch(logout())
    STORAGE.remove(STORAGE.TOKEN)
    STORAGE.remove(STORAGE.NOTIFICATION)
    STORAGE.remove(STORAGE.CHANGE_PASSWORD)
    return <Navigate to="/login" />
  }

  return isStringEmpty(STORAGE.get(STORAGE.CHANGE_PASSWORD).toString()) ||
    isStringEmpty(STORAGE.get(STORAGE.TOKEN)) ||
    errorToken ? (
    logoutHandle()
  ) : isAuth && STORAGE.get(STORAGE.CHANGE_PASSWORD) !== true && pathName !== "" ? (
    <Outlet />
  ) : isStringEmpty(STORAGE.get(STORAGE.TOKEN)) || STORAGE.get(STORAGE.CHANGE_PASSWORD) === true ? (
    <Navigate to="/login" />
  ) : (
    <Navigate to="/home" />
  )
}

export default PrivateRoute
