import styled from "styled-components"
import { type IStyle } from "../../../../interfaces/IStyle"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"

export const Container = styled.div<IStyle>`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 15px;
  line-height: 1rem;
  cursor: pointer;
  font-weight: 400;
  margin-right: 5px;
`
export const ContainerMenu = styled.div<IStyle>`
  position: absolute;
  width: 140px;
  box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 1);
  padding-top: 2px;
  top: 50px;
  border-radius: 5px;
  left: 0;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background-color: ${({ themeContext }) => GLOBAL_STYLES.BACKGROUND.TYPE_7[themeContext]};
`

export const Icon = styled.span`
  font-size: 25px;
  margin-left: 5px;
  margin-top: 5px;
`

export const UserMenu = styled.span<IStyle>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 120px;
  height: 50px;
  padding: 0 10px;
  background-color: ${({ background }) => background};
`
