import React, { type ReactElement } from "react"
import { useTranslation } from "react-i18next"
import { IoMdArrowDropdown } from "react-icons/io"
import { useNavigate } from "react-router-dom"
import { getAuth, isStringEmpty } from "../../utils/functions"
import SubMenuItem from "../SubMenuItem"
import { Container, ContainerSubMenu } from "./_styles"
import { type Props } from "./_types"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { useGlobalContext } from "../../context/GlobalContext"

const MenuItem: React.FC<Props> = ({ label, route, submenu, permission }): ReactElement => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [hoverActive, setHoverActive] = React.useState<boolean>(false)
  const { themeContext } = useGlobalContext()
  const routeHandle = (route: any): void => {
    if (!isStringEmpty(route)) navigate(route)
  }

  if (!permission.some(item => getAuth()[2].includes(item))) return <></>
  return (
    <Container
      themeContext={themeContext}
      data-testid="menu-item"
      onMouseLeave={() => {
        setHoverActive(false)
      }}
      onClick={() => {
        routeHandle(route)
        setHoverActive(!hoverActive)
      }}
      background={
        hoverActive ? GLOBAL_STYLES.BACKGROUND.TYPE_8[themeContext] : GLOBAL_STYLES.BACKGROUND.TYPE_3[themeContext]
      }
    >
      {t(label)}
      {submenu?.length > 0 && <IoMdArrowDropdown fontSize={20} style={{ marginLeft: "2px", marginTop: "3px" }} />}
      {hoverActive && submenu?.length > 0 && (
        <ContainerSubMenu themeContext={themeContext}>
          {submenu.map(item => {
            return (
              <SubMenuItem
                key={item.label}
                label={t(item.label)}
                route={item.route}
                permission={item.permission}
                submenu={item.submenu}
                setHoverActiveMain={setHoverActive}
              />
            )
          })}
        </ContainerSubMenu>
      )}
    </Container>
  )
}

export default MenuItem
