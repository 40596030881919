/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { userBackoffice } from "./_duck"
import * as types from "./types"

// Types
import { type PayloadAction } from "../../types"
import { type ModuleType } from "./_definitions"
import { TRANSLATION_INDEX_GLOBAL, TRANSLATION_INDEX_RESPONSE_CODE } from "../../../config/translation"
import { STORAGE } from "../../../utils/localStorage"

const initialModuleState: ModuleType = {
  data: [],
  imageCurrentUser: "",
  imageURLCurrentUser: "",
  loading: false,
  showToast: false,
  toastMessage: "",
  hasErrMessage: false,
  errorCode: ""
}

const reducer = userBackoffice.createReducer(
  {
    [types.USER_BACKOFFICE_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: "-"
    }),
    [types.USER_BACKOFFICE_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        data: payload?.usuariosBackoffice ?? [],
        loading: false
      }
    },
    [types.USER_BACKOFFICE_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      data: [],
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),

    [types.USER_BACKOFFICE_NOTIFICATION_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: "-"
    }),
    [types.USER_BACKOFFICE_NOTIFICATION_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      const noti = STORAGE.get(STORAGE.NOTIFICATION) ?? null
      if (noti !== null) STORAGE.set(STORAGE.NOTIFICATION, noti === 1 ? 0 : 1)
      return {
        ...state,
        loading: false
      }
    },
    [types.USER_BACKOFFICE_NOTIFICATION_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        showToast: true,
        toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
        loading: false,
        hasErrMessage: true,
        errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
      }
    },

    [types.USER_BACKOFFICE_IMAGE_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      imageCurrentUser: "-",
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: "-"
    }),
    [types.USER_BACKOFFICE_IMAGE_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        imageCurrentUser: payload.contenidoImagenFirma,
        loading: false
      }
    },
    [types.USER_BACKOFFICE_IMAGE_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      imageCurrentUser: "falla",
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.USER_BACKOFFICE_IMAGE_URL_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      imageURLCurrentUser: "-",
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: "-"
    }),
    [types.USER_BACKOFFICE_IMAGE_URL_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        imageURLCurrentUser: payload.contenidoImagenFirma,
        loading: false
      }
    },
    [types.USER_BACKOFFICE_IMAGE_URL_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      imageURLCurrentUser: "-",
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),

    [types.USER_BACKOFFICE_ADD_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.USER_BACKOFFICE_ADD_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        showToast: true,
        toastMessage: TRANSLATION_INDEX_GLOBAL.SUCCESS_UPDATE,
        loading: false
      }
    },
    [types.USER_BACKOFFICE_ADD_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        toastMessage: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"],
        loading: false,
        hasErrMessage: true,
        showToast: true,
        errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
      }
    },
    [types.USER_BACKOFFICE_POST_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.USER_BACKOFFICE_POST_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        loading: false,
        showToast: true,
        toastMessage: TRANSLATION_INDEX_GLOBAL.SUCCESS_UPDATE
      }
    },
    [types.USER_BACKOFFICE_POST_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      loading: false,
      showToast: true,
      hasErrMessage: true,
      toastMessage: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"],
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.USER_BACKOFFICE_DELETE_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.USER_BACKOFFICE_DELETE_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        loading: false,
        showToast: true,
        toastMessage: TRANSLATION_INDEX_GLOBAL.SUCCESS_DELETE
      }
    },
    [types.USER_BACKOFFICE_DELETE_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      loading: false,
      showToast: true,
      toastMessage: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"],
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    })
  },
  initialModuleState
)

export default reducer
