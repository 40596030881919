import styled from "styled-components"
import { type IStyle } from "../../interfaces/IStyle"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"

export const Container = styled.div<IStyle>`
  width: ${({ width }) => width};
  margin: 5px 0 0px 0;
  margin-left: ${({ marginLeft }) => (marginLeft ?? false ? "10px" : "0px")};
`

export const Label = styled.label<IStyle>`
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
  font-weight: 500;
  width: 100%;
  display: flex;
  margin-left: 7px;
  font-size: 0.8rem !important;
  @media (max-width: 1080px) {
    font-size: 0.7rem !important;
  }
`

export const Input = styled.input<IStyle>`
  width: 20px;
  height: 20px;
  margin: 0;
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};

  @media (max-width: 1080px) {
  }
`

export const ContainerInput = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
`

export const ContainerError = styled.div`
  font-weight: 600;
  font-size: 15px;
  margin-top: 0px;
  color: red;
`
