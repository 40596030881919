import React from "react"

export const useResize = (): number => {
  const [size, setSize] = React.useState(window.innerWidth)
  const handleResize = (): void => {
    setSize(window.innerWidth)
  }
  React.useLayoutEffect(() => {
    setSize(window.innerWidth)
    window.addEventListener("resize", handleResize)
  }, [])

  return size
}
