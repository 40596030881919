import * as Yup from "yup"
import { TRANSLATION_INDEX_GLOBAL } from "../../config/translation"

export const deleteScheme = Yup.object().shape({})

export const addUpdateScheme = Yup.object().shape({
  apellido: Yup.string().required(TRANSLATION_INDEX_GLOBAL.REQUIRED),
  nombre: Yup.string().required(TRANSLATION_INDEX_GLOBAL.REQUIRED),
  dni: Yup.string().required(TRANSLATION_INDEX_GLOBAL.REQUIRED),
  email: Yup.string()
    .required(TRANSLATION_INDEX_GLOBAL.REQUIRED)
    .email(TRANSLATION_INDEX_GLOBAL.EMAIL_FORMAT_INCORRECT),
  numeroCelular: Yup.string().required(TRANSLATION_INDEX_GLOBAL.REQUIRED),
  puesto: Yup.string().required(TRANSLATION_INDEX_GLOBAL.REQUIRED),
  organizacionesFormik: Yup.array().min(1, TRANSLATION_INDEX_GLOBAL.REQUIRED)
})
