import { authorization } from "./_duck"

export const AUTHORIZATION_GET_REQUEST: string = authorization.defineType("AUTHORIZATION_GET_REQUEST")
export const AUTHORIZATION_GET_SUCCESS: string = authorization.defineType("AUTHORIZATION_GET_SUCCESS")
export const AUTHORIZATION_GET_FAILURE: string = authorization.defineType("AUTHORIZATION_GET_FAILURE")

export const AUTHORIZATION_PATCH_REQUEST: string = authorization.defineType("AUTHORIZATION_PATCH_REQUEST")
export const AUTHORIZATION_PATCH_SUCCESS: string = authorization.defineType("AUTHORIZATION_PATCH_SUCCESS")
export const AUTHORIZATION_PATCH_FAILURE: string = authorization.defineType("AUTHORIZATION_PATCH_FAILURE")

export const AUTHORIZATION_POST_REQUEST: string = authorization.defineType("AUTHORIZATION_POST_REQUEST")
export const AUTHORIZATION_POST_SUCCESS: string = authorization.defineType("AUTHORIZATION_POST_SUCCESS")
export const AUTHORIZATION_POST_FAILURE: string = authorization.defineType("AUTHORIZATION_POST_FAILURE")

export const AUTHORIZATION_DELETE_REQUEST: string = authorization.defineType("AUTHORIZATION_DELETE_REQUEST")
export const AUTHORIZATION_DELETE_SUCCESS: string = authorization.defineType("AUTHORIZATION_DELETE_SUCCESS")
export const AUTHORIZATION_DELETE_FAILURE: string = authorization.defineType("AUTHORIZATION_DELETE_FAILURE")
