/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { type ReactElement } from "react"
import { Container, ContainerMenu, ContainerMenuMobile, Image, Menu } from "./_styles"
import { useNavigate } from "react-router-dom"
import { ROUTES } from "../../utils/constants"
import Cargo_horizontal from "../../assets/Cargo-horizontal.png"
import Cargo_horizontal_white from "../../assets/Cargo-horizontal-white.png"
import MenuItem from "../../components/MenuItem"
import { menu } from "./data"
import UserContainer from "./components/UserContainer"
import { getAuth } from "../../utils/functions"
import { STORAGE } from "../../utils/localStorage"
import { AiOutlineMenu } from "react-icons/ai"
import MenuMobile from "../../components/MenuMobile"
import { useGlobalContext } from "../../context/GlobalContext"
import { useMobile } from "../../hook/useMobile"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
const HeaderLayout = (): ReactElement => {
  const navigate = useNavigate()
  const [isAuth] = getAuth("*")
  const isMobile = useMobile()
  const { openMenuMobile, setOpenMenuMobile } = useGlobalContext()
  const { themeContext } = useGlobalContext()
  const handleCloseModal = (): void => {
    setOpenMenuMobile(false)
  }

  return (
    <React.Fragment>
      {isAuth && STORAGE.get(STORAGE.CHANGE_PASSWORD) !== true ? (
        <Container themeContext={themeContext}>
          <ContainerMenu>
            <Image
              src={themeContext === "DARK" ? Cargo_horizontal_white : Cargo_horizontal}
              alt="Logo CARGO SA"
              onClick={() => {
                navigate(ROUTES.HOME.pathName)
              }}
            />
            {!isMobile && (
              <Menu>
                {menu.map(item => {
                  return (
                    <MenuItem
                      key={item.label}
                      label={item.label}
                      route={item.route}
                      submenu={item.subMenu}
                      permission={item.permission}
                    />
                  )
                })}
              </Menu>
            )}
          </ContainerMenu>
          {isMobile ? (
            <ContainerMenuMobile>
              <AiOutlineMenu
                size={22}
                onClick={() => {
                  setOpenMenuMobile(!openMenuMobile)
                }}
              />
              {openMenuMobile && <MenuMobile closeMenu={handleCloseModal} />}
            </ContainerMenuMobile>
          ) : (
            <UserContainer />
          )}
        </Container>
      ) : (
        <div style={{ background: GLOBAL_STYLES.BACKGROUND.TYPE_1[themeContext], height: "100%" }}></div>
      )}
    </React.Fragment>
  )
}

export default HeaderLayout
