/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { type IUserAppClient } from "../../interfaces/IUserAppClient"

export const filterData = (data, filters): any => {
  let newList: IUserAppClient[] = []
  if (
    filters?.search != null &&
    filters?.search.length > 0 &&
    filters?.estado != null &&
    filters?.idRol != null &&
    filters?.clienteAdmin != null
  ) {
    newList = data?.filter(
      item =>
        filters?.search &&
        filters?.search.length > 0 &&
        (item.email.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.nombre.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.puesto.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.apellido.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.numeroCelular.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.organizaciones
            .map((org: { descripcion: string }) => org.descripcion.toLowerCase())
            .some((org: string | string[]) => org.includes(filters?.search?.toLowerCase()))) &&
        item.estado.idEstado === filters?.estado?.value &&
        filters?.clienteAdmin &&
        item.clienteAdmin === filters?.clienteAdmin?.value &&
        filters?.idRol &&
        item.idRol === filters?.idRol?.value
    )
    return newList
  } else if (
    filters?.search != null &&
    filters?.search.length > 0 &&
    filters?.estado != null &&
    filters?.clienteAdmin != null
  ) {
    newList = data?.filter(
      item =>
        filters?.search &&
        filters?.search.length > 0 &&
        (item.email.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.nombre.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.puesto.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.apellido.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.numeroCelular.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.organizaciones
            .map((org: { descripcion: string }) => org.descripcion.toLowerCase())
            .some((org: string | string[]) => org.includes(filters?.search?.toLowerCase()))) &&
        item.estado.idEstado === filters?.estado?.value &&
        filters?.clienteAdmin &&
        item.clienteAdmin === filters?.clienteAdmin?.value
    )
    return newList
  } else if (filters?.search && filters?.search.length > 0 && filters?.estado) {
    newList = data?.filter(
      item =>
        filters?.search &&
        filters?.search.length > 0 &&
        (item.email.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.nombre.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.puesto.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.apellido.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.numeroCelular.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.organizaciones
            .map((org: { descripcion: string }) => org.descripcion.toLowerCase())
            .some((org: string | string[]) => org.includes(filters?.search?.toLowerCase()))) &&
        item.estado.idEstado === filters?.estado?.value
    )
    return newList
  } else if (filters?.search && filters?.search.length > 0 && filters?.estado && filters?.idRol) {
    newList = data?.filter(
      item =>
        filters?.search &&
        filters?.search.length > 0 &&
        (item.email.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.nombre.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.puesto.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.apellido.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.numeroCelular.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.organizaciones
            .map((org: { descripcion: string }) => org.descripcion.toLowerCase())
            .some((org: string | string[]) => org.includes(filters?.search?.toLowerCase()))) &&
        item.estado.idEstado === filters?.estado?.value &&
        item.idRol === filters?.idRol?.value
    )
    return newList
  } else if (filters?.search && filters?.search.length > 0 && filters?.clienteAdmin && filters?.idRol) {
    newList = data?.filter(
      item =>
        filters?.search &&
        filters?.search.length > 0 &&
        (item.email.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.nombre.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.puesto.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.apellido.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.numeroCelular.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.organizaciones
            .map((org: { descripcion: string }) => org.descripcion.toLowerCase())
            .some((org: string | string[]) => org.includes(filters?.search?.toLowerCase()))) &&
        filters?.clienteAdmin &&
        item.clienteAdmin === filters?.clienteAdmin?.value &&
        filters?.idRol &&
        item.idRol === filters?.idRol?.value
    )
    return newList
  } else if (filters?.search && filters?.search.length > 0 && filters?.clienteAdmin) {
    newList = data?.filter(
      item =>
        filters?.search &&
        filters?.search.length > 0 &&
        (item.email.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.nombre.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.puesto.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.apellido.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.numeroCelular.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.organizaciones
            .map((org: { descripcion: string }) => org.descripcion.toLowerCase())
            .some((org: string | string[]) => org.includes(filters?.search?.toLowerCase()))) &&
        filters?.clienteAdmin &&
        item.clienteAdmin === filters?.clienteAdmin?.value
    )
    return newList
  } else if (filters?.estado && filters?.clienteAdmin && filters?.idRol) {
    newList = data?.filter(
      item =>
        item.estado.idEstado === filters?.estado?.value &&
        filters?.clienteAdmin &&
        item.clienteAdmin === filters?.clienteAdmin?.value &&
        filters?.idRol &&
        item.idRol === filters?.idRol?.value
    )
    return newList
  } else if (filters?.estado && filters?.clienteAdmin) {
    newList = data?.filter(
      item =>
        item.estado.idEstado === filters?.estado?.value &&
        filters?.clienteAdmin &&
        item.clienteAdmin === filters?.clienteAdmin?.value
    )
    return newList
  } else if (filters?.search && filters?.search.length > 0 && filters?.idRol) {
    newList = data?.filter(
      item =>
        filters?.search &&
        filters?.search.length > 0 &&
        (item.email.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.nombre.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.puesto.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.apellido.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.numeroCelular.toLowerCase().includes(filters.search.toLowerCase()) ||
          item.organizaciones
            .map((org: { descripcion: string }) => org.descripcion.toLowerCase())
            .some((org: string | string[]) => org.includes(filters?.search?.toLowerCase()))) &&
        filters?.idRol &&
        item.idRol === filters?.idRol?.value
    )
    return newList
  } else if (filters?.search && filters?.search.length > 0) {
    newList = data?.filter(
      item =>
        item.email.toLowerCase().includes(filters.search.toLowerCase()) ||
        item.nombre.toLowerCase().includes(filters.search.toLowerCase()) ||
        item.puesto.toLowerCase().includes(filters.search.toLowerCase()) ||
        item.apellido.toLowerCase().includes(filters.search.toLowerCase()) ||
        item.numeroCelular.toLowerCase().includes(filters.search.toLowerCase()) ||
        item.organizaciones
          .map((org: { descripcion: string }) => org.descripcion.toLowerCase())
          .some((org: string | string[]) => org.includes(filters?.search?.toLowerCase()))
    )
    return newList
  } else if (filters?.clienteAdmin && filters?.idRol) {
    newList = data?.filter(
      item =>
        filters?.clienteAdmin &&
        item.clienteAdmin === filters?.clienteAdmin?.value &&
        filters?.idRol &&
        item.idRol === filters?.idRol?.value
    )
    return newList
  } else if (filters?.estado && filters?.idRol) {
    newList = data?.filter(
      item =>
        filters?.estado &&
        item.estado.idEstado === filters?.estado?.value &&
        filters?.idRol &&
        item.idRol === filters?.idRol?.value
    )
    return newList
  } else if (filters?.idRol) {
    newList = data?.filter(item => filters?.idRol && item.idRol === filters?.idRol?.value)
    return newList
  } else if (filters?.clienteAdmin) {
    newList = data?.filter(item => filters?.clienteAdmin && item.clienteAdmin === filters?.clienteAdmin?.value)
    return newList
  } else if (filters?.estado) {
    newList = data?.filter(item => filters?.estado && item.estado.idEstado === filters?.estado?.value)
    return newList
  } else {
    return data
  }
}
