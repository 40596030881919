import React, { type ReactElement } from "react"
import "./style.css"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { useGlobalContext } from "../../context/GlobalContext"

const LoaderDots = (): ReactElement => {
  const { themeContext } = useGlobalContext()
  return (
    <div className="wrapper">
      <div className="circle" style={{ background: GLOBAL_STYLES.LABEL.TYPE_1[themeContext] }}></div>
      <div className="circle" style={{ background: GLOBAL_STYLES.LABEL.TYPE_1[themeContext] }}></div>
      <div className="circle" style={{ background: GLOBAL_STYLES.LABEL.TYPE_1[themeContext] }}></div>
    </div>
  )
}

export default LoaderDots
