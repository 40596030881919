/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { type ReactElement } from "react"
import { INPUT_TYPE } from "../../utils/constants"
import { Container, Input, Label, ContainerIcon, ContainerInput, TextArea } from "./_styles"
import { type Props } from "./_types"
import { isStringEmpty } from "../../utils/functions"
import IconSearch from "../Icons/IconSearch"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { useTranslation } from "react-i18next"
import { FormHelperText } from "@mui/material"
import { useGlobalContext } from "../../context/GlobalContext"
const InputCustomSet = ({
  name,
  label = "",
  value = "",
  setValue,
  handleChange,
  handleDelete,
  type = INPUT_TYPE.TEXT,
  placeHolder = "",
  width = "100%",
  marginLeft = false,
  disabled = false,
  iconLeft = false,
  iconRight = false,
  errors = "",
  autoFocus = false,
  touched,
  validError = true,
  isTextArea = false,
  style,
  borderRadius = "5px",
  handleKeyPressEnter
}: Props): ReactElement => {
  const { themeContext } = useGlobalContext()
  const { t } = useTranslation()
  const inputRef = React.useRef(null)
  const handleKey = (name, e): void => {
    if (e.keyCode === 13) e.preventDefault()
    handleKeyPressEnter != null ? handleKeyPressEnter(name, e) : {}
  }
  return (
    <Container themeContext={themeContext} width={width} marginLeft={marginLeft} data-testid="containerInput">
      {!isStringEmpty(label) && <Label themeContext={themeContext}>{label}</Label>}
      <ContainerInput>
        {iconLeft && (
          <ContainerIcon
            themeContext={themeContext}
            iconLeft={iconLeft}
            background={iconLeft && GLOBAL_STYLES.BACKGROUND.TYPE_6[themeContext]}
          >
            <IconSearch color="#495057" />
          </ContainerIcon>
        )}
        {isTextArea ? (
          <TextArea themeContext={themeContext} value={value ?? ""} disabled={disabled} />
        ) : (
          <>
            <Input
              themeContext={themeContext}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={autoFocus}
              ref={inputRef}
              borderRadius={borderRadius}
              style={style}
              iconRight={iconRight}
              iconLeft={iconLeft}
              autoComplete="off"
              name={name}
              disabled={disabled}
              value={value ?? ""}
              onKeyDown={e => {
                handleKey(name, e)
              }}
              onChange={e => {
                if (setValue != null) setValue(e?.target?.value ?? 0)
              }}
              type={type}
              placeholder={placeHolder}
              data-testid="input"
              border={
                !isStringEmpty(errors)
                  ? GLOBAL_STYLES.BORDER.TYPE_4[themeContext]
                  : GLOBAL_STYLES.BORDER.TYPE_3[themeContext]
              }
            />
          </>
        )}
      </ContainerInput>
      {validError && (
        <FormHelperText style={{ color: GLOBAL_STYLES.LABEL.TYPE_4[themeContext] }}>{t(errors)}</FormHelperText>
      )}
    </Container>
  )
}

export default InputCustomSet
