import { type AxiosPromise } from "axios"
import httpModule from "../../../../utils/http"

export const getQuoteTemplate = async (): Promise<AxiosPromise<any>> =>
  await httpModule.get(`/api/backoffice/v1/cotizaciones`)

export const getQuoteSent = async (): Promise<AxiosPromise<any>> =>
  await httpModule.get(`/api/backoffice/v1/cotizaciones/enviadas`)

export const getQuoteSentCustomer = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.get(`/api/backoffice/v1/cotizaciones/enviadas/clientes/${request}`)

export const getQuoteSentById = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.get(`/api/backoffice/v1/cotizaciones/enviadas/${request}`)

export const addQuoteTemplate = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/cotizaciones`, request)

export const sendQuote = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/cotizaciones/enviar`, request)

export const createPDFQuote = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/cotizaciones/generarPDF`, request)

export const previewPDFQuote = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/cotizaciones/previsualizarPDF`, request)

export const putQuoteTemplate = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.put(`/api/backoffice/v1/cotizaciones`, request)

export const deleteQuoteTemplate = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.delete(`/api/backoffice/v1/cotizaciones/${request}`)

export const getQuoteTemplateCustomerRelative = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/cotizaciones/clientesrelacionados`, request)
