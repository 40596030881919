import { userBackoffice } from "./_duck"

export const USER_BACKOFFICE_GET_REQUEST: string = userBackoffice.defineType("USER_BACKOFFICE_GET_REQUEST")
export const USER_BACKOFFICE_GET_SUCCESS: string = userBackoffice.defineType("USER_BACKOFFICE_GET_SUCCESS")
export const USER_BACKOFFICE_GET_FAILURE: string = userBackoffice.defineType("USER_BACKOFFICE_GET_FAILURE")

export const USER_BACKOFFICE_IMAGE_GET_REQUEST: string = userBackoffice.defineType("USER_BACKOFFICE_IMAGE_GET_REQUEST")
export const USER_BACKOFFICE_IMAGE_GET_SUCCESS: string = userBackoffice.defineType("USER_BACKOFFICE_IMAGE_GET_SUCCESS")
export const USER_BACKOFFICE_IMAGE_GET_FAILURE: string = userBackoffice.defineType("USER_BACKOFFICE_IMAGE_GET_FAILURE")

export const USER_BACKOFFICE_IMAGE_URL_GET_REQUEST: string = userBackoffice.defineType(
  "USER_BACKOFFICE_IMAGE_URL_GET_REQUEST"
)
export const USER_BACKOFFICE_IMAGE_URL_GET_SUCCESS: string = userBackoffice.defineType(
  "USER_BACKOFFICE_IMAGE_URL_GET_SUCCESS"
)
export const USER_BACKOFFICE_IMAGE_URL_GET_FAILURE: string = userBackoffice.defineType(
  "USER_BACKOFFICE_IMAGE_URL_GET_FAILURE"
)

export const USER_BACKOFFICE_NOTIFICATION_GET_REQUEST: string = userBackoffice.defineType(
  "USER_BACKOFFICE_NOTIFICATION_GET_REQUEST"
)
export const USER_BACKOFFICE_NOTIFICATION_GET_SUCCESS: string = userBackoffice.defineType(
  "USER_BACKOFFICE_NOTIFICATION_GET_SUCCESS"
)
export const USER_BACKOFFICE_NOTIFICATION_GET_FAILURE: string = userBackoffice.defineType(
  "USER_BACKOFFICE_NOTIFICATION_GET_FAILURE"
)

export const USER_BACKOFFICE_ADD_REQUEST: string = userBackoffice.defineType("USER_BACKOFFICE_ADD_REQUEST")
export const USER_BACKOFFICE_ADD_SUCCESS: string = userBackoffice.defineType("USER_BACKOFFICE_ADD_SUCCESS")
export const USER_BACKOFFICE_ADD_FAILURE: string = userBackoffice.defineType("USER_BACKOFFICE_ADD_FAILURE")

export const USER_BACKOFFICE_POST_REQUEST: string = userBackoffice.defineType("USER_BACKOFFICE_POST_REQUEST")
export const USER_BACKOFFICE_POST_SUCCESS: string = userBackoffice.defineType("USER_BACKOFFICE_POST_SUCCESS")
export const USER_BACKOFFICE_POST_FAILURE: string = userBackoffice.defineType("USER_BACKOFFICE_POST_FAILURE")

export const USER_BACKOFFICE_DELETE_REQUEST: string = userBackoffice.defineType("USER_BACKOFFICE_DELETE_REQUEST")
export const USER_BACKOFFICE_DELETE_SUCCESS: string = userBackoffice.defineType("USER_BACKOFFICE_DELETE_SUCCESS")
export const USER_BACKOFFICE_DELETE_FAILURE: string = userBackoffice.defineType("USER_BACKOFFICE_DELETE_FAILURE")
