import styled from "styled-components"
import { type IStyle } from "../../interfaces/IStyle"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"

export const Button = styled.button<IStyle>`
  position: relative;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin-left: ${({ marginLeft }) => (marginLeft ?? false ? "10px" : "")};
  margin-bottom: ${({ marginBottom }) => (marginBottom ?? false ? "3px" : "")};
  background-image: ${({ background, disabled }) => (disabled === true ? "grey" : background)};
  color: ${({ disabled }) => (disabled === true ? "#D5D5D5" : "#fff")};
  cursor: pointer;
  font-family: ${GLOBAL_STYLES.FONT.FAMILY};
  font-weight: 500;
  border: none;
  overflow: hidden;
  opacity: 1;
  display: flex;
  justify-content: center;
  box-shadow: ${({ themeContext }) => GLOBAL_STYLES.BUTTON.BORDER_SHADOW_1[themeContext]};
  align-items: center;
  padding: 0.25rem 0.6rem;
  font-size: 0.82031rem;
  border-radius: 0.2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  background-repeat: no-repeat;
  white-space: nowrap;

  filter: none;
  :hover {
    background-image: ${({ backgroundHover, disabled }) => (disabled === true ? "" : backgroundHover)};
    cursor: ${({ disabled }) => (disabled === true ? "no-drop" : "pointer")};
  }
  :active {
    box-shadow: ${({ disabled, themeContext }) =>
      disabled === true ? "" : GLOBAL_STYLES.BUTTON.BORDER_SHADOW_2[themeContext]};
  }
`

export const Icon = styled.div`
  line-height: 0%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 0.82031rem;
`
