import { expensiveorigin } from "./_duck"

export const EXPENSIVE_ORIGIN_GET_REQUEST: string = expensiveorigin.defineType("EXPENSIVE_ORIGIN_GET_REQUEST")
export const EXPENSIVE_ORIGIN_GET_SUCCESS: string = expensiveorigin.defineType("EXPENSIVE_ORIGIN_GET_SUCCESS")
export const EXPENSIVE_ORIGIN_GET_FAILURE: string = expensiveorigin.defineType("EXPENSIVE_ORIGIN_GET_FAILURE")

export const EXPENSIVE_ORIGIN_ADD_REQUEST: string = expensiveorigin.defineType("EXPENSIVE_ORIGIN_ADD_REQUEST")
export const EXPENSIVE_ORIGIN_ADD_SUCCESS: string = expensiveorigin.defineType("EXPENSIVE_ORIGIN_ADD_SUCCESS")
export const EXPENSIVE_ORIGIN_ADD_FAILURE: string = expensiveorigin.defineType("EXPENSIVE_ORIGIN_ADD_FAILURE")

export const EXPENSIVE_ORIGIN_PUT_REQUEST: string = expensiveorigin.defineType("EXPENSIVE_ORIGIN_PUT_REQUEST")
export const EXPENSIVE_ORIGIN_PUT_SUCCESS: string = expensiveorigin.defineType("EXPENSIVE_ORIGIN_PUT_SUCCESS")
export const EXPENSIVE_ORIGIN_PUT_FAILURE: string = expensiveorigin.defineType("EXPENSIVE_ORIGIN_PUT_FAILURE")

export const EXPENSIVE_ORIGIN_DELETE_REQUEST: string = expensiveorigin.defineType("EXPENSIVE_ORIGIN_DELETE_REQUEST")
export const EXPENSIVE_ORIGIN_DELETE_SUCCESS: string = expensiveorigin.defineType("EXPENSIVE_ORIGIN_DELETE_SUCCESS")
export const EXPENSIVE_ORIGIN_DELETE_FAILURE: string = expensiveorigin.defineType("EXPENSIVE_ORIGIN_DELETE_FAILURE")
