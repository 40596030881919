import { userAppClient } from "./_duck"
import * as types from "./types"

export const getUserAppClientRequest = userAppClient.createAction(types.USER_APP_CLIENT_GET_REQUEST)
export const getUserAppClientSuccess = userAppClient.createAction(types.USER_APP_CLIENT_GET_SUCCESS)
export const getUserAppClientFailure = userAppClient.createAction(types.USER_APP_CLIENT_GET_FAILURE)

export const patchUserAppClientRequest = userAppClient.createAction(types.USER_APP_CLIENT_PATCH_REQUEST)
export const patchUserAppClientSuccess = userAppClient.createAction(types.USER_APP_CLIENT_PATCH_SUCCESS)
export const patchUserAppClientFailure = userAppClient.createAction(types.USER_APP_CLIENT_PATCH_FAILURE)

export const postUserAppClientRequest = userAppClient.createAction(types.USER_APP_CLIENT_POST_REQUEST)
export const postUserAppClientSuccess = userAppClient.createAction(types.USER_APP_CLIENT_POST_SUCCESS)
export const postUserAppClientFailure = userAppClient.createAction(types.USER_APP_CLIENT_POST_FAILURE)

export const deleteUserAppClientRequest = userAppClient.createAction(types.USER_APP_CLIENT_DELETE_REQUEST)
export const deleteUserAppClientSuccess = userAppClient.createAction(types.USER_APP_CLIENT_DELETE_SUCCESS)
export const deleteUserAppClientFailure = userAppClient.createAction(types.USER_APP_CLIENT_DELETE_FAILURE)
