import { quoteTemplate } from "./_duck"
import * as types from "./types"

// Types
import { type PayloadAction } from "../../types"
import { type ModuleType } from "./_definitions"
import { TRANSLATION_INDEX_GLOBAL, TRANSLATION_INDEX_RESPONSE_CODE } from "../../../config/translation"

const initialModuleState: ModuleType = {
  data: [],
  customerRelative: [],
  dataQuoteSent: [],
  dataCustomer: [],
  responseSend: null,
  dataQuote: null,
  codePDF: "",
  loading: false,
  showToast: false,
  toastMessage: "",
  hasErrMessage: false,
  errorCode: ""
}

const reducer = quoteTemplate.createReducer(
  {
    [types.QUOTE_TEMPLATE_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.QUOTE_TEMPLATE_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        data: payload,
        loading: false
      }
    },
    [types.QUOTE_TEMPLATE_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      data: [],
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),

    [types.QUOTE_SEND_POST_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      responseSend: null,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.QUOTE_SEND_POST_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        responseSend: payload,
        loading: false
      }
    },
    [types.QUOTE_SEND_POST_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      responseSend: null,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),

    [types.QUOTE_SENT_CUSTOMER_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.QUOTE_SENT_CUSTOMER_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        dataCustomer: payload,
        loading: false
      }
    },
    [types.QUOTE_SENT_CUSTOMER_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),

    [types.QUOTE_SENT_BY_ID_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      dataCustomer: null,
      dataQuote: null,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.QUOTE_SENT_BY_ID_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        dataCustomer: null,
        dataQuote: payload,
        loading: false
      }
    },
    [types.QUOTE_SENT_BY_ID_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      dataCustomer: null,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),

    [types.QUOTE_SENT_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.QUOTE_SENT_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        dataQuoteSent: payload,
        loading: false
      }
    },
    [types.QUOTE_SENT_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      dataQuoteSent: [],
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),

    [types.QUOTE_TEMPLATE_CREATE_PDF_REQUEST]: (state: ModuleType): ModuleType => {
      return {
        ...state,
        loading: true,
        codePDF: "",
        showToast: false,
        toastMessage: "",
        hasErrMessage: false,
        errorCode: ""
      }
    },
    [types.QUOTE_TEMPLATE_CREATE_PDF_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        codePDF: payload.codigoPDF,
        loading: false
      }
    },
    [types.QUOTE_TEMPLATE_CUSTOMER_RELATIVE_GET_FAILURE]: (
      state: ModuleType,
      { payload }: PayloadAction
    ): ModuleType => ({
      ...state,
      codePDF: "",
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),

    [types.QUOTE_TEMPLATE_PREVIEW_PDF_SUCCESS]: (state: ModuleType): ModuleType => {
      return {
        ...state,
        loading: true,
        codePDF: "",
        showToast: false,
        toastMessage: "",
        hasErrMessage: false,
        errorCode: ""
      }
    },
    [types.QUOTE_TEMPLATE_PREVIEW_PDF_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        codePDF: payload.codigoPDF,
        loading: false
      }
    },
    [types.QUOTE_TEMPLATE_PREVIEW_PDF_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      codePDF: "",
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),

    [types.QUOTE_TEMPLATE_CLEAN_PDF]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        codePDF: "",
        dataCustomer: null,
        dataQuote: null,
        responseSend: null
      }
    },
    [types.QUOTE_TEMPLATE_CLEAN_RESPONSE_ERROR]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        responseSend: null
      }
    },
    [types.QUOTE_TEMPLATE_CUSTOMER_RELATIVE_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.QUOTE_TEMPLATE_CUSTOMER_RELATIVE_GET_SUCCESS]: (
      state: ModuleType,
      { payload }: PayloadAction
    ): ModuleType => {
      return {
        ...state,
        customerRelative: payload,
        loading: false
      }
    },
    [types.QUOTE_TEMPLATE_ADD_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      customerRelative: [],
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),

    [types.QUOTE_TEMPLATE_ADD_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.QUOTE_TEMPLATE_ADD_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        showToast: true,
        toastMessage: TRANSLATION_INDEX_GLOBAL.SUCCESS_ADD,
        loading: false
      }
    },
    [types.QUOTE_TEMPLATE_ADD_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.QUOTE_TEMPLATE_PUT_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.QUOTE_TEMPLATE_PUT_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        loading: false,
        showToast: true,
        toastMessage: TRANSLATION_INDEX_GLOBAL.SUCCESS_UPDATE
      }
    },
    [types.QUOTE_TEMPLATE_PUT_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.QUOTE_TEMPLATE_DELETE_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.QUOTE_TEMPLATE_DELETE_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        loading: false,
        showToast: true,
        toastMessage: TRANSLATION_INDEX_GLOBAL.SUCCESS_DELETE
      }
    },
    [types.QUOTE_TEMPLATE_DELETE_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    })
  },
  initialModuleState
)

export default reducer
