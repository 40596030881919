import styled from "styled-components"
import { type IStyle } from "../../interfaces/IStyle"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"

export const Container = styled.div<IStyle>`
  position: relative;
  background-color: ${({ background }) => background};
  display: flex;
  font-size: 15px;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  height: 50px;
  cursor: pointer;
`

export const ContainerSubMenu = styled.div<IStyle>`
  position: absolute;
  box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 1);
  padding-top: 2px;
  width: auto;
  white-space: nowrap;
  left: 0;
  top: 50px;
  background: ${({ themeContext }) => GLOBAL_STYLES.BACKGROUND.TYPE_2[themeContext]};
  /* overflow: hidden; */
  z-index: 9999999999;
  border-radius: 5px;
`
