// SAGAS
import { put, takeLatest, call } from "redux-saga/effects"
import { type SagaIterator } from "redux-saga"
import * as types from "../types"

import {
  getExpensiveOriginFailure,
  getExpensiveOriginSuccess,
  addExpensiveOriginSuccess,
  addExpensiveOriginFailure,
  deleteExpensiveOriginSuccess,
  deleteExpensiveOriginFailure,
  putExpensiveOriginSuccess,
  putExpensiveOriginFailure
} from "../actions"

import { addExpensiveOrigin, deleteExpensiveOrigin, getExpensiveOrigin, putExpensiveOrigin } from "../services"

function* getUsetAppClientWorker(): SagaIterator {
  try {
    const { data } = yield call(getExpensiveOrigin)
    yield put(getExpensiveOriginSuccess(data))
  } catch (error) {
    yield put(getExpensiveOriginFailure(error))
  }
}

function* addUserClientWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(addExpensiveOrigin, request.payload)
    yield put(addExpensiveOriginSuccess(data))
  } catch (error) {
    yield put(addExpensiveOriginFailure(error))
  }
}

function* putUserClientWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(putExpensiveOrigin, request.payload)
    yield put(putExpensiveOriginSuccess(data))
  } catch (error) {
    yield put(putExpensiveOriginFailure(error))
  }
}

function* deleteUserClientWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(deleteExpensiveOrigin, request.payload)
    yield put(deleteExpensiveOriginSuccess(data))
  } catch (error) {
    yield put(deleteExpensiveOriginFailure(error))
  }
}

export function* listWatcher(): SagaIterator {
  yield takeLatest(types.EXPENSIVE_ORIGIN_GET_REQUEST, getUsetAppClientWorker)
  yield takeLatest(types.EXPENSIVE_ORIGIN_ADD_REQUEST, addUserClientWorker)
  yield takeLatest(types.EXPENSIVE_ORIGIN_PUT_REQUEST, putUserClientWorker)
  yield takeLatest(types.EXPENSIVE_ORIGIN_DELETE_REQUEST, deleteUserClientWorker)
}

export const sagas = [listWatcher]
