import { remenders } from "./_duck"
import * as types from "./types"

// Types
import { type PayloadAction } from "../../types"
import { type ModuleType } from "./_definitions"
import { TRANSLATION_INDEX_GLOBAL, TRANSLATION_INDEX_RESPONSE_CODE } from "../../../config/translation"

const initialModuleState: ModuleType = {
  data: null,
  loading: false,
  showToast: false,
  toastMessage: "",
  hasErrMessage: false,
  errorCode: ""
}

const reducer = remenders.createReducer(
  {
    [types.REMENDERS_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.REMENDERS_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      // payload = [
      //   {
      //     idRecordatorio: 1,
      //     cliente: { idCliente: 102, cuit: "30-58745478-8", razonSocial: "DAEVA S.A" },
      //     contacto: { idClienteContacto: 20, apellido: "Gomez", nombre: "Juan" },
      //     fechaHoraInicio: 1708234732423,
      //     fechaHoraFin: 1708235732423,
      //     usuario: "fernando_neirot",
      //     comentario: "Llamar al cliente xxxxxx para informar nuevos precios"
      //   }
      // ]
      return {
        ...state,
        data: payload,
        loading: false
      }
    },
    [types.REMENDERS_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      data: null,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.REMENDERS_ADD_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.REMENDERS_ADD_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        showToast: true,
        toastMessage: TRANSLATION_INDEX_GLOBAL.SUCCESS_ADD,
        loading: false
      }
    },
    [types.REMENDERS_ADD_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.REMENDERS_PUT_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.REMENDERS_PUT_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        loading: false,
        showToast: true,
        toastMessage: TRANSLATION_INDEX_GLOBAL.SUCCESS_UPDATE
      }
    },
    [types.REMENDERS_PUT_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.REMENDERS_DELETE_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.REMENDERS_DELETE_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        loading: false,
        showToast: true,
        toastMessage: TRANSLATION_INDEX_GLOBAL.SUCCESS_DELETE
      }
    },
    [types.REMENDERS_DELETE_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.REMENDERS_CLEAR_ALL]: (state: ModuleType, { payload }: PayloadAction): ModuleType => initialModuleState
  },
  initialModuleState
)

export default reducer
