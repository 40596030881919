import React, { type ReactElement } from "react"
import { Active, ContainerSuboption, ContainerTheme, Icon, Label } from "../_styles"
import { useTranslation } from "react-i18next"
import { GLOBAL_STYLES } from "../../../styles/GLOBAL_STYLE"
import { STORAGE } from "../../../utils/localStorage"
import { TRANSLATION_INDEX_GLOBAL } from "../../../config/translation"
import IconArrowDown from "../../../components/Icons/IconArrowDown"
import { useGlobalContext } from "../../../context/GlobalContext"

const Theme = (): ReactElement => {
  const { t } = useTranslation()
  const [activeSubMenu, setActiveSubMenu] = React.useState<boolean>(false)
  const { themeContext } = useGlobalContext()
  const selectTheme = (theme: string): void => {
    STORAGE.set(STORAGE.THEME, theme)
    window.location.reload()
  }

  return (
    <ContainerTheme themeContext={themeContext}>
      <Active
        themeContext={themeContext}
        onClick={() => {
          setActiveSubMenu(!activeSubMenu)
        }}
      >
        <Label themeContext={themeContext}>{t(TRANSLATION_INDEX_GLOBAL.MODE)}</Label>
        <Icon>
          <IconArrowDown size={18} color={GLOBAL_STYLES.LABEL.TYPE_1[themeContext]} />
        </Icon>
      </Active>
      {activeSubMenu && (
        <>
          <ContainerSuboption
            themeContext={themeContext}
            onClick={() => {
              selectTheme("LIGHT")
            }}
          >
            <Label themeContext={themeContext}>{t(TRANSLATION_INDEX_GLOBAL.LIGHT)}</Label>
          </ContainerSuboption>
          <ContainerSuboption
            themeContext={themeContext}
            onClick={() => {
              selectTheme("DARK")
            }}
          >
            <Label themeContext={themeContext}>{t(TRANSLATION_INDEX_GLOBAL.DARK)}</Label>
          </ContainerSuboption>
        </>
      )}
    </ContainerTheme>
  )
}

export default Theme
