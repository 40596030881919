/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import ContainerPage from "../../components/ContainerPage"
import {
  TRANSLATION_INDEX_AUTHORIZE_INFORMACION,
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_MENU,
  TRANSLATION_INDEX_PROFILE
} from "../../config/translation"
import DataTable from "react-data-table-component"

import { ContainerTable } from "./_styles"
import { customStylesContext } from "../../styles/_styleDatatable"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { getProfileRequest } from "../../state/modules/profile/actions"
import { NoData } from "../../components/NoData"
import "../../styles/styles.css"
import Search from "./forms/search"
import { referenceSelector } from "../../state/modules/reference/_selectors"
import { type IReference } from "../../interfaces/IReference"
import ContainerLabelTwoRow from "../../components/ContainerLabelTwoRow"
import { format } from "date-fns"
import { FORMAT_DATE } from "../../utils/constants"
import { useGlobalContext } from "../../context/GlobalContext"
import ExportToExcel from "./ExportToExcel"

const SearchCustomerReferencesPage = (): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const reference = useSelector(referenceSelector)
  const [dataFiltered, setDataFiltered] = React.useState<IReference[]>(reference.data)
  const { themeContext } = useGlobalContext()
  const [openModal, setOpenModal] = React.useState(false)

  const handleOpenModal = (action = ""): void => {
    setOpenModal(!openModal)
  }

  const paginationComponentOptions = {
    rowsPerPageText: t(TRANSLATION_INDEX_GLOBAL.ROWS_PER_PAGE),
    rangeSeparatorText: t(TRANSLATION_INDEX_GLOBAL.OF),
    selectAllRowsItem: true,
    selectAllRowsItemText: t(TRANSLATION_INDEX_GLOBAL.ALL)
  }

  const columns = [
    {
      name: t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.SHIPMENT_ID),
      selector: (row: IReference) => row.shipmentId,
      width: "33%",
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_PROFILE.COLUMNS.REFERENCE),
      selector: (row: IReference) => row.referencia.toLocaleUpperCase(),
      sortable: true,
      width: `33%`
    },

    {
      name: t(TRANSLATION_INDEX_PROFILE.COLUMNS.USER_NAME),
      selector: (row: IReference) => `${row.usuarioAppActualizacion} ${row?.fechaActualizacion.toString() ?? "-"} `,
      cell: (row: IReference) => {
        const label = `${row?.usuarioAppActualizacion ?? ""} ${
          row?.fechaActualizacion ? format(new Date(row?.fechaActualizacion), FORMAT_DATE) : "-"
        }`
        return <ContainerLabelTwoRow label={label === null ? "-" : label} />
      },
      sortable: true,
      width: `33%`
    }
  ]

  React.useEffect(() => {
    if (reference.showToast) {
      if (reference.hasErrMessage) {
        toast.error(t(reference.errorCode))
      } else {
        toast.success(t(reference.toastMessage))
        handleOpenModal()
        dispatch(getProfileRequest())
      }
    }
  }, [reference.showToast])

  React.useEffect(() => {
    setDataFiltered(reference?.data)
  }, [reference])

  return (
    <>
      <ContainerPage
        title={`${t(TRANSLATION_INDEX_MENU.CONSULT_REFERENCE)}`}
        actions={[
          {
            isButton: true,
            component: <ExportToExcel data={dataFiltered} />
          }
        ]}
      >
        <>
          <Search setDataFiltered={setDataFiltered} />
          <ContainerTable themeContext={themeContext} opacity={1}>
            <DataTable
              data={dataFiltered ?? []}
              theme="solarized"
              noDataComponent={<NoData />}
              columns={columns}
              dense
              pagination
              paginationComponentOptions={paginationComponentOptions}
              striped
              customStyles={customStylesContext(themeContext)}
              defaultSortFieldId={1}
            />
          </ContainerTable>
        </>
      </ContainerPage>
    </>
  )
}

export default SearchCustomerReferencesPage
