import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import "./config/translation/i18next-config"
import App from "./App"
import { ContextProvider } from "./context/ContextProvider"
import { Provider } from "react-redux"
import makeStore from "./state"
const store = makeStore()

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <Provider store={store}>
    <ContextProvider>
      <App />
    </ContextProvider>
  </Provider>
)
