/* eslint-disable react-hooks/exhaustive-deps */
import React, { type ReactElement } from "react"

import ButtonCustom from "../../../../components/ButtonCustom"

import { useTranslation } from "react-i18next"
import { v4 as uuidv4 } from "uuid"
import {
  TRANSLATION_INDEX_CUSTOMER_PROSPECT,
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_MENU
} from "../../../../config/translation"
import {
  Container,
  Group,
  GroupButtom,
  ContainerGroup,
  ContainerMessage,
  ContainerItems,
  ContainerItem,
  ButtonDelete,
  Text
} from "./_styles"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { ACTIONS } from "../../../../utils/constants"

import "react-toastify/dist/ReactToastify.css"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"
import { withFormik } from "formik"
import { schemaAdd, deleteScheme } from "../../_schema"
import InputCustom from "../../../../components/InputCustom"
import { useMobile } from "../../../../hook/useMobile"
import { useGlobalContext } from "../../../../context/GlobalContext"
import { toast } from "react-toastify"
import { isStringEmpty } from "../../../../utils/functions"

const ContactAddUpdate = ({
  data,
  isUserPending = false,
  loading = false,
  action,
  closeModal = () => {},
  accept = () => {},
  setFieldValue,
  values,
  touched,
  errors,
  setSubmitting,
  isSubmitting,
  handleChange,
  handleBlur,
  handleSubmit
}: IFormListProps): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useMobile()
  const { themeContext } = useGlobalContext()
  const [mails, setMails] = React.useState<string[]>(data?.emails ?? [])
  const [phones, setPhones] = React.useState<string[]>(data?.telefonos ?? [])
  const [errorConstact, setErrorConstact] = React.useState<any>({})
  const [clickSend, setClickSend] = React.useState(false)

  const validation = (): void => {
    let isValidated = true
    setClickSend(true)
    if (isStringEmpty(values?.nombre.trim()) || isStringEmpty(values?.apellido.trim())) {
      setErrorConstact({
        ...errorConstact,
        nombre: isStringEmpty(values?.nombre.trim()) ? TRANSLATION_INDEX_GLOBAL.REQUIRED : "",
        apellido: isStringEmpty(values?.apellido.trim()) ? TRANSLATION_INDEX_GLOBAL.REQUIRED : "",
        puesto: ""
      })
      isValidated = false
    }

    if (isValidated) accept(values)
  }

  const handleAddItems = (key: string, value: string): void => {
    if (!isStringEmpty(value)) {
      if (key === "mail" && !mails.includes(value)) {
        if (value.match(/^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/) != null) {
          setMails([...mails, value])
          if (setFieldValue != null) {
            setFieldValue(key, "")
            setFieldValue("emails", [...mails, value])
          }
        } else {
          toast.error(t(TRANSLATION_INDEX_GLOBAL.EMAIL_FORMAT_INCORRECT))
        }
      } else if (key === "phone" && !phones.includes(value)) {
        setPhones([...phones, value])
        if (setFieldValue != null) {
          setFieldValue(key, "")
          setFieldValue("telefonos", [...phones, value])
        }
      }
    }
  }

  const handleKeyPressEnter = (key: string, keyCode: number): void => {
    if (keyCode === 13 || (keyCode === 9 && isMobile)) {
      handleAddItems(key, values != null ? values[key] : "")
    }
  }

  const handleDelete = (key, value): void => {
    if (key === "mail") {
      setMails(mails.filter(item => item !== value))
      if (setFieldValue != null) {
        setFieldValue(key, "")
        setFieldValue(
          "emails",
          mails.filter(item => item !== value)
        )
      }
    } else if (key === "phone") {
      setPhones(phones.filter(item => item !== value))
      if (setFieldValue != null) {
        setFieldValue(key, "")
        setFieldValue(
          "telefonos",
          mails.filter(item => item !== value)
        )
      }
    }
  }
  React.useEffect(() => {
    if (clickSend) {
      setErrorConstact({
        ...errorConstact,
        nombre: isStringEmpty(values?.nombre.trim()) ? TRANSLATION_INDEX_GLOBAL.REQUIRED : "",
        apellido: isStringEmpty(values?.apellido.trim()) ? TRANSLATION_INDEX_GLOBAL.REQUIRED : "",
        puesto: ""
      })
    }
  }, [values, clickSend])

  return (
    <Container>
      {action === ACTIONS.DELETE.VALUE ? (
        <ContainerMessage themeContext={themeContext}>{t(TRANSLATION_INDEX_GLOBAL.CONFIRM_DELETE)}</ContainerMessage>
      ) : (
        <ContainerGroup themeContext={themeContext}>
          <Group themeContext={themeContext} alignItems="flex-start">
            <InputCustom
              label={`${t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.LAST_NAME) ?? ""} (*)`}
              value={values?.apellido ?? ""}
              handleChange={handleChange}
              name="apellido"
              placeHolder={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.LAST_NAME) ?? ""}
              width={isMobile ? "100%" : "50%"}
              errors={errorConstact?.apellido ?? ""}
            />
            <InputCustom
              label={`${t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.NAME) ?? ""} (*)`}
              value={values?.nombre ?? ""}
              handleChange={handleChange}
              marginLeft
              name="nombre"
              placeHolder={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.NAME) ?? ""}
              width={isMobile ? "100%" : "50%"}
              errors={errorConstact?.nombre ?? ""}
            />
            <InputCustom
              label={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.POSITION) ?? ""}
              value={values?.puesto ?? ""}
              handleChange={handleChange}
              marginLeft
              name="puesto"
              placeHolder={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.NAME) ?? ""}
              width={isMobile ? "100%" : "50%"}
              errors={errorConstact?.puesto ?? ""}
            />
          </Group>
          <Group themeContext={themeContext}>
            <InputCustom
              label="Mails"
              subLabel={`${t(TRANSLATION_INDEX_GLOBAL.IMPORTANT).toUpperCase() ?? ""}: ${
                t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.NO_MAILS) ?? ""
              }`}
              value={values?.mail ?? ""}
              handleChange={handleChange}
              handleKeyPressEnter={handleKeyPressEnter}
              name="mail"
              placeHolder="Mails"
              width={isMobile ? "100%" : "50%"}
            />
            {isMobile && (
              <>
                (
                <ContainerItems themeContext={themeContext} isActive>
                  {mails.length > 0 &&
                    mails.map(item => {
                      return (
                        <ContainerItem key={item}>
                          <Text themeContext={themeContext}>{item}</Text>{" "}
                          <ButtonDelete
                            themeContext={themeContext}
                            onClick={() => {
                              handleDelete("mail", item)
                            }}
                          >
                            X
                          </ButtonDelete>
                        </ContainerItem>
                      )
                    })}
                </ContainerItems>
                )
                <div
                  style={{
                    height: "32px",
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    color: GLOBAL_STYLES.LABEL.TYPE_3[themeContext]
                  }}
                >
                  <input
                    type="checkbox"
                    name="envioCotizacion"
                    checked={values?.envioCotizacion === 1}
                    value={values?.envioCotizacion}
                    onChange={e => {
                      if (setFieldValue != null) setFieldValue("envioCotizacion", e.target.checked ? 1 : 0)
                    }}
                  />
                  <span style={{ marginLeft: "5px" }}>{t(TRANSLATION_INDEX_MENU.SEND_QUOTE)}</span>
                </div>
              </>
            )}

            <InputCustom
              label={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.PHONES) ?? ""}
              subLabel={`${t(TRANSLATION_INDEX_GLOBAL.IMPORTANT).toUpperCase() ?? ""}: ${
                t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.NO_PHONES) ?? ""
              }`}
              value={values?.phone ?? ""}
              handleChange={handleChange}
              handleKeyPressEnter={handleKeyPressEnter}
              marginLeft
              name="phone"
              placeHolder={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.PHONES) ?? ""}
              width={isMobile ? "100%" : "50%"}
            />
            {isMobile && (
              <ContainerItems themeContext={themeContext} isActive style={{ marginLeft: "10px" }}>
                {phones.length > 0 &&
                  phones.map(item => {
                    return (
                      <ContainerItem key={item}>
                        <Text themeContext={themeContext}>{item}</Text>{" "}
                        <ButtonDelete
                          themeContext={themeContext}
                          onClick={() => {
                            handleDelete("phone", item)
                          }}
                        >
                          X
                        </ButtonDelete>
                      </ContainerItem>
                    )
                  })}
              </ContainerItems>
            )}
          </Group>
          {!isMobile && (
            <>
              <Group themeContext={themeContext} alignItems="flex-start">
                <ContainerItems themeContext={themeContext} isActive style={{ width: isMobile ? "100%" : "50%" }}>
                  {mails.length > 0 &&
                    mails.map(item => {
                      return (
                        <ContainerItem key={item}>
                          <Text themeContext={themeContext}>{item}</Text>{" "}
                          <ButtonDelete
                            themeContext={themeContext}
                            onClick={() => {
                              handleDelete("mail", item)
                            }}
                          >
                            X
                          </ButtonDelete>
                        </ContainerItem>
                      )
                    })}
                </ContainerItems>
                <ContainerItems themeContext={themeContext} isActive style={{ marginLeft: "10px" }}>
                  {phones.length > 0 &&
                    phones.map(item => {
                      return (
                        <ContainerItem key={item}>
                          <Text themeContext={themeContext}>{item}</Text>{" "}
                          <ButtonDelete
                            themeContext={themeContext}
                            onClick={() => {
                              handleDelete("phone", item)
                            }}
                          >
                            X
                          </ButtonDelete>
                        </ContainerItem>
                      )
                    })}
                </ContainerItems>
              </Group>

              <div
                style={{
                  height: "32px",
                  marginTop: "10px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  color: GLOBAL_STYLES.LABEL.TYPE_3[themeContext]
                }}
              >
                <input
                  type="checkbox"
                  name="envioCotizacion"
                  checked={values?.envioCotizacion === 1}
                  value={values?.envioCotizacion}
                  onChange={e => {
                    if (setFieldValue != null) setFieldValue("envioCotizacion", e.target.checked ? 1 : 0)
                  }}
                />
                <span style={{ marginLeft: "5px" }}>{t(TRANSLATION_INDEX_MENU.SEND_QUOTE)}</span>
              </div>
            </>
          )}
        </ContainerGroup>
      )}

      <GroupButtom themeContext={themeContext}>
        <ButtonCustom
          onClick={closeModal}
          width="80px"
          type="button"
          label={t("global.cancel") ?? ""}
          background={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE[themeContext]}
          backgroundHover={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE_HOVER[themeContext]}
        />
        <ButtonCustom
          onClick={validation}
          type="button"
          width="80px"
          label={
            action === ACTIONS.DELETE.VALUE
              ? t(TRANSLATION_INDEX_GLOBAL.DELETE) ?? ""
              : t(TRANSLATION_INDEX_GLOBAL.CONFIRM) ?? ""
          }
          background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
          backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]}
          marginLeft
        />
      </GroupButtom>
    </Container>
  )
}

export default withFormik<IFormListProps, any>({
  mapPropsToValues: ({ data }): any => ({
    id: data?.id ?? `cargo-${uuidv4()}`,
    idClienteContacto: data?.idClienteContacto ?? null,
    apellido: data?.apellido ?? "",
    nombre: data?.nombre ?? "",
    puesto: data?.puesto ?? "",
    emails: data?.emails ?? [],
    envioCotizacion: data?.envioCotizacion ?? 1,
    telefonos: data?.telefonos ?? []
  }),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: ({ action }) => (action === ACTIONS.DELETE.VALUE ? deleteScheme : schemaAdd),
  handleSubmit: (values, { setSubmitting }): any => {
    setSubmitting(false)
  }
})(ContactAddUpdate)
