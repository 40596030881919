// SAGAS
import { put, takeLatest, call } from "redux-saga/effects"
import { type SagaIterator } from "redux-saga"
import * as types from "../types"

import {
  getProfileFailure,
  getProfileSuccess,
  addProfileSuccess,
  addProfileFailure,
  deleteProfileSuccess,
  deleteProfileFailure,
  putProfileSuccess,
  putProfileFailure
} from "../actions"

import { addProfile, deleteProfile, getProfile, putProfile } from "../services"

function* getUsetAppClientWorker(): SagaIterator {
  try {
    const { data } = yield call(getProfile)
    yield put(getProfileSuccess(data))
  } catch (error) {
    yield put(getProfileFailure(error))
  }
}

function* addUserClientWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(addProfile, request.payload)
    yield put(addProfileSuccess(data))
  } catch (error) {
    yield put(addProfileFailure(error))
  }
}

function* putUserClientWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(putProfile, request.payload)
    yield put(putProfileSuccess(data))
  } catch (error) {
    yield put(putProfileFailure(error))
  }
}

function* deleteUserClientWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(deleteProfile, request.payload)
    yield put(deleteProfileSuccess(data))
  } catch (error) {
    yield put(deleteProfileFailure(error))
  }
}

export function* listWatcher(): SagaIterator {
  yield takeLatest(types.PROFILE_GET_REQUEST, getUsetAppClientWorker)
  yield takeLatest(types.PROFILE_ADD_REQUEST, addUserClientWorker)
  yield takeLatest(types.PROFILE_PUT_REQUEST, putUserClientWorker)
  yield takeLatest(types.PROFILE_DELETE_REQUEST, deleteUserClientWorker)
}

export const sagas = [listWatcher]
