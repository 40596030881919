// SAGAS
import { put, takeLatest, call } from "redux-saga/effects"
import { type SagaIterator } from "redux-saga"
import * as types from "../types"

import {
  getPermissionFailure,
  getPermissionSuccess,
  addPermissionSuccess,
  addPermissionFailure,
  deletePermissionSuccess,
  deletePermissionFailure,
  postPermissionSuccess,
  postPermissionFailure,
  getPermissionByProfileSuccess,
  getPermissionByProfileFailure
} from "../actions"

import { addPermission, deletePermission, getPermission, getPermissionByProfile, postPermission } from "../services"

function* getPermissionWorker(): SagaIterator {
  try {
    const { data } = yield call(getPermission)
    yield put(getPermissionSuccess(data))
  } catch (error) {
    yield put(getPermissionFailure(error))
  }
}

function* getPermissionByProfileWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(getPermissionByProfile, request.payload)
    yield put(getPermissionByProfileSuccess(data))
  } catch (error) {
    yield put(getPermissionByProfileFailure(error))
  }
}

function* addPermissionWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(addPermission, request.payload)
    yield put(addPermissionSuccess(data))
  } catch (error) {
    yield put(addPermissionFailure(error))
  }
}

function* postPermissionWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(postPermission, request.payload)
    yield put(postPermissionSuccess(data))
  } catch (error) {
    yield put(postPermissionFailure(error))
  }
}

function* deletePermissionWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(deletePermission, request.payload)
    yield put(deletePermissionSuccess(data))
  } catch (error) {
    yield put(deletePermissionFailure(error))
  }
}

export function* listWatcher(): SagaIterator {
  yield takeLatest(types.PERMISSION_GET_REQUEST, getPermissionWorker)
  yield takeLatest(types.PERMISSION_GET_BY_PROFILE_REQUEST, getPermissionByProfileWorker)
  yield takeLatest(types.PERMISSION_ADD_REQUEST, addPermissionWorker)
  yield takeLatest(types.PERMISSION_POST_REQUEST, postPermissionWorker)
  yield takeLatest(types.PERMISSION_DELETE_REQUEST, deletePermissionWorker)
}

export const sagas = [listWatcher]
