import { expensiveba } from "./_duck"
import * as types from "./types"

export const getExpensiveBARequest = expensiveba.createAction(types.EXPENSIVE_BA_GET_REQUEST)
export const getExpensiveBASuccess = expensiveba.createAction(types.EXPENSIVE_BA_GET_SUCCESS)
export const getExpensiveBAFailure = expensiveba.createAction(types.EXPENSIVE_BA_GET_FAILURE)

export const addExpensiveBARequest = expensiveba.createAction(types.EXPENSIVE_BA_ADD_REQUEST)
export const addExpensiveBASuccess = expensiveba.createAction(types.EXPENSIVE_BA_ADD_SUCCESS)
export const addExpensiveBAFailure = expensiveba.createAction(types.EXPENSIVE_BA_ADD_FAILURE)

export const putExpensiveBARequest = expensiveba.createAction(types.EXPENSIVE_BA_PUT_REQUEST)
export const putExpensiveBASuccess = expensiveba.createAction(types.EXPENSIVE_BA_PUT_SUCCESS)
export const putExpensiveBAFailure = expensiveba.createAction(types.EXPENSIVE_BA_PUT_FAILURE)

export const deleteExpensiveBARequest = expensiveba.createAction(types.EXPENSIVE_BA_DELETE_REQUEST)
export const deleteExpensiveBASuccess = expensiveba.createAction(types.EXPENSIVE_BA_DELETE_SUCCESS)
export const deleteExpensiveBAFailure = expensiveba.createAction(types.EXPENSIVE_BA_DELETE_FAILURE)
