import { customs } from "./_duck"

export const CUSTOMS_GET_REQUEST: string = customs.defineType("CUSTOMS_GET_REQUEST")
export const CUSTOMS_GET_SUCCESS: string = customs.defineType("CUSTOMS_GET_SUCCESS")
export const CUSTOMS_GET_FAILURE: string = customs.defineType("CUSTOMS_GET_FAILURE")

export const CUSTOMS_CLEAR: string = customs.defineType("CUSTOMS_CLEAR")
export const CUSTOMS_CLEAR_ALL: string = customs.defineType("CUSTOMS_CLEAR_ALL")

export const CUSTOMS_POST_IMPORT_REQUEST: string = customs.defineType("CUSTOMS_POST_IMPORT_REQUEST")
export const CUSTOMS_POST_IMPORT_SUCCESS: string = customs.defineType("CUSTOMS_POST_IMPORT_SUCCESS")
export const CUSTOMS_POST_IMPORT_FAILURE: string = customs.defineType("CUSTOMS_POST_IMPORT_FAILURE")

export const CUSTOMS_BY_ID_GET_REQUEST: string = customs.defineType("CUSTOMS_BY_ID_GET_REQUEST")
export const CUSTOMS_BY_ID_GET_SUCCESS: string = customs.defineType("CUSTOMS_BY_ID_GET_SUCCESS")
export const CUSTOMS_BY_ID_GET_FAILURE: string = customs.defineType("CUSTOMS_BY_ID_GET_FAILURE")

export const CUSTOMS_PLACES_GET_REQUEST: string = customs.defineType("CUSTOMS_PLACES_GET_REQUEST")
export const CUSTOMS_PLACES_GET_SUCCESS: string = customs.defineType("CUSTOMS_PLACES_GET_SUCCESS")
export const CUSTOMS_PLACES_GET_FAILURE: string = customs.defineType("CUSTOMS_PLACES_GET_FAILURE")
