/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { type ReactElement } from "react"

import {
  Container,
  ContainerColumn,
  ContainerRow,
  ContainerValue,
  ErrorMessage,
  Label,
  Td,
  Th,
  Thead,
  Title,
  Tr
} from "./_styles"

import "react-toastify/dist/ReactToastify.css"
import { useSelector } from "react-redux"
import { quoterSelector } from "../../../../state/modules/Quoter/_selectors"
import { ContainerTypeHapagLloydMOCK, PortMOCK, companyMock } from "../../../../__mock/mock"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { formatNumber } from "../../../../utils/functions"
import { format } from "date-fns"
import { FORMAT_DATE_ONLY } from "../../../../utils/constants"
import { useTranslation } from "react-i18next"
import { TRANSLATION_INDEX_QUOTER } from "../../../../config/translation"
import { useGlobalContext } from "../../../../context/GlobalContext"

const ReportQuote = (): ReactElement => {
  const { t } = useTranslation()
  const quoter = useSelector(quoterSelector)
  const maersk = quoter?.data?.maerskOfferList
  const hapagLlyd = quoter?.data?.hapagLloydOfferList
  const { themeContext } = useGlobalContext()
  const getPortDescription = (code): string => {
    const port = PortMOCK.find(item => item.unLocCode === code)
    return `(${port?.unLocCode}) ${port?.cityName} (${port?.regionName}), ${port?.countryName}`
  }

  const getContainerTypeHapagLloydDescription = (code): string => {
    const value = ContainerTypeHapagLloydMOCK.find(item => item.containerCode === code)
    return `${value?.description}`
  }

  const getValueByKey = (obj, key): string => {
    const value = obj ? obj.offerList?.find(item => item[key].length > 0) : null
    return value ? value[key][0] : ""
  }

  const getHaulageDescription = (code: string): string => {
    if (code === "MERCHANT_HAULAGE" || code === "CY") return "(Merchant)"
    else if (code === "CARRIERS_HAULAGE" || code === "SD") return "(Carrier)"
    else return "(*ERR*)"
  }

  return (
    <Container>
      {maersk.map((item, index) => {
        return (
          <div key={`${index}-maersk`}>
            <ContainerRow>
              <ContainerColumn>
                <ContainerValue themeContext={themeContext}>
                  <Title>Company:</Title>
                  <Label>{companyMock?.find(compa => compa.value === item.request.company)?.label}</Label>
                </ContainerValue>
                <ContainerValue themeContext={themeContext}>
                  <Title>Port Origin:</Title>
                  <Label>{`(${item.request.portFrom?.unLocCode}) ${item.request.portFrom?.findMatch}`}</Label>
                </ContainerValue>
                <ContainerValue themeContext={themeContext}>
                  <Title>{t(TRANSLATION_INDEX_QUOTER.DESTINATION_PORT)}:</Title>
                  <Label>{`(${item.request.portTo?.unLocCode}) ${item.request.portTo?.findMatch}`}</Label>
                </ContainerValue>
              </ContainerColumn>
              <ContainerColumn>
                <ContainerValue themeContext={themeContext}>
                  <Title>Commodity:</Title>
                  <Label>{item.request.commodity}</Label>
                </ContainerValue>
                <ContainerValue themeContext={themeContext}>
                  <Title>Container Type:</Title>
                  <Label>{item.request.containerType}</Label>
                </ContainerValue>
                <ContainerValue themeContext={themeContext}>
                  <Title>Estimated Departure Date:</Title>
                  <Label></Label>
                </ContainerValue>
              </ContainerColumn>
              <ContainerColumn>
                <ContainerValue themeContext={themeContext}>
                  <Title>Container Quantity:</Title>
                  <Label>{item.request.containerQuantity}</Label>
                </ContainerValue>
                <ContainerValue themeContext={themeContext}>
                  <Title>Weight - Cargo Only (Kg):</Title>
                  <Label>{item.request.weight}</Label>
                </ContainerValue>
                <ContainerValue themeContext={themeContext}>
                  <Title></Title>
                  <Label></Label>
                </ContainerValue>
              </ContainerColumn>
            </ContainerRow>

            <table
              style={{
                borderSpacing: "0px",
                borderLeft: GLOBAL_STYLES.BORDER.TYPE_2[themeContext],
                borderTop: GLOBAL_STYLES.BORDER.TYPE_2[themeContext],
                borderRight: GLOBAL_STYLES.BORDER.TYPE_2[themeContext],
                width: "calc(100% - 20px)",
                marginLeft: "10px"
              }}
            >
              <Thead themeContext={themeContext}>
                <Tr>
                  <Th themeContext={themeContext}>Departure Date</Th>
                  <Th themeContext={themeContext}>Arrival Date</Th>
                  <Th themeContext={themeContext}>Transit Days</Th>
                  <Th themeContext={themeContext}>Total Price</Th>
                  {item?.offerList &&
                    item?.offerList
                      ?.find(itemMaers => itemMaers?.detailInfoList && itemMaers?.detailInfoList?.length > 0)
                      ?.detailInfoList?.map(itemMaers => {
                        return (
                          <Th themeContext={themeContext} key={itemMaers.description}>
                            {itemMaers.description}
                          </Th>
                        )
                      })}
                </Tr>
              </Thead>
              <tbody>
                {item?.offerList &&
                  item?.offerList?.map((oflis, index) => {
                    return (
                      <Tr key={`${index}-item?.offerList-1`}>
                        {!oflis.detailInfoList || oflis.detailInfoList.length === 0 ? (
                          <Td
                            themeContext={themeContext}
                            colSpan={30}
                            background={index % 2 === 0 ? GLOBAL_STYLES.BACKGROUND.TYPE_1[themeContext] : ""}
                          >
                            Not Available
                          </Td>
                        ) : (
                          <>
                            <Td
                              themeContext={themeContext}
                              background={index % 2 === 0 ? GLOBAL_STYLES.BACKGROUND.TYPE_1[themeContext] : ""}
                            >
                              {format(new Date(`${oflis?.departureDate}T00:00:00`), FORMAT_DATE_ONLY)}
                            </Td>
                            <Td
                              themeContext={themeContext}
                              background={index % 2 === 0 ? GLOBAL_STYLES.BACKGROUND.TYPE_1[themeContext] : ""}
                            >
                              {oflis?.arrivalDate &&
                                format(new Date(`${oflis?.arrivalDate}T00:00:00`), FORMAT_DATE_ONLY)}
                            </Td>
                            <Td
                              themeContext={themeContext}
                              background={index % 2 === 0 ? GLOBAL_STYLES.BACKGROUND.TYPE_1[themeContext] : ""}
                            >
                              {(oflis?.transitTime / 60 / 24).toFixed(0)}
                            </Td>
                            <Td
                              themeContext={themeContext}
                              background={index % 2 === 0 ? GLOBAL_STYLES.BACKGROUND.TYPE_1[themeContext] : ""}
                            >
                              {oflis?.totalPrice}
                            </Td>
                            {oflis?.detailInfoList &&
                              oflis?.detailInfoList.length > 0 &&
                              oflis?.detailInfoList.map(itemMaers => {
                                return (
                                  <Td
                                    themeContext={themeContext}
                                    background={index % 2 === 0 ? GLOBAL_STYLES.BACKGROUND.TYPE_1[themeContext] : ""}
                                    key={itemMaers.amount}
                                  >
                                    {new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(
                                      itemMaers.amount
                                    )}
                                  </Td>
                                )
                              })}
                          </>
                        )}
                      </Tr>
                    )
                  })}
              </tbody>
            </table>
            {item.hasError && (
              <ErrorMessage themeContext={themeContext}>Operation Failed: {item.errorMessage}</ErrorMessage>
            )}
            {!item.hasError && item.offerList && item.offerList.length === 0 && (
              <ErrorMessage themeContext={themeContext}>No Search Results</ErrorMessage>
            )}
            {!item.hasError && item.hasPartialLoading && (
              <ErrorMessage themeContext={themeContext}>
                Hay más ofertas disponibles que no pudieron ser procesadas, verifique en el sitio de Maersk
              </ErrorMessage>
            )}
            <hr
              style={{
                margin: "25px 0 25px 0",
                borderBottom: `1px solid ${themeContext === "dark" ? "black" : GLOBAL_STYLES.LABEL.TYPE_5.LIGHT}`,
                borderTop: "none"
              }}
            />
          </div>
        )
      })}
      {hapagLlyd.map((item, index) => {
        return (
          <div key={`${index}-hapagLlyd`}>
            <ContainerRow>
              <ContainerColumn>
                <ContainerValue themeContext={themeContext}>
                  <Title>Company:</Title>
                  <Label>Hapag Lloyd</Label>
                </ContainerValue>
                <ContainerValue themeContext={themeContext}>
                  <Title>Port Origin:</Title>
                  <Label>{`${getPortDescription(item.request.startLocation?.locode)}  ${getHaulageDescription(
                    item.request.startLocation.haulage
                  )}`}</Label>
                </ContainerValue>
                <ContainerValue themeContext={themeContext}>
                  <Title>Port Destination:</Title>
                  <Label>{`${getPortDescription(item.request.endLocation?.locode)} ${getHaulageDescription(
                    item.request.startLocation.haulage
                  )}`}</Label>
                </ContainerValue>
              </ContainerColumn>
              <ContainerColumn>
                <ContainerValue themeContext={themeContext}>
                  <Title>Commodity:</Title>
                  <Label>{item.request.cargoDetails.commodityGroup.name}</Label>
                </ContainerValue>
                <ContainerValue themeContext={themeContext}>
                  <Title>Container Type:</Title>
                  <Label>
                    {getContainerTypeHapagLloydDescription(item.request.cargoDetails.container.containerType)}
                  </Label>
                </ContainerValue>
                <ContainerValue themeContext={themeContext}>
                  <Title></Title>
                  <Label></Label>
                </ContainerValue>
              </ContainerColumn>
              <ContainerColumn>
                <ContainerValue themeContext={themeContext}>
                  <Title>Container Quantity:</Title>
                  <Label>{item.request.cargoDetails.container.amount}</Label>
                </ContainerValue>
                <ContainerValue themeContext={themeContext}>
                  <Title>Weight Per Container:</Title>
                  <Label>{formatNumber(item.request.cargoDetails.container.cargoWeightPerContainer)}</Label>
                </ContainerValue>
                <ContainerValue themeContext={themeContext}>
                  <Title></Title>
                  <Label></Label>
                </ContainerValue>
              </ContainerColumn>
            </ContainerRow>
            <ContainerRow>
              <ContainerColumn>
                <ContainerValue themeContext={themeContext}>
                  <Title>Additionals:</Title>
                  <Label>{`${getValueByKey(item, "additionalsList")}`}</Label>
                </ContainerValue>
                <ContainerValue themeContext={themeContext}>
                  <Title>Included Charges:</Title>
                  <Label>{`${getValueByKey(item, "includedChargesList")}`}</Label>
                </ContainerValue>
                <ContainerValue themeContext={themeContext}>
                  <Title>Excluded Charges:</Title>
                  <Label>{`${getValueByKey(item, "excludedChargesList")}`}</Label>
                </ContainerValue>
                <ContainerValue themeContext={themeContext}>
                  <Title>Notes:</Title>
                  <Label>{`${getValueByKey(item, "notesList")}`}</Label>
                </ContainerValue>
              </ContainerColumn>
            </ContainerRow>
            <table
              style={{
                borderSpacing: "0px",
                borderLeft: GLOBAL_STYLES.BORDER.TYPE_2[themeContext],
                borderTop: GLOBAL_STYLES.BORDER.TYPE_2[themeContext],
                borderRight: GLOBAL_STYLES.BORDER.TYPE_2[themeContext],
                width: "calc(100% - 20px)",
                marginLeft: "10px",
                marginTop: "10px"
              }}
            >
              <Thead themeContext={themeContext}>
                <Tr key={"hll"}>
                  <Th themeContext={themeContext}>Departure Date</Th>
                  <Th themeContext={themeContext}>Arrival Date</Th>
                  <Th themeContext={themeContext}>Transit Days</Th>
                  <Th themeContext={themeContext}>Transshipment</Th>
                  <>
                    {item?.offerList &&
                      item?.offerList
                        ?.find(itemMaers => itemMaers?.detailInfoList && itemMaers?.detailInfoList?.length > 0)
                        ?.detailInfoList?.map(itemMaers => {
                          return (
                            <Th themeContext={themeContext} key={itemMaers.description}>
                              {itemMaers.description}
                            </Th>
                          )
                        })}
                  </>
                </Tr>
              </Thead>
              <tbody>
                {item?.offerList &&
                  item?.offerList?.map((oflis, index) => {
                    return (
                      <Tr key={`${index}-item?.offerList-2`}>
                        <Td
                          themeContext={themeContext}
                          background={index % 2 === 0 ? GLOBAL_STYLES.BACKGROUND.TYPE_1[themeContext] : ""}
                        >
                          {format(new Date(`${oflis?.departureDate}T00:00:00`), FORMAT_DATE_ONLY)}
                        </Td>
                        <Td
                          themeContext={themeContext}
                          background={index % 2 === 0 ? GLOBAL_STYLES.BACKGROUND.TYPE_1[themeContext] : ""}
                        >
                          {oflis?.arrivalDate && format(new Date(`${oflis?.arrivalDate}T00:00:00`), FORMAT_DATE_ONLY)}
                        </Td>
                        <Td
                          themeContext={themeContext}
                          background={index % 2 === 0 ? GLOBAL_STYLES.BACKGROUND.TYPE_1[themeContext] : ""}
                        >
                          {(oflis?.transitTime).toFixed(0)}
                        </Td>
                        <Td
                          themeContext={themeContext}
                          background={index % 2 === 0 ? GLOBAL_STYLES.BACKGROUND.TYPE_1[themeContext] : ""}
                        >
                          {oflis?.transshipmentQty}
                        </Td>
                        {!oflis.detailInfoList || oflis.detailInfoList.length === 0 ? (
                          <Td
                            themeContext={themeContext}
                            colSpan={30}
                            background={index % 2 === 0 ? GLOBAL_STYLES.BACKGROUND.TYPE_1[themeContext] : ""}
                          >
                            Not Available
                          </Td>
                        ) : (
                          <>
                            {oflis?.detailInfoList &&
                              oflis?.detailInfoList.length > 0 &&
                              oflis?.detailInfoList.map((itemMaers, indexDetail2) => {
                                return (
                                  <Td
                                    themeContext={themeContext}
                                    background={index % 2 === 0 ? GLOBAL_STYLES.BACKGROUND.TYPE_1[themeContext] : ""}
                                    key={indexDetail2}
                                  >
                                    {formatNumber(itemMaers.amount)}
                                  </Td>
                                )
                              })}
                          </>
                        )}
                      </Tr>
                    )
                  })}
              </tbody>
            </table>
            {item.hasError && (
              <ErrorMessage themeContext={themeContext}>Operation Failed: {item.errorMessage}</ErrorMessage>
            )}
            {!item.hasError && item.offerList && item.offerList.length === 0 && (
              <ErrorMessage themeContext={themeContext}>No Search Results</ErrorMessage>
            )}
            <hr
              style={{
                margin: "25px 0 15px 0",
                borderBottom: `1px solid ${themeContext === "dark" ? "black" : GLOBAL_STYLES.LABEL.TYPE_5.LIGHT}`,
                borderTop: "none"
              }}
            />
          </div>
        )
      })}
    </Container>
  )
}

export default ReportQuote
