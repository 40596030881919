import styled from "styled-components"
import { type IStyle } from "../../../../interfaces/IStyle"

export const ContainerIcons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const ContainerSerch = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 15px;
`

export const Group = styled.div<IStyle>`
  width: calc(100% - 30px);
  display: flex;
  justify-content: flex-start;
  align-items: ${({ alignItems }) => alignItems ?? "flex-start"};
  margin-top: 10px;
  @media (max-width: 1020px) {
    flex-direction: column;
    width: 100%;
    margin-top: 0;
  }
`

export const ContainerTable = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const ContainerButtom = styled.div<IStyle>`
  width: ${({ width }) => width};
  margin-left: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  @media (max-width: 900px) {
    margin-left: 0;
    margin-top: 5%;
    width: 100%;
  }
`
