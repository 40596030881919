import React from "react"
import { ImCheckmark } from "react-icons/im"
import { type Props } from "./_types"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
const IconCheck: React.FC<Props> = ({
  size = 15,
  color = "#fff",
  isCheck = false,
  onclick = () => {}
}): React.ReactElement => {
  return (
    <ImCheckmark
      size={size}
      color={color}
      style={{
        padding: "1px",
        border: `${isCheck ? "solid 1px transparent" : "solid 1px #a8a8a8"}`,
        borderRadius: "2px",
        cursor: "pointer",
        background: isCheck ? GLOBAL_STYLES.BUTTON.PRIMARY_BG_COLOR.LIGHT : "#fff"
      }}
      onClick={onclick}
    />
  )
}

export default IconCheck
