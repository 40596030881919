/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import ContainerPage from "../../components/ContainerPage"
import {
  TRANSLATION_INDEX_EVENT,
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_PERMISSION,
  TRANSLATION_INDEX_REPORT
} from "../../config/translation"
import DataTable from "react-data-table-component"

import { ContainerTable } from "./_styles"
import { customStylesContext } from "../../styles/_styleDatatable"
import { useDispatch, useSelector } from "react-redux"
import SearchUserClientApp from "./forms/search/index"

import { NoData } from "../../components/NoData"
import { useGlobalContext } from "../../context/GlobalContext"
import "../../styles/styles.css"
import { useMobile } from "../../hook/useMobile"
import { getReportClearAll, getReportCustomerRequest } from "../../state/modules/reportCommercial/actions"
import { reportCommercialSelector } from "../../state/modules/reportCommercial/_selectors"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { ToolTipRow } from "../CustomerProspect/_styles"
import { type IEvent } from "../../interfaces/IEvent"
import { EVENT_TYPE } from "../Event/constants"
import { format } from "date-fns"
import { FORMAT_DATE_ONLY } from "../../utils/constants"
import IconExcel from "../../components/Icons/IconExcel"
import ContainerLabelTwoRow from "../../components/ContainerLabelTwoRow"
import { type IReportCustomer } from "../../interfaces/IReportCustomer"

const ReportCustomerPage = (): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useMobile()
  const dispatch = useDispatch()
  const reportCustomer = useSelector(reportCommercialSelector)

  const [dataFiltered, setDataFiltered] = React.useState<IReportCustomer[]>()
  const [filters, setFilters] = React.useState<any>()
  const [clearFilter, setClearFilter] = React.useState(false)
  const [searchData, setSearchData] = React.useState(false)
  const { setLoadingContext, themeContext } = useGlobalContext()
  const { loadingContext } = useGlobalContext()

  const paginationComponentOptions = {
    rowsPerPageText: t(TRANSLATION_INDEX_GLOBAL.ROWS_PER_PAGE),
    rangeSeparatorText: t(TRANSLATION_INDEX_GLOBAL.OF),
    selectAllRowsItem: true,
    selectAllRowsItemText: t(TRANSLATION_INDEX_GLOBAL.ALL)
  }

  const columns = [
    {
      name: t(TRANSLATION_INDEX_REPORT.COUNT_EVENT),
      selector: (row: IReportCustomer) => row?.cliente?.cantidadEventos,
      width: isMobile ? "60px" : "10%",
      center: true,
      sortable: true
    },
    {
      name: "CUIT",
      selector: (row: IReportCustomer) => `${row.cliente.cuit}`,
      width: isMobile ? "200px" : "20%",
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_EVENT.CUSTOMER_PROSPECT),
      selector: (row: IReportCustomer) => `${row.cliente.razonSocial}`,
      width: isMobile ? "300px" : "60%",
      sortable: true
    }
  ]

  const columns2 = [
    {
      name: t(TRANSLATION_INDEX_EVENT.DATE),
      selector: (row: IEvent) => row.fecha,
      sortable: true,
      width: isMobile ? "150px" : `10%`,
      center: true,
      cell: (row: IEvent) => {
        return <div>{row?.fecha != null ? format(new Date(row?.fecha), FORMAT_DATE_ONLY) : "-"}</div>
      }
    },
    {
      name: t(TRANSLATION_INDEX_EVENT.EVENT_TYPE),
      selector: (row: IEvent) =>
        t(
          EVENT_TYPE.find(itemEvent => itemEvent.idTipoEvento === row.tipoEvento.idTipoEvento)?.descripcion ?? "-"
        ).toUpperCase() ?? "-",
      sortable: true,
      width: isMobile ? "200px" : `15%`
    },
    {
      name: t(TRANSLATION_INDEX_EVENT.CUSTOMER_PROSPECT),
      selector: (row: IEvent) => row.cliente.razonSocial,
      sortable: true,
      width: isMobile ? "400px" : `calc(30%)`,
      cell: (row: IEvent) => {
        return <ToolTipRow>{row.cliente.razonSocial}</ToolTipRow>
      }
    },

    {
      name: t(TRANSLATION_INDEX_EVENT.CONTACT),
      selector: (row: IEvent) => (row?.contacto ? `${row?.contacto?.apellido} ${row?.contacto?.nombre}` : ""),
      sortable: true,
      width: isMobile ? "250px" : `15%`
    },
    {
      name: t(TRANSLATION_INDEX_EVENT.COMMENT),
      selector: (row: IEvent) => row.comentario,
      sortable: true,
      width: isMobile ? "150px" : `30%`,
      cell: (row: IEvent) => {
        return <ContainerLabelTwoRow label={row.comentario} />
      }
    }
  ]

  const ExpandedComponent = (rowData): any => {
    return (
      <div
        style={{
          width: "calc(100% - 10px)",
          padding: "5px",
          background: GLOBAL_STYLES.BACKGROUND.TYPE_1[themeContext],
          boxShadow: GLOBAL_STYLES.BOX_SHADOW.TYPE_1[themeContext]
        }}
      >
        <ContainerPage title={``} actions={[]}>
          <ContainerTable themeContext={themeContext} opacity={1}>
            <DataTable
              data={rowData.data.eventos ?? []}
              theme="solarized"
              noDataComponent={<NoData />}
              columns={columns2}
              dense
              pagination
              paginationComponentOptions={paginationComponentOptions}
              striped
              customStyles={customStylesContext(themeContext)}
              defaultSortFieldId={0}
            />
          </ContainerTable>
        </ContainerPage>
      </div>
    )
  }

  const handleExcel = (): void => {
    let queryParam = ""
    if (filters.fechaDesde) queryParam = queryParam + `fechaDesde=${filters?.fechaDesde}&`
    if (filters.fechaHasta) queryParam = queryParam + `fechaHasta=${filters?.fechaHasta}&`
    if (filters.clientes) queryParam = queryParam + `idsClientes=${filters?.clientes.join(",")}&`

    window.open(`${process.env.REACT_APP_API_URL}/api/backoffice/v1/reportes/eventosPorCliente/exportar?${queryParam}`)
  }

  React.useEffect(() => {
    setLoadingContext(reportCustomer?.loading)
    setDataFiltered(reportCustomer?.reportCustomer)
  }, [reportCustomer])

  React.useEffect(() => {
    if (searchData) {
      setLoadingContext(true)
      dispatch(getReportCustomerRequest(filters))
      setSearchData(false)
    }
  }, [searchData])

  React.useEffect(() => {
    if (clearFilter) {
      setDataFiltered([])
      dispatch(getReportClearAll())
      setClearFilter(false)
    }
  }, [clearFilter])

  React.useEffect(() => {
    return () => dispatch(getReportClearAll())
  }, [])

  return (
    <>
      <ContainerPage
        title={`${t(TRANSLATION_INDEX_PERMISSION.REPORTE_EVENTOS_X_CLIENTE)}`}
        actions={
          reportCustomer?.reportCustomer?.length > 0
            ? [
                {
                  label: t(TRANSLATION_INDEX_GLOBAL.EXPORT),
                  icon: <IconExcel size={13} />,
                  permission: "*",
                  click: handleExcel
                }
              ]
            : []
        }
      >
        <>
          <ContainerTable themeContext={themeContext} opacity={1}>
            <SearchUserClientApp
              data={reportCustomer?.data}
              setDataFiltered={setDataFiltered}
              setFilters={setFilters}
              setSearchData={setSearchData}
              setClearFilter={setClearFilter}
            />
            <DataTable
              data={dataFiltered ?? []}
              theme="solarized"
              noDataComponent={
                <NoData
                  label={
                    loadingContext
                      ? ""
                      : filters
                      ? TRANSLATION_INDEX_GLOBAL.NO_DATA_2
                      : TRANSLATION_INDEX_GLOBAL.USE_SEARCH_TO_GET_RESULTS
                  }
                />
              }
              columns={columns}
              dense
              pagination
              expandableRows
              expandableRowDisabled={row => row?.eventos.length === 0}
              expandableRowsComponent={ExpandedComponent}
              paginationComponentOptions={paginationComponentOptions}
              striped
              customStyles={customStylesContext(themeContext)}
            />
          </ContainerTable>
        </>
      </ContainerPage>
    </>
  )
}

export default ReportCustomerPage
