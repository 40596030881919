import styled from "styled-components"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { type IStyle } from "../../interfaces/IStyle"

export const ContainerIcons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const ContainerSerch = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
`

export const ContainerTable = styled.div<IStyle>`
  width: calc(100% - 20px);
  opacity: ${({ opacity }) => opacity};
  display: flex;
  background-color: ${({ themeContext }) => GLOBAL_STYLES.BUTTON.TAB_BG_IMAGE_ACTIVE[themeContext]};
  border: ${({ themeContext }) => GLOBAL_STYLES.BORDER.TYPE_2[themeContext]};
  justify-content: center;
  padding: 10px 10px;
  flex-direction: column;
  transition: opacity 3s;
  margin-top: -1px;

  margin-left: 2px;
  z-index: 10;
  border-radius: 0 5px 5px 5px;
`

export const Icon = styled.div<IStyle>`
  font-size: 0.8rem;
  background: ${({ themeContext }) => GLOBAL_STYLES.BACKGROUND.TYPE_2[themeContext]};
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ themeContext }) => (themeContext === "DARK" ? "#fff" : "#fff")};
  border-radius: 3px;
  margin: 0 8px;
  cursor: pointer;
  :hover {
    box-shadow: ${({ themeContext }) => GLOBAL_STYLES.BOX_SHADOW.TYPE_3[themeContext]};
  }
`

export const LabelAction = styled.div`
  margin-left: 5px;
`

export const ContainerTab = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
`

export const Tab = styled.div<IStyle>`
  background-color: ${({ background }) => background};
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_2[themeContext]};
  border: ${({ themeContext }) => GLOBAL_STYLES.BORDER.TYPE_2[themeContext]};
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  z-index: 12;
  cursor: pointer;
  border-radius: 5px 5px 0 0;
`

export const ContainerOrganizations = styled.div`
  padding: 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 140%;
`
