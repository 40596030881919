/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { type ReactElement } from "react"

import { useTranslation } from "react-i18next"

import {
  TRANSLATION_INDEX_CUSTOMER_PROSPECT,
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_QUOTER
} from "../../../../config/translation"

import { ACTIONS } from "../../../../utils/constants"

import "react-toastify/dist/ReactToastify.css"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"

import { withFormik } from "formik"
import { addUpdateScheme, deleteScheme } from "../../_schema"
import { useGlobalContext } from "../../../../context/GlobalContext"
import { Container, ContainerGroup, ContainerMessage, Group } from "./_styles"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import SearchUserClientApp from "./components/search/index"
import { useDispatch, useSelector } from "react-redux"
import { customerSelector } from "../../../../state/modules/customer/_selectors"
import { getCustomerClearAll, getCustomerRequest } from "../../../../state/modules/customer/actions"
import { type ICustomer, type ICustomerFilter } from "../../../../interfaces/ICustomer"
import DataTable from "react-data-table-component"
import { NoData } from "../../../../components/NoData"
import { customStylesContext } from "../../../../styles/_styleDatatable"
import IconCheck from "../../../../components/Icons/IconCheck"
import IconTrash from "../../../../components/Icons/IconTrash"
import { ToolTipRow } from "../../../CustomerProspect/_styles"
import { useMobile } from "../../../../hook/useMobile"
const Step1 = ({
  data,
  setDataFiltered,
  isUserPending = false,
  handleOpenModalSelectedCustomer,
  showSelectedCustomer,
  loading = false,
  action,
  closeModal = () => {},
  accept = () => {},
  setFieldValue,
  values,
  touched,
  errors,
  setSubmitting,
  isSubmitting,
  handleChange,
  handleBlur,
  handleSubmit
}: IFormListProps): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useMobile()
  const dispatch = useDispatch()
  const { themeContext, loadingContext } = useGlobalContext()
  const [filters, setFilters] = React.useState<any>(null)
  const [resetFilter, setResetFilter] = React.useState<boolean>(false)
  const customer = useSelector(customerSelector)
  const [firstLoad, setFirstLoad] = React.useState(true)

  const [selectedAll, setSelectedAll] = React.useState<boolean>(false)
  const [selectedAllCheck, setSelectedAllCheck] = React.useState<boolean>(false)
  const getRequest = (): ICustomerFilter => {
    return {
      ordenamiento: { columna: 1, direccion: "asc" },
      paginado: { pagina: -1, registrosPorPagina: 10 },
      filtro: {
        cuit: filters?.cuit ?? null,
        destinos: filters?.destinos
          ? filters?.destinos.map(item => {
              return item.value
            })
          : null,
        procedencias: filters?.procedencias
          ? filters?.procedencias.map(item => {
              return item.value
            })
          : null,
        razonSocial: filters?.companyName ?? null,
        idTipoCliente: filters?.clientType?.value ?? null,
        comercialAsignado: filters?.assignedCommercial?.value ?? null,
        esCargaCIF: filters?.cifCargo?.value ?? null,
        esImportador: filters?.isImporter?.value ?? null,
        esExportador: filters?.isExporter?.value ?? null,
        modoTransporte: filters?.modoTransporte?.value ?? null,
        paginaOrigen: "envioCotizacion"
      }
    }
  }
  const getData = (): void => {
    const request = getRequest()
    dispatch(getCustomerRequest(request))
  }

  const paginationComponentOptions = {
    rowsPerPageText: t(TRANSLATION_INDEX_GLOBAL.ROWS_PER_PAGE),
    rangeSeparatorText: t(TRANSLATION_INDEX_GLOBAL.OF),
    selectAllRowsItem: false,
    selectAllRowsItemText: t(TRANSLATION_INDEX_GLOBAL.ALL)
  }

  const validateAllRowSelected = (): boolean => {
    let isAllChecked = true
    customer.data?.clientes.forEach(item => {
      if (
        !data
          .map(dat => {
            return dat.idCliente
          })
          .includes(item.idCliente)
      )
        isAllChecked = false
    })
    return isAllChecked
  }

  const columns = [
    {
      id: "0",
      name: (
        <IconCheck
          isCheck={validateAllRowSelected()}
          size={10}
          onclick={() => {
            setSelectedAllCheck(selectedAll !== selectedAllCheck ? !selectedAll : !selectedAllCheck)
            setFirstLoad(false)

            setSelectedAll(selectedAll !== selectedAllCheck ? !selectedAll : !selectedAllCheck)
          }}
        />
      ),
      selector: (row: ICustomer) => row.razonSocial.toUpperCase(),
      cell: (row: ICustomer) => {
        return (
          <IconCheck
            isCheck={data.some(item => item.idCliente === row.idCliente)}
            size={10}
            onclick={() => {
              handleChangeSelected(row)
            }}
          />
        )
      },
      width: `50px`,
      center: true
    },
    {
      id: "1",
      name: t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.COMPANY_NAME),
      selector: (row: ICustomer) => row.razonSocial.toUpperCase(),
      cell: (row: ICustomer) => {
        return (
          <ToolTipRow
            onClick={() => {
              handleChangeSelected(row)
            }}
          >
            {row.razonSocial.toUpperCase()}
          </ToolTipRow>
        )
      },
      width: `calc(70% - 50px)`,
      sortable: true
    },
    {
      id: "2",
      name: "CUIT",
      selector: (row: ICustomer) => row.cuit,
      width: "30%",
      center: true,
      sortable: true
    }
  ]

  const columnsSelected = [
    {
      id: "0",
      name: (
        <IconTrash
          size={20}
          color={GLOBAL_STYLES.BUTTON.DANGER_BG[themeContext]}
          onclick={() => {
            if (setDataFiltered) setDataFiltered([])
          }}
        />
      ),
      selector: (row: ICustomer) => row.razonSocial.toUpperCase(),
      cell: (row: ICustomer) => {
        return (
          <IconTrash
            size={20}
            color={GLOBAL_STYLES.BUTTON.DANGER_BG[themeContext]}
            onclick={() => {
              handleChangeDelete(row)
            }}
          />
        )
      },
      width: `50px`,
      center: true
    },
    {
      id: "1",
      name: t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.COMPANY_NAME),
      selector: (row: ICustomer) => row.razonSocial.toUpperCase(),
      cell: (row: ICustomer) => {
        return <ToolTipRow>{row.razonSocial.toUpperCase()}</ToolTipRow>
      },
      width: `calc(70% - 50px)`,
      sortable: true
    },
    {
      id: "2",
      name: "CUIT",
      selector: (row: ICustomer) => row.cuit,
      width: "30%",
      center: true,
      sortable: true
    }
  ]

  const handleChangeSelectedAll = (): void => {
    if (setDataFiltered) {
      if (selectedAll) {
        const itemFiltered = customer.data?.clientes
          ? customer.data?.clientes.filter(
              item =>
                !data
                  .map(cus => {
                    return cus.idCliente
                  })
                  .includes(item.idCliente)
            )
          : []
        const newList = data.concat(itemFiltered)
        setDataFiltered(newList)
      } else {
        const itemFiltered = data.filter(
          item =>
            !customer.data?.clientes
              .map(cus => {
                return cus.idCliente
              })
              .includes(item.idCliente)
        )
        setDataFiltered(itemFiltered)
      }
    }
  }

  const handleChangeDelete = (selectedRow: ICustomer): void => {
    setFirstLoad(false)
    const newData = data.filter(item => item.idCliente !== selectedRow.idCliente)
    if (setDataFiltered) setDataFiltered(newData)
    setSelectedAllCheck(false)
  }

  const handleChangeSelected = (selectedRow: ICustomer): void => {
    setFirstLoad(false)
    if (setDataFiltered) {
      if (data.some(item => item.idCliente === selectedRow.idCliente)) {
        const newData = data.filter(item => item.idCliente !== selectedRow.idCliente)
        setDataFiltered(newData)
        setSelectedAllCheck(false)
      } else {
        const newData = [...data, selectedRow]
        setDataFiltered(newData)
        let selectAllRows = 0
        customer.data?.clientes.forEach(item => {
          if (
            newData
              .map(selec => {
                return selec.idCliente
              })
              .includes(item.idCliente)
          )
            selectAllRows = selectAllRows + 1
        })
        if (selectAllRows === customer.data?.clientes.length) {
          setSelectedAll(true)
          setSelectedAllCheck(true)
        } else {
          setSelectedAll(false)
          setSelectedAllCheck(false)
        }
      }
    }
  }

  React.useEffect(() => {
    if (filters) {
      getData()
    }
  }, [filters])

  React.useEffect(() => {
    if (resetFilter) {
      setResetFilter(false)
      dispatch(getCustomerClearAll())
    }
  }, [resetFilter])

  React.useEffect(() => {
    if (!firstLoad) handleChangeSelectedAll()
  }, [selectedAll])

  return (
    <Container onSubmit={handleSubmit}>
      {action === ACTIONS.DELETE.VALUE ? (
        <ContainerMessage themeContext={themeContext}>{t(TRANSLATION_INDEX_GLOBAL.CONFIRM_DELETE)}</ContainerMessage>
      ) : (
        <ContainerGroup themeContext={themeContext}>
          <Group>
            <SearchUserClientApp
              data={customer.data?.clientes}
              setFilters={setFilters}
              resetFilter={resetFilter}
              setClearFilter={setResetFilter}
            />
          </Group>
          <Group>
            <div style={{ width: isMobile ? "100%" : "50%", display: "flex", flexDirection: "column" }}>
              <div style={{ textAlign: "center", margin: "15px 0" }}>{t(TRANSLATION_INDEX_QUOTER.FILTER_RESULT)}</div>
              <DataTable
                data={customer.data?.clientes ?? []}
                theme="solarized"
                noDataComponent={
                  <NoData
                    label={
                      loadingContext
                        ? ""
                        : filters
                        ? TRANSLATION_INDEX_GLOBAL.NO_DATA_2
                        : TRANSLATION_INDEX_GLOBAL.USE_SEARCH_TO_GET_RESULTS
                    }
                  />
                }
                columns={columns}
                dense
                pagination
                paginationComponentOptions={paginationComponentOptions}
                paginationRowsPerPageOptions={[10, 25, 50, 100, 500]}
                striped
                customStyles={customStylesContext(themeContext)}
                paginationPerPage={10}
                paginationDefaultPage={1}
              />
            </div>
            <div
              style={{
                marginLeft: isMobile ? "" : "10%",
                width: isMobile ? "100%" : "50%",
                display: "flex",
                paddingTop: isMobile ? "15px" : "",
                flexDirection: "column"
              }}
            >
              <div style={{ textAlign: "center", margin: "15px 0" }}>
                {t(TRANSLATION_INDEX_QUOTER.SELECTED_CUSTOMER)}
              </div>
              <DataTable
                data={data}
                theme="solarized"
                noDataComponent={
                  <NoData label={loadingContext ? "" : t(TRANSLATION_INDEX_QUOTER.NO_CUSTOMER_SELECTED) ?? ""} />
                }
                columns={columnsSelected}
                dense
                pagination
                paginationComponentOptions={paginationComponentOptions}
                paginationRowsPerPageOptions={[10, 25, 50, 100, 500]}
                striped
                customStyles={customStylesContext(themeContext)}
                paginationDefaultPage={1}
                // paginationResetDefaultPage={resetPage}
              />
            </div>
          </Group>
        </ContainerGroup>
      )}
    </Container>
  )
}

export default withFormik<IFormListProps, any>({
  mapPropsToValues: ({ data }): any => ({}),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: ({ action }) => (action === ACTIONS.DELETE.VALUE ? deleteScheme : addUpdateScheme),
  handleSubmit: (values, { setSubmitting, props: { accept } }): any => {
    if (accept != null) accept(values)
    setSubmitting(false)
  }
})(Step1)
