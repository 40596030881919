/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { type ReactElement } from "react"
import InputCustom from "../../../../components/InputCustom"

import ButtonCustom from "../../../../components/ButtonCustom"

import { useTranslation } from "react-i18next"

import {
  TRANSLATION_INDEX_CALENDAR,
  TRANSLATION_INDEX_EVENT,
  TRANSLATION_INDEX_GLOBAL
} from "../../../../config/translation"
import { Container, Group, GroupButtom, ContainerGroup, ContainerMessage } from "./_styles"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { ACTIONS, FORMAT_DATE_ONLY, PERMISSION } from "../../../../utils/constants"

import "react-toastify/dist/ReactToastify.css"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"
import { withFormik } from "formik"
import { addUpdateScheme, deleteScheme } from "../../_schema"
import { useGlobalContext } from "../../../../context/GlobalContext"
import { DatePickerCustom } from "../../../../components/DatePickerCustom/DatePickerCustom/Index"
import { useMobile } from "../../../../hook/useMobile"
import { useResize } from "../../../../hook/useResize"
import SelectAsyncCustom from "../../../../components/SelectAsyncCustom"
import CustomerService from "../../../../services/CustomerService"
import SelectCustom from "../../../../components/SelectCustom"
import { useDispatch, useSelector } from "react-redux"
import { eventSelector } from "../../../../state/modules/event/_selectors"
import { clearContactEvents, getContactEventRequest } from "../../../../state/modules/event/actions"
import { getAuth } from "../../../../utils/functions"
import { format } from "date-fns"

const FormaddUpdate = ({
  data,
  isUserPending = false,
  loading = false,
  action,
  setAction,
  closeModal = () => {},
  accept = () => {},
  setFieldValue,
  values,
  touched,
  validateForm,
  errors,
  setSubmitting,
  isSubmitting,
  handleChange,
  handleBlur,
  handleSubmit
}: IFormListProps): ReactElement => {
  const { t } = useTranslation()
  const canEdit =
    (getAuth(PERMISSION.RECORDATORIOS_MODIFICAR)[0] && action === ACTIONS.EDIT.VALUE) ||
    getAuth(PERMISSION.RECORDATORIOS_CREAR)[0]
  const { themeContext } = useGlobalContext()
  const events = useSelector(eventSelector)
  const dispatch = useDispatch()
  const screenSize = useResize()
  const [errorDate, setErrorDate] = React.useState(false)
  const [errorLabel, setErrorLabel] = React.useState("")

  const [fromDate, setFromDate] = React.useState<string | Date | null>(
    data?.fechaHoraInicio ? new Date(data?.fechaHoraInicio) : null
  )
  const [toDate, setToDate] = React.useState<string | Date | null>(
    data?.fechaHoraFin ? new Date(data?.fechaHoraFin) : null
  )
  const [isFirst, setIsFirst] = React.useState(true)
  const isMobile = useMobile()
  const promiseOptions = async (inputValue: string): Promise<any> => {
    const response = await new Promise(resolve => {
      if (inputValue.length > 2) {
        resolve(CustomerService(inputValue))
      } else {
        resolve([{ value: "-1", label: t(TRANSLATION_INDEX_GLOBAL.ENTER_THREE_CHARACTER), isDisabled: true }])
      }
    })
    return response
  }

  React.useEffect(() => {
    dispatch(clearContactEvents())
    if (values?.cliente?.value) dispatch(getContactEventRequest(values?.cliente?.value))
    if (!isFirst && setFieldValue) setFieldValue("contacto", null)
  }, [values?.cliente])

  React.useEffect(() => {
    if (events.contactByCustomer.length > 0) setIsFirst(false)
  }, [events.contactByCustomer])

  React.useEffect(() => {
    if (setFieldValue) {
      if (fromDate && toDate && touched.comentario === true) {
        if (
          (fromDate && typeof fromDate === "object" ? fromDate?.getTime() : 0) >
          (toDate && typeof toDate === "object" ? toDate?.getTime() : 0)
        ) {
          setErrorDate(true)
          setErrorLabel(TRANSLATION_INDEX_GLOBAL.ERROR_DATE_SOME)
        } else if (
          typeof fromDate === "object" &&
          typeof toDate === "object" &&
          format(new Date(fromDate?.getTime()), FORMAT_DATE_ONLY) !==
            format(new Date(toDate?.getTime()), FORMAT_DATE_ONLY)
        ) {
          setErrorDate(true)
          setErrorLabel(TRANSLATION_INDEX_GLOBAL.ERROR_DATE_DIFFERENTE_DAY)
        } else setErrorDate(false)
      } else setErrorDate(false)
    }
  }, [fromDate, toDate, touched.comentario])
  React.useEffect(() => {
    if (setFieldValue) {
      setFieldValue("fechaHoraInicio", fromDate)
    }
  }, [fromDate])

  React.useEffect(() => {
    if (setFieldValue) {
      setFieldValue("fechaHoraFin", toDate)
    }
  }, [toDate])
  React.useEffect(() => {
    return () => {
      dispatch(clearContactEvents())
    }
  }, [])
  React.useEffect(() => {
    if (touched.fecha === true && Object.keys(errors ?? {}).length > 0) validateForm()
  }, [values])

  return (
    <Container onSubmit={handleSubmit}>
      {action === ACTIONS.DELETE.VALUE ? (
        <ContainerMessage themeContext={themeContext}>{t(TRANSLATION_INDEX_GLOBAL.CONFIRM_DELETE)}</ContainerMessage>
      ) : (
        <ContainerGroup themeContext={themeContext}>
          <Group>
            <DatePickerCustom
              value={fromDate}
              placeHolder={t(TRANSLATION_INDEX_CALENDAR.DATE_FROM) ?? ""}
              label={`${t(TRANSLATION_INDEX_CALENDAR.DATE_FROM)} (*)` ?? ""}
              setValue={setFromDate}
              name="fecha"
              disabled={!canEdit}
              showTimeSelect
              errors={errors?.fechaHoraInicio}
              validError
              dateFormat="dd/MM/yyyy HH:mm"
              width={isMobile ? "100%" : `${screenSize / 2}px`}
              excludeDateIntervals={[{ start: 1610288000000, end: new Date().getTime() - 86400000 }]}
            />
            <DatePickerCustom
              value={toDate}
              placeHolder={t(TRANSLATION_INDEX_CALENDAR.DATE_TO) ?? ""}
              label={`${t(TRANSLATION_INDEX_CALENDAR.DATE_TO)} (*)` ?? ""}
              setValue={setToDate}
              errors={errorDate ? errorLabel : errors?.fechaHoraFin}
              name="toDate"
              disabled={!canEdit}
              validError
              showTimeSelect
              dateFormat="dd/MM/yyyy HH:mm"
              marginLeft
              excludeDateIntervals={[{ start: 1610288000000, end: new Date().getTime() - 86400000 }]}
              width={isMobile ? "100%" : `${screenSize / 2}px`}
            />
          </Group>
          <Group>
            {setFieldValue != null && (
              <SelectAsyncCustom
                listTop={false}
                width={isMobile ? "100%" : `50%`}
                label={`${t(TRANSLATION_INDEX_EVENT.CUSTOMER_PROSPECT)} (*)` ?? ""}
                value={values?.cliente}
                handleChange={setFieldValue}
                name="cliente"
                validError
                placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
                promiseOptions={promiseOptions}
                isDisabled={!canEdit}
                errors={errors?.cliente}
              />
            )}
            {setFieldValue != null && (
              <SelectCustom
                label={t(TRANSLATION_INDEX_EVENT.CONTACT) ?? ""}
                value={values?.contacto ?? null}
                handleChange={setFieldValue}
                name="contacto"
                isDisabled={!canEdit}
                placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
                width={isMobile ? "100%" : `50%`}
                marginLeft
                options={events.contactByCustomer.map(item => {
                  return {
                    value: item.idClienteContacto,
                    label: `${item.apellido.toUpperCase()} ${item.nombre.toUpperCase()}`
                  }
                })}
              />
            )}
          </Group>
          <Group>
            <InputCustom
              label={t(TRANSLATION_INDEX_CALENDAR.COMMENTS) ?? ""}
              value={values?.comentario ?? ""}
              handleChange={handleChange}
              name="comentario"
              isTextArea
              height="80px"
              width={isMobile ? "100%" : `100%`}
              disabled={!canEdit}
              errors={errors?.comentario}
              touched={touched?.comentario}
            />
          </Group>
        </ContainerGroup>
      )}
      <GroupButtom themeContext={themeContext}>
        <ButtonCustom
          onClick={() => {
            action === ACTIONS.DELETE.VALUE ? setAction(ACTIONS.EDIT.VALUE) : closeModal()
          }}
          width="80px"
          type="button"
          label={t("global.cancel") ?? ""}
          background={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE[themeContext]}
          backgroundHover={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE_HOVER[themeContext]}
        />
        {getAuth(PERMISSION.RECORDATORIOS_MODIFICAR)[0] && (
          <ButtonCustom
            width="80px"
            type="submit"
            label={t(TRANSLATION_INDEX_GLOBAL.CONFIRM) ?? ""}
            background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
            backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]}
            marginLeft
          />
        )}
      </GroupButtom>
    </Container>
  )
}

export default withFormik<IFormListProps, any>({
  mapPropsToValues: ({ data }): any => ({
    idRecordatorio: data?.idRecordatorio,
    cliente: data?.cliente ? { value: data.cliente.idCliente, label: data.cliente.razonSocial } : null,
    contacto:
      data?.contacto && data?.contacto?.apellido && data?.contacto?.nombre
        ? {
            value: data.contacto.idClienteContacto,
            label: `${data?.contacto?.apellido?.toUpperCase()} ${data?.contacto?.nombre?.toUpperCase()}`
          }
        : null,
    comentario: data?.comentario,
    fechaHoraInicio: data?.fechaHoraInicio,
    fechaHoraFin: data?.fechaHoraFin
  }),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: ({ action }) => (action === ACTIONS.DELETE.VALUE ? deleteScheme : addUpdateScheme),
  handleSubmit: (values, { setSubmitting, props: { accept } }): any => {
    const request = {
      cliente: { idCliente: values?.cliente?.value, cuit: "", razonSocial: "" },
      contacto: { idClienteContacto: values?.contacto?.value, apellido: "", nombre: "" },
      idRecordatorio: values?.idRecordatorio,
      fechaHoraInicio: values?.fechaHoraInicio,
      fechaHoraFin:
        values?.fechaHoraInicio !== values?.fechaHoraFin
          ? Number(values?.fechaHoraFin) + 1800000
          : values?.fechaHoraFin,
      comentario: values?.comentario
    }
    if (accept != null && request.fechaHoraInicio <= request.fechaHoraFin) accept(request)
    setSubmitting(false)
  }
})(FormaddUpdate)
