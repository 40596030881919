/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { type ReactElement } from "react"
import { ContainerForm, ErrorLogin, Label, LabelChange } from "../_styles"
import { useDispatch, useSelector } from "react-redux"
import ButtonCustom from "../../../components/ButtonCustom"
import { withFormik } from "formik"
import FormSchema from "../_schemaPass"
import { loginSelector } from "../../../state/modules/login/_selectors"
import { useTranslation } from "react-i18next"
import { GLOBAL_STYLES } from "../../../styles/GLOBAL_STYLE"
import { type IFormListProps } from "../../../interfaces/IFormListProps"
import { TRANSLATION_INDEX_GLOBAL, TRANSLATION_INDEX_RESPONSE_CODE } from "../../../config/translation"
import InputCustom from "../../../components/InputCustom"

import { INPUT_TYPE, ROUTES } from "../../../utils/constants"
import { useGlobalContext } from "../../../context/GlobalContext"
import { logout } from "../../../state/modules/login/actions"
import { STORAGE } from "../../../utils/localStorage"
import { useNavigate } from "react-router"

const ChangePassword = ({
  values,
  accept,
  errors,
  setSubmitting,
  isSubmitting,
  handleChange,
  handleBlur,
  setFieldValue,
  handleSubmit,
  touched
}: IFormListProps): ReactElement => {
  const [passNotOk, setPassNotOk] = React.useState(false)
  const { setLoadingContext } = useGlobalContext()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const login = useSelector(loginSelector)

  const logoutHandle = (): void => {
    dispatch(logout())
    STORAGE.remove(STORAGE.TOKEN)
    STORAGE.remove(STORAGE.NOTIFICATION)
    STORAGE.remove(STORAGE.CHANGE_PASSWORD)
    navigate(ROUTES.LOGIN.pathName)
  }

  React.useEffect(() => {
    setLoadingContext(login?.loading)
  }, [login])
  
  return (
    <ContainerForm onSubmit={handleSubmit}>
      <LabelChange>{t("login.changePassword")}</LabelChange>

      <InputCustom
        name="newPass"
        type={INPUT_TYPE.PASSWORD}
        value={values?.newPass ?? ""}
        handleChange={handleChange}
        placeHolder={t("login.newPass") ?? ""}
        errors={errors?.newPass}
        touched={touched?.newPass}
        width="90%"
        borderRadius="10px"
        iconRight
      />
      <InputCustom
        name="repeat"
        type={INPUT_TYPE.PASSWORD}
        value={values?.repeat ?? ""}
        handleChange={handleChange}
        placeHolder={t("login.repeat") ?? ""}
        errors={errors?.repeat}
        touched={touched?.repeat}
        width="90%"
        borderRadius="10px"
        iconRight
      />
      <Label
        onClick={() => {
          logoutHandle()
        }}
      >
        {t(TRANSLATION_INDEX_GLOBAL.GO_BACK)}
      </Label>
      <ButtonCustom
        style={{ marginTop: "15px" }}
        type="submit"
        label={t(TRANSLATION_INDEX_GLOBAL.CONFIRM) ?? ""}
        height="40px"
        onClick={() => {
          setPassNotOk(values?.newPass !== values?.repeat)
        }}
        background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_LOGIN.LIGHT}
        backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER_LOGIN.LIGHT}
        width="90%"
      />
      {((login?.hasErrMessage??false) || passNotOk) && (
          <ErrorLogin>
            {t(TRANSLATION_INDEX_RESPONSE_CODE[passNotOk ? "PASSWORD_DIFFERENT" : login?.errorCode ?? "GENERIC_ERROR"])}
          </ErrorLogin>
        )}
    </ContainerForm>
  )
}

export default withFormik<IFormListProps, any>({
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: FormSchema,
  handleSubmit: (values, { setSubmitting, props: { accept } }): any => {
    if (accept != null && values.newPass === values.repeat) accept(values)
    setSubmitting(false)
  }
})(ChangePassword)
