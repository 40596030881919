// SAGAS
import { put, takeLatest, call } from "redux-saga/effects"
import { type SagaIterator } from "redux-saga"
import * as types from "../types"

import {
  getReportCommercialFailure,
  getReportCommercialSuccess,
  getReportCustomerFailure,
  getReportCustomerSuccess,
  getReportUncontactCustomerFailure,
  getReportUncontactCustomerSuccess
} from "../actions"

import { postReportCommercial, postReportCustomer, postReportUncontactCustomer } from "../services"

function* getReportCommercialWorker(request): SagaIterator {
  try {
    const { data } = yield call(postReportCommercial, request.payload)
    yield put(getReportCommercialSuccess(data))
  } catch (error) {
    yield put(getReportCommercialFailure(error))
  }
}

function* getReportCustomerWorker(request): SagaIterator {
  try {
    const { data } = yield call(postReportCustomer, request.payload)
    yield put(getReportCustomerSuccess(data))
  } catch (error) {
    yield put(getReportCustomerFailure(error))
  }
}

function* getReportUncontactCustomerWorker(request): SagaIterator {
  try {
    const { data } = yield call(postReportUncontactCustomer, request.payload)
    yield put(getReportUncontactCustomerSuccess(data))
  } catch (error) {
    yield put(getReportUncontactCustomerFailure(error))
  }
}

export function* listWatcher(): SagaIterator {
  yield takeLatest(types.REPORT_COMMERCIAL_GET_REQUEST, getReportCommercialWorker)
  yield takeLatest(types.REPORT_CUSTOMER_GET_REQUEST, getReportCustomerWorker)
  yield takeLatest(types.REPORT_UNCONTACT_CUSTOMER_GET_REQUEST, getReportUncontactCustomerWorker)
}

export const sagas = [listWatcher]
