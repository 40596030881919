import { type AxiosPromise } from "axios"
import httpModule from "../../../../utils/http"

export const postReportCommercial = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/reportes/eventosPorComercial`, request)

export const postReportCustomer = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/reportes/eventosPorCliente`, request)

export const postReportUncontactCustomer = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/reportes/clientesSinContactar`, request)
