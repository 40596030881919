import { type AxiosPromise } from "axios"
import httpModule from "../../../../utils/http"

export const getUserAppClient = async (): Promise<AxiosPromise<any>> =>
  await httpModule.get(`/api/backoffice/v1/usuariosapp`)

export const postUserAppClient = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.put(`/api/backoffice/v1/usuariosapp`, request)

export const patchUserAppClient = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.patch(`/api/backoffice/v1/usuariosapp`, request)

export const deleteUserAppClient = async (email): Promise<AxiosPromise<any>> =>
  await httpModule.delete(`/api/backoffice/v1/usuariosapp/${email}`)
