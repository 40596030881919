/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import ContainerPage from "../../components/ContainerPage"
import SearchRating from "./forms/search/index"
import {
  TRANSLATION_INDEX_AUTHORIZE_INFORMACION,
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_MENU
} from "../../config/translation"

import { ContainerAverage, ContainerTable } from "./_styles"
import "../../styles/styles.css"
import { useDispatch, useSelector } from "react-redux"
import { getRatingsRequest } from "../../state/modules/ratings/actions"
import DataTable from "react-data-table-component"
import { ratingsSelector } from "../../state/modules/ratings/_selectors"
import { type IRatingsGroup, type IRatings } from "../../interfaces/IRatings"
import { NoData } from "../../components/NoData"
import { customStylesContext } from "../../styles/_styleDatatable"
import { useGlobalContext } from "../../context/GlobalContext"
import { filterData, filterDataGroup } from "./functios"
import { format } from "date-fns"
import { FORMAT_DATE } from "../../utils/constants"
import { useMobile } from "../../hook/useMobile"

const RatingsPage = (): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isMobile = useMobile()
  const { setLoadingContext, themeContext } = useGlobalContext()
  const ratings = useSelector(ratingsSelector)
  const [dataFiltered, setDataFiltered] = React.useState<IRatings[]>(ratings.data)
  const [dataFilteredGroup, setDataFilteredGroup] = React.useState<IRatingsGroup[]>(ratings.dataGroup)
  const [filters, setFilters] = React.useState<any>(null)
  const paginationComponentOptions = {
    rowsPerPageText: t(TRANSLATION_INDEX_GLOBAL.ROWS_PER_PAGE),
    rangeSeparatorText: t(TRANSLATION_INDEX_GLOBAL.OF),
    selectAllRowsItem: true,
    selectAllRowsItemText: t(TRANSLATION_INDEX_GLOBAL.ALL)
  }

  const getAverage = (): string => {
    let sum = 0
    dataFiltered.forEach(item => {
      sum += item?.puntaje ?? 0
    })
    if (dataFiltered.length > 0) return (sum / dataFiltered.length).toFixed(2).toString().replace(".", ",")
    else return "0,00"
  }

  const columnsGroup = [
    {
      name: t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.ORGANIZATION),
      selector: (row: IRatingsGroup) => row.organizacion.descripcion ?? "",
      cell: (row: IRatingsGroup) => {
        return <div>{row.organizacion.descripcion}</div>
      },
      width: isMobile ? "125px" : "15%",
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.SCORE),
      selector: (row: IRatingsGroup) => Number(row.puntaje / row.count) ?? 0,
      cell: (row: IRatingsGroup) => {
        return <div>{(row.puntaje / row.count).toFixed(2).toString().replace(".", ",")}</div>
      },
      center: true,
      width: isMobile ? "100px" : "12%",
      sortable: true
    }
  ]

  const columns = [
    {
      name: t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.SHIPMENT_ID),
      selector: (row: IRatings) => row.idEmbarque ?? "",
      cell: (row: IRatings) => {
        return <div>{row.idEmbarque}</div>
      },
      width: isMobile ? "125px" : "15%",
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.ORGANIZATION),
      selector: (row: IRatings) => row.organizacion?.descripcion ?? "",
      cell: (row: IRatings) => {
        return <div>{row.organizacion?.descripcion}</div>
      },
      width: isMobile ? "125px" : "15%",
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.SCORE),
      selector: (row: IRatings) => row.puntaje ?? "",
      cell: (row: IRatings) => {
        return <div>{row?.puntaje ? row?.puntaje.toString().replace(".", ",") : ""}</div>
      },
      center: true,
      width: isMobile ? "100px" : "12%",
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.COMMENT),
      selector: (row: IRatings) => row.comentario ?? "",
      cell: (row: IRatings) => {
        return <div>{row.comentario}</div>
      },
      width: isMobile ? "150px" : "18%"
    },
    {
      name: "Email",
      selector: (row: IRatings) => row.email ?? "",
      cell: (row: IRatings) => {
        return <div>{row.email}</div>
      },
      width: isMobile ? "200px" : "18%",
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.DATE),
      selector: (row: IRatings) => Number(row.fecha) ?? 0,
      cell: (row: IRatings) => {
        return <div>{row.fecha ? format(new Date(row.fecha ?? ""), FORMAT_DATE) : ""}</div>
      },
      center: true,
      width: isMobile ? "175px" : "18%",
      sortable: true
    }
  ]

  React.useEffect(() => {
    setLoadingContext(ratings.loading)
    setDataFiltered(filterData(ratings.data, filters))
    setDataFilteredGroup(filterDataGroup(ratings.dataGroup, filters))
  }, [ratings])

  React.useEffect(() => {
    setDataFilteredGroup(filterDataGroup(ratings.dataGroup, filters))
  }, [filters])

  React.useEffect(() => {
    dispatch(getRatingsRequest())
  }, [])

  return (
    <>
      <ContainerPage title={`${t(TRANSLATION_INDEX_MENU.RATINGS)}`} actions={[]}>
        <>
          <ContainerTable themeContext={themeContext} opacity={1}>
            <SearchRating data={ratings.data} setDataFiltered={setDataFiltered} setFilters={setFilters} />
            <ContainerAverage>
              {`${t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.RATINGS_AVERAGE)}: ${getAverage()} (${
                filters?.group ? dataFilteredGroup.length : dataFiltered.length
              })`}
            </ContainerAverage>
            {filters?.group ? (
              <DataTable
                data={dataFilteredGroup}
                columns={columnsGroup}
                noDataComponent={<NoData />}
                dense
                pagination
                striped
                customStyles={customStylesContext(themeContext)}
                paginationComponentOptions={paginationComponentOptions}
                defaultSortFieldId={1}
              />
            ) : (
              <DataTable
                data={dataFiltered}
                columns={columns}
                noDataComponent={<NoData />}
                dense
                pagination
                striped
                customStyles={customStylesContext(themeContext)}
                paginationComponentOptions={paginationComponentOptions}
                defaultSortAsc={false}
                defaultSortFieldId={6}
              />
            )}
          </ContainerTable>
        </>
      </ContainerPage>
    </>
  )
}

export default RatingsPage
