import { preference } from "./_duck"
import * as types from "./types"

export const getPreferenceRequest = preference.createAction(types.PREFERENCE_GET_REQUEST)
export const getPreferenceSuccess = preference.createAction(types.PREFERENCE_GET_SUCCESS)
export const getPreferenceFailure = preference.createAction(types.PREFERENCE_GET_FAILURE)

export const putPreferenceRequest = preference.createAction(types.PREFERENCE_PUT_REQUEST)
export const putPreferenceSuccess = preference.createAction(types.PREFERENCE_PUT_SUCCESS)
export const putPreferenceFailure = preference.createAction(types.PREFERENCE_PUT_FAILURE)
