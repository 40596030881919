import { userAppClient } from "./_duck"

export const USER_APP_CLIENT_GET_REQUEST: string = userAppClient.defineType("USER_APP_CLIENT_GET_REQUEST")
export const USER_APP_CLIENT_GET_SUCCESS: string = userAppClient.defineType("USER_APP_CLIENT_GET_SUCCESS")
export const USER_APP_CLIENT_GET_FAILURE: string = userAppClient.defineType("USER_APP_CLIENT_GET_FAILURE")

export const USER_APP_CLIENT_PATCH_REQUEST: string = userAppClient.defineType("USER_APP_CLIENT_PATCH_REQUEST")
export const USER_APP_CLIENT_PATCH_SUCCESS: string = userAppClient.defineType("USER_APP_CLIENT_PATCH_SUCCESS")
export const USER_APP_CLIENT_PATCH_FAILURE: string = userAppClient.defineType("USER_APP_CLIENT_PATCH_FAILURE")

export const USER_APP_CLIENT_POST_REQUEST: string = userAppClient.defineType("USER_APP_CLIENT_POST_REQUEST")
export const USER_APP_CLIENT_POST_SUCCESS: string = userAppClient.defineType("USER_APP_CLIENT_POST_SUCCESS")
export const USER_APP_CLIENT_POST_FAILURE: string = userAppClient.defineType("USER_APP_CLIENT_POST_FAILURE")

export const USER_APP_CLIENT_DELETE_REQUEST: string = userAppClient.defineType("USER_APP_CLIENT_DELETE_REQUEST")
export const USER_APP_CLIENT_DELETE_SUCCESS: string = userAppClient.defineType("USER_APP_CLIENT_DELETE_SUCCESS")
export const USER_APP_CLIENT_DELETE_FAILURE: string = userAppClient.defineType("USER_APP_CLIENT_DELETE_FAILURE")
