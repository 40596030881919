// SAGAS
import { put, takeLatest, call } from "redux-saga/effects"
import { type SagaIterator } from "redux-saga"
import * as types from "../types"

import {
  getQuoteTemplateFailure,
  getQuoteTemplateSuccess,
  addQuoteTemplateSuccess,
  addQuoteTemplateFailure,
  deleteQuoteTemplateSuccess,
  deleteQuoteTemplateFailure,
  putQuoteTemplateSuccess,
  putQuoteTemplateFailure,
  getQuoteTemplateCustomerRelativeSuccess,
  createPDFQuoteTemplateSuccess,
  createPDFQuoteTemplateFailure,
  getQuoteSentSuccess,
  getQuoteSentFailure,
  getQuoteSentCustomerSuccess,
  getQuoteSentCustomerFailure,
  getQuoteSentByIdSuccess,
  getQuoteSentByIdFailure,
  postSendQuoteFailure,
  postSendQuoteSuccess,
  previewPDFQuoteTemplateSuccess,
  previewPDFQuoteTemplateFailure
} from "../actions"

import {
  addQuoteTemplate,
  createPDFQuote,
  deleteQuoteTemplate,
  getQuoteSent,
  getQuoteSentById,
  getQuoteSentCustomer,
  getQuoteTemplate,
  getQuoteTemplateCustomerRelative,
  previewPDFQuote,
  putQuoteTemplate,
  sendQuote
} from "../services"
import { getCustomerByFilterFailure } from "../../customer/actions"

function* getUsetAppClientWorker(): SagaIterator {
  try {
    const { data } = yield call(getQuoteTemplate)
    yield put(getQuoteTemplateSuccess(data))
  } catch (error) {
    yield put(getQuoteTemplateFailure(error))
  }
}

function* getQuoteSentWorker(): SagaIterator {
  try {
    const { data } = yield call(getQuoteSent)
    yield put(getQuoteSentSuccess(data))
  } catch (error) {
    yield put(getQuoteSentFailure(error))
  }
}

function* getQuoteSentCustomerWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(getQuoteSentCustomer, request.payload)
    yield put(getQuoteSentCustomerSuccess(data))
  } catch (error) {
    yield put(getQuoteSentCustomerFailure(error))
  }
}

function* getQuoteSentByIdWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(getQuoteSentById, request.payload)
    yield put(getQuoteSentByIdSuccess(data))
  } catch (error) {
    yield put(getQuoteSentByIdFailure(error))
  }
}

function* postSendQuoteWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(sendQuote, request.payload)
    yield put(postSendQuoteSuccess(data))
  } catch (error) {
    yield put(postSendQuoteFailure(error))
  }
}

function* getCustomerRelativeWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(getQuoteTemplateCustomerRelative, request.payload)
    yield put(getQuoteTemplateCustomerRelativeSuccess(data))
  } catch (error) {
    yield put(getCustomerByFilterFailure(error))
  }
}

function* addUserClientWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(addQuoteTemplate, request.payload)
    yield put(addQuoteTemplateSuccess(data))
  } catch (error) {
    yield put(addQuoteTemplateFailure(error))
  }
}

function* createPDFQuoteTemplateWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(createPDFQuote, request.payload)
    yield put(createPDFQuoteTemplateSuccess(data))
  } catch (error) {
    yield put(createPDFQuoteTemplateFailure(error))
  }
}

function* previewPDFQuoteTemplateWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(previewPDFQuote, request.payload)
    yield put(previewPDFQuoteTemplateSuccess(data))
  } catch (error) {
    yield put(previewPDFQuoteTemplateFailure(error))
  }
}

function* putUserClientWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(putQuoteTemplate, request.payload)
    yield put(putQuoteTemplateSuccess(data))
  } catch (error) {
    yield put(putQuoteTemplateFailure(error))
  }
}

function* deleteUserClientWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(deleteQuoteTemplate, request.payload)
    yield put(deleteQuoteTemplateSuccess(data))
  } catch (error) {
    yield put(deleteQuoteTemplateFailure(error))
  }
}

export function* listWatcher(): SagaIterator {
  yield takeLatest(types.QUOTE_TEMPLATE_GET_REQUEST, getUsetAppClientWorker)
  yield takeLatest(types.QUOTE_TEMPLATE_ADD_REQUEST, addUserClientWorker)
  yield takeLatest(types.QUOTE_SENT_CUSTOMER_GET_REQUEST, getQuoteSentCustomerWorker)
  yield takeLatest(types.QUOTE_SENT_BY_ID_GET_REQUEST, getQuoteSentByIdWorker)
  yield takeLatest(types.QUOTE_SENT_GET_REQUEST, getQuoteSentWorker)
  yield takeLatest(types.QUOTE_SEND_POST_REQUEST, postSendQuoteWorker)
  yield takeLatest(types.QUOTE_TEMPLATE_CREATE_PDF_REQUEST, createPDFQuoteTemplateWorker)
  yield takeLatest(types.QUOTE_TEMPLATE_PREVIEW_PDF_REQUEST, previewPDFQuoteTemplateWorker)
  yield takeLatest(types.QUOTE_TEMPLATE_CUSTOMER_RELATIVE_GET_REQUEST, getCustomerRelativeWorker)
  yield takeLatest(types.QUOTE_TEMPLATE_PUT_REQUEST, putUserClientWorker)
  yield takeLatest(types.QUOTE_TEMPLATE_DELETE_REQUEST, deleteUserClientWorker)
}

export const sagas = [listWatcher]
