/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { quoter } from "./_duck"
import * as types from "./types"

// Types
import { type PayloadAction } from "../../types"
import { type ModuleType } from "./_definitions"
import { TRANSLATION_INDEX_GLOBAL, TRANSLATION_INDEX_RESPONSE_CODE } from "../../../config/translation"
import { ContainerTypeHapagLloydMOCK, PortMOCK } from "../../../__mock/mock"

const initialModuleState: ModuleType = {
  data: null,
  commodities: [],
  proccessInfo: {},
  inProccess: -1,
  loading: false,
  loadingQuoter: false,
  showToast: false,
  toastMessage: "",
  hasErrMessage: false,
  errorCode: "",
  exportData: null
}

const reducer = quoter.createReducer(
  {
    [types.COMMODITIES_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      loadingQuoter: false,
      showToast: false,
      inProccess: -1,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: "",
      exportData: null
    }),
    [types.COMMODITIES_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        commodities: payload?.commodities,
        loadingQuoter: false,
        loading: false
      }
    },
    [types.COMMODITIES_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      data: null,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      loadingQuoter: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.PROCCESS_INFO_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: "",
      exportData: null
    }),
    [types.PROCCESS_INFO_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        proccessInfo: payload
      }
    },
    [types.PROCCESS_INFO_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      data: null,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.QUOTER_POST_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      data: null,
      loading: true,
      loadingQuoter: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.QUOTER_POST_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      if (!payload.containerTypeHapagLloydList) payload.containerTypeHapagLloydList = ContainerTypeHapagLloydMOCK
      if (!payload.portList) payload.portList = PortMOCK
      return {
        ...state,
        data: payload,
        proccessInfo: {},
        loadingQuoter: false,
        loading: false,
        exportData: null
      }
    },
    [types.QUOTER_POST_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      data: null,
      showToast: true,
      proccessInfo: {},
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      loadingQuoter: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.EXPORT_POST_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      inProccess: -1,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: "",
      exportData: null
    }),
    [types.EXPORT_POST_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      const link = document.createElement("a")
      link.href = `${process.env.REACT_APP_API_QUOTER}/quote/export3`
      link.setAttribute("download", `${Date.now()}.xlsx`)
      document.body.appendChild(link)
      link.click()
      return {
        ...state,
        showToast: true,
        toastMessage: TRANSLATION_INDEX_GLOBAL.SUCCESS_ADD,
        loading: false,
        exportData: payload
      }
    },
    [types.EXPORT_POST_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.IN_PROCCESS_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      loadingQuoter: true,
      inProccess: -1,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.IN_PROCCESS_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        inProccess: payload === true ? 1 : 0,
        loadingQuoter: false,
        loading: false
      }
    },
    [types.IN_PROCCESS_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      showToast: true,
      loadingQuoter: false,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.CLEAR]: (state: ModuleType): ModuleType => ({
      ...state,
      data: null
    })
  },
  initialModuleState
)

export default reducer
