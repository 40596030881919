/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import {
  TRANSLATION_INDEX_AUTHORIZE_INFORMACION,
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_MENU
} from "../../../../config/translation"

import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"

import { type IPropSearch } from "./_types"
import { withFormik } from "formik"

import { ContainerInputs, ContainerSerch } from "./_styles"
import InputCustom from "../../../../components/InputCustom"
import ButtonCustom from "../../../../components/ButtonCustom"
import { InfoCustom } from "../../../../components/InfoCustom"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"
import { filterData } from "../../functios"
import { useMobile } from "../../../../hook/useMobile"
import { DatePickerCustom } from "../../../../components/DatePickerCustom/DatePickerCustom/Index"
import { INPUT_TYPE } from "../../../../utils/constants"
import { useGlobalContext } from "../../../../context/GlobalContext"

const SearchRating = ({
  setDataFiltered = () => {},
  data,
  values,
  errors,
  setFilters,
  setSubmitting,
  isSubmitting,
  handleChange,
  setFieldValue,
  handleReset,
  handleBlur,
  handleSubmit
}: IFormListProps): ReactElement => {
  const { t } = useTranslation()
  const { themeContext } = useGlobalContext()
  const isMobile = useMobile()
  const clearFilter = (): void => {
    if (handleReset != null) {
      setFromDate(null)
      setToDate(null)
      handleReset()
    }
  }
  const [fromDate, setFromDate] = React.useState<string | Date | null>(null)
  const [toDate, setToDate] = React.useState<string | Date | null>(null)

  const handleSearch = (): void => {
    if (setFilters) setFilters(values)
    setDataFiltered(filterData(data, values))
  }

  React.useEffect(() => {
    if (setFieldValue) {
      setFieldValue("dateFrom", toDate === null ? null : fromDate)
      setFieldValue("dateTo", toDate)
    }
  }, [fromDate, toDate])

  React.useEffect(() => {
    handleSearch()
  }, [values])

  return (
    <ContainerSerch>
      <ContainerInputs>
        <InputCustom
          value={values?.search ?? ""}
          handleChange={handleChange}
          name="search"
          iconLeft
          placeHolder={`${t(TRANSLATION_INDEX_GLOBAL.SEARCH) ?? ""} ${t(
            TRANSLATION_INDEX_MENU.RATINGS
          ).toLocaleLowerCase()}`}
          width={isMobile ? "100%" : "350px"}
          validError={false}
        />
        {!isMobile && (
          <InfoCustom
            info={`${t(TRANSLATION_INDEX_GLOBAL.SEARCH)} ${t(
              TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.SHIPMENT_ID
            )}, ${t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.ORGANIZATION)}, ${t(
              TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.COMMENT
            )} ${t(TRANSLATION_INDEX_GLOBAL.OR1)} Email.`}
            color={GLOBAL_STYLES.LABEL.TYPE_1[themeContext]}
          />
        )}
        <InputCustom
          value={values?.puntaje ?? ""}
          handleChange={handleChange}
          name="puntaje"
          type={INPUT_TYPE.NUMBER}
          marginLeft
          iconLeft
          min={1}
          max={5}
          placeHolder={t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.SCORE) ?? ""}
          width={isMobile ? "100%" : "200px"}
          validError={false}
        />
        <DatePickerCustom
          iconLeft
          value={fromDate}
          placeHolder={t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.DATE_BETWEEN) ?? ""}
          setValue={setFromDate}
          isRange
          endDate={toDate ?? null}
          setEndDate={setToDate}
          dateFormat="dd/MM/yyyy"
          marginLeft
          width={isMobile ? "100%" : "200px"}
          // showMonthYearPicker
        />
        <div style={{ marginLeft: "30px", height: "32px", display: "flex", alignItems: "center" }}>
          <input
            type="checkbox"
            name="miCheck"
            value={values?.group}
            onChange={e => {
              if (setFieldValue) setFieldValue("group", e.target.checked)
            }}
          />
          <span style={{ marginLeft: "5px" }}>
            {t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.GROUP_BY_CLIENT)}
          </span>
        </div>
      </ContainerInputs>
      <ButtonCustom
        onClick={() => {
          clearFilter()
        }}
        label={t(TRANSLATION_INDEX_GLOBAL.CLEAR) ?? ""}
        backgroundHover={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE_HOVER[themeContext]}
        background={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE[themeContext]}
        width={isMobile ? "100%" : "100px"}
        marginLeft
      />
    </ContainerSerch>
  )
}

export default withFormik<IFormListProps, IPropSearch>({
  mapPropsToValues: (): IPropSearch => ({}),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, { setSubmitting }): any => {}
})(SearchRating)
