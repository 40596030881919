/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { type ReactElement } from "react"

import ButtonCustom from "../../../../components/ButtonCustom"

import { useTranslation } from "react-i18next"
// import { RiShipFill } from "react-icons/ri"
import { TRANSLATION_INDEX_GLOBAL, TRANSLATION_INDEX_QUOTER } from "../../../../config/translation"
import { Container, Group, GroupButtom, ContainerGroup, ContainerMessage } from "./_styles"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { ACTIONS, INPUT_TYPE } from "../../../../utils/constants"

import "react-toastify/dist/ReactToastify.css"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"
import { withFormik } from "formik"
import SelectCustom from "../../../../components/SelectCustom"
import CheckboxCustom from "../../../../components/CheckboxCustom"
import InputCustom from "../../../../components/InputCustom"
import {
  ContainerTypeHapagLloydMOCK,
  PortMOCK,
  RadioOptionQuoter,
  companyMock,
  containerTypeMaerskMOCK
} from "../../../../__mock/mock"
import { useSelector } from "react-redux"
import { quoterSelector } from "../../../../state/modules/Quoter/_selectors"
import { useMobile } from "../../../../hook/useMobile"
import { useGlobalContext } from "../../../../context/GlobalContext"

const FormaddUpdate = ({
  data,
  isUserPending = false,
  loading = false,
  action,
  closeModal = () => {},
  accept = () => {},
  setFieldValue,
  values,
  touched,
  errors,
  setSubmitting,
  isSubmitting,
  handleChange,
  handleBlur,
  handleSubmit
}: IFormListProps): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useMobile()
  const { themeContext } = useGlobalContext()
  const quoter = useSelector(quoterSelector)
  return (
    <Container onSubmit={handleSubmit}>
      {action === ACTIONS.DELETE.VALUE ? (
        <ContainerMessage themeContext={themeContext}>{t(TRANSLATION_INDEX_GLOBAL.CONFIRM_DELETE)}</ContainerMessage>
      ) : (
        <ContainerGroup themeContext={themeContext}>
          <Group>
            {setFieldValue != null && (
              <SelectCustom
                label={`${t(TRANSLATION_INDEX_QUOTER.COMPANY_TO_SEARCH)} (*)`}
                value={values?.companiesToSearch ?? null}
                handleChange={setFieldValue}
                isMulti
                name="companiesToSearch"
                options={companyMock}
                errors={errors?.companiesToSearch}
              />
            )}
          </Group>
          <Group>
            {setFieldValue != null && (
              <SelectCustom
                label={`${t(TRANSLATION_INDEX_QUOTER.SOURCE_PORT)} (*)`}
                value={values?.portsFrom ?? null}
                handleChange={setFieldValue}
                isMulti
                name="portsFrom"
                placeHolder={`${t(TRANSLATION_INDEX_GLOBAL.SEARCH_FOR)} ${t(TRANSLATION_INDEX_QUOTER.SOURCE_PORT)} `}
                options={PortMOCK?.map(item => {
                  const labelCustom = `(${item.unLocCode}) ${item.cityName} ${
                    item.regionName ? `(${item.regionName})` : ""
                  },${" "} ${item.countryName}`
                  return {
                    label: labelCustom,
                    value: item.unLocCode
                  }
                })}
                errors={errors?.portsFrom}
              />
            )}
            {setFieldValue != null && isMobile && (
              <CheckboxCustom
                name="haulageFrom"
                handleChange={setFieldValue}
                value={values?.haulageFrom ?? null}
                options={RadioOptionQuoter}
              />
            )}
            {setFieldValue != null && (
              <SelectCustom
                label={`${t(TRANSLATION_INDEX_QUOTER.DESTINATION_PORT)} (*)`}
                value={values?.portsTo ?? null}
                handleChange={setFieldValue}
                isMulti
                marginLeft
                name="portsTo"
                placeHolder={`${t(TRANSLATION_INDEX_GLOBAL.SEARCH_FOR)} ${t(
                  TRANSLATION_INDEX_QUOTER.DESTINATION_PORT
                )}`}
                options={PortMOCK?.map(item => {
                  const labelCustom = `(${item.unLocCode}) ${item.cityName} ${
                    item.regionName ? `(${item.regionName})` : ""
                  },${" "} ${item.countryName}`
                  return {
                    label: labelCustom,
                    value: item.unLocCode
                  }
                })}
                errors={errors?.portsTo}
              />
            )}
          </Group>
          <Group>
            {setFieldValue != null && !isMobile && (
              <CheckboxCustom
                name="haulageFrom"
                handleChange={setFieldValue}
                value={values?.haulageFrom ?? null}
                options={RadioOptionQuoter}
              />
            )}
            {setFieldValue != null && (
              <CheckboxCustom
                name="haulageTo"
                handleChange={setFieldValue}
                value={values?.haulageTo ?? null}
                options={RadioOptionQuoter}
              />
            )}
          </Group>
          <Group>
            {setFieldValue != null && (
              <SelectCustom
                isDisabled={
                  values?.companiesToSearch.length === 0 ||
                  !values?.companiesToSearch?.some(item => item.value === "SEAU" || item.value === "MAEU")
                }
                label={`${t(TRANSLATION_INDEX_QUOTER.COMMOODITY)} (*)`}
                value={values?.commodity ?? null}
                handleChange={setFieldValue}
                name="commodity"
                options={quoter?.commodities?.map(item => {
                  return {
                    value: item.commodityCode,
                    label: item.commodityName
                  }
                })}
                errors={errors?.commodity}
              />
            )}
          </Group>
          <Group>
            {setFieldValue != null && (
              <SelectCustom
                isDisabled={
                  values?.companiesToSearch.length === 0 ||
                  !values?.companiesToSearch?.some(item => item.value === "HLL")
                }
                label={`${t(TRANSLATION_INDEX_QUOTER.CONTAINER_TYPE_HL)} (*)`}
                value={values?.containerTypesHapagLloyd ?? null}
                handleChange={setFieldValue}
                isMulti
                name="containerTypesHapagLloyd"
                options={ContainerTypeHapagLloydMOCK?.map(item => {
                  return {
                    value: item.containerCode,
                    label: item.description?.toUpperCase()
                  }
                })}
                errors={errors?.containerTypesHapagLloyd}
              />
            )}
            {setFieldValue != null && (
              <SelectCustom
                isDisabled={
                  values?.companiesToSearch.length === 0 ||
                  !values?.companiesToSearch?.some(item => item.value === "SEAU" || item.value === "MAEU")
                }
                label={`${t(TRANSLATION_INDEX_QUOTER.CONTAINER_TYPE_MK)} (*)`}
                value={values?.containerTypesMaersk ?? null}
                handleChange={setFieldValue}
                isMulti
                marginLeft
                name="containerTypesMaersk"
                options={containerTypeMaerskMOCK?.map(item => {
                  return {
                    value: item.containerCode,
                    label: `${item.description} (${item.group?.toUpperCase()})`
                  }
                })}
                errors={errors?.containerTypesMaersk}
              />
            )}
          </Group>
          <Group>
            <InputCustom
              label={`${t(TRANSLATION_INDEX_QUOTER.CONTAINER_QUANTITY)} (*)`}
              type={INPUT_TYPE.NUMBER}
              value={values?.containerQuantity ?? null}
              handleChange={handleChange}
              name="containerQuantity"
              errors={errors?.containerQuantity}
            />
            <InputCustom
              label={`${t(TRANSLATION_INDEX_QUOTER.WEIGHT)} (Kg) (*)`}
              type={INPUT_TYPE.NUMBER}
              value={values?.weight ?? null}
              handleChange={handleChange}
              marginLeft
              name="weight"
              errors={errors?.weight}
            />
          </Group>
        </ContainerGroup>
      )}
      <GroupButtom themeContext={themeContext}>
        <ButtonCustom
          width="80px"
          type="submit"
          label={
            action === ACTIONS.DELETE.VALUE
              ? t(TRANSLATION_INDEX_GLOBAL.DELETE) ?? ""
              : t(TRANSLATION_INDEX_GLOBAL.GENERATE) ?? ""
          }
          background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
          backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]}
          marginLeft
        />
      </GroupButtom>
    </Container>
  )
}

export default withFormik<IFormListProps, any>({
  mapPropsToValues: (): any => ({
    companiesToSearch: companyMock,
    haulageFrom: RadioOptionQuoter[0],
    haulageTo: RadioOptionQuoter[0]
  }),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  // validationSchema: ,
  handleSubmit: (values, { setSubmitting, props: { accept } }): any => {
    const request = {
      companiesToSearch: values?.companiesToSearch?.map(item => item.value),
      portsFrom: PortMOCK.filter(item => values?.portsFrom?.map(opc => opc.value).includes(item.unLocCode)),
      portsTo: PortMOCK.filter(item => values?.portsTo?.map(opc => opc.value).includes(item.unLocCode)),
      haulageFrom: values?.haulageFrom?.value,
      haulageTo: values?.haulageTo?.value,
      commodity: values?.commodity?.label,
      containerTypesHapagLloyd: values?.containerTypesHapagLloyd?.map(item => {
        return {
          containerCode: item?.value,
          description: item?.label
        }
      }),
      containerTypesMaersk: values?.containerTypesMaersk?.map(item => item.value),
      containerQuantity: values?.containerQuantity,
      weight: values.weight
    }

    if (accept != null) accept(request)
    setSubmitting(false)
  }
})(FormaddUpdate)
