import { permission } from "./_duck"
import * as types from "./types"

export const getPermissionRequest = permission.createAction(types.PERMISSION_GET_REQUEST)
export const getPermissionSuccess = permission.createAction(types.PERMISSION_GET_SUCCESS)
export const getPermissionFailure = permission.createAction(types.PERMISSION_GET_FAILURE)

export const getPermissionByProfileRequest = permission.createAction(types.PERMISSION_GET_BY_PROFILE_REQUEST)
export const getPermissionByProfileSuccess = permission.createAction(types.PERMISSION_GET_BY_PROFILE_SUCCESS)
export const getPermissionByProfileFailure = permission.createAction(types.PERMISSION_GET_BY_PROFILE_FAILURE)

export const addPermissionRequest = permission.createAction(types.PERMISSION_ADD_REQUEST)
export const addPermissionSuccess = permission.createAction(types.PERMISSION_ADD_SUCCESS)
export const addPermissionFailure = permission.createAction(types.PERMISSION_ADD_FAILURE)

export const postPermissionRequest = permission.createAction(types.PERMISSION_POST_REQUEST)
export const postPermissionSuccess = permission.createAction(types.PERMISSION_POST_SUCCESS)
export const postPermissionFailure = permission.createAction(types.PERMISSION_POST_FAILURE)

export const deletePermissionRequest = permission.createAction(types.PERMISSION_DELETE_REQUEST)
export const deletePermissionSuccess = permission.createAction(types.PERMISSION_DELETE_SUCCESS)
export const deletePermissionFailure = permission.createAction(types.PERMISSION_DELETE_FAILURE)
