/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import ContainerPage from "../../components/ContainerPage"
import {
  TRANSLATION_INDEX_EVENT,
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_MENU,
  TRANSLATION_INDEX_PROFILE
} from "../../config/translation"
import DataTable from "react-data-table-component"

import AlertModal from "../../components/AlertModal"

import { ACTIONS, FORMAT_DATE_ONLY, PERMISSION } from "../../utils/constants"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { getAuth } from "../../utils/functions"
import ButtonCustom from "../../components/ButtonCustom"
import { ContainerIcons, ContainerTable } from "./_styles"
import { customStylesContext } from "../../styles/_styleDatatable"
import { useDispatch, useSelector } from "react-redux"
import SearchUserClientApp from "./forms/search/index"
import { toast } from "react-toastify"
import IconEdit from "../../components/Icons/IconEdit"
import IconPlus from "../../components/Icons/IconPlus"
import FormaddUpdate from "./forms/addUpdate/FormaddUpdate"
import IconDelete from "../../components/Icons/IconDelete"
import { NoData } from "../../components/NoData"
import { useGlobalContext } from "../../context/GlobalContext"
import "../../styles/styles.css"
import { format } from "date-fns"
import { useMobile } from "../../hook/useMobile"
import {
  addEventRequest,
  clearAllEvents,
  deleteEventRequest,
  getEventRequest,
  getTypeEventRequest,
  putEventRequest
} from "../../state/modules/event/actions"
import { eventSelector } from "../../state/modules/event/_selectors"
import { type IEvent } from "../../interfaces/IEvent"
import { getAssignedCommercialRequest } from "../../state/modules/customer/actions"
import { EVENT_TYPE } from "./constants"
import { filterDataEvent } from "./forms/search/filter"
import ContainerLabelTwoRow from "../../components/ContainerLabelTwoRow"

const EventPage = (): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useMobile()
  const dispatch = useDispatch()
  const events = useSelector(eventSelector)
  const [dataFiltered, setDataFiltered] = React.useState<IEvent[] | null>(null)
  const [openModal, setOpenModal] = React.useState(false)
  const { loadingContext, setLoadingContext, themeContext } = useGlobalContext()
  const [userSelected, setUserSelected] = React.useState<IEvent | null>(null)
  const [action, setAction] = React.useState<string>("")
  const [resetFilter, setResetFilter] = React.useState<boolean>(false)
  const [filter, setSetfilter] = React.useState<any>(null)

  const buttomsToEdit =
    (getAuth(PERMISSION.EVENTOS_MODIFICAR)[0] ? 1 : 0) + (getAuth(PERMISSION.EVENTOS_ELIMINAR)[0] ? 1 : 0)

  const handleOpenModal = (action = ""): void => {
    setAction(action)
    setOpenModal(!openModal)
  }

  const handleAdd = (): void => {
    setUserSelected(null)
    handleOpenModal(ACTIONS.ADD.VALUE)
  }

  const paginationComponentOptions = {
    rowsPerPageText: t(TRANSLATION_INDEX_GLOBAL.ROWS_PER_PAGE),
    rangeSeparatorText: t(TRANSLATION_INDEX_GLOBAL.OF),
    selectAllRowsItem: true,
    selectAllRowsItemText: t(TRANSLATION_INDEX_GLOBAL.ALL)
  }

  const columns = [
    {
      name: t(TRANSLATION_INDEX_EVENT.DATE),
      selector: (row: IEvent) => row.fecha,
      sortable: true,
      width: isMobile ? "150px" : `10%`,
      center: true,
      cell: (row: IEvent) => {
        return <div>{row?.fecha != null ? format(new Date(row?.fecha), FORMAT_DATE_ONLY) : "-"}</div>
      }
    },

    {
      name: t(TRANSLATION_INDEX_EVENT.CUSTOMER_PROSPECT),
      selector: (row: IEvent) => row.cliente.razonSocial,
      sortable: true,
      width: isMobile ? "180px" : `calc(35% - ${buttomsToEdit * 100 + 100}px)`,
      cell: (row: IEvent) => {
        return <ContainerLabelTwoRow label={row.cliente.razonSocial} />
      }
    },
    {
      name: t(TRANSLATION_INDEX_EVENT.EVENT_TYPE),
      selector: (row: IEvent) =>
        t(
          EVENT_TYPE.find(itemEvent => itemEvent.idTipoEvento === row.tipoEvento.idTipoEvento)?.descripcion ?? "-"
        ).toUpperCase() ?? "-",
      sortable: true,
      width: isMobile ? "150px" : `10%`
    },
    {
      name: t(TRANSLATION_INDEX_EVENT.CONTACT),
      selector: (row: IEvent) => (row?.contacto ? `${row?.contacto?.apellido} ${row?.contacto?.nombre}` : ""),
      sortable: true,
      width: isMobile ? "150px" : `15%`
    },
    {
      name: t(TRANSLATION_INDEX_EVENT.COMMENT),
      selector: (row: IEvent) => row.comentario,
      sortable: true,
      width: isMobile ? "150px" : `20%`,
      cell: (row: IEvent) => {
        return <ContainerLabelTwoRow label={row.comentario} />
      }
    },
    {
      name: t(TRANSLATION_INDEX_EVENT.USER),
      selector: (row: IEvent) => row.nombreUsuario,
      sortable: true,
      width: isMobile ? "150px" : `10%`
    },
    {
      name: t(TRANSLATION_INDEX_PROFILE.COLUMNS.ACTIONS),
      cell: (row: IEvent) => {
        return (
          <ContainerIcons>
            {getAuth(PERMISSION.EVENTOS_CONSULTAR)[0] && (
              <ButtonCustom
                label={t(TRANSLATION_INDEX_GLOBAL.VIEW) ?? ""}
                onClick={() => {
                  setUserSelected(row)
                  handleOpenModal(ACTIONS.VIEW.VALUE)
                }}
                width="80px"
                background={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE[themeContext]}
                backgroundHover={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE_HOVER[themeContext]}
                icon={<IconEdit />}
              />
            )}
            {getAuth(PERMISSION.EVENTOS_MODIFICAR)[0] && (
              <ButtonCustom
                marginLeft
                label={t(TRANSLATION_INDEX_GLOBAL.EDIT) ?? ""}
                onClick={() => {
                  setUserSelected(row)
                  handleOpenModal(ACTIONS.EDIT.VALUE)
                }}
                width="80px"
                background={GLOBAL_STYLES.BUTTON.DARK_BG_IMAGE[themeContext]}
                backgroundHover={GLOBAL_STYLES.BUTTON.DARK_BG_IMAGE_HOVER[themeContext]}
                icon={<IconEdit />}
              />
            )}

            {getAuth(PERMISSION.EVENTOS_ELIMINAR)[0] && (
              <ButtonCustom
                marginLeft
                label={t(TRANSLATION_INDEX_GLOBAL.DELETE) ?? ""}
                onClick={() => {
                  setUserSelected(row)
                  handleOpenModal(ACTIONS.DELETE.VALUE)
                }}
                width="85px"
                background={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE[themeContext]}
                backgroundHover={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE_HOVER[themeContext]}
                icon={<IconDelete />}
              />
            )}
          </ContainerIcons>
        )
      },
      center: true,
      width: isMobile
        ? "270px"
        : getAuth(PERMISSION.EVENTOS_MODIFICAR)[0] && getAuth(PERMISSION.EVENTOS_ELIMINAR)[0]
        ? "300px"
        : getAuth(PERMISSION.EVENTOS_MODIFICAR)[0] || getAuth(PERMISSION.EVENTOS_ELIMINAR)[0]
        ? "200px"
        : "100px"
    }
  ]

  const handleDispatch = (values: any): void => {
    switch (action) {
      case ACTIONS.DELETE.VALUE:
        dispatch(deleteEventRequest(values.idEvento))
        break
      case ACTIONS.ADD.VALUE:
        dispatch(addEventRequest(values))
        break
      case ACTIONS.EDIT.VALUE:
        dispatch(putEventRequest(values))
        break
      default:
        break
    }
  }

  React.useEffect(() => {
    if (events.showToast) {
      if (events.hasErrMessage) {
        toast.error(t(events.errorCode))
      } else {
        toast.success(t(events.toastMessage))
        handleOpenModal()
        dispatch(getEventRequest())
      }
    }
  }, [events.showToast])

  React.useEffect(() => {
    setDataFiltered(events?.data)
    const response: IEvent[] = filterDataEvent(events?.data, filter)
    setDataFiltered(response)
  }, [events?.data])

  React.useEffect(() => {
    setLoadingContext(events.loading)
  }, [events])

  React.useEffect(() => {
    setLoadingContext(true)
    dispatch(getEventRequest())
    dispatch(getTypeEventRequest())
    dispatch(getAssignedCommercialRequest())

    return () => {
      dispatch(clearAllEvents())
    }
  }, [])

  return (
    <>
      <ContainerPage
        title={`${t(TRANSLATION_INDEX_MENU.EVENTS)}`}
        actions={[
          {
            label: t(TRANSLATION_INDEX_GLOBAL.ADD),
            icon: <IconPlus size={13} />,
            permission: PERMISSION.EVENTOS_CREAR,
            click: () => {
              handleAdd()
            }
          }
        ]}
      >
        <>
          {openModal && (
            <AlertModal
              maxWidth="70%"
              activeCloseModal
              closeModal={() => {
                handleOpenModal()
              }}
              title={`${t(TRANSLATION_INDEX_MENU.EVENTS)} | ${t(TRANSLATION_INDEX_GLOBAL[action.toUpperCase()])}`}
            >
              <FormaddUpdate
                data={userSelected}
                action={action}
                closeModal={handleOpenModal}
                loading={loadingContext}
                accept={handleDispatch}
              />
            </AlertModal>
          )}

          <ContainerTable themeContext={themeContext} opacity={1}>
            <SearchUserClientApp
              data={events?.data}
              setDataFiltered={setDataFiltered}
              resetFilter={resetFilter}
              setFilters={setSetfilter}
              setClearFilter={setResetFilter}
            />
            <DataTable
              data={dataFiltered ?? []}
              theme="solarized"
              noDataComponent={<NoData />}
              columns={columns}
              dense
              pagination
              paginationComponentOptions={paginationComponentOptions}
              striped
              customStyles={customStylesContext(themeContext)}
              defaultSortAsc={false}
              defaultSortFieldId={1}
            />
          </ContainerTable>
        </>
      </ContainerPage>
    </>
  )
}

export default EventPage
