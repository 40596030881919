import React, { type ReactElement } from "react"

import ButtonCustom from "../../../../components/ButtonCustom"

import { useTranslation } from "react-i18next"

import { TRANSLATION_INDEX_CUSTOMER_PROSPECT, TRANSLATION_INDEX_GLOBAL } from "../../../../config/translation"
import { Container, GroupButtom } from "./_styles"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { ACTIONS } from "../../../../utils/constants"

import "react-toastify/dist/ReactToastify.css"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"
import { type IProfile } from "../../../../interfaces/IProfile"
import { withFormik } from "formik"
import { addUpdateScheme, deleteScheme } from "../../_schema"
import { useGlobalContext } from "../../../../context/GlobalContext"
import FormaddUpdate from "../../../QuotesTemplate/forms/addUpdate/FormaddUpdate"
import DataTable from "react-data-table-component"
import { customStylesContext } from "../../../../styles/_styleDatatable"

const FormData = ({
  data,
  isUserPending = false,
  loading = false,
  action,
  closeModal = () => {},
  accept = () => {},
  setFieldValue,
  values,
  touched,
  errors,
  setSubmitting,
  isSubmitting,
  handleChange,
  handleBlur,
  handleSubmit
}: IFormListProps): ReactElement => {
  const { t } = useTranslation()
  const { themeContext } = useGlobalContext()

  const paginationComponentOptions = {
    rowsPerPageText: t(TRANSLATION_INDEX_GLOBAL.ROWS_PER_PAGE),
    rangeSeparatorText: t(TRANSLATION_INDEX_GLOBAL.OF),
    selectAllRowsItem: true,
    selectAllRowsItemText: t(TRANSLATION_INDEX_GLOBAL.ALL)
  }

  const columns = [
    {
      name: `${t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.CUSTOMER)}s`,
      selector: (row: any) => row?.razonSocial,
      sortable: true
    }
  ]

  return (
    <Container onSubmit={handleSubmit}>
      {action === "CUSTOMER" ? (
        <DataTable
          data={data}
          theme="solarized"
          columns={columns}
          dense
          pagination
          paginationComponentOptions={paginationComponentOptions}
          striped
          customStyles={customStylesContext(themeContext)}
          defaultSortFieldId={1}
        />
      ) : (
        <FormaddUpdate noEdit={true} data={data} />
      )}
      <GroupButtom themeContext={themeContext}>
        <ButtonCustom
          onClick={closeModal}
          width="80px"
          type="button"
          label={t("global.cancel") ?? ""}
          background={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE[themeContext]}
          backgroundHover={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE_HOVER[themeContext]}
        />
      </GroupButtom>
    </Container>
  )
}

export default withFormik<IFormListProps, IProfile>({
  mapPropsToValues: ({ data }): IProfile => ({
    idPerfil: data?.idPerfil,
    descripcion: data?.descripcion ?? ""
  }),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: ({ action }) => (action === ACTIONS.DELETE.VALUE ? deleteScheme : addUpdateScheme),
  handleSubmit: (values, { setSubmitting, props: { accept } }): any => {
    if (accept != null) accept(values)
    setSubmitting(false)
  }
})(FormData)
