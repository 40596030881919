// SAGAS
import { put, takeLatest, call } from "redux-saga/effects"
import { type SagaIterator } from "redux-saga"
import * as types from "../types"

import {
  getExpensiveBAFailure,
  getExpensiveBASuccess,
  addExpensiveBASuccess,
  addExpensiveBAFailure,
  deleteExpensiveBASuccess,
  deleteExpensiveBAFailure,
  putExpensiveBASuccess,
  putExpensiveBAFailure
} from "../actions"

import { addExpensiveBA, deleteExpensiveBA, getExpensiveBA, putExpensiveBA } from "../services"

function* getUsetAppClientWorker(): SagaIterator {
  try {
    const { data } = yield call(getExpensiveBA)
    yield put(getExpensiveBASuccess(data))
  } catch (error) {
    yield put(getExpensiveBAFailure(error))
  }
}

function* addUserClientWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(addExpensiveBA, request.payload)
    yield put(addExpensiveBASuccess(data))
  } catch (error) {
    yield put(addExpensiveBAFailure(error))
  }
}

function* putUserClientWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(putExpensiveBA, request.payload)
    yield put(putExpensiveBASuccess(data))
  } catch (error) {
    yield put(putExpensiveBAFailure(error))
  }
}

function* deleteUserClientWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(deleteExpensiveBA, request.payload)
    yield put(deleteExpensiveBASuccess(data))
  } catch (error) {
    yield put(deleteExpensiveBAFailure(error))
  }
}

export function* listWatcher(): SagaIterator {
  yield takeLatest(types.EXPENSIVE_BA_GET_REQUEST, getUsetAppClientWorker)
  yield takeLatest(types.EXPENSIVE_BA_ADD_REQUEST, addUserClientWorker)
  yield takeLatest(types.EXPENSIVE_BA_PUT_REQUEST, putUserClientWorker)
  yield takeLatest(types.EXPENSIVE_BA_DELETE_REQUEST, deleteUserClientWorker)
}

export const sagas = [listWatcher]
