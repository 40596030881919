import { event } from "./_duck"

export const EVENT_GET_REQUEST: string = event.defineType("EVENT_GET_REQUEST")
export const EVENT_GET_SUCCESS: string = event.defineType("EVENT_GET_SUCCESS")
export const EVENT_GET_FAILURE: string = event.defineType("EVENT_GET_FAILURE")

export const EVENT_TYPE_GET_REQUEST: string = event.defineType("EVENT_TYPE_GET_REQUEST")
export const EVENT_TYPE_GET_SUCCESS: string = event.defineType("EVENT_TYPE_GET_SUCCESS")
export const EVENT_TYPE_GET_FAILURE: string = event.defineType("EVENT_TYPE_GET_FAILURE")

export const EVENT_CONTACT_GET_REQUEST: string = event.defineType("EVENT_CONTACT_GET_REQUEST")
export const EVENT_CONTACT_GET_SUCCESS: string = event.defineType("EVENT_CONTACT_GET_SUCCESS")
export const EVENT_CONTACT_GET_FAILURE: string = event.defineType("EVENT_CONTACT_GET_FAILURE")

export const EVENT_ADD_REQUEST: string = event.defineType("EVENT_ADD_REQUEST")
export const EVENT_ADD_SUCCESS: string = event.defineType("EVENT_ADD_SUCCESS")
export const EVENT_ADD_FAILURE: string = event.defineType("EVENT_ADD_FAILURE")

export const EVENT_PUT_REQUEST: string = event.defineType("EVENT_PUT_REQUEST")
export const EVENT_PUT_SUCCESS: string = event.defineType("EVENT_PUT_SUCCESS")
export const EVENT_PUT_FAILURE: string = event.defineType("EVENT_PUT_FAILURE")

export const EVENT_DELETE_REQUEST: string = event.defineType("EVENT_DELETE_REQUEST")
export const EVENT_DELETE_SUCCESS: string = event.defineType("EVENT_DELETE_SUCCESS")
export const EVENT_DELETE_FAILURE: string = event.defineType("EVENT_DELETE_FAILURE")

export const EVENT_CLEAR_ALL: string = event.defineType("EVENT_CLEAR_ALL")
export const EVENT_CLEAR_CONTACT: string = event.defineType("EVENT_CLEAR_CONTACT")
