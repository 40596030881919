/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import styled from "styled-components"
import { type IStyle } from "../../interfaces/IStyle"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"

export const Container = styled.div<IStyle>`
  position: relative;
  background-color: ${({ background }) => background};
  color: ${({ color }) => color};
  display: flex;
  font-size: 15px;
  font-weight: 400;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  padding-right: ${({ showPaddingRight }) => (showPaddingRight ? "20px" : "")};
  height: 30px;
  cursor: pointer;
`

export const ContainerSubMenu = styled.div`
  position: absolute;
  left: 0;
  top: 50px;
`

export const SubMenuItem = styled.div<IStyle>`
  padding: 10px;
  background-color: ${({ background }) => background};
  border: ${({ themeContext }) => GLOBAL_STYLES.HEADER.MENU.BORDER[themeContext]};
`
