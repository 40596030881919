import React from "react"
import { HashRouter } from "react-router-dom"

import HeaderLayout from "./layouts/Header"
import MainLayout from "./layouts/Main"
import { Container } from "./_style"
import { ToastContainer } from "react-toastify"

import Loader from "./components/Loader"
import { useGlobalContext } from "./context/GlobalContext"

const App = (): React.ReactElement => {
  const { loadingContext, themeContext } = useGlobalContext()
  return (
    <>
      <HashRouter>
        <Container themeContext={themeContext}>
          <HeaderLayout />
          <MainLayout />
        </Container>
      </HashRouter>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={`${themeContext === "DARK" ? "dark" : "light"}`}
      />
      {loadingContext === true && <Loader width="100px" />}
    </>
  )
}

export default App
