/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from "react"
import DatePicker from "react-datepicker"

import { Container, ContainerIcon, Label } from "./DatePickerCustom.styled"
import { type Props } from "./interfaces"
import "react-datepicker/dist/react-datepicker.css"
import { stylesConfig } from "./data"
import { FormHelperText } from "@mui/material"
import { GLOBAL_STYLES } from "../../../styles/GLOBAL_STYLE"
import { useTranslation } from "react-i18next"
import { useGlobalContext } from "../../../context/GlobalContext"
import IconSearch from "../../Icons/IconSearch"
import { enUS, es } from "date-fns/locale"
import { getCurrentLanguage } from "../../../utils/functions"
import { TRANSLATION_INDEX_GLOBAL } from "../../../config/translation"

export const DatePickerCustom: React.FC<Props> = ({
  showMonthYearPicker = false,
  dateFormat = "dd/MM/yyyy",
  label,
  endDate,
  setEndDate,
  isRange,
  disabled,
  value,
  setValue,
  marginLeft = false,
  marginRight = false,
  validError,
  errors,
  width = "100%",
  placeHolder,
  iconLeft,
  showTimeSelect = false,
  name,
  excludeDateIntervals = []
}) => {
  const { t } = useTranslation()
  const { themeContext } = useGlobalContext()

  const onChange = (dates): void => {
    const [start, end] = dates

    setValue(start)
    if (setEndDate != null) setEndDate(end)
  }

  return (
    <Container themeContext={themeContext} marginLeft={marginLeft} marginRight={marginRight} width={width}>
      <style>{stylesConfig()}</style>
      <Label themeContext={themeContext}>{label}</Label>
      <div
        style={{
          display: "flex",
          position: "relative",
          justifyContent: "flex-start",
          border: typeof errors === "string" ? "solid 1px red" : "",
          borderRadius: "5px"
        }}
      >
        {iconLeft && (
          <ContainerIcon
            themeContext={themeContext}
            iconLeft={iconLeft}
            borderRadius="5px"
            background={iconLeft && GLOBAL_STYLES.BACKGROUND.TYPE_6[themeContext]}
          >
            <IconSearch color="#495057" />
          </ContainerIcon>
        )}
        <DatePicker
          autoComplete="off"
          placeholderText={placeHolder}
          style={{ width }}
          wrapperClassName="date-picker"
          dateFormat={dateFormat}
          showMonthYearPicker={showMonthYearPicker}
          selected={value}
          onChange={isRange ? onChange : setValue}
          startDate={isRange ? value : null}
          endDate={isRange ? endDate : null}
          selectsRange={isRange}
          name={name}
          isClearable={!disabled}
          locale={getCurrentLanguage() === "en" ? enUS : es}
          timeFormat="p"
          timeCaption={t(TRANSLATION_INDEX_GLOBAL.TIME)}
          showTimeSelect={showTimeSelect}
          excludeDateIntervals={excludeDateIntervals}
        />
        {disabled && (
          <div
            style={{
              background: "black",
              opacity: "0.1",
              position: "absolute",
              width: "100%",
              height: "100%",
              borderRadius: "5px",
              cursor: "no-drop"
            }}
          ></div>
        )}
      </div>
      {validError && (
        <FormHelperText style={{ color: GLOBAL_STYLES.LABEL.TYPE_4[themeContext] }}>{t(errors ?? "")}</FormHelperText>
      )}
    </Container>
  )
}
