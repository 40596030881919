import React, { type ReactElement } from "react"
import { useTranslation } from "react-i18next"
import { STORAGE } from "../../../utils/localStorage"
import { ContainerLogout, Label } from "../_styles"
import { useNavigate } from "react-router-dom"
import { ROUTES } from "../../../utils/constants"
import { useDispatch } from "react-redux"
import { logout } from "../../../state/modules/login/actions"
import { useGlobalContext } from "../../../context/GlobalContext"

const Logout = (): ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { themeContext } = useGlobalContext()
  const logoutHandle = (): void => {
    dispatch(logout())
    STORAGE.remove(STORAGE.TOKEN)
    STORAGE.remove(STORAGE.NOTIFICATION)
    STORAGE.remove(STORAGE.CHANGE_PASSWORD)
    navigate(ROUTES.LOGIN.pathName)
  }

  return (
    <ContainerLogout themeContext={themeContext} onClick={logoutHandle}>
      <Label themeContext={themeContext}>{`${t("login.logout")}`}</Label>
    </ContainerLogout>
  )
}

export default Logout
