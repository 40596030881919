import { type AxiosPromise } from "axios"
import httpModule from "../../../../utils/http"

export const getProfile = async (): Promise<AxiosPromise<any>> => await httpModule.get(`/api/backoffice/v1/perfiles`)

export const addProfile = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/perfiles`, request)

export const putProfile = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.put(`/api/backoffice/v1/perfiles`, request)

export const deleteProfile = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.delete(`/api/backoffice/v1/perfiles/${request}`)
